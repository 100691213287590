import { useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { JAM_REFETCH_INTERVAL } from 'constants/encube';
import { useTreadCreator } from 'hooks/useThreadCreator';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import { selectContributors, selectIsContributor } from 'modules/contributors/contributorsSlice';
import { useEncubeStatus } from 'modules/encube';
import { useModal } from 'modules/modals/ModalProvider';
import { EditThread, RemoveThread } from 'modules/threads/modals';
import { useGetThreadEncubeJamListQuery } from 'modules/threads/threadsApi';
import { selectThread } from 'modules/threads/threadsSlice';
import { IThreadsResult } from 'modules/threads/types';
import React, { useMemo, useState } from 'react';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import devices from 'theme/devices';
import { EThreadStatuses } from 'types';
import {
  AddButton,
  Avatar,
  Button,
  DropDownAction,
  Group,
  Icon,
  MemberList,
  Modal,
  PriorityBadge,
  Stack,
  StatusBadge,
  Text,
  Title,
  Tooltip,
} from 'ui';
import { ThreadLiveJams } from 'ui/threadLiveJams';
import { getFullName } from 'utils/helpers';
import PrivateThreadIndicator from './privateThreadIndicator';

const IconButton = styled(Button)`
  padding: 8px;
`;

const AdaptiveWrapper = styled(Stack)`
  position: relative;
  z-index: 10;
  @media screen and ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

interface IProps {
  isAdmin?: boolean;
  isJoinRequestFetching: boolean;
  handleRequestToJoinThread: () => Promise<void>;
}

const Header = ({ isAdmin = false, isJoinRequestFetching, handleRequestToJoinThread }: IProps) => {
  const { open } = useModal();
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const { id } = useParams();
  const {
    status,
    priority,
    creator,
    name,
    updatedAt,
    isRequestedToJoin,
    isContributor,
    isPrivate,
  } = useAppSelector(selectThread(id as string));
  const { data, isLoading } = useGetThreadEncubeJamListQuery(
    { id: id as string },
    { pollingInterval: isEncubeFunctionalAvailable ? JAM_REFETCH_INTERVAL : undefined }
  );
  const { id: currentUserId } = useAppSelector(selectUserInfo);
  const isUserContributor = useAppSelector(selectIsContributor(currentUserId));
  const cypressAttribute = 'thread-header-details';
  const contributors = useAppSelector(selectContributors);
  const memberList = useMemo(() => {
    return contributors.map((member) => {
      const { firstName, lastName, avatar } = member.user;
      return { firstName, lastName, avatar, id: member.id };
    });
  }, [contributors]);

  const { isUserThreadCreator } = useTreadCreator();

  const threadLiveJams = data || [];

  const hasThreadLiveJams = threadLiveJams.length > 0 && isEncubeFunctionalAvailable;

  const toggleDeleteModal = () => {
    open({
      variant: 'center',
      contentLabel: 'Delete thread',
      name: 'Delete Thread',
      modal: 'deleteThread',
      context: { threadId: id },
      id: 'modal-remove-thread',
    });
  };

  const toggleEditModal = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'edit thread',
      name: 'Edit Thread',
      modal: 'editThread',
      context: { threadId: id },
      id: 'modal-edit-thread',
    });
  };

  const addContributor = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'add contributor',
      name: 'Add Contributors',
      modal: 'addContributors',
      context: {
        threadId: id,
      },
      id: 'modal-add-contributor',
    });
  };

  const handleLeaveThread = () => {
    open({
      variant: 'center',
      contentLabel: 'Leave thread',
      name: 'Leave thread',
      modal: 'leaveThread',
      context: { threadName: name, threadId: id },
    });
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/thread/${id}`);
      showSuccessMessage('Thread link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };

  const actions = useMemo(
    () => [
      {
        id: 'copy thread link',
        title: 'Copy link',
        action: handleCopyLink,
        icon: <Icon size="small" icon="CopyLinkIcon" />,
      },
      {
        id: 'edit',
        title: 'Edit',
        action: toggleEditModal,
        icon: <Icon size="small" icon="PencilIcon" />,
      },
      {
        id: 'delete',
        title: 'Delete',
        action: toggleDeleteModal,
        icon: <Icon size="small" icon="BinIcon" />,
      },
    ],
    []
  );

  const isArchived = EThreadStatuses.ARCHIVE === status;
  const creatorName = getFullName(creator);
  const threadCreatedByThisUser = creator.id === currentUserId;

  return (
    <Group
      style={{ paddingBottom: 10 }}
      fluid
      justify="space-between"
      align="center"
      data-cy="thread-header-group"
    >
      <AdaptiveWrapper gap="15px">
        <AdaptiveWrapper gap="15px">
          <Stack>
            <Group>
              <Title
                weight="700"
                heading="h5"
                data-cy={cypressAttribute && `${cypressAttribute}-heading`}
              >
                {name}
              </Title>
            </Group>
            <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-update-group`}>
              <Text size="xs" color={colors.gray1}>
                Last updated on
              </Text>
              <Text
                size="xs"
                color={colors.gray1}
                cypressAttribute={cypressAttribute && `${cypressAttribute}-update-date`}
              >
                <Moment format="MM/DD/YYYY" date={updatedAt} />
              </Text>
            </Group>
          </Stack>

          {isPrivate && (
            <Tooltip data={<PrivateThreadIndicator isPrivate={isPrivate} />}>
              <Icon icon="StrikeEyeIcon" size="medium" stroke="none" />
            </Tooltip>
          )}

          <Group data-cy={cypressAttribute && `${cypressAttribute}-members-group`}>
            <MemberList list={memberList} />
            {!isArchived && (
              <AccessControl permissions={[EPermissions.ADD_CONTRIBUTORS]} threadId={id as string}>
                <AddButton onClick={addContributor} cypressAttribute="add-thread-member-btn" />
              </AccessControl>
            )}
          </Group>
        </AdaptiveWrapper>
      </AdaptiveWrapper>

      <Group gap="16px" align="center">
        <Group gap="16px" align="center">
          {hasThreadLiveJams && <ThreadLiveJams jams={threadLiveJams} loading={isLoading} />}
          <StatusBadge status={status} cypressAttribute="thread-header-status" />
          <PriorityBadge priority={priority} cypressAttribute="thread-header-priority" />
          <Button
            leftIcon={<Avatar src={creator?.avatar} size="small" />}
            size="sm"
            color={colors.dark6}
            cypressAttribute="thread-header-creator-btn"
          >
            {creatorName}
          </Button>
        </Group>

        {!threadCreatedByThisUser && !isAdmin && !isContributor && (
          <Tooltip
            data="You have requested to join this thread."
            mode="dark"
            position="left"
            width="md"
            cypressAttribute="chat-message-content-date-tooltip"
            isVisible={isRequestedToJoin}
          >
            <Button
              variant="filled"
              color={colors.dark2}
              onClick={isRequestedToJoin ? null : handleRequestToJoinThread}
              disabled={isJoinRequestFetching || isRequestedToJoin}
            >
              {isRequestedToJoin ? (
                <Icon icon="PendingRequestIcon" size="small" />
              ) : (
                <>
                  <Icon size="small" icon="UserWithPlus" stroke="white" />
                  Request to Join
                </>
              )}
            </Button>
          </Tooltip>
        )}

        <AccessControl
          permissions={[EPermissions.DELETE_THREAD, EPermissions.EDIT_THREAD]}
          threadId={id as string}
        >
          <IconButton onClick={() => null} color={colors.dark2}>
            <DropDownAction actions={actions} cypressAttribute="thread-dropdown" />
          </IconButton>
        </AccessControl>
        {!isUserThreadCreator() && isUserContributor && (
          <Button
            variant="plain"
            leftIcon={<Icon icon="ExitIcon" style={{ width: 18, height: 18 }} path={colors.red} />}
            onClick={handleLeaveThread}
          >
            <Text weight="400" size="sm">
              Leave thread
            </Text>
          </Button>
        )}
      </Group>
    </Group>
  );
};

export default Header;
