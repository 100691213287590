import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Group, ReactSelect, Stack, Text, Title } from 'ui';
import Events from 'modules/events';
import { TReactSelectOption } from 'types';
import { useGetAllEventsQuery } from 'modules/events/eventsApi';
import { Virtuoso } from 'react-virtuoso';
import EventCard from 'modules/events/components/EventCard';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import colors from 'theme/colors';
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import { EEventStatus } from 'constants/events';
import { IEventResponse } from 'modules/events/types';
import { generateShortUUID } from 'utils/helpers';
import * as Layout from './Layout';

interface IProps {
  cypressAttribute?: string;
}

enum EDashboardEventsValues {
  ALL_EVENTS = 'ALL_EVENTS',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  LATE = 'LATE',
}

/**
 * List of events that is intended to be displayed on Dashboard only.
 */
const DashboardEvents = ({ cypressAttribute }: IProps) => {
  const { data, isLoading } = useGetAllEventsQuery(null);
  const { id: userId } = useAppSelector(selectUserInfo);

  const eventsOptions: TReactSelectOption[] = [
    {
      label: 'All Events',
      value: EDashboardEventsValues.ALL_EVENTS,
    },
    {
      label: 'Completed',
      value: EDashboardEventsValues.COMPLETED,
    },
    {
      label: 'In Progress',
      value: EDashboardEventsValues.IN_PROGRESS,
    },
    {
      label: 'Not Started',
      value: EDashboardEventsValues.NOT_STARTED,
    },
    {
      label: 'Late',
      value: EDashboardEventsValues.LATE,
    },
  ];

  const [selectedOptionValue, setSelectedOptionValue] = useState(EDashboardEventsValues.ALL_EVENTS);

  // const [filteredEvents, setFilteredEvents] = useState(data?.results);

  // ⚠️ TODO: Move this filter query to the backend
  const filteredEvents = useMemo(() => {
    let result = data?.results;

    // Filter based on selected value which should happen to be filter value.
    if (
      [
        EDashboardEventsValues.COMPLETED,
        EDashboardEventsValues.IN_PROGRESS,
        EDashboardEventsValues.LATE,
        EDashboardEventsValues.NOT_STARTED,
      ].includes(selectedOptionValue)
    ) {
      result = result?.filter(
        (event) => event.status === (selectedOptionValue as unknown as EEventStatus)
      );
    }

    result = result?.filter((event) => {
      return event.assignees?.some((assignee) => assignee.id === userId);
    });

    return result;
  }, [data?.results, selectedOptionValue]);
  // ⚠️ End of TODO

  return (
    <Layout.DropdownSelector
      id="dashboard-events-container"
      background={colors.dark1}
      data-cy={cypressAttribute && `${cypressAttribute}-data`}
      height="auto"
    >
      <Group justify="space-between" align="start">
        <Title heading="h5">Events</Title>
        <ReactSelect
          id={cypressAttribute && `${cypressAttribute}-dropdown`}
          isMulti={false}
          defaultValue={eventsOptions[0]}
          isSearchable={false}
          options={eventsOptions}
          onChange={({ value }) => setSelectedOptionValue(value)}
        />
      </Group>
      <Layout.DropdownSelectorContent
        overflow="hidden"
        data-cy={cypressAttribute && `${cypressAttribute}-data-id-${selectedOptionValue}`}
      >
        {filteredEvents?.map((event: IEventResponse) => (
          <EventCard key={`event-${generateShortUUID()}`} {...event} showLinkToOriginThread />
        ))}
      </Layout.DropdownSelectorContent>
    </Layout.DropdownSelector>
  );
};

export default DashboardEvents;
