import styled, { CSSProperties } from 'styled-components';
import { Button } from 'ui';
import colors from 'theme/colors';
import { IDropdownSelectorContentProps } from '../types';

export const Main = styled.main`
  padding: 24px 0 31px;
  background-color: ${colors.neutralBlue100};
`;

export const DashboardContainer = styled.div<{
  hideOverflow?: boolean;
  height?: CSSProperties['height'];
  maxHeight?: CSSProperties['maxHeight'];
}>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  wrap: wrap;
  flex-wrap: nowrap;
  width: ${({ height }) => height || '100%'};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  height: 100%
  padding: 0;
  margin: 0;
  overflow: ${({ hideOverflow = true }) => (hideOverflow ? 'hidden' : 'auto')};
`;

export const ThreadButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 151px;
  height: 40px;
  padding: 8px 18px 8px 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const TabsContainer = styled.div`
  position: relative;
  margin-top: 24px;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  background: ${colors.dark1};
  border-radius: 8px;
`;

export const SwitchContainer = styled.div`
  position: absolute;
  right: 25px;
  z-index: 2;
`;

/**
 * Simple component that adds CSS property of `flex: 1`.
 */
export const FlexItem = styled.div<{ overflow?: boolean }>`
  flex: 1;
  overflow: ${({ overflow = false }) => (overflow ? 'auto' : 'unset')};
  height: 100%;
`;

export const DropdownSelector = styled.div<{
  background?: CSSProperties['background'];
  height?: CSSProperties['height'];
}>`
  position: relative;
  height: ${({ height }) => height || '100%'};
  padding: 20px;
  background: ${({ background }) => background || colors.dark1};
`;

export const DropdownSelectorContent = styled.div<IDropdownSelectorContentProps>`
  position: relative;
  margin: 18px 0;
  padding-top: 5px;
  height: ${({ height }) => height || '100%'};
  overflow: ${({ overflow }) => overflow || 'auto'};
`;
