import styled from 'styled-components';

export const DropdownTrigger = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark5};
  font-size: 0;
  line-height: 0;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark7};
  }
`;
