import { api } from 'app/api';

const featureApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFeatures: build.query({
      query: () => ({
        url: `/sites/features/`,
      }),
    }),
  }),
});

export const { useGetFeaturesQuery } = featureApi;
export default featureApi;
