import React, { FC, useMemo } from 'react';
import { IEncubeJam } from 'modules/liveJams/types';
import { JamListHolder } from './JamListLayout';
import { JamItem } from './JamItem';
import { JamItemEmpty } from './JamItemLayout';
import { JamItemSkeleton } from './JamItemSkeleton';

interface IJamListProps {
  /**
   * List of the live jams for this thread.
   */
  jams: IEncubeJam[];
  /**
   * Flag that indicates whether jams are loading.
   * @default false
   */
  loading: boolean;
}

export const JamList: FC<IJamListProps> = (props) => {
  const { jams, loading } = props;
  const isEmpty: boolean = useMemo(() => jams.length === 0, [jams]);
  return (
    <JamListHolder data-cy="jam-list">
      {loading && <JamItemSkeleton />}
      {isEmpty && !loading && (
        <JamItemEmpty data-cy="jam-list-item-empty">
          There no live Jams for this thread
        </JamItemEmpty>
      )}
      {!isEmpty &&
        !loading &&
        jams.map((jam) => (
          <JamItem
            key={jam.id}
            avatar={jam.creator.avatar || ''}
            fileLink={jam.fileVersion.encubeUrl || ''}
            fileName={jam.reference.name}
            fileVersion={jam.fileVersion.version.toString()}
            startedAt={jam.createdAt}
            sender={jam.creator}
          />
        ))}
    </JamListHolder>
  );
};
