import { api } from 'app/api';
import { IEncubePing } from '../types';

export const encubePingApi = api.injectEndpoints({
  endpoints: (build) => ({
    pingEncube: build.query<IEncubePing, null>({
      query: () => `/encube/ping/`,
      providesTags: ['encube'],
    }),
  }),
});

export const { usePingEncubeQuery } = encubePingApi;
