import React, { forwardRef, Ref, useRef } from 'react';
import styled from 'styled-components';
import { StyledLabel } from 'layout/App';
import { Stack, Text } from 'ui';
import { mergeRefs } from 'utils/helpers';
import { AiOutlineClose } from 'react-icons/ai';
import colors from 'theme/colors';
import { StyledInput } from './Layout';
import { ITextInputProps } from './types';

const StyledIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
`;

const TextInput = forwardRef(
  (
    {
      label,
      error,
      placeholder,
      required,
      value,
      onChange,
      onClearTextInput,
      name,
      type,
      icon,
      defaultValue,
      isSearch,
      disabled = false,
      register = () => ({ ref: null }),
      ...props
    }: ITextInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { ref: registerRef, ...rest } = register(name);

    const internalRef = useRef<HTMLInputElement | null>(null);
    const containsValue = internalRef.current && !!internalRef.current.value.length;

    return (
      <Stack fluid>
        {label && (
          <StyledLabel htmlFor={name}>
            {label}
            {required && <span>*</span>}
          </StyledLabel>
        )}
        <div style={{ position: 'relative' }}>
          <StyledInput
            id={name}
            name={name}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            disabled={disabled}
            error={error}
            {...rest}
            {...props}
            ref={mergeRefs([internalRef, ref, registerRef])}
          />
          {icon && <StyledIcon>{icon}</StyledIcon>}
          {isSearch && containsValue && (
            <StyledIcon
              id="clearable"
              style={{ cursor: 'pointer' }}
              role="button"
              onClick={onClearTextInput}
            >
              <AiOutlineClose color={colors.gray} />
            </StyledIcon>
          )}
        </div>
        {error && (
          <Text size="xs" color="red" style={{ marginTop: '5px' }}>
            {error.message}
          </Text>
        )}
      </Stack>
    );
  }
);

export default TextInput;
