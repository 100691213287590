import React, { useState, useRef } from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { useOnClickOutside } from 'hooks';
import { Text, Icon, Stack, Group } from 'ui';
import { EVENTS_ICON_MAP } from 'ui/eventBadge/EventBadge';
import { IRelatedEvent } from 'modules/relatedThreads/types';
import * as Layout from './Layout';

interface ICreateEventProps {
  events: IRelatedEvent[];
  onChange: (option: IRelatedEvent) => void;
}

export const SelectEvent: React.FC<ICreateEventProps> = ({ events, onChange }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IRelatedEvent | null>(null);

  const toggle = () => setIsOpen(!isOpen);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleClick = (option: IRelatedEvent) => () => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange?.(option);
  };

  return (
    <Layout.DropdownContainer ref={dropdownRef}>
      <Layout.DropdownTitle onClick={toggle}>
        <Icon icon="ChevronDownIcon" size="tiny" rotate={isOpen ? '180deg' : '360deg'} />
        {selectedOption?.type ? (
          <Group align="center" gap="5px">
            {EVENTS_ICON_MAP[selectedOption.type]}
            <Text>{selectedOption.name}</Text>
          </Group>
        ) : (
          <Group align="center">
            <Text>Select Event</Text>
          </Group>
        )}
      </Layout.DropdownTitle>
      {isOpen && (
        <Layout.Dropdown>
          <Layout.DropdownList>
            {events?.length > 0 ? (
              <>
                {events.map((event) => (
                  <Layout.DropdownOption key={event.id} onClick={handleClick(event)}>
                    {EVENTS_ICON_MAP[event.type]}
                    <Stack gap="5px">
                      <Text color={colors.gray2} size="sm" cut={47}>
                        {event.name}
                      </Text>
                      <Text color={colors.gray4} size="xs">
                        <Moment date={event.dueDate} format="D MMM YYYY, hh:mm" />
                      </Text>
                    </Stack>
                  </Layout.DropdownOption>
                ))}
              </>
            ) : (
              <Text>Events not found in this thread...</Text>
            )}
          </Layout.DropdownList>
        </Layout.Dropdown>
      )}
    </Layout.DropdownContainer>
  );
};

export default SelectEvent;
