import React, { FC, useMemo } from 'react';
import Moment from 'react-moment';
import { Group, Icon, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { IMessageResponse } from 'modules/chat/types';
import { getFullName } from 'utils/helpers';
import { useEncubeStatus } from 'modules/encube';
import { EncubeLink, FileLink } from './Layout';
import * as ChatLayout from '../../Layout';

interface IMessageData {
  message: string;
  linkUrl: string | null;
  fileName: string | null;
  fileUrl: string | null;
}

export const MessageContent: FC<IMessageResponse> = (props) => {
  const { creator, createdAt, text = '' } = props;

  const { isEncubeFunctionalAvailable } = useEncubeStatus();

  const messageData: IMessageData = useMemo(() => {
    const { blocks = [] } = JSON.parse(text) || {};
    const [parsed] = blocks;

    return {
      message: parsed?.text || '',
      linkUrl: parsed?.encube_deeplink || null,
      fileName: parsed?.reference_versioned_file?.name || null,
      fileUrl: parsed?.reference_versioned_file?.url || null,
    };
  }, [text]);

  return (
    <ChatLayout.MessageContent
      isEncubeMessage
      owner={false}
      isCopiedMessage={false}
      data-cy="chat-layout-message-content"
    >
      <ChatLayout.MessageHeader owner={false}>
        <Group>
          <Stack data-cy="chat-layout-message-content-stack">
            <Text
              align="left"
              size="sm"
              color={colors.orange}
              cypressAttribute="chat-layout-message-content-full-name"
            >
              {getFullName(creator)}
            </Text>
            {creator?.group && (
              <Text
                align="left"
                size="xs"
                color={colors.gray1}
                cypressAttribute="chat-layout-message-content-group"
              >
                {creator?.group}
              </Text>
            )}
          </Stack>
        </Group>
        <Text size="xs" color={colors.gray1} cypressAttribute="chat-layout-message-content-time">
          <Moment fromNow date={createdAt} />
        </Text>
      </ChatLayout.MessageHeader>
      <Text
        align="left"
        color={colors.white}
        size="sm"
        cypressAttribute="chat-layout-message-content-message"
      >
        {messageData.message}
      </Text>
      <Group justify="space-between" gap="24px" align="center">
        {messageData.fileName && messageData.fileUrl && (
          <FileLink
            href={messageData.fileUrl}
            target="_blank"
            rel="noreferrer"
            data-cy="chat-layout-message-content-file-link"
          >
            <Icon
              icon="AttachIcon"
              size="small"
              path={colors.green1}
              style={{ width: '20px', height: '20px' }}
            />
            {messageData.fileName}
          </FileLink>
        )}
        {isEncubeFunctionalAvailable && messageData.linkUrl && (
          <EncubeLink
            href={messageData.linkUrl}
            target="_blank"
            rel="noreferrer"
            data-cy="chat-layout-message-content-encube-link"
          >
            <Icon icon="EncubeLogoIcon" size="medium" />
            <Icon icon="ArrowRightIcon" size="small" />
          </EncubeLink>
        )}
      </Group>
    </ChatLayout.MessageContent>
  );
};
