import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetAll } from 'app/api';
import _keyBy from 'lodash/keyBy';
import { RootState } from 'app/store';
import { IPaginatedResponse } from 'types';
import { eventsApi } from './eventsApi';
import { IEventResponse, IEventsResult } from './types';

interface IEventsState {
  list: IEventsResult[];
  isLoading: boolean;
}

export const initialState: IEventsState = {
  list: [],
  isLoading: false,
};

export const loadAllEvents = createAsyncThunk('events/loadAllEvents', async (_, { dispatch }) => {
  const result = dispatch(eventsApi.endpoints.getAllEvents.initiate(null));
  result.unsubscribe();
  const response = await result;
  return response.data;
});

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addCase(loadAllEvents.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadAllEvents.fulfilled, (state) => {
        state.isLoading = false;
        return state;
      })
      .addMatcher(
        eventsApi.endpoints.getAllEvents.matchFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.list = payload.results;
          return state;
        }
      )
      .addMatcher(
        eventsApi.endpoints.getEvents.matchFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.list = payload.results;
          return state;
        }
      ),
});

export const selectAllEvents = (state: RootState) => state.events.list;
export const selectAllEventsById = (state: RootState) => _keyBy(state.events.list, 'id');
export const selectEventsIsLoading = (state: RootState) => state.events.isLoading;

export default eventsSlice.reducer;
