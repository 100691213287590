import React, { ReactElement, useMemo, useState } from 'react';
import { Group, ReactSelect, Title } from 'ui';
import { CSSProperties } from 'styled-components';
import { TReactSelectOption } from 'types';
import * as Layout from './Layout';
import { TDropdownSelectorOption } from '../types';

interface IProps {
  title?: string;
  options: TDropdownSelectorOption[];
  cypressAttribute: string;
  height?: CSSProperties['height'];
  children?: ReactElement | ReactElement[];
}

/**
 * Wraps the component in a selectable dropdown in which options can be passed into.
 * Indended to be used on Dashboard page only.
 */
const WrappedDropdownSelector = ({
  options,
  cypressAttribute,
  height,
  title,
  children,
}: IProps) => {
  const [activeOptionId, setActiveOptionId] = useState<number | string>(options[0].id);

  const transformedOptions: TReactSelectOption[] = useMemo(
    () =>
      options.map((option) => ({
        label: option.name,
        value: String(option.id),
      })),
    [options]
  );

  const activeOption = useMemo(
    () =>
      options.find(
        (option: TDropdownSelectorOption) => String(option.id) === String(activeOptionId)
      ),
    [activeOptionId, options]
  );

  return (
    <Layout.DropdownSelector height="auto" data-cy={cypressAttribute && `${cypressAttribute}-data`}>
      <Group justify="space-between">
        {title && <Title heading="h5">{title}</Title>}
        <ReactSelect
          id={cypressAttribute && `${cypressAttribute}-dropdown`}
          isMulti={false}
          defaultValue={transformedOptions[0]}
          isSearchable={false}
          options={transformedOptions}
          onChange={({ value }) => setActiveOptionId(value)}
        />
        {children}
      </Group>
      <Layout.DropdownSelectorContent
        data-cy={cypressAttribute && `${cypressAttribute}-data-id-${activeOptionId}`}
      >
        {activeOption?.content}
      </Layout.DropdownSelectorContent>
    </Layout.DropdownSelector>
  );
};

export default WrappedDropdownSelector;
