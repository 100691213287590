import { useAppDispatch } from 'app/hooks';
import { fixedBottomRightStyle } from 'modules/threadsBot/constants';
import { toggleThreadsBotViewExpanded } from 'modules/threadsBot/threadsBotSlice';
import React from 'react';
import Card from 'ui/card';
import Logo from 'ui/logo';

/**
 * @description Floating action card for collapsed ThreadsBot, clicking will
 * expand the full UI.
 */
const ThreadsBotCollapsedFloatingCard = () => {
  const dispatch = useAppDispatch();

  const toggleThreadsBotExpanded = () => {
    dispatch(toggleThreadsBotViewExpanded());
  };

  return (
    <Card
      cursorPointer
      borderRadius="20px 25px"
      width="fit-content"
      style={fixedBottomRightStyle}
      onClick={toggleThreadsBotExpanded}
      padding="15px 20px"
      height="fit-content"
    >
      <Logo />
    </Card>
  );
};

export default ThreadsBotCollapsedFloatingCard;
