import React from 'react';
import AnchorLink from 'ui/anchorLink';

const SupportModalContent = () => {
  return (
    <div>
      <p>
        Explore our comprehensive collection of{' '}
        <AnchorLink href="https://authentise.zendesk.com/hc/en-us/categories/18378893436820-Threads">
          Threads user articles
        </AnchorLink>{' '}
        to find answers to common queries.
      </p>
      <br />
      <p>
        Still can&apos;t find what you&apos;re looking for? Reach out to us directly via email at{' '}
        <AnchorLink href="mailto:threads@authentise.zendesk.com">
          threads@authentise.zendesk.com
        </AnchorLink>
        , and our dedicated team will get back to you as soon as possible.
      </p>
    </div>
  );
};

export default SupportModalContent;
