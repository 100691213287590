import React, { FC, useState } from 'react';
import { ITab } from 'ui/tabs/types';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Tabs, Stack, AddButton } from 'ui';
import { useParams } from 'react-router-dom';
import AccessControl from 'app/permissions/AccessControl';

interface IAction {
  [key: string | number]: () => void;
}

interface ITabGroupProps {
  tabs: ITab[];
  actions?: IAction;
  isArchived?: boolean;
  cypressAttribute?: string;
}

const TabsContainer = styled(Stack)`
  padding: 20px;
  width: 100%;
  height: 100%;
  background: ${colors.dark1};
  border-radius: 8px;
  position: relative;
  flex: 1;
`;

const Action = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`;

const TabGroup: FC<ITabGroupProps> = ({ tabs, actions, isArchived, cypressAttribute }) => {
  const [activeTabId, setActiveTabId] = useState(tabs[0].id);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { id } = useParams();

  const onTabsChange = (tabId: number | string, tab?: ITab) => {
    setActiveTabId(tabId);
    if (tab) {
      setActiveTab(tab);
    }
  };

  return (
    <TabsContainer data-cy={cypressAttribute}>
      <Tabs
        tabs={tabs}
        // FIXME: This is a HACK
        // Due to the layout of tabs (content is a child of the header, not a sibling)
        // We need to shrink content a little.
        height="calc(100% - 100px)"
        onChange={onTabsChange}
        cypressAttribute={cypressAttribute}
      />
      {actions && !isArchived && (
        <AccessControl permissions={activeTab.permissions} threadId={id as string}>
          <Action
            onClick={actions[activeTabId]}
            data-cy={cypressAttribute && `${cypressAttribute}-action`}
          >
            <AddButton data-cy={cypressAttribute && `${cypressAttribute}-action-btn`} />
          </Action>
        </AccessControl>
      )}
    </TabsContainer>
  );
};

export default TabGroup;
