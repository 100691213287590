import React, { FC, useMemo } from 'react';
import { Group, Icon, Title } from 'ui';
import JamsList from './JamsList';
import { IEncubeJam } from '../types';
import * as Layout from './Layout';

interface IProps {
  jamsCount: number;
  jams: IEncubeJam[];
}

const ActiveJamsContainer: FC<IProps> = ({ jamsCount, jams }) => {
  const headingText = useMemo(() => {
    return jamsCount > 0 ? `Live Jams (${jamsCount})` : 'Live Jams';
  }, [jamsCount]);

  return (
    <Layout.JamsContainer gap="15px">
      <Group gap="6px" align="center">
        <Icon size="medium" icon="JamListIcon" />
        <Title heading="h6" cypressAttribute="active-jams-container">
          {headingText}
        </Title>
      </Group>
      <JamsList jams={jams} />
    </Layout.JamsContainer>
  );
};

export default ActiveJamsContainer;
