import React, { FC, useMemo } from 'react';
import Group from 'ui/group';
import { IEncubeJam } from 'modules/liveJams/types';
import Icon from 'ui/icon';
import { PopupContentArrow, PopupContentHolder, PopupContentTitle } from './PopupContentLayout';
import { JamList } from './JamList';

interface IPopupContentProps {
  /**
   * List of the live jams for this thread.
   */
  jams: IEncubeJam[];
  /**
   * Flag that indicates whether jams are loading.
   * @default false
   */
  loading?: boolean;
  cypressAttribute?: string;
}

export const PopupContent: FC<IPopupContentProps> = (props) => {
  const { jams = [], loading = false, cypressAttribute } = props;

  const jamsCount: number = useMemo(() => jams.length, [jams]);

  return (
    <PopupContentHolder gap="20px" data-cy={cypressAttribute}>
      <PopupContentArrow />
      <Group
        gap="6px"
        align="center"
        justify="flex-start"
        data-cy={cypressAttribute && `${cypressAttribute}-group`}
      >
        <Icon size="medium" icon="JamListIcon" />
        <PopupContentTitle heading="h5" data-cy={cypressAttribute && `${cypressAttribute}-title`}>
          Live Jams for This Thread ({jamsCount})
        </PopupContentTitle>
      </Group>
      <JamList jams={jams} loading={loading} />
    </PopupContentHolder>
  );
};
