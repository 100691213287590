import { useAppDispatch, useAppSelector } from 'app/hooks';
import { THREAD_POLLING_INTERVAL } from 'constants/encube';
import routes from 'constants/routes';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { Annotations } from 'modules/EncubeAnnotations';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserRole } from 'modules/auth/userSlice';
import Chat from 'modules/chat';
import { reset, selectFilter } from 'modules/chat/chatSlice';
import { FocusProvider } from 'modules/chat/components/FocusContext';
import { EChatFilter } from 'modules/chat/types';
import { useRequestToJoinThreadMutation } from 'modules/contributors/contributorsApi';
import { resetState } from 'modules/contributors/contributorsSlice';
import { useEncubePing, useEncubeStatus } from 'modules/encube';
import { useModal } from 'modules/modals/ModalProvider';
import { subscribe, unsubscribe } from 'modules/stream/streamSlice';
import { useGetThreadQuery } from 'modules/threads/threadsApi';
import { selectThread } from 'modules/threads/threadsSlice';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import { EThreadStatuses, IError } from 'types';
import { Divider, Group, ScreenContent, ScreenLoader, Stack } from 'ui';
import { handleApiCall, isAdmin } from 'utils/helpers';
import { Header, TabGroup, ThreadTitle } from './components';
import { THREAD_TABS_GROUP } from './constants';

const Content = styled(Group)`
  padding: 20px 0 0;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 0;
  position: relative;
  z-index: 1;
`;

const Thread = () => {
  useEncubePing();
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const { handleSubscriptionPermission } = useSubscriptionPermissions();
  const [requestToJoinThreadAsContributor, { isLoading: isJoinRequestFetching }] =
    useRequestToJoinThreadMutation();
  const { id } = useParams();
  const [query] = useSearchParams();
  const requestJoinThreadInviteCode = query.get('join_request_id') || '';
  const {
    isLoading,
    error,
    data: threadData,
  } = useGetThreadQuery(
    { id: id as string },
    { pollingInterval: isEncubeFunctionalAvailable ? THREAD_POLLING_INTERVAL : undefined }
  );
  const selectedThread = useAppSelector(selectThread(id as string));
  const { status } = selectedThread;
  const userRole = useAppSelector(selectUserRole);
  const chatFilter = useAppSelector(selectFilter);
  const { open } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isArchivedThread = useMemo(() => EThreadStatuses.ARCHIVE === status, [status]);

  const isAnnotations = useMemo(
    () => chatFilter === EChatFilter.ENCUBE_EVENT || chatFilter === EChatFilter.PLAYABLE_EVENT,
    [chatFilter]
  );

  const onError = (err: IError['error']) => {
    if (err.status === 404) {
      showErrorMessage(NOTIFICATION.THREAD_NOT_FOUND);
    } else {
      showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
    }
    navigate(routes.dashboard);
  };

  const actionsTopGroup = useMemo(() => {
    return {
      1: () =>
        open({
          variant: 'fullRight',
          contentLabel: 'events',
          name: 'Create an Event',
          modal: 'createEvent',
          context: {
            threadId: id,
          },
          id: 'modal-create-event',
        }),
      2: () =>
        open({
          variant: 'fullRight',
          contentLabel: 'add contributor',
          name: 'Add Contributors',
          modal: 'addContributors',
          context: {
            threadId: id,
          },
          id: 'modal-add-contributors',
        }),
      3: () =>
        open({
          variant: 'fullRight',
          contentLabel: 'add reference',
          name: 'Add Reference',
          modal: 'addReference',
          context: {
            threadId: id,
          },
          id: 'modal-add-reference',
        }),
      4: () =>
        open({
          variant: 'fullRight',
          contentLabel: 'create resource',
          name: 'Add Resource',
          modal: 'createResource',
          context: {
            threadId: id,
          },
          id: 'modal-resource',
        }),
      5: () =>
        open({
          variant: 'fullRight',
          contentLabel: 'add related thread',
          name: 'Add Related Thread',
          modal: 'addRelatedThread',
          context: {
            threadId: id,
          },
          id: 'modal-add-related-thread',
        }),
    };
  }, []);

  useEffect(() => {
    dispatch(subscribe(id as string));
    return () => {
      dispatch(reset());
      dispatch(resetState());
      dispatch(unsubscribe());
    };
  }, []);

  useEffect(() => {
    if (requestJoinThreadInviteCode && threadData) {
      open({
        variant: 'center',
        contentLabel: 'contributor-pending-action',
        name: 'Contributor Pending Action',
        context: { threadData, inviteId: requestJoinThreadInviteCode },
        modal: 'contributorPendingActions',
      });
    }
  }, [requestJoinThreadInviteCode, threadData]);

  useEffect(() => {
    if (error) {
      onError(error as IError['error']);
    }
  }, [error]);

  if (isLoading) {
    return <ScreenLoader />;
  }

  const handleRequestToJoinThread = async () => {
    if (!id) return;

    const response = await requestToJoinThreadAsContributor({ thread: id });
    handleApiCall(
      response,
      (responseError) =>
        handleSubscriptionPermission(
          responseError,
          'An error occurred while requesting to join thread as contributor.'
        ),
      () => {
        showSuccessMessage('Request to join thread as contributor sent successfully.');
      }
    );
  };

  return (
    <ScreenContent>
      <Stack style={{ flex: 1 }} fullHeight>
        <Header
          isAdmin={isAdmin(userRole)}
          isJoinRequestFetching={isJoinRequestFetching}
          handleRequestToJoinThread={handleRequestToJoinThread}
        />
        <Divider color={colors.dark2} />
        <ThreadTitle isArchived={isArchivedThread} cypressAttribute="thread-title" />
        <Content gap="24px">
          <div style={{ flex: 3, minWidth: 0 }}>
            <FocusProvider>
              {isAnnotations ? (
                <Annotations annotationType={chatFilter} />
              ) : (
                <Chat isArchived={isArchivedThread} />
              )}
            </FocusProvider>
          </div>
          <Stack gap="24px" style={{ height: '100%', flex: 2, maxWidth: '560px' }}>
            <TabGroup
              tabs={THREAD_TABS_GROUP}
              actions={actionsTopGroup}
              isArchived={isArchivedThread}
              cypressAttribute="thread-top-tabs"
            />
          </Stack>
        </Content>
      </Stack>
    </ScreenContent>
  );
};

export default Thread;
