import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Icon } from 'ui';
import { EChatFilter } from 'modules/chat/types';
import { selectFilter } from 'modules/chat/chatSlice';
import { useEncubeAnnotationsPollingQuery } from '../api';
import { REFETCH_INTERVAL, SKELETON_COUNT } from '../helpers';
import { loadNextAnnotationsByLink, loadPrevAnnotationsByLink, selectAnnotations } from '../store';
import {
  AnnotationListScroller,
  AnnotationRefreshButton,
  AnnotationsContainer,
  LoadButtonContainer,
} from './AnnotationLayout';
import { AnnotationsSkeleton } from './AnnotationsSkeleton';
import { AnnotationsEmpty } from './AnnotationsEmpty';
import { AnnotationItem } from './AnnotationItem';

const EncubeAnnotations: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const chatFilter = useAppSelector(selectFilter);
  const [limit, setLimit] = useState<number>(10);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const isEncubeAnnotations = useMemo(() => chatFilter === EChatFilter.ENCUBE_EVENT, [chatFilter]);
  const { isLoading: isInitiallyLoading, refetch } = useEncubeAnnotationsPollingQuery(
    { id: id as string, limit },
    {
      refetchOnMountOrArgChange: false,
      pollingInterval: isEncubeAnnotations ? REFETCH_INTERVAL : undefined,
      skip: !isEncubeAnnotations,
    }
  );

  const { annotations, previous, next, isLoadingNext, isLoadingPrev } =
    useAppSelector(selectAnnotations);

  const hasPrevLink = useMemo(() => previous && previous?.length > 0, [previous]);
  const hasNextLink = useMemo(() => next && next?.length > 0, [next]);

  const isEmpty: boolean = useMemo(() => annotations.length === 0, [annotations]);
  const isLoading: boolean = useMemo(
    () => isInitiallyLoading || isRefetchLoading,
    [isInitiallyLoading, isRefetchLoading]
  );

  const refreshAnnotationsClickHandler = () => {
    setIsRefetchLoading(true);
    refetch().finally(() => setIsRefetchLoading(false));
  };

  const loadMore = () => {
    setLimit((prev) => prev + 10);
    dispatch(loadNextAnnotationsByLink({ link: next }));
  };

  const loadPrev = () => {
    setLimit((prev) => prev + 10);
    dispatch(loadPrevAnnotationsByLink({ link: previous }));
  };
  return (
    <AnnotationListScroller fullHeight justify="flex-start" data-cy="annotation-list-scroller">
      <AnnotationsContainer gap="35px" fluid fullHeight data-cy="annotations-container">
        {
          isLoading &&
            new Array(SKELETON_COUNT)
              .fill(null)
              .map((_, index) => <AnnotationsSkeleton key={index} />) // eslint-disable-line react/no-array-index-key
        }
        {isEmpty && !isLoading && (
          <AnnotationsEmpty onRefreshClick={refreshAnnotationsClickHandler} />
        )}
        {hasPrevLink && !isLoading && (
          <LoadButtonContainer isBottomButton={false} data-cy="load-btn-container">
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
              onClick={loadPrev}
              data-cy="annotation-load-previous-btn"
            >
              {isLoadingPrev ? 'Loading...' : 'Load Previous'}
            </AnnotationRefreshButton>
          </LoadButtonContainer>
        )}
        {isEncubeAnnotations &&
          !isEmpty &&
          !isLoading &&
          annotations.map((annotation) => (
            <AnnotationItem
              key={annotation.encubeId}
              id={annotation.id.toString()}
              authorAvatarSrc={annotation.creator.avatar}
              authorName={`${annotation.creator.firstName} ${annotation.creator.lastName}`}
              createdAt={annotation.createdAt}
              fileId={annotation.versionedFile.id}
              fileName={annotation.versionedFile.file}
              fileVersion={annotation.versionedFile.version.toString()}
              fileUrl={annotation.versionedFile.encubeUrl}
              comments={annotation.encubeComments}
            />
          ))}
        {hasNextLink && !isLoading && (
          <LoadButtonContainer isBottomButton data-cy="annotation-load-btn-container">
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
              onClick={loadMore}
              data-cy="annotation-load-more-btn"
            >
              {isLoadingNext ? 'Loading...' : 'Load More'}
            </AnnotationRefreshButton>
          </LoadButtonContainer>
        )}
      </AnnotationsContainer>
    </AnnotationListScroller>
  );
};

export default EncubeAnnotations;
