import React, { MutableRefObject, ReactElement, useRef } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import { useOnClickOutside } from '../../hooks';

interface IProps {
  trigger: MutableRefObject<HTMLElement | null>;
  children: ReactElement;
  isOpen: boolean;
  options?: Omit<Partial<Options>, 'modifiers'>;
  onClose: (evt: Event) => void;
  cypressAttribute?: string;
}

const Popup = ({ trigger, children, isOpen, options, onClose, cypressAttribute }: IProps) => {
  const popupRef = useRef(null);
  const { styles, attributes } = usePopper(trigger.current, popupRef.current, options);
  useOnClickOutside(popupRef, onClose);
  return (
    <div
      style={{ zIndex: 100, ...styles.popper }}
      ref={popupRef}
      {...attributes.popper}
      data-cy={cypressAttribute}
    >
      {isOpen ? children : null}
    </div>
  );
};
export default Popup;
