import styled from 'styled-components';
import colors from 'theme/colors';

export const GoogleFileLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: ${colors.blue};
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 4px 8px;
  background: rgba(20, 121, 240, 0.2);
`;
