import React, { FC } from 'react';
import { useEncubeStatus } from 'modules/encube';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import routes from 'constants/routes';
import { Container } from 'layout/App';
import { selectUserInfo, selectUserRole } from 'modules/auth/userSlice';
import * as Layout from 'modules/header/components/Layout';
import { Search } from 'modules/header/components/Search';
import { NavLink, useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import {
  isCurrentCustomPaidSubscriptionPlan,
  selectSubscriptionName,
} from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { Group, Icon, TagBadge } from 'ui';
import { ThreadButton } from 'pages/dashboard/components/Layout';
import { isAdmin, isSiteUser } from 'utils/helpers';
import { handleOpenCreateThreadModal } from 'modules/threads/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { setShowSupportModal } from 'modules/app/appSlice';
import Notification from '../notification';
import ProfileMenu from './components/ProfileMenu';
import LiveJams from '../liveJams';
import { Banner } from './components/Banner';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import Logo from '!file-loader!assets/icons/Logo_header.svg';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const { open } = useModal();

  const { firstName, lastName, isImpersonate, isSiteOwner } = useAppSelector(selectUserInfo);
  const userRole = useAppSelector(selectUserRole);
  const currentSubscriptionName = useAppSelector(selectSubscriptionName);
  const isCustomPaidSubscription = useAppSelector(isCurrentCustomPaidSubscriptionPlan);

  const handleUpgradeClick = () => {
    navigation(`${routes.subscriptionsPlans}/dashboard`);
  };

  const handleShowSupportModal = () => {
    dispatch(setShowSupportModal(true));
  };

  return (
    <Layout.Header id="app-header">
      <Banner />
      <Container>
        <Group align="center" justify="space-between" gap="20px" wrap="wrap">
          <Group align="center" gap="20px" style={{ flexBasis: '480px' }}>
            <NavLink to="/">
              <img src={Logo} alt="logo" width="48" height="48" />
            </NavLink>
            <Search
              data-cy="header-search"
              name="search"
              placeholder="Search..."
              aria-label="search"
            />
            {(isAdmin(userRole) || isSiteUser(userRole)) && (
              <Group align="center" gap="16px">
                <ThreadButton
                  onClick={() => handleOpenCreateThreadModal(open)}
                  data-cy="dashboard-new-thread"
                >
                  <Icon icon="PlusIcon" stroke="white" />
                  New thread
                </ThreadButton>
              </Group>
            )}
          </Group>
          <Group align="center" gap="20px">
            <FaRegQuestionCircle
              role="button"
              onClick={handleShowSupportModal}
              size={18}
              color={colors.gray1}
            />

            {isSiteOwner && currentSubscriptionName && !isCustomPaidSubscription && (
              <Layout.UpgradeButton onClick={handleUpgradeClick}>Upgrade</Layout.UpgradeButton>
            )}

            {isEncubeFunctionalAvailable && <LiveJams />}
            <Notification />

            <Group align="center" gap="8px" style={{ position: 'relative' }}>
              <span
                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                data-cy="header-user-info"
              >
                {isImpersonate && (
                  <TagBadge size="xs" color={colors.orange}>
                    Impersonating
                  </TagBadge>
                )}
                {firstName} {lastName}
              </span>
              <ProfileMenu />
            </Group>
          </Group>
        </Group>
      </Container>
    </Layout.Header>
  );
};

export default Header;
