import React, { useMemo } from 'react';
import { Group, Tooltip } from 'ui/index';
import TagBadge from 'ui/tagBadge';

interface ITags {
  offset?: number;
  tags: Array<string> | null;
  cypressAttribute?: string;
}

const Tags: React.FC<ITags> = ({ tags, offset = 3, cypressAttribute }) => {
  const tagsInView = useMemo(() => tags?.slice(0, offset), [tags, offset]);

  if (tags === null) return null;

  return (
    <Group gap="6px" data-cy={cypressAttribute} wrap="wrap">
      {tagsInView?.map((tag: string) => (
        <TagBadge key={tag} cypressAttribute={cypressAttribute && `${cypressAttribute}-tag-badge`}>
          {tag}
        </TagBadge>
      ))}

      {tags.length > offset ? (
        <Tooltip
          data={tags.slice(offset)}
          mode="dark"
          position="left"
          cypressAttribute={cypressAttribute && `${cypressAttribute}-tooltip`}
        >
          <TagBadge cypressAttribute={cypressAttribute && `${cypressAttribute}-tooltip-tag-badge`}>
            +{tags.slice(offset).length}
          </TagBadge>
        </Tooltip>
      ) : null}
    </Group>
  );
};

export default Tags;
