import { api } from 'app/api';
import { DEFAULT_LIMIT } from 'modules/threads/constants';
import { IUser } from 'types/user';
import { IEvent, IEventRequestParams, IEventResponse } from './types';
import { IId, IPaginatedResponse } from '../../types';

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createEvent: build.mutation<IEventResponse, IEvent>({
      query: (payload) => ({
        url: '/events/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['events'],
    }),
    getAllEvents: build.query<IPaginatedResponse<IEventResponse>, null>({
      query: () => ({
        url: `/events/?`,
      }),
    }),
    getEvents: build.query<IPaginatedResponse<IEventResponse>, IEventRequestParams>({
      query: ({ id, eventId, limit = DEFAULT_LIMIT }) => ({
        url: `/threads/${id}/events/?event_id=${eventId}&limit=${limit}`,
      }),
      providesTags: ['events'],
    }),
    getEvent: build.query<IEventResponse, IId>({
      query: ({ id }) => ({
        url: `/events/${id}/`,
      }),
      keepUnusedDataFor: 2,
    }),
    deleteEvent: build.mutation<unknown, number>({
      query: (id) => ({
        url: `/events/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['events'],
    }),
    editEvent: build.mutation<IEventResponse, IEvent>({
      query: ({ id, ...payload }) => ({
        url: `/events/${id}/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['events'],
    }),
    getEventAssignees: build.query<IPaginatedResponse<IUser>, IId>({
      query: ({ id }) => ({
        url: `threads/${id}/allowed-event-assignees/?limit=${DEFAULT_LIMIT}`,
      }),
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetAllEventsQuery,
  useGetEventsQuery,
  useDeleteEventMutation,
  useEditEventMutation,
  useGetEventAssigneesQuery,
  useGetEventQuery,
} = eventsApi;
