import styled from 'styled-components';
import colors from 'theme/colors';
import { Stack } from 'ui';

export const Notify = styled.div`
  position: relative;
  cursor: pointer;
`;

export const NotifyCount = styled.span`
  position: absolute;
  bottom: -4px;
  right: -6px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 16px;
  height: 16px;
  padding: 1px;
  font-size: 11px;
  border-radius: 50%;
  color: white;
  background-color: ${colors.red2};
`;

export const ViewWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark5};
  padding: 18px 18px 5px;
  max-width: 680px;
  max-height: 500px;
  border-radius: 8px 0px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  width: 100vw;
`;

export const Reply = styled.div`
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 4px 8px;
  border-radius: 4px;
`;

export const Blank = styled.div`
  height: 10px;
  width: 10px;
`;

export const EncubeButton = styled.a`
  background-color: ${colors.green1};
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: max-content;
  align-self: flex-end;
`;
