import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetAll } from 'app/api';
import { RootState } from 'app/store';
import { DEFAULT_PLYABLE_CREATOR } from 'modules/EncubeAnnotations/helpers';
import type { IPaginatedResponse } from 'types';
import type {
  IPlayableAnnotationRaw,
  IPlyableAnnotation,
  IPlyableReply,
} from 'modules/playable/types';
import { plyableAnnotationApi } from '../api';

export interface IPlyableAnnotationState {
  playableAnnotations: IPlyableAnnotation[];
  next: string | null;
  previous: string | null;
  isLoadingPrev: boolean;
  isLoadingNext: boolean;
}

export const initialState: IPlyableAnnotationState = {
  playableAnnotations: [],
  next: null,
  previous: null,
  isLoadingNext: false,
  isLoadingPrev: false,
};

const adaptAnnotationItem = (annotation: IPlayableAnnotationRaw): IPlyableAnnotation => {
  const firstReply: IPlyableReply = {
    message: annotation.message,
    plyableId: annotation.plyableId,
    createdAt: annotation.createdAt,
    creator: annotation.creator,
  };
  return {
    ...annotation,
    creator: annotation.creator || DEFAULT_PLYABLE_CREATOR,
    replies: [firstReply, ...annotation.replies],
  };
};

export const loadNextAnnotationsByLink = createAsyncThunk(
  'encube-annotations/loadNext',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(plyableAnnotationApi.endpoints.plyableAnnotations.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IPlayableAnnotationRaw>;
  }
);

export const loadPrevAnnotationsByLink = createAsyncThunk(
  'encube-annotations/loadPrev',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(plyableAnnotationApi.endpoints.plyableAnnotations.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IPlayableAnnotationRaw>;
  }
);

const adaptAnnotations = (annotations: IPlayableAnnotationRaw[]): IPlyableAnnotation[] =>
  annotations.map(adaptAnnotationItem);

const playableAnnotationsSlice = createSlice({
  name: 'playableAnnotations',
  initialState,
  reducers: {
    updatedAllPlayableAnnotations: (
      state,
      { payload }: PayloadAction<IPlayableAnnotationRaw[]>
    ) => ({
      ...state,
      playableAnnotations: adaptAnnotations(payload),
    }),
    updatePlayableAnnotation: (state, { payload }: PayloadAction<IPlayableAnnotationRaw>) => ({
      ...state,
      playableAnnotations: state.playableAnnotations.map((annotation) =>
        annotation.id === payload.id ? adaptAnnotationItem(payload) : annotation
      ),
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addCase(loadPrevAnnotationsByLink.pending, (state) => ({
        ...state,
        isLoadingPrev: true,
      }))
      .addCase(loadNextAnnotationsByLink.pending, (state) => ({
        ...state,
        isLoadingNext: true,
      }))
      .addCase(loadPrevAnnotationsByLink.fulfilled, (state, { payload }) => ({
        ...state,
        previous: payload.previous,
        playableAnnotations: [...adaptAnnotations(payload.results), ...state.playableAnnotations],
        isLoadingPrev: false,
      }))
      .addCase(loadNextAnnotationsByLink.fulfilled, (state, { payload }) => ({
        ...state,
        next: payload.next,
        isLoadingNext: false,
        playableAnnotations: [...state.playableAnnotations, ...adaptAnnotations(payload.results)],
      }))
      .addMatcher(
        plyableAnnotationApi.endpoints.plyableAnnotationsPolling.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          playableAnnotations: adaptAnnotations(payload.results),
          next: payload.next,
          previous: payload.previous,
          isLoadingNext: false,
          isLoadingPrev: false,
        })
      )
      .addMatcher(
        plyableAnnotationApi.endpoints.plyableAnnotationById.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          playableAnnotations: state.playableAnnotations.map((annotation) =>
            annotation.id === payload.id ? adaptAnnotationItem(payload) : annotation
          ),
        })
      ),
});

export const { updatedAllPlayableAnnotations, updatePlayableAnnotation } =
  playableAnnotationsSlice.actions;
export const playableAnnotationsReducer = playableAnnotationsSlice.reducer;

export const selectPlayableAnnotations = (state: RootState) => state.plyableAnnotations;
