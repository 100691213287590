export interface IThreadsBotChatResponse {
  data: {
    response: string;
    chatHistory: string;
  };
}

export enum EThreadsBotMessageSenderTypes {
  USER = 'USER',
  THREADS_BOT = 'THREADS_BOT',
}

export enum EThreadsBotMessageTypes {
  MESSAGE = 'MESSAGE',
  ERROR = 'ERROR',
}

export type TThreadsBotMessage = {
  type: EThreadsBotMessageTypes;
  id?: number | string;
  message: string;
  sender: EThreadsBotMessageSenderTypes;
};
