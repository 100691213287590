import { ReactElement, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectChannels, selectSocket } from './streamSlice';
import { selectUser } from '../auth/userSlice';
import { eventHandler } from './handlers';
import { EAction, ISocketPayload } from './types';

const Stream = ({ children }: { children: ReactElement }) => {
  const { url } = useAppSelector(selectSocket);
  const { token, userInfo } = useAppSelector(selectUser);
  const channels = useAppSelector(selectChannels);
  const connectUrl = `${url}/${userInfo.id}/${token}`;
  const dispatch = useAppDispatch();

  const { lastJsonMessage, sendMessage } = useWebSocket<ISocketPayload>(connectUrl, {
    share: true,
    onOpen: () => console.info('connected'),
    onClose: () => console.info('close'),
    retryOnError: true,
  });

  const getActionPayload = (action: EAction) =>
    JSON.stringify({
      action,
      data: { channels },
    });

  useEffect(() => {
    if (lastJsonMessage) {
      dispatch(eventHandler(lastJsonMessage));
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    sendMessage(getActionPayload(channels.length ? EAction.SUBSCRIBE : EAction.UNSUBSCRIBE));
  }, [channels]);
  return children;
};
export default Stream;
