import { api } from 'app/api';

export const impersonateApi = api.injectEndpoints({
  endpoints: (build) => ({
    impersonateUser: build.mutation<void, string>({
      query: (userUuid: string) => ({
        url: `/impersonate/${userUuid}/`,
        method: 'GET',
      }),
    }),
    endImpersonation: build.mutation<void, void>({
      query: () => ({
        url: '/impersonate/stop/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useImpersonateUserMutation, useEndImpersonationMutation } = impersonateApi;
