import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { selectUserInfo } from '../../modules/auth/userSlice';
import { selectOwner } from '../../modules/threads/threadsSlice';
import { selectIsContributor } from '../../modules/contributors/contributorsSlice';
import { getRole } from './utils';
import { ERoles } from './constants';

export const useCanContribute = () => {
  const { id: threadId } = useParams();
  const { id, userRole } = useAppSelector(selectUserInfo);
  const { id: ownerId } = useAppSelector(selectOwner(threadId as string));
  const isContributor = useAppSelector(selectIsContributor(id));
  const role = getRole(userRole, ownerId === id, isContributor);
  return [ERoles.OWNER, ERoles.CONTRIBUTOR].includes(role);
};
