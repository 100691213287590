import React, { FC, useMemo } from 'react';
import Moment from 'react-moment';
import { Group, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { IMessageResponse } from 'modules/chat/types';
import { AVAILABLE_INTEGRATIONS, REFERENCE_INTEGRATION_ICONS } from 'constants/integrations';
import * as ChatLayout from '../../Layout';
import { GoogleFileLink } from './Layout';

interface IGoogleMessageData {
  message: string;
  link: {
    url: string;
    name: string;
  };
  icons: [keyof typeof AVAILABLE_INTEGRATIONS];
  author: string;
}

const GoogleMessageContent: FC<IMessageResponse> = (props) => {
  const { createdAt, text = '', references } = props;

  const messageData: IGoogleMessageData = useMemo(() => {
    const { blocks = [] } = JSON.parse(text) || {};
    const [parsed] = blocks;

    return {
      message: parsed?.text || '',
      link: parsed?.link || null,
      icons: parsed?.icons || null,
      author: parsed?.author || '',
    };
  }, [text]);

  return (
    <ChatLayout.MessageContent
      isGoogleMessage
      owner={false}
      isCopiedMessage={false}
      data-cy="chat-layout-message-google"
    >
      <ChatLayout.MessageHeader owner={false}>
        <Group>
          <Stack data-cy="chat-layout-message-google-stack">
            <Text
              align="left"
              size="sm"
              color={colors.orange}
              cypressAttribute="chat-layout-message-google-author"
            >
              {messageData.author}
            </Text>
          </Stack>
        </Group>
        <Text size="xs" color={colors.gray1} cypressAttribute="chat-layout-message-google-time">
          Posted <Moment date={createdAt} format="L LT" />
        </Text>
      </ChatLayout.MessageHeader>
      <Text
        align="left"
        color={colors.white}
        size="sm"
        cypressAttribute="chat-layout-message-google-message"
      >
        {messageData.message}
      </Text>
      <Group justify="space-between" gap="24px" align="center">
        {messageData.link.name && messageData.link.url && (
          <GoogleFileLink
            href={messageData.link.url}
            target="_blank"
            rel="noreferrer"
            data-cy="chat-layout-message-google-link"
          >
            {REFERENCE_INTEGRATION_ICONS[messageData.icons[0]]}
            {messageData.link.name}
          </GoogleFileLink>
        )}
      </Group>
    </ChatLayout.MessageContent>
  );
};

export default GoogleMessageContent;
