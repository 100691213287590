import React, { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { Button, Dropdown, Group, ReactSelect, Spinner, Stack, TextArea, TextInput } from 'ui';
import colors from 'theme/colors';
import { EEvents, EEventStatus, EVENTS_OPTIONS, EVENTS_STATUS_OPTIONS } from 'constants/events';
import CalendarInput from 'ui/calendar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from 'utils/validation';
import { useModal } from 'modules/modals/ModalProvider';
import { getOption, handleApiCall } from 'utils/helpers';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { TReactSelectOption } from 'types';
import moment from 'moment';
import { useAppDispatch } from 'app/hooks';
import { pushAppEvent } from 'modules/app/appSlice';
import _ from 'lodash';
import { ECopilotSuggestionTypes, ICopilotSuggestion } from 'modules/copilot/types';
import { onCopilotEditEvent } from 'modules/copilot/utils';
import { EAppEventTypes } from 'modules/app/constants';
import { IEvent, IEventResponse } from '../types';
import {
  useCreateEventMutation,
  useEditEventMutation,
  useGetEventAssigneesQuery,
  useGetEventQuery,
} from '../eventsApi';

const validation = yup.object({
  name: validationSchema.stringRequired(),
  type: validationSchema.stringRequired(),
});

interface IProps {
  eventId?: number;
  threadId: number;
  messageId?: number;
  name?: string;
  type?: EEvents;
  description?: string;
  dueDate?: Date;
  status?: EEventStatus;
  copilotSuggestion?: ICopilotSuggestion;
}

const CreateEvent = ({
  eventId,
  threadId,
  messageId,
  name,
  type,
  description,
  status,
  dueDate,
  copilotSuggestion,
}: IProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IEvent>({
    resolver: yupResolver(validation),
    defaultValues: { name, type, description, status, dueDate },
  });

  const {
    data: eventData,
    isLoading: isEventDataLoading,
    isFetching,
  } = useGetEventQuery(
    { id: eventId?.toString() as string },
    { skip: !eventId, refetchOnMountOrArgChange: true }
  );

  const updatedFormValues = watch();

  const isFormValuesEqualToInitial =
    eventData &&
    _.entries(updatedFormValues).every(([key, value]) => {
      if (key === 'dueDate') {
        return moment(eventData.dueDate).isSame(value);
      }

      return value === eventData[key as keyof IEventResponse];
    });

  const { data: eventPossibleAssignees } = useGetEventAssigneesQuery({ id: threadId });

  const { close } = useModal();
  const dispatch = useAppDispatch();
  const [createEvent, createOptions] = useCreateEventMutation();
  const [editEvent, editOptions] = useEditEventMutation();

  const changeEventType = (value: string) => setValue('type', value as EEvents);
  const changeStatus = (value: string) => setValue('status', value as EEventStatus);
  const changeDate = (value: Date) => {
    setValue('dueDate', value);
  };

  const isLoading = createOptions.isLoading || editOptions.isLoading;

  const changeAssignee = (value: TReactSelectOption[]) => {
    setValue(
      'assignees',
      value.map((item) => item.value)
    );
  };

  const assignedToOptions: TReactSelectOption[] = useMemo(
    () =>
      eventPossibleAssignees?.results.map((contributor) => ({
        // Add email here to be able to search by email
        label: `${contributor.firstName} ${contributor.lastName} | ${contributor.email}`,
        value: contributor.id.toString(),
      })) || [{ label: '', value: '' }],
    [eventPossibleAssignees]
  );

  const getDefaultAssignees = useCallback(() => {
    if (eventData) {
      return eventData?.assignees?.map((assignee) => ({
        // Add email here to be able to search by email
        label: `${assignee.firstName} ${assignee.lastName} | ${assignee.email}`,
        value: assignee.id.toString(),
      }));
    }
    return [];
  }, [eventData]);

  const onCreate = async (values: Omit<IEvent, 'thread'>) => {
    const payload = { ...values, thread: threadId };

    if (messageId) {
      Object.assign(payload, { fromMessage: messageId });
    }
    const res = await createEvent(payload);
    handleApiCall<IEventResponse>(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        showSuccessMessage(NOTIFICATION.EVENT_CREATED);
        close();

        dispatch(
          pushAppEvent({
            type: EAppEventTypes.EVENT_CREATED,
            appEventPayload: res,
          })
        );
      }
    );
  };
  const onEdit = async (values: Omit<IEvent, 'thread'>) => {
    const payload = { ...values, thread: threadId, id: eventId };
    const res = await editEvent(payload);
    handleApiCall<IEventResponse>(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        showSuccessMessage(NOTIFICATION.EVENT_UPDATED);
        close();
      }
    );
  };

  useEffect(() => {
    // If editing, manually set the existing values here.
    if (eventData) {
      setValue('description', eventData.description);
      setValue('name', eventData.name);
      setValue('type', eventData.type);
      setValue('dueDate', moment(eventData.dueDate).toDate());
    } else {
      // If there is no eventData, then set default values.
      setValue('dueDate', moment().startOf('day').toDate());
    }
  }, [eventData]);

  if (isEventDataLoading || isFetching) {
    return (
      <Stack style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <Spinner color="white" size="small" />
      </Stack>
    );
  }

  /**
   * @description Determine whether request should be regular onEdit, onCreate, or if it is a Copilot suggestion.
   */
  const getEventSubmitType = async (values: IEvent) => {
    // There is a copilot suggestion and its suggestionType is MODIFY: Call its edit function from `copilot/utils.ts`
    if (copilotSuggestion && copilotSuggestion.suggestion_type === ECopilotSuggestionTypes.MODIFY) {
      return onCopilotEditEvent(values, copilotSuggestion, threadId, editEvent, close);
    }

    // There is existing data: edit this event.
    if (eventData) {
      return onEdit(values);
    }

    // There is no existing data: create new event.
    return onCreate(values);
  };

  return (
    <form
      onSubmit={handleSubmit(getEventSubmitType)}
      style={{ height: '100%' }}
      data-cy="modal-event-form"
    >
      <Stack justify="space-between" fullHeight>
        <Stack gap="25px">
          <Dropdown
            label="Event Type"
            name="type"
            badge="event"
            placeholder="Choose type"
            required
            defaultValue={
              (eventData && getOption(EVENTS_OPTIONS, eventData.type)) ||
              (type && getOption(EVENTS_OPTIONS, type))
            }
            bg={colors.dark2}
            options={EVENTS_OPTIONS}
            onClick={changeEventType}
            error={errors.type}
            cypressAttribute="modal-event-type"
          />
          <TextInput
            name="name"
            required
            register={register}
            label="Event Name"
            placeholder="Event Name"
            defaultValue={eventData?.name}
            error={errors.name}
            data-cy="modal-event-name"
          />
          <TextArea
            name="description"
            register={register}
            label="Description"
            placeholder="Description"
            resize="none"
            data-cy="modal-event-description"
          />
          <ReactSelect
            label="Assigned to"
            id="event-assignee-select"
            options={assignedToOptions}
            defaultValue={getDefaultAssignees()}
            placeholder="Select assignees"
            isCreatable={false}
            isMulti
            onChange={changeAssignee}
          />
          <Dropdown
            label="Status"
            name="status"
            badge="status"
            placeholder="Choose status"
            bg={colors.dark2}
            defaultValue={
              eventData
                ? getOption(EVENTS_STATUS_OPTIONS, eventData.status)
                : EVENTS_STATUS_OPTIONS[0]
            }
            options={EVENTS_STATUS_OPTIONS}
            onClick={changeStatus}
            error={errors.status}
            cypressAttribute="modal-event-status"
          />
          <CalendarInput
            value={eventData?.dueDate}
            onChange={changeDate}
            label="Due Date"
            cypressAttribute="modal-event-calendar"
          />
        </Stack>
        <Group justify="flex-end" gap="15px">
          <Button onClick={close} color={colors.dark2}>
            {isFormValuesEqualToInitial ? 'Close' : 'Cancel'}
          </Button>
          <Button type="submit" disabled={isLoading || isFormValuesEqualToInitial}>
            {isLoading ? <Spinner color="white" size="small" /> : 'Save'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default CreateEvent;
