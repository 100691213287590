/**
 * Slice for general app state.
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import featureApi from './featuresApi';
import { SAVED_STATE } from '../../constants';
import { IAppEvent } from './types';

interface IAppState {
  debugMode: boolean;
  showSupportModal: boolean;
  features: [];
  lastEvents: Array<IAppEvent>;
}

export const initialState: IAppState = {
  debugMode: loadStateFromLocalStorage(localStorage.getItem(SAVED_STATE))?.app?.debugMode || false,
  showSupportModal: false,
  features: [],
  lastEvents: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    /** Set debug mode state */
    setDebugMode: (state, { payload }) => {
      state.debugMode = payload;
      return state;
    },
    /** Set display for support modal. */
    setShowSupportModal: (state, { payload }) => {
      return { ...state, showSupportModal: payload };
    },

    /** Push a general app event. */
    pushAppEvent: (state, { payload }: PayloadAction<IAppEvent>) => {
      const updatedPayload = { ...payload };
      updatedPayload.timestamp = new Date();

      // Create a new stack array with the last 5 events
      const stack = [updatedPayload, ...state.lastEvents.slice(0, 4)];

      state.lastEvents = stack;

      return state;
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(featureApi.endpoints.getFeatures.matchFulfilled, (state, { payload }) => {
      state.features = payload;
      return state;
    }),
});

export const { setShowSupportModal, setDebugMode, pushAppEvent } = appSlice.actions;

export const selectAppState = (state: RootState) => state.app;
export const selectFeatures = (state: RootState) => state.app.features;
export const selectDebugModeEnabled = (state: RootState) => state.app.debugMode;
export const selectLastAppEvents = (state: RootState) => state.app.lastEvents;
export const selectLatestAppEvent = (state: RootState) =>
  state.app.lastEvents[state.app.lastEvents.length - 1];

export default appSlice.reducer;
