import React, { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'app/hooks';
import { selectThread } from 'modules/threads/threadsSlice';
import { ReactSelect } from 'ui';
import { useOnClickOutside } from 'hooks';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { createOption, handleApiCall } from 'utils/helpers';
import { useGetThreadTagsQuery, useUpdateThreadMutation } from 'modules/threads/threadsApi';
import { TReactSelectOption, IModalDefaultProps, EThreadParams } from 'types';
import { ICreateThreadMutation } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import _ from 'lodash';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';

export const AddTagsModal: React.FC<IModalDefaultProps> = ({ closeModal }) => {
  const { id } = useParams();
  const { tags, name } = useAppSelector(selectThread(id as string));
  const { data: tagOptions, isLoading: isTagsFetching } = useGetThreadTagsQuery('');
  const [updateThread] = useUpdateThreadMutation();

  const { handleSubscriptionPermission } = useSubscriptionPermissions();
  const ref = useRef(null);

  const { handleSubmit, setValue, getValues } = useForm<ICreateThreadMutation>({
    defaultValues: { name, tags },
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const threadTagOptions = useMemo(() => {
    return tagOptions?.map((tag: string) => createOption(tag)) || [];
  }, [isTagsFetching]);

  const threadTags = useMemo(() => {
    return tags?.map((tag: string) => createOption(tag)) || [];
  }, [tags]);

  const onSubmit = async (payload: ICreateThreadMutation) => {
    const res = await updateThread({ id: id as string, ...payload });
    handleApiCall(
      res,
      (error) =>
        handleSubscriptionPermission(
          error,
          'An error occurred while updating tags. Please try again.'
        ),
      () => {
        showSuccessMessage(NOTIFICATION.ADD_TAGS);
        closeModal();
      }
    );
  };

  useOnClickOutside(ref, () => {
    const newTags = getValues().tags;

    // If nothing has been changed, no need to send a HTTP request.
    if (!_.isEqual(newTags, tags)) {
      onSubmit(getValues());
    }

    closeModal();
  });

  const handleChange = (options: TReactSelectOption[]) => {
    const newTags = options?.map((option: TReactSelectOption) => option.value);
    setValue(EThreadParams.TAGS, newTags);
  };

  return (
    <Layout.AddTagsModal ref={ref} onSubmit={handleSubmit(onSubmit)} data-cy="modal-add-tags">
      <ReactSelect
        placeholder="Add Tags"
        isSearchable
        defaultValue={threadTags}
        options={threadTagOptions}
        onChange={handleChange}
        id="add-tags-dropdown"
      />
    </Layout.AddTagsModal>
  );
};

export default AddTagsModal;
