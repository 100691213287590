import React, { CSSProperties } from 'react';
import styled, { CSSProp } from 'styled-components';

interface IStackProps {
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
  fluid?: boolean;
  gap?: string;
  fullHeight?: boolean;
  maxHeight?: CSSProperties['maxHeight'];
  overflow?: boolean;
}

const Stack = styled.div<IStackProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'start'};
  align-items: ${({ align }) => align || 'stretch'};
  gap: ${({ gap }) => gap || '5px'};
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  overflow: ${({ overflow = false }) => (overflow ? 'auto' : 'unset')};
`;

export default Stack;
