import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import Icon from '../icon';

const StyledButton = styled.button`
  padding: 6px;
  background-color: ${colors.addBtnBg};
  border: none;
  outline: none;
  border-radius: 50%;
`;

interface IAddButton {
  onClick?: () => void;
  cypressAttribute?: string;
}

const AddButton: React.FC<IAddButton> = ({ onClick, cypressAttribute }) => {
  return (
    <StyledButton onClick={onClick} data-cy={cypressAttribute}>
      <Icon size="small" icon="PlusIcon" stroke={colors.orange} />
    </StyledButton>
  );
};

export default AddButton;
