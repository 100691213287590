export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

// eslint-disable-next-line no-underscore-dangle
window._env_ = window._env_ || process.env;
export const ENV = {
  // eslint-disable-next-line no-underscore-dangle
  REACT_APP_API_URL_IMAGES: window._env_.REACT_APP_API_URL_IMAGES,
  // eslint-disable-next-line no-underscore-dangle
  REACT_APP_WEBSOCKET_URL: window._env_.REACT_APP_WEBSOCKET_URL,
  // eslint-disable-next-line no-underscore-dangle
  REACT_APP_API_URL: window._env_.REACT_APP_API_URL,
  // eslint-disable-next-line no-underscore-dangle
  REACT_APP_SENTRY_DSN: window._env_.REACT_APP_SENTRY_DSN,
  // eslint-disable-next-line no-underscore-dangle
  REACT_APP_ENVIRONMENT: window._env_.REACT_APP_ENVIRONMENT,
};
