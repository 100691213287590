import React, { FC, useCallback, useRef, useState } from 'react';
import { useGetGeneralEncubeJamListQuery } from 'modules/threads/threadsApi';
import { useEncubeStatus } from 'modules/encube';
import { Icon, Popup } from 'ui';
import { JAM_REFETCH_INTERVAL } from 'constants/encube';
import ActiveJamsContainer from './components/ActiveJamsContainer';
import * as Layout from './components/Layout';

const LiveJams: FC = () => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();

  const jamRef = useRef(null);
  const [showActiveJams, setShowActiveJams] = useState(false);
  const openActiveJams = () => setShowActiveJams(true);
  const closeActiveJams = useCallback(() => setShowActiveJams(false), []);

  const { data } = useGetGeneralEncubeJamListQuery(undefined, {
    pollingInterval: isEncubeFunctionalAvailable ? JAM_REFETCH_INTERVAL : undefined,
  });

  const count = data?.count || 0;
  const activeJams = data?.results || [];

  return (
    <>
      <Layout.Jam ref={jamRef} onClick={openActiveJams}>
        {count > 0 && <Layout.ActiveJamCount>{count > 9 ? '9+' : count}</Layout.ActiveJamCount>}
        <Icon icon="JamIcon" size="medium" />
      </Layout.Jam>
      <Popup
        isOpen={showActiveJams}
        trigger={jamRef}
        onClose={closeActiveJams}
        options={{ placement: 'bottom-end' }}
      >
        <ActiveJamsContainer jamsCount={count} jams={activeJams} />
      </Popup>
    </>
  );
};

export default LiveJams;
