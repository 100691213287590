import React, { FC } from 'react';
import { Group, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { AVAILABLE_INTEGRATIONS, INTEGRATION_SUCCESSFUL } from 'constants/integrations';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-icon.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync-icon.svg';
import * as Layout from './Layout';

interface IProps {
  successIntegrationName: keyof typeof AVAILABLE_INTEGRATIONS;
}

export const IntegrationSuccessful: FC<IProps> = ({ successIntegrationName }) => {
  const { icon, integrationName, helpLink } = INTEGRATION_SUCCESSFUL[successIntegrationName];
  return (
    <Layout.SuccessIntegrationsMenuContainer gap="20px" data-cy="integration-successful-container">
      <Group
        gap="20px"
        align="center"
        justify="center"
        fluid
        data-cy="integration-successful-icon-group"
      >
        {icon}
        <SyncIcon width={44} height={44} />
        <SuccessIcon width={100} height={100} />
      </Group>
      <Stack gap="12px">
        <Text
          weight="600"
          align="center"
          size="lg"
          cypressAttribute="integration-successful-text-1"
        >
          Integrated successfully
        </Text>
        <Text
          weight="400"
          align="left"
          size="sm"
          style={{ lineHeight: 1.3, letterSpacing: 0.4 }}
          cypressAttribute="integration-successful-text-2"
        >
          Your files are now integrated with {integrationName}. For more information on usage,
          please see our{' '}
          <a
            href={helpLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: colors.brandSecondaryLight }}
            data-cy="integration-successful-text-link"
          >
            Help Section
          </a>
          .
        </Text>
      </Stack>
    </Layout.SuccessIntegrationsMenuContainer>
  );
};
