import styled from 'styled-components';

export const PopupTrigger = styled.button`
  position: relative;
  padding: 4px 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green1};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-family: Inter, sans-serif;
`;
