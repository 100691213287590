import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { truncateRawString } from 'utils/helpers';
import { MODAL_STATUS_LABELS_COLORS, MODAL_STATUS_LABELS_TEXT } from 'constants/labels';
import { Group, Icon, Stack, Title, Text, Button, Tooltip, TagBadge } from 'ui';
import { selectSelectedReference, setSelectedReference } from 'modules/threads/referencesSlice';
import colors from 'theme/colors';
import { useEncubeStatus } from 'modules/encube';
import { IReferences } from 'modules/threads/types';
import * as Layout from './Layout';

interface IProps {
  reference: IReferences;
  updateReference: () => void;
}

const SyncDataForm: FC<IProps> = ({ reference, updateReference }) => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const dispatch = useAppDispatch();
  const selectedEncubeFile = useAppSelector(selectSelectedReference);
  const isFileSelected = useMemo(() => selectedEncubeFile !== null, [selectedEncubeFile]);

  const resourceSyncUrl = useMemo(
    () =>
      selectedEncubeFile?.encubeUrl
        ? truncateRawString(selectedEncubeFile?.encubeUrl, 70)
        : 'File is not synced yet',
    [selectedEncubeFile?.encubeUrl]
  );

  const encubeSyncUrl = useMemo(
    () => (isFileSelected ? resourceSyncUrl : 'Please select a file to view its sync data.'),
    [resourceSyncUrl, isFileSelected]
  );

  const syncStatus = useMemo(
    () => (selectedEncubeFile?.encubeUrl ? 'Synced' : 'Syncing...'),
    [selectedEncubeFile?.encubeUrl]
  );

  const syncStatusColor = useMemo(
    () => (selectedEncubeFile?.encubeUrl ? colors.green1 : colors.orange),
    [selectedEncubeFile?.encubeUrl]
  );

  const encubeSyncStatus = useMemo(
    () => (isFileSelected ? syncStatus : 'Null'),
    [syncStatus, isFileSelected]
  );
  const encubeSyncStatusColor = useMemo(
    () => (isFileSelected ? syncStatusColor : colors.gray1),
    [isFileSelected, syncStatusColor]
  );

  const syncAtString = useMemo(
    () =>
      selectedEncubeFile?.syncedAt
        ? `Synced at: ${moment(selectedEncubeFile?.syncedAt).format('DD/MM/YYYY HH:mm')}`
        : `Not synced yet`,
    [selectedEncubeFile?.syncedAt]
  );

  const handleRefresh = async () => {
    updateReference();
  };

  useEffect(() => {
    if (reference) {
      const updatedEncubeFile = reference?.fileVersions?.find(
        (item) => item.id === selectedEncubeFile?.id
      );
      if (updatedEncubeFile) {
        dispatch(setSelectedReference(updatedEncubeFile));
      }
    }
  }, [reference, selectedEncubeFile]);

  const isVersionExist = !!selectedEncubeFile?.version;
  return (
    <Layout.AdditionalSectionContainer
      color={MODAL_STATUS_LABELS_COLORS[MODAL_STATUS_LABELS_TEXT.SYNCED]}
      data-cy="encube-sync-data-container"
    >
      <Stack gap="15px" data-cy="encube-sync-data-stack">
        <Group gap="8px" data-cy="encube-sync-data-group">
          <Title heading="h6" color={colors.gray2} cypressAttribute="encube-sync-data-title">
            Sync Data
          </Title>
          {isVersionExist ? (
            <TagBadge data-cy="encube-sync-data-badge">
              <Text weight="500">{`Version ${selectedEncubeFile?.version}`}</Text>
            </TagBadge>
          ) : null}
        </Group>
        <Stack gap="10px" data-cy="encube-sync-data-resource-url-stack">
          <Group gap="8px" data-cy="encube-sync-data-resource-url-group">
            <Icon size="small" icon="LinkIcon" path="#fff" />
            <Text weight="500">Synced Resource URL</Text>
          </Group>
          <Text weight="500" color={colors.gray1}>
            {encubeSyncUrl}
          </Text>
        </Stack>
        <Stack gap="10px" data-cy="encube-sync-data-sync-state-stack">
          <Group gap="8px" data-cy="encube-sync-data-sync-state-group">
            <Icon size="small" icon="EncubeSyncStateIcon" path="#fff" />
            <Text weight="500">Resource Sync State</Text>
          </Group>
          <Group style={{ alignItems: 'center' }} justify="space-between">
            <Group
              gap="8px"
              style={{ alignItems: 'center' }}
              data-cy="encube-sync-data-sync-state-status-group"
            >
              <Tooltip
                data={syncAtString}
                position="top"
                mode="dark"
                width="auto"
                cypressAttribute="encube-sync-data-sync-state-status-tooltip"
              >
                <Layout.SyncStatusContainer
                  color={encubeSyncStatusColor}
                  data-cy="encube-sync-data-sync-state-status-tooltip-text"
                >
                  <Text weight="500">
                    {isEncubeFunctionalAvailable ? encubeSyncStatus : 'Disabled'}
                  </Text>
                </Layout.SyncStatusContainer>
              </Tooltip>
              {isFileSelected && isEncubeFunctionalAvailable ? (
                <Button
                  variant="filled"
                  size="sm"
                  onClick={handleRefresh}
                  leftIcon={
                    <Icon
                      size="small"
                      style={{ width: '18px', height: '18px' }}
                      icon="RefreshEncubeSync"
                    />
                  }
                  color={colors.dark2}
                  cypressAttribute="encube-sync-data-sync-state-refresh-btn"
                >
                  Refresh
                </Button>
              ) : null}
            </Group>
            {isFileSelected && selectedEncubeFile?.encubeUrl?.length ? (
              <Layout.EncubeLink
                href={selectedEncubeFile?.encubeUrl as string}
                target="_blank"
                data-cy="encube-sync-data-sync-state-encube-link"
              >
                <Icon icon="EncubeLogoIcon" size="large" />
                <Icon icon="ArrowRightIcon" size="medium" />
              </Layout.EncubeLink>
            ) : null}
          </Group>
        </Stack>
      </Stack>
    </Layout.AdditionalSectionContainer>
  );
};

export default SyncDataForm;
