import React, { useState } from 'react';
import styled from 'styled-components';
import routes from 'constants/routes';
import * as yup from 'yup';
import devices, { mediaSize } from 'theme/devices';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { validationSchema } from 'utils/validation';
import {
  Button,
  Group,
  Stack,
  TextInput,
  PhoneInput,
  TextArea,
  FileLoader,
  Spinner,
  Text,
} from 'ui';
import useSSO from 'hooks/useSSO';
import { useWindowSize } from 'hooks/useWindowSize';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { handleApiCall, displayErrorMessages } from 'utils/helpers';
import { NOTIFICATION } from 'modules/alert/constants';
import { IErrorMessages } from 'types';
import { IRegistrationForm } from '../types';
import { useRegisterMutation, useSelfLinkRegisterMutation } from '../signupApi';

const validation = yup.object({
  firstName: validationSchema.stringRequired(),
  lastName: validationSchema.stringRequired(),
  phone: validationSchema.phone(),
  password: validationSchema.passwordRequired(),
  repeatedPassword: validationSchema.confirmPassword(),
});

const UPLOAD_OPTIONS = {
  SIZE: 5_000_000,
  ACCEPT: 'image/png, image/jpeg',
};

const defaultValues: IRegistrationForm = {
  avatar: null,
  firstName: '',
  lastName: '',
  password: '',
  repeatedPassword: '',
  description: '',
  phone: null,
  country: '',
};

const AdaptiveField = styled(Group)`
  @media screen and ${devices.mobileS} {
    flex-direction: column;
  }
  @media screen and ${devices.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const SignupForm: React.FC = () => {
  const [ava, setAva] = useState<File | null>(null);
  const { signUpWithSSO } = useSSO();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues,
    mode: 'onSubmit',
  });
  const windowSize = useWindowSize();
  const [signUp, { isLoading: isSignUpLoading }] = useRegisterMutation();
  const [selfLinkSignUp, { isLoading: isSelfSignUpLoading }] = useSelfLinkRegisterMutation();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const userEmail = query.get('email') || '';
  const timestamp = query.get('timestamp') || '';
  const siteName = query.get('site_name') || '';
  const selfSignUpToken = query.get('token') || '';

  // check if user has auth method set to sso
  const isAuthMethodSSO = query.get('auth_method') === 'sso';

  // check for subscription plan id
  const subscriptionPriceId = query.get('price_id') || '';

  const isSelfSignUpForm = !!userEmail && !!timestamp && !!siteName && !!selfSignUpToken;
  const isSelfSignUpLinkValid = userEmail || timestamp || siteName || selfSignUpToken;
  const isFormLoading = isSignUpLoading || isSelfSignUpLoading;

  const handleAvatarChange = (file: File) => {
    setAva(file);
  };

  const onSubmitSelfSignUp = async (payload: IRegistrationForm) => {
    if (!isSelfSignUpLinkValid) {
      showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
      return;
    }
    if (ava) {
      payload.avatar = ava;
    }
    const selfSignUpPayload = {
      ...payload,
      email: userEmail,
      timestamp: Number(timestamp),
      siteName,
      magicToken: selfSignUpToken,
    };

    // check if it is a self sign up or create own workspace(the link will include price id)
    const requestPayload =
      subscriptionPriceId?.length > 0
        ? { ...selfSignUpPayload, priceId: subscriptionPriceId }
        : selfSignUpPayload;

    const result = await selfLinkSignUp(requestPayload);

    handleApiCall(
      result,
      (res) => {
        displayErrorMessages(res.error.data as IErrorMessages);
      },
      () => {
        showSuccessMessage(NOTIFICATION.ACCOUNT_CREATED);
        navigate(routes.dashboard);
      }
    );
  };

  const onSubmit = async (payload: IRegistrationForm) => {
    const token = query.get('invite');
    if (!token) {
      showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
      return;
    }

    if (ava) {
      payload.avatar = ava;
    }

    const result = await signUp({ ...payload, token });

    handleApiCall(
      result,
      ({ error }) => {
        displayErrorMessages(error?.data as IErrorMessages);
      },
      () => {
        showSuccessMessage(NOTIFICATION.ACCOUNT_CREATED);
        navigate(routes.login);
      }
    );
  };

  if (isAuthMethodSSO) {
    return (
      <Stack gap="30px">
        <AdaptiveField gap="20px">
          <Button fluid type="button" onClick={signUpWithSSO} cypressAttribute="sign-up-sso-btn">
            <Text weight="700">Sign up with SSO</Text>
          </Button>
        </AdaptiveField>
      </Stack>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(isSelfSignUpForm ? onSubmitSelfSignUp : onSubmit)}
      data-cy="sign-up-form"
    >
      <Stack gap="30px">
        <AdaptiveField gap="20px">
          <div style={{ flex: 3 }}>
            <TextInput
              error={errors.firstName}
              register={register}
              name="firstName"
              label="First name"
              required
              data-cy="sign-up-first-name"
            />
          </div>
          <div style={{ flex: 3 }}>
            <TextInput
              error={errors.lastName}
              register={register}
              name="lastName"
              label="Last name"
              required
              data-cy="sign-up-last-name"
            />
          </div>
          <div>
            <PhoneInput error={errors.phone} name="phone" label="Phone" control={control} />
          </div>
        </AdaptiveField>
        <TextArea
          label="Description"
          name="description"
          placeholder="Write a short description about yourself"
          resize="none"
          register={register}
          data-cy="sign-up-form-description"
        />
        <FileLoader
          register={register}
          name="avatar"
          label="Upload a profile picture. Max size is 50 MB"
          preview
          accept={UPLOAD_OPTIONS.ACCEPT}
          size={UPLOAD_OPTIONS.SIZE}
          onChange={handleAvatarChange}
        />
        <Group gap="20px">
          <TextInput
            autoComplete="new-password"
            register={register}
            required
            type="password"
            name="password"
            label="Password"
            error={errors.password}
            data-cy="sign-up-form-password"
          />
          <TextInput
            register={register}
            required
            type="password"
            name="repeatedPassword"
            label="Confirm password"
            error={errors.repeatedPassword}
            data-cy="sign-up-form-confirm-password"
          />
        </Group>
        <Group justify="flex-end">
          <Button
            fluid
            type="submit"
            disabled={isFormLoading}
            cypressAttribute="sign-up-submit-btn"
          >
            {isFormLoading ? (
              <Spinner color="#000" size="small" />
            ) : (
              <Text weight="700">Sign up</Text>
            )}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default SignupForm;
