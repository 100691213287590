import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import EmptyAvatar from 'assets/icons/ava_placeholder.jpg';

type TSize = 'small' | 'medium' | 'mediumSmall';

interface IProps {
  src?: string | null;
  size?: TSize;
  style?: React.CSSProperties;
  cypressAttribute?: string;
}

const sizeMap = {
  small: '18px',
  mediumSmall: '25px',
  medium: '40px',
};

const StyledImg = styled.img<IProps>`
  border-radius: 50%;
  object-fit: cover;
  max-width: inherit;
  display: block;
`;
const Avatar: FC<IProps> = ({ src, size = 'medium', cypressAttribute, ...props }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const handleImgError = () => {
    if (imgRef?.current) {
      imgRef.current.src = EmptyAvatar;
    }
  };

  return (
    <StyledImg
      width={sizeMap[size]}
      ref={imgRef}
      height={sizeMap[size]}
      src={src || EmptyAvatar}
      onError={handleImgError}
      data-cy={cypressAttribute || 'avatar'}
      {...props}
    />
  );
};

export default Avatar;
