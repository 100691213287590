import styled from 'styled-components';
import colors from 'theme/colors';

export const FileLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${colors.green1};
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 4px 8px;
  background: rgba(17, 182, 103, 0.4);
`;

export const EncubeLink = styled.a`
  background-color: ${colors.green1};
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;
`;
