import React, { ForwardRefRenderFunction, forwardRef, useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { RawDraftContentState } from 'draft-js';
import _isEmpty from 'lodash/isEmpty';
import {
  loadCopilotSuggestions,
  selectIsLoadingCopilotsuggestions,
} from 'modules/copilot/copilotSlice';
import { loadNotifications } from 'modules/notification/slice';
import { useDropzone } from 'react-dropzone';
import { FiChevronUp } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { Button, Icon, Stack } from 'ui';
import { handleApiCall } from 'utils/helpers';
import { v4 as uuid } from 'uuid';
import _debounce from 'lodash/debounce';
import TextEditor from '@draft-js-plugins/editor';
import { DEFAULT_LIMIT } from 'modules/threads/constants';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import { EFeatureFlags } from 'constants/features';
import { NOTIFICATION } from '../../alert/constants';
import { showErrorMessage } from '../../alert/utils';
import { selectUserInfo } from '../../auth/userSlice';
import { useSendMessagesMutation } from '../chatApi';
import { deleteMessage, pushMessage } from '../chatSlice';
import { IMessageResponse } from '../types';
import { getLinks, getReferences, messageToStateData } from '../utils';
import Editor from './Editor/Editor';
import { Input as Wrapper } from './Layout';
import MediaBlock from './MediaBlock';
import { MessageContext } from './Message';

interface IProps {
  replyTo?: number;
}

const Input: ForwardRefRenderFunction<TextEditor, IProps> = ({ replyTo }, replyInputRef) => {
  const { replyState, replyMode, disableReplyMode } = useContext(MessageContext);
  const { id: threadId } = useParams<{ id: string }>();
  const [attaches, setAttaches] = useState<File[]>([]);
  const [sendMessage] = useSendMessagesMutation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserInfo);

  const isCopilotFeatureEnabled = useIsFeatureEnabled(EFeatureFlags.COPILOT);

  const isLoadingCopilotSuggestions = useAppSelector(selectIsLoadingCopilotsuggestions);
  const [isLoadingCopilotSuggestionPromises, setIsLoadingCopilotSuggestionPromises] =
    useState(false);

  const onFileChange = (files: File[]) => setAttaches(files);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onFileChange,
    noClick: true,
  });

  /**
   * Debounce calls to get suggestions on msg send, to prevent overloading of copilot API.
   */
  const debouncedRequestCopilotSuggestions = _debounce(() => {
    if (isLoadingCopilotSuggestions || isLoadingCopilotSuggestionPromises) {
      return;
    }

    Promise.all([
      dispatch(
        loadCopilotSuggestions({
          isCopilotFeatureEnabled,
          threadId: Number(threadId),
        })
      ),
      dispatch(loadNotifications({ offset: 0, limit: DEFAULT_LIMIT })),
    ]).finally(() => setIsLoadingCopilotSuggestionPromises(false));
  }, 1000);

  const onMessageSend = async (message: RawDraftContentState, hasText: boolean) => {
    setIsLoadingCopilotSuggestionPromises(true);
    if (!hasText && !attaches.length) return;
    const text = JSON.stringify(message);
    const tempHash = uuid();
    const payload = {
      text,
      thread: Number(threadId),
      references: [...getReferences(attaches), ...getLinks(message)],
      hash: tempHash,
    };

    const reqData = replyTo ? { ...payload, replyTo } : payload;
    dispatch(pushMessage(messageToStateData(reqData, user, attaches)));
    setAttaches([]);

    const res = await sendMessage(reqData);
    handleApiCall<IMessageResponse>(res, () => {
      showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
      dispatch(deleteMessage({ id: tempHash }));
    });

    debouncedRequestCopilotSuggestions();
  };

  const removeFile = (fileName: string) =>
    setAttaches(attaches.filter((file) => file.name !== fileName));

  return (
    <Wrapper
      id={replyTo ? 'input-wrapper' : 'sticky-input-wrapper'}
      data-cy="chat-input-wrapper"
      gap="10px"
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      {replyMode && (
        <Button
          variant="plain"
          onClick={disableReplyMode}
          cypressAttribute="chat-input-disable-reply-mode-btn"
        >
          <FiChevronUp size={20} strokeWidth={3} color={colors.gray1} />
        </Button>
      )}
      <AccessControl
        permissions={[EPermissions.SEND_ATTACHMENTS_IN_CHAT]}
        threadId={threadId as string}
      >
        <div>
          <input data-cy="chat-input-attachment" {...getInputProps()} />
          <Button onMouseDown={open} variant="plain" cypressAttribute="chat-input-attachment-btn">
            <Icon icon="AttachIcon" />
          </Button>
        </div>
      </AccessControl>
      <Stack style={{ flex: 1, minWidth: 0 }}>
        <Editor
          placeholder={
            replyMode && replyState.replyingToMessage
              ? `Reply to ${replyState.replyingToMessage.creator.firstName}...`
              : 'Comment...'
          }
          onSubmit={onMessageSend}
          onPasteFiles={setAttaches}
          ref={replyInputRef}
        />
        {attaches.length > 0 && <MediaBlock files={attaches} removeFile={removeFile} />}
      </Stack>
    </Wrapper>
  );
};

export default forwardRef(Input);
