import styled, { css, keyframes } from 'styled-components';
import { Group, Stack, Text } from 'ui';
import { IOwner } from '../types';

export const Wrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark1};
  position: relative;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  overflow: visible;
  justify-content: space-between;
`;

const drag = css`
  box-shadow: inset 0px 0px 10px 0 rgba(56, 69, 105, 0.75);
  border-top: 3px solid ${({ theme }) => theme.colors.blue3};
`;

export const Input = styled(Group)<{ isDragActive: boolean }>`
  width: 100%;
  border-radius: 0 0 8px 8px;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-top: 1px solid ${({ theme }) => theme.colors.blue3};
  padding: 15px;
  ${({ isDragActive }) => isDragActive && drag}
`;

const actionPanelPopIn = keyframes`
  0% { scale: 0; }
  100% { scale: 1; }
`;

export const ActionWrapper = styled(Group)<{ owner: boolean; isActionVisible: boolean }>`
  background-color: ${({ theme }) => theme.colors.dark7};
  padding: 8px 12px;
  align-items: center;
  top: 0;
  position: absolute;
  display: flex;
  border-radius: 4px;
  right: 0;
  z-index: 100;
  transform: translate(-10px, -25%);
  display: ${({ isActionVisible }) => (isActionVisible ? 'flex' : 'none')};
  animation: ${actionPanelPopIn} 0.08s ease-in-out;
`;

const rightReplyBorder = css`
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.blue7};
    height: 80%;
    top: 20%;
  }
`;

const leftReplyBorder = css`
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    border-left: 1px solid ${({ theme }) => theme.colors.blue7};
    height: 80%;
    top: 20%;
  }
`;

export const Message = styled(Stack)<{ owner: boolean; showBorder: boolean; isRootOwner: boolean }>`
  width: 100%;
  padding: 10px 24px;
  position: relative;
  flex-direction: ${({ owner }) => (owner ? 'row-reverse' : 'row')};
  justify-content: ${({ owner }) => (owner ? 'end' : 'start')};
  ${({ showBorder, isRootOwner }) =>
    showBorder && (isRootOwner ? rightReplyBorder : leftReplyBorder)}
`;

export const MessageContent = styled(Stack)<{
  owner: boolean;
  isCopiedMessage: boolean;
  isEncubeMessage?: boolean;
  isGoogleMessage?: boolean;
  isPlyableMessage?: boolean;
}>`
  background-color: ${({ theme, owner, isCopiedMessage }) => {
    if (isCopiedMessage) {
      return theme.colors.blue10;
    }
    return owner ? theme.colors.blue2 : theme.colors.dark2;
  }};
  border: ${({
    theme,
    owner,
    isCopiedMessage,
    isEncubeMessage,
    isGoogleMessage,
    isPlyableMessage,
  }) => {
    if (isCopiedMessage) {
      return `2px solid ${theme.colors.blue}`;
    }
    if (isEncubeMessage) {
      return `1px solid ${theme.colors.green1}`;
    }
    if (isPlyableMessage) {
      return `1px solid ${theme.colors.purple}`;
    }
    if (isGoogleMessage) {
      return `1px solid ${theme.colors.indicator}`;
    }
    return owner ? `2px solid ${theme.colors.blue2}` : `2px solid ${theme.colors.dark2}`;
  }};
  transition: background-color 0.3s ease;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
  gap: 20px;
  min-width: 0;
  position: relative;
  box-shadow: ${({ theme, isEncubeMessage, isGoogleMessage, isPlyableMessage }) => {
    if (isEncubeMessage) {
      return `inset 0 0 10px 0 ${theme.colors.green1}`;
    }
    if (isGoogleMessage) {
      return `inset 0 0 10px 0 ${theme.colors.indicator}`;
    }
    if (isPlyableMessage) {
      return `inset 0 0 10px 0 ${theme.colors.purple}`;
    }
    return 'none';
  }};
`;

export const MessageHeader = styled(Group)<{ owner: boolean }>`
  width: 100%;
  justify-content: space-between;
  flex-direction: ${({ owner }) => (owner ? 'row-reverse' : 'row')};
`;

export const ScrollArea = styled(Stack)`
  flex: 1;
  min-height: 0;
  overflow: hidden;
  width: 100%;
`;

export const MediaContainer = styled(Group)`
  overflow-x: auto;
  padding: 10px 0;
  min-height: 0;
  gap: 10px;
`;

export const Comment = styled.input`
  border: none;
  outline: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.gray1};
  flex: 1;
`;

export const CloseFile = styled(Group)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue3};
  cursor: pointer;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
  display: none;
`;

export const FileWrapper = styled(Group)`
  padding: 6px 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.blue4};
  position: relative;
  cursor: pointer;
  &:hover ${CloseFile} {
    display: flex;
  }
`;

export const Placeholder = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.gray1};
`;

export const EditWrapper = styled.div<{ readOnly: boolean }>`
  background-color: ${({ theme, readOnly }) => (readOnly ? 'transparent' : theme.colors.dark2)};
  padding: ${({ readOnly }) => (readOnly ? 0 : '8px 6px')};
`;

const replyVerticalLine = css`
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    top: 20px;
    left: 50%;
    background-color: ${({ theme }) => theme.colors.blue7};
  }
`;

export const ReplayLine = styled.div<{ hasReplies?: boolean }>`
  position: relative;
  ${({ hasReplies }) => hasReplies && replyVerticalLine}
`;

const rightLine = css`
  right: 0;
  transform: translate(100%, 0);
  border-right: 1px solid ${({ theme }) => theme.colors.blue7};
  border-bottom-right-radius: 4px;
`;
const leftLine = css`
  left: 0;
  transform: translate(-100%, 0);
  border-left: 1px solid ${({ theme }) => theme.colors.blue7};
  border-bottom-left-radius: 4px;
`;
export const WithLine = styled.div<{ owner: boolean }>`
  position: relative;
  height: 100%;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 24px;
    height: inherit;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue7};
    top: -50%;
    ${({ owner }) => (owner ? rightLine : leftLine)}
  }
`;

const leftPadding = css`
  padding-left: 44px;
`;
const rightPadding = css`
  padding-right: 43px;
`;
export const ReplyWrapper = styled.div<IOwner>`
  ${({ owner }) => (owner ? rightPadding : leftPadding)}
  padding-bottom: 3px;
`;
const rightBorder = css`
  border-right: 1px solid ${({ theme }) => theme.colors.blue7};
`;
const leftBorder = css`
  border-left: 1px solid ${({ theme }) => theme.colors.blue7};
`;
export const ReplyContent = styled.div<IOwner>`
  overflow: visible;
`;

export const MembersWrapper = styled(Group)<{ withBorder?: boolean; owner: boolean }>`
  justify-content: ${({ owner }) => (owner ? 'flex-end' : 'flex-start')};
  padding: 0 24px;
  ${({ withBorder, owner }) => withBorder && (owner ? rightBorder : leftBorder)}
`;

export const SysMessage = styled.div`
  width: 100%;
  padding: 15px 24px;
`;
export const SysMessageContent = styled(Group)`
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 16px;
  border-radius: 8px;
`;
export const Name = styled(Text)`
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
  overflow: hidden;
  text-overflow: ellipsis;
`;
