import styled from 'styled-components';
import Avatar from 'ui/avatar';

export const JamItemHolder = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px 5px;
  width: 100%;
`;

export const JamItemLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: ${({ theme }) => theme.colors.brandSecondaryLight};
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
`;

export const JamItemEmpty = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
  color: ${({ theme }) => theme.colors.gray3};
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
`;
