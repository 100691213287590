import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import _isEmpty from 'lodash/isEmpty';
import { useSendCopilotChatMessageMutation } from 'modules/copilot/copilotApi';
import { useForm } from 'react-hook-form';
import { IoClose, IoWarning } from 'react-icons/io5';
import colors from 'theme/colors';
import { IPayloadThunk, TErrorData } from 'types';
import { Group, Text } from 'ui';
import Logo from 'ui/logo';
import { ThreadsBotBody, ThreadsBotCard, ThreadsBotHeader } from 'ui/threadsBot/Layout';
import Card from 'ui/card';
import { generateShortUUID, handleApiCall } from 'utils/helpers';
import { NOTIFICATION } from 'modules/alert/constants';
import {
  pushThreadsBotError,
  pushThreadsBotMessageToChat,
  selectThreadsBotIsExpanded,
  selectThreadsBotMessages,
  sendThreadsBotMessage,
  toggleThreadsBotViewExpanded,
} from '../threadsBotSlice';
import {
  EThreadsBotMessageSenderTypes,
  EThreadsBotMessageTypes,
  IThreadsBotChatResponse,
  TThreadsBotMessage,
} from '../types';
import ThreadsBotCollapsedFloatingCard from './collapsedFloatingCard';
import ThreadsBotMessage, { ThreadsBotError } from './threadsBotMessage';
import ThreadsBotTextInput from './threadsBotTextInput';

const ThreadsBot = () => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(selectThreadsBotIsExpanded);
  const threadsBotMessages = useAppSelector(selectThreadsBotMessages);

  const threadsBotChatFeedRef = useRef<HTMLDivElement>(null);

  /**
   * Provides copilot with context in the current conversation.
   */
  const [chatHistory, setChatHistory] = useState('First message');

  const [, { isLoading: isMessageSendingFromAPI }] = useSendCopilotChatMessageMutation();
  const [isMessageSending, setIsMessageSending] = useState(false);

  const { setValue, handleSubmit, register } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const handleScrollToBottom = () => {
    // Add a short timeout to allow succeeding DOM elements to render.
    setTimeout(() => {
      if (threadsBotChatFeedRef.current) {
        const bottom = threadsBotChatFeedRef.current.scrollHeight;
        threadsBotChatFeedRef.current.scrollTo({
          top: bottom,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [threadsBotMessages]);

  const handleSendThreadsBotMessage = async ({ message }: { message: string }) => {
    if (_isEmpty(message) || isMessageSendingFromAPI || isMessageSending) {
      return;
    }

    handleScrollToBottom();

    // Clear chat input UI.
    setValue('message', '');

    dispatch(
      pushThreadsBotMessageToChat({
        type: EThreadsBotMessageTypes.MESSAGE,
        sender: EThreadsBotMessageSenderTypes.USER,
        message,
      })
    );

    setIsMessageSending(true);

    const response = (await dispatch(
      sendThreadsBotMessage({ message, pre_chat_history: chatHistory })
    )) as unknown as IPayloadThunk<IThreadsBotChatResponse>;

    handleApiCall(
      response.payload,
      () => {
        // Display error message
        dispatch(pushThreadsBotError());
      },
      ({ data }) => {
        setChatHistory(data?.chatHistory);

        // Get response from copilot and push to chat.
        dispatch(
          pushThreadsBotMessageToChat({
            type: EThreadsBotMessageTypes.MESSAGE,
            sender: EThreadsBotMessageSenderTypes.THREADS_BOT,
            message: data?.response,
          })
        );
      }
    );

    setIsMessageSending(false);
  };

  return expanded ? (
    <>
      <ThreadsBotCard id="threads-bot-wrapper">
        <ThreadsBotHeader id="threads-bot-header">
          <Group fluid align="center" justify="space-between" minWidth={100}>
            <Group gap="10px" align="center">
              <Logo /> ThreadsBot
            </Group>
            <IoClose
              role="button"
              color={colors.gray}
              onClick={() => dispatch(toggleThreadsBotViewExpanded())}
              size={25}
            />
          </Group>
        </ThreadsBotHeader>
        <Card padding="10px" height="fit-content" backgroundColor={colors.gray4}>
          <Group>
            <IoWarning size={30} />
            <Text size="xs">
              Although there are safeguards in place, the system may occasionally generate incorrect
              or misleading information. Please be cautious in your use of the system.
            </Text>
          </Group>
        </Card>
        <ThreadsBotBody
          ref={threadsBotChatFeedRef}
          backgroundColor={colors.dark1}
          id="threads-bot-chat-feed"
        >
          {threadsBotMessages.map((data: TThreadsBotMessage) => {
            if (data.type === EThreadsBotMessageTypes.ERROR) {
              return <ThreadsBotError id={`message-${data.id}`} isMe={false} text={data.message} />;
            }

            return (
              <ThreadsBotMessage
                id={`message-${data.id}`}
                isMe={data.sender === EThreadsBotMessageSenderTypes.USER}
                text={data.message}
              />
            );
          })}
          {isMessageSending && (
            <ThreadsBotMessage
              id="threads-bot-processing-view"
              noCursor
              textColor={colors.gray5}
              isMe={false}
              text="ThreadsBot is processing..."
            />
          )}
        </ThreadsBotBody>
        <ThreadsBotTextInput
          isSending={isMessageSendingFromAPI || isMessageSending}
          handleSubmit={handleSubmit}
          register={register}
          handleSendThreadsBotMessage={handleSendThreadsBotMessage}
        />
      </ThreadsBotCard>
      <ThreadsBotCollapsedFloatingCard />
    </>
  ) : (
    <ThreadsBotCollapsedFloatingCard />
  );
};

export default ThreadsBot;
