import React, { FC, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useAppSelector } from 'app/hooks';
import { selectAllEvents, selectAllEventsById } from 'modules/events/eventsSlice';
import MessageCard from './MessageCard';
import { LIMIT, selectNotifications, selectTotal } from '../slice';
import { useGetNotificationsQuery } from '../notificationApi';

interface IProps {
  closeNotificationModal: () => void;
}

const NotificationList: FC<IProps> = ({ closeNotificationModal }) => {
  const notifications = useAppSelector(selectNotifications);
  const eventsById = useAppSelector(selectAllEventsById);
  const total = useAppSelector(selectTotal);
  const [offset, setOffset] = useState(0);
  useGetNotificationsQuery({ offset, limit: LIMIT });

  const loadNext = () => {
    if (total > notifications.length) {
      setOffset(notifications.length);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Virtuoso
        style={{ height: '250px' }}
        data={notifications}
        endReached={loadNext}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        itemContent={(_index, data) => (
          <MessageCard
            key={data.id}
            cypressAttribute="notification-message-card"
            eventsById={eventsById}
            closeNotificationModal={closeNotificationModal}
            {...data}
          />
        )}
      />
    </div>
  );
};

export default NotificationList;
