import React, { FC, useMemo } from 'react';

import Moment from 'react-moment';
import { Group, Icon, Stack, Text } from 'ui';

import type { IMessageResponse } from 'modules/chat/types';

import colors from 'theme/colors';
import { getFullName } from 'utils/helpers';

import * as ChatLayout from '../../Layout';
import { PlyableFileContainer, PlyableLogoWrapper, PlyableMessageLink } from './Layout';

interface IMessageData {
  message: string;
  linkUrl: string | null;
  fileName: string | null;
  fileUrl: string | null;
}

const PlayableMessageContent: FC<IMessageResponse> = (props) => {
  const { creator, createdAt, text } = props;

  const messageData: IMessageData = useMemo(() => {
    const { blocks = [] } = JSON.parse(text as string) || {};
    const [parsed] = blocks;
    return {
      message: parsed?.text || '',
      linkUrl: parsed?.plyable_url || null,
      fileName: parsed?.reference_versioned_file?.name || null,
      fileUrl: parsed?.reference_versioned_file?.url || null,
    };
  }, [text]);

  return (
    <ChatLayout.MessageContent
      isPlyableMessage
      owner={false}
      isCopiedMessage={false}
      data-cy="chat-layout-plyable-content"
    >
      <ChatLayout.MessageHeader owner={false}>
        <Group>
          <Stack data-cy="chat-layout-message-content-stack">
            <Text
              align="left"
              size="sm"
              color={colors.orange}
              cypressAttribute="chat-layout-message-content-full-name"
            >
              {getFullName(creator)}
            </Text>
          </Stack>
        </Group>
        <Text size="xs" color={colors.gray1} cypressAttribute="chat-layout-message-content-time">
          <Moment fromNow date={createdAt} />
        </Text>
      </ChatLayout.MessageHeader>
      <Text
        align="left"
        color={colors.white}
        size="sm"
        cypressAttribute="chat-layout-message-content-message"
      >
        {messageData.message}
      </Text>
      <Group justify="space-between" gap="24px" align="center">
        {messageData.fileName && messageData.fileUrl && (
          <PlyableFileContainer data-cy="chat-layout-message-content-file-link">
            <Icon
              icon="AttachIcon"
              size="small"
              path={colors.purple}
              style={{ width: '18px', height: '18px' }}
            />
            {messageData.fileName}
          </PlyableFileContainer>
        )}
        {messageData.linkUrl && (
          <PlyableMessageLink
            href={messageData.linkUrl}
            target="_blank"
            rel="noreferrer"
            data-cy="chat-layout-message-content-encube-link"
          >
            <PlyableLogoWrapper>
              <Icon icon="PlyableLogo" size="medium" />
            </PlyableLogoWrapper>

            <Icon icon="ArrowRightIcon" size="small" />
          </PlyableMessageLink>
        )}
      </Group>
    </ChatLayout.MessageContent>
  );
};

export default PlayableMessageContent;
