import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFileVersions } from './types';

interface IReferences {
  selectedReference: IFileVersions | null;
  referenceName: string;
}

export const initialState: IReferences = {
  selectedReference: null,
  referenceName: '',
};

const referencesSlice = createSlice({
  name: 'references',
  initialState,
  reducers: {
    setSelectedReference: (state, { payload }: PayloadAction<IFileVersions>) => {
      state.selectedReference = payload;
      return state;
    },
    setReferenceName: (state, { payload }: PayloadAction<string>) => {
      state.referenceName = payload;
      return state;
    },
    removeSelectedReference: (state) => {
      state.selectedReference = null;
      return state;
    },
  },
});

export const { setSelectedReference, removeSelectedReference, setReferenceName } =
  referencesSlice.actions;

export const selectSelectedReference = (state: { references: IReferences }) =>
  state.references.selectedReference;

export const selectReferenceName = (state: { references: IReferences }) =>
  state.references.referenceName;

export default referencesSlice.reducer;
