import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { IMAGE_TYPES, SECONDS_IN_DAY } from 'constants/index';
import { EditorState, convertFromRaw } from 'draft-js';
import { saveAs } from 'file-saver';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import { showErrorMessage } from 'modules/alert/utils';
import { REFERENCE_TYPE } from 'modules/threads/types';
import { CSSProperties, MouseEvent } from 'react';
import { css } from 'styled-components';
import { IError, IErrorMessages, ISuccessResult, TFeatureFlag } from 'types';
import { EUserRole, IUser, IUserMinimize } from 'types/user';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const createOption = (label: string) => ({
  label,
  value: label,
});

type TRes<T> = { data: T } | { error: FetchBaseQueryError | SerializedError };
type TReference = typeof REFERENCE_TYPE[keyof typeof REFERENCE_TYPE];

export const handleApiCall = <T>(
  res: TRes<T>,
  onError: (error: IError) => void,
  onSuccess?: (res: ISuccessResult<T>) => void
) => {
  if ('error' in res) {
    onError(res as IError);
    return;
  }
  if (onSuccess) {
    onSuccess(res);
  }
};

export const isImage = (url?: string | null) => {
  if (!url) return false;
  const type = url?.split('.').pop() || '';
  return IMAGE_TYPES.includes(type);
};

export const getReferenceIconName = (referenceType: TReference, image: boolean, link: string) => {
  if (referenceType === REFERENCE_TYPE.LINK) {
    try {
      const url = new URL(link);

      const GOOGLE_DRIVE_HOSTNAMES = ['drive.google.com', 'docs.google.com'];
      if (GOOGLE_DRIVE_HOSTNAMES.includes(url.hostname)) {
        return 'ReferenceGoogleDriveLinkIcon';
      }
      if (url.hostname.includes('slack.com')) {
        return 'ReferenceSlackLinkIcon';
      }
    } catch {
      // URL is not valid
    }
    return 'ReferenceLinkIcon';
  }
  if (referenceType === REFERENCE_TYPE.FILE && !image) {
    return 'ReferenceDocumentIcon';
  }
  if (referenceType === REFERENCE_TYPE.PLAYABLE) {
    return 'ReferenceDocumentIcon';
  }
  if (referenceType === REFERENCE_TYPE.ENCUBE) {
    return 'ReferenceEncubeIcon';
  }
  return 'ReferenceImageIcon';
};

export const convertToBase64 = (uploadFile: File, cb: (val: string | undefined) => void) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    cb(reader.result?.toString());
  };
  reader.readAsDataURL(uploadFile);
};

/**
 * Convert cents to dollars and format it as currency
 * @param cents
 * @returns number
 */
export const convertCentsToDollars = (
  cents: number | undefined | null,
  currency: string | undefined
) => {
  if (!cents) return 0;
  const f = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });
  return f.format(cents / 100);
};

export const handleDownloadFile =
  (name: string, file: string) =>
  (e: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    const fileType = file?.split('.').pop() || '';
    if (isImage(file)) {
      return saveAs(file, `image.${fileType}`);
    }
    return saveAs(file, `${name}.${fileType}`);
  };

export const getOption = (options: { optionLabel: string; value: string }[], val: string) =>
  options.find(({ value }) => value === val);

export const isValidUrl = (path: string) => {
  let url;
  try {
    url = new URL(path);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getFullName = (user?: IUser | IUserMinimize) =>
  user ? `${user.firstName} ${user.lastName}` : 'Deleted user';
export const getThreadIdFromUrl = (url: string) => url.split('/').at(-1) || '';

export const getUserNameAndFirstCharacterSurname = (user?: IUser | IUserMinimize) =>
  user ? `${user.firstName} ${user.lastName.charAt(0)}.` : 'Deleted user';

export const getPhoneNumber = (phone?: string | null) => (phone ? phone.split(' ')[1] : null);

export const getFileName = (fileName: string) => {
  if (!fileName) return '';
  const parseUrl: string[] = fileName?.split('/');
  return parseUrl[parseUrl.length - 1]?.split('.')[0] || '';
};

export const formatDate = (date: Date | undefined) => {
  if (!date) return '';
  // format: YYYY-MM-DD
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  return [year, month, day].join('-');
};

export const getNextDay = (date: Date | undefined) => {
  if (!date) return undefined;
  // format Mon Feb 28 2023 00:00:00 GMT+0200
  const existingDate = new Date(date);
  const timestamp = existingDate.getTime();
  const newTimestamp = timestamp + SECONDS_IN_DAY;
  return new Date(newTimestamp);
};

export const setInitialDate = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
};

export const isAdmin = (role: EUserRole) => role === EUserRole.ADMIN;
export const isGuest = (role: EUserRole) => role === EUserRole.EXTERNAL_USER;
export const isSiteUser = (role: EUserRole) => role === EUserRole.USER;

export const getEditorText = (text: string) => {
  try {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(text)))
      .getCurrentContent()
      .getPlainText();
  } catch {
    return '';
  }
};

export const displayErrorMessages = (obj: IErrorMessages): void => {
  Object.keys(obj).forEach((key: string) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((message: string) => showErrorMessage(message));
    }
  });
};

export const removeTimeFromDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const truncateRawString = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};

/**
 *
 * @param date a date that comes from backend
 * @returns a date in format MM/DD/YYYY
 */
export const convertPlayableDeliveryDate = (date: string | null | undefined) => {
  if (!date) return 'N/A';
  return moment(date).format('L');
};

/**
 * When developing low level UI components, it is common to have to use a local ref but also support an external
 * one using React.forwardRef. Natively, React does not offer a way to set two refs inside the ref property.
 * This is the goal of this small utility.
 *
 * @param refs - array of ref objects
 */
export function mergeRefs<T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
/**
 * Check if file is STP or STEP to display Plyable integration section
 *
 * @param file file to check or null
 * @returns boolean if file is STP or STEP
 */
export const fileTypeMatches = (file: File | null | string, types: string[]): boolean => {
  if (!file) return false;
  if (typeof file === 'string') return !!_find(types, (type) => file.toLowerCase().endsWith(type));
  const fileType = file?.name?.split('.')?.pop()?.toLowerCase();
  return !!_find(types, (type) => fileType === type);
};

/**
 * To create an existing user in our DB in Keycloak, we need to redirect to reset password page.
 * When user will finish reset password flow, user will be created in Keycloak.
 *
 * @param url - a link that comes from backend
 */
export const redirectToSSOResetPassword = (url: string | undefined) => {
  if (!url) return;
  window.open(url, '_self');
};

/**
 * Allow dynamic props to be passed into a styled component.
 */
export const mapStyledProps = (props: { [key: string]: keyof CSSProperties }) => {
  return css`
    ${props
      ? Object.keys(props).map(
          (key) => css`
            // Converts from camelCase to snake-case
            ${key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()}: ${props[key]};
          `
        )
      : ''}
  `;
};
/**
 *
 * @param str basic string
 * @returns a string without _ and with first letter of each word capitalized
 * @example titleizeString('hello_world') => 'Hello World'
 */
export const titleizeString = (str: string) => {
  const newStr = str.replace(/_/g, ' ');
  return _startCase(newStr);
};

export const safeJSONParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
};

/**
 * Generates 8 character random uuid.
 */
export const generateShortUUID = () => uuidv4().slice(0, 8);

/**
 * Inserts an item into an array at a specific index
 */
export const insertToArrayAtIndex = (arr: unknown[], index: number, newItem: unknown) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

/**
 * Some avatar URLs return the pathname only, if this is the case, prepend the API base URL to the URI.
 */
export const formatAvatarSrcURI = (APIBaseUrl: string, resourceUri: string) => {
  const baseUrlWithoutApiVersion = APIBaseUrl.slice(0, -3);
  return !isValidUrl(resourceUri) ? `${baseUrlWithoutApiVersion}${resourceUri}` : resourceUri;
};
