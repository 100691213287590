import { api } from 'app/api';
import type { IPlayablePing } from '../types';

export const playablePingApi = api.injectEndpoints({
  endpoints: (build) => ({
    pingPlayable: build.query<IPlayablePing, null>({
      query: () => `plyable/ping/`,
      providesTags: ['playablePing'],
    }),
  }),
});

export const { usePingPlayableQuery } = playablePingApi;
