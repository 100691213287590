import { useAppSelector } from 'app/hooks';
import { EPermissions } from 'app/permissions/constants';
import _isEqual from 'lodash/isEqual';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useModal } from 'modules/modals/ModalProvider';
import StatusBadgeWithCircleIcon from 'modules/threads/components/badge/StatusBadgeWithCircleIcon';
import { IRows, ITableThread } from 'modules/threads/types';
import PrivateThreadIndicator from 'pages/thread/components/privateThreadIndicator';
import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import { DropDownAction, Group, Icon, Stack, Tags, Text, Tooltip } from 'ui';
import { truncateRawString } from 'utils/helpers';

export const Tr = styled.tr`
  border-radius: 8px;

  &:nth-child(odd) {
    background-color: ${colors.dark2};
  }
`;

export const Td = styled.td`
  position: relative;
  font-size: 14px;
  color: ${colors.gray};
  padding: 18px 8px 18px 12px;

  .link {
    color: inherit;
    display: inline;
    text-decoration: none;

    :hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    text-align: right;
    padding-right: 22px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TextOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TBody = ({ rows, cypressAttribute, isAllThreads }: IRows) => {
  const { open } = useModal();
  const { id: userId } = useAppSelector(selectUserInfo);

  const handleCopyEventLink = async (id: string | number) => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/thread/${id}`);
      showSuccessMessage('Thread link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };
  const handleEditThread = (id: string | number) => {
    open({
      variant: 'fullRight',
      contentLabel: 'Edit thread properties',
      name: 'Edit Thread',
      modal: 'editThread',
      context: { threadId: id },
      id: 'modal-edit-thread',
    });
  };
  const handleJoinThread = (id: string | number, name: string) => {
    open({
      variant: 'center',
      contentLabel: 'Join thread',
      name: 'Join Thread',
      modal: 'joinThread',
      context: { threadName: name, threadId: id },
      id: 'modal-join-thread',
    });
  };
  const handleDeleteThread = (id: string | number, name: string) => {
    open({
      variant: 'center',
      contentLabel: 'Delete thread',
      name: 'Delete Thread',
      modal: 'deleteThread',
      context: { threadName: name, threadId: id },
      id: 'modal-remove-thread',
    });
  };

  const getThreadActions = useCallback(
    (
      id: string | number,
      name: string,
      isContributor: boolean | undefined,
      isRequestSent: boolean | undefined,
      isThreadCreator: boolean | undefined,
      isPrivateThread: boolean | undefined
    ) => {
      const defaultActions = [
        {
          id: '1',
          title: 'Copy link',
          action: () => handleCopyEventLink(id),
          icon: <Icon size="small" icon="CopyLinkIcon" stroke="white" />,
        },
        {
          id: '2',
          title: 'Edit',
          action: () => handleEditThread(id),
          icon: <Icon size="small" icon="PencilIcon" stroke="white" />,
          permissions: [EPermissions.EDIT_THREAD],
        },
        {
          id: '3',
          title: 'Delete',
          action: () => handleDeleteThread(id, name),
          icon: <Icon size="small" icon="BinIcon" />,
          permissions: [EPermissions.DELETE_THREAD],
        },
      ];
      return isAllThreads &&
        !isContributor &&
        !isRequestSent &&
        !isThreadCreator &&
        !isPrivateThread
        ? [
            {
              id: '0',
              title: 'Join Thread',
              action: () => handleJoinThread(id, name),
              icon: <Icon size="small" icon="UserWithPlus" stroke="white" />,
            },
            ...defaultActions,
          ]
        : defaultActions;
    },
    [isAllThreads]
  );

  return (
    <tbody data-cy={cypressAttribute}>
      {rows.map((thread: ITableThread) => (
        <Tr key={thread.id}>
          <Td>
            <StatusBadgeWithCircleIcon text={false} status={thread.status} />
          </Td>

          <Td>
            <Group gap="5px" align="center">
              <div>
                {thread.isRequestedToJoin && (
                  <Tooltip
                    position="right"
                    data={
                      <Stack gap="6px">
                        <Text size="xs">You have requested to join this thread.</Text>
                        <Text size="xs">Awaiting contributor approval</Text>
                      </Stack>
                    }
                  >
                    <Icon icon="PendingRequestIcon" size="small" />
                  </Tooltip>
                )}
              </div>
              <Tooltip
                data={thread.name}
                mode="dark"
                position="top"
                width="full"
                isVisible={thread.name.length > 23}
              >
                <TextOverflow>
                  <Link to={`/thread/${thread.id}`} className="link">
                    {truncateRawString(thread.name, 23)}
                  </Link>
                </TextOverflow>
              </Tooltip>
            </Group>
          </Td>

          <Td id="dropdown-menu-header-cell">
            <DropDownAction
              position="vertical"
              actions={getThreadActions(
                thread.id,
                thread.name,
                thread.isContributor,
                thread.isRequestedToJoin,
                _isEqual(userId, thread.creator?.id),
                thread.isPrivate
              )}
              cypressAttribute="thread-card-dropdown-actions"
            />
          </Td>

          <Td>
            <Tags tags={thread.tags} />
          </Td>

          <Td>
            <Tooltip data={<PrivateThreadIndicator isPrivate={thread.isPrivate} />}>
              {thread.isPrivate ? (
                <Icon icon="StrikeEyeIcon" size="medium" stroke="none" />
              ) : (
                <Icon icon="EyeIcon" size="medium" stroke="none" />
              )}
            </Tooltip>
          </Td>

          <Td>
            <Group justify="start" align="center" fluid>
              <Moment format="MM/DD/YYYY" date={thread.updatedAt} />
            </Group>
          </Td>
        </Tr>
      ))}
    </tbody>
  );
};

export default TBody;
