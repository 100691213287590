import React from 'react';
import { Text, Stack, Spinner } from 'ui';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { useGetEventsQuery } from './eventsApi';
import EventCard from './components/EventCard';

const Events = () => {
  const { id, eventId } = useParams();
  const { data, isLoading } = useGetEventsQuery({
    id: id as string,
    eventId: eventId || '',
  });

  if (isLoading) {
    return (
      <Stack justify="center" align="center" fluid fullHeight>
        <Spinner />
      </Stack>
    );
  }

  const copiedEvent = eventId
    ? data?.results?.findIndex((event) => event.id === Number(eventId))
    : 0;

  if (data?.results?.length) {
    return (
      <Virtuoso
        style={{ flex: 1 }}
        data={data.results}
        initialTopMostItemIndex={copiedEvent}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        itemContent={(_, event) => <EventCard {...event} />}
      />
    );
  }

  return (
    <Stack align="center" justify="center" style={{ height: '50%' }}>
      <Text size="lg">No Events...</Text>
    </Stack>
  );
};

export default Events;
