import React, { FC, useCallback } from 'react';
import { EMessageType, IMessageResponse } from 'modules/chat/types';
import { Avatar, Stack } from 'ui';
import { MessageContent } from './MessageContent';
import * as ChatLayout from '../../Layout';
import GoogleMessageContent from '../../GoogleMessage/components/GoogleMessageContent';
import PlayableMessageContent from '../../PlayableMessage/components/PlayableMessageContent';

interface IMessageContainerProps extends IMessageResponse {
  hasReplies?: boolean;
  isReply?: boolean;
  showReplyBorder?: boolean;
  messageType: EMessageType;
}

export const MessageContainer: FC<IMessageContainerProps> = (props) => {
  const {
    creator,
    showReplyBorder = false,
    hasReplies = false,
    isReply = false,
    messageType,
  } = props;

  const renderMessageContent = () =>
    useCallback(() => {
      switch (messageType) {
        case EMessageType.ENCUBE_MESSAGE:
          return <MessageContent {...props} />;
        case EMessageType.PLYABLE_MESSAGE:
          return <PlayableMessageContent {...props} />;
        case EMessageType.GOOGLE_MESSAGE:
          return <GoogleMessageContent {...props} />;
        default:
          return null;
      }
    }, [messageType]);

  return (
    <ChatLayout.Message owner={false} isRootOwner={false} showBorder={showReplyBorder}>
      <ChatLayout.ReplayLine hasReplies={hasReplies}>
        <Stack style={{ position: 'relative', zIndex: 5 }} justify="center">
          {isReply ? (
            <ChatLayout.WithLine owner={false}>
              <Avatar src={creator?.avatar} cypressAttribute="message-container-avatar-with-line" />
            </ChatLayout.WithLine>
          ) : (
            <Avatar src={creator?.avatar} cypressAttribute="message-container-avatar" />
          )}
        </Stack>
      </ChatLayout.ReplayLine>
      {renderMessageContent()()}
    </ChatLayout.Message>
  );
};
