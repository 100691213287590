import React, { ComponentType, useEffect } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../modules/auth/userSlice';

const withAuth =
  <T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) =>
  (props: T) => {
    const { isAuthenticated, email, id } = useAppSelector(selectUser);
    const navigate = useNavigate();

    const shouldRedirect = _.every([email, id, isAuthenticated], Boolean);

    useEffect(() => {
      if (shouldRedirect) {
        navigate('/');
      }
    }, [shouldRedirect]);

    if (shouldRedirect) return null;

    return <Component {...props} />;
  };

export default withAuth;
