import React, { FC } from 'react';
import moment from 'moment';
import { Avatar, Stack, Group, Icon, Text } from 'ui';
import colors from 'theme/colors';
import { IUserMinimize } from 'types/user';
import { getFullName } from 'utils/helpers';
import { JamItemHolder, JamItemLink } from './JamItemLayout';

interface IJamItemProps {
  /**
   * Avatar of the jam item.
   */
  avatar: string;
  /**
   * Name of the file that is being jammed.
   */
  fileName: string;
  /**
   * Version of the file that is being jammed.
   */
  fileVersion: string;
  /**
   * Link to the file that is being jammed.
   */
  fileLink: string;
  /**
   * Date when the jam was started.
   */
  startedAt: string;
  /**
   * Sender object.
   */
  sender: IUserMinimize;
}

export const JamItem: FC<IJamItemProps> = (props) => {
  const { avatar, fileLink, fileName, fileVersion, startedAt, sender } = props;

  return (
    <JamItemHolder data-cy="jam-item-holder">
      <Avatar src={avatar} size="medium" />
      <Stack gap="5px" justify="center" align="flex-start" fullHeight data-cy="jam-item-stack">
        <Group
          gap="12px"
          align="center"
          justify="space-between"
          fluid
          data-cy="jam-item-version-group"
        >
          <JamItemLink href={fileLink} target="_blank" rel="noreferrer" data-cy="jam-item-link">
            {fileName} (Version {fileVersion})
            <Icon
              icon="ExternalLinkIcon"
              stroke={colors.brandSecondaryLight}
              style={{ width: '18px', height: '18px' }}
            />
          </JamItemLink>
        </Group>
        <Group
          gap="10px"
          align="center"
          justify="flex-start"
          style={{ padding: '5px 0' }}
          data-cy="jam-item-time-group"
        >
          <Group gap="5px" justify="flex-start" align="center">
            <Icon icon="TimeIcon" style={{ width: '18px', height: '18px' }} />
            <Text size="xs" weight="400" color={colors.gray3} cypressAttribute="jam-item-time-text">
              Started {moment(startedAt).fromNow()}
            </Text>
          </Group>
          <Group gap="5px" justify="flex-start" align="center" data-cy="jam-item-sender-group">
            <Icon icon="UserIcon" style={{ width: '18px', height: '18px' }} />
            <Text
              size="xs"
              weight="400"
              color={colors.gray3}
              cypressAttribute="jam-item-sender-name"
            >
              {getFullName(sender)}
            </Text>
          </Group>
        </Group>
      </Stack>
    </JamItemHolder>
  );
};
