import React from 'react';
import { useAppSelector } from 'app/hooks';
import { selectGanttView } from 'modules/threads/GanttSlice';
import { GanttView as Gantt } from 'modules/threads/components';
import { TITLES } from 'pages/dashboard/constants';
import Table from 'modules/threads';
import path from 'constants/path';

export const MyThreads = () => {
  const isGantt = useAppSelector(selectGanttView);

  return (
    <div>
      {isGantt ? (
        <Gantt url={path.ganttList} title={TITLES.OWNER} />
      ) : (
        <Table url={path.threadsList} title={TITLES.OWNER} cypressAttribute="my-threads-table" />
      )}
    </div>
  );
};

export default MyThreads;
