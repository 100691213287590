import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useSSO from 'hooks/useSSO';
import * as yup from 'yup';
import { Button, Group, Spinner, Stack, TextInput, Text } from 'ui';
import { ICheckAuthMethodResponse, ILoginMutation } from 'modules/auth/types';
import { validationSchema } from 'utils/validation';
import { useCheckAuthMethodMutation, useLoginMutation } from 'modules/auth/authApi';
import { showErrorMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import colors from 'theme/colors';
import { handleApiCall, redirectToSSOResetPassword } from 'utils/helpers';
import { IError } from 'types';

const initialValues = {
  email: '',
  password: '',
};

const validation = yup.object({
  email: validationSchema.emailRequired(),
  password: validationSchema.stringRequired(),
});

const checkAuthMethodValidation = yup.object({
  email: validationSchema.emailRequired(),
});

const LOGIN_SSO_STATUS = {
  initial: 'initial',
  sso: 'sso',
  email: 'email',
} as const;

type TLoginSsoStatus = typeof LOGIN_SSO_STATUS[keyof typeof LOGIN_SSO_STATUS];

const Form = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { loginWithSSO } = useSSO();

  const [authMethod, setAuthMethod] = useState<TLoginSsoStatus>(LOGIN_SSO_STATUS.initial);

  const isAuthMethodEmail = useMemo(() => authMethod === LOGIN_SSO_STATUS.email, [authMethod]);
  const isAuthMethodSSO = useMemo(() => authMethod === LOGIN_SSO_STATUS.sso, [authMethod]);
  const submitButtonText = useMemo(() => (isAuthMethodEmail ? 'Login' : 'Proceed'), [authMethod]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginMutation>({
    defaultValues: initialValues,
    resolver: yupResolver(isAuthMethodEmail ? validation : checkAuthMethodValidation),
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const [login, { isLoading }] = useLoginMutation();
  const [checkAuthMethod, { isLoading: isAuthMethodLoading }] = useCheckAuthMethodMutation();

  const isFormSubmitting = useMemo(
    () => isLoading || isAuthMethodLoading,
    [isLoading, isAuthMethodLoading]
  );

  const navigate = useNavigate();

  const handleCreateWorkspace = () => {
    navigate(`${routes.subscriptionsPlans}/auth`);
  };

  const checkForAuthMethod = useCallback((data: ICheckAuthMethodResponse) => {
    if (data.redirectUrl) {
      redirectToSSOResetPassword(data.redirectUrl);
      return;
    }
    setAuthMethod(data.isSso ? LOGIN_SSO_STATUS.sso : LOGIN_SSO_STATUS.email);
  }, []);

  const onSubmit = async (values: ILoginMutation) => {
    if (isAuthMethodEmail) {
      const res = await login(values);
      handleApiCall(res, ({ error }) => {
        if (error.status === 400) {
          showErrorMessage(NOTIFICATION.INVALID_LOGIN);
        } else {
          showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
        }
      });
    } else {
      // `checkAuthMethod` means POST to `/auth/is-sso/`
      const res = await checkAuthMethod({ email: values.email });
      handleApiCall(
        res,
        ({ error }: IError) => {
          if (error && 'data' in error) {
            const errorData = error.data as { email: string[] };
            return showErrorMessage(errorData.email[0]);
          }

          return showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
        },

        ({ data }) => checkForAuthMethod(data)
      );
    }
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, [emailRef.current]);

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [passwordRef.current, isAuthMethodEmail]);

  if (isAuthMethodSSO) {
    return (
      <Button fluid type="button" onClick={loginWithSSO} cypressAttribute="sign-in-with-sso">
        <Text weight="700" color="#000">
          Sign in with SSO
        </Text>
      </Button>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack fluid gap="48px">
        <TextInput
          register={register}
          name="email"
          label="Email"
          error={errors.email}
          ref={emailRef}
          disabled={isAuthMethodEmail || isFormSubmitting}
          data-cy="sign-in-email"
        />
        {isAuthMethodEmail && (
          <Stack gap="20px" fluid>
            <TextInput
              register={register}
              name="password"
              type="password"
              label="Password"
              ref={passwordRef}
              disabled={isFormSubmitting}
              error={errors.password}
              data-cy="sign-in-password"
            />
            <Group align="center" fluid justify="flex-end">
              <Button
                onClick={() => navigate(routes.forgotPassword)}
                variant="plain"
                cypressAttribute="forgot-password-btn"
              >
                Forgot my password
              </Button>
            </Group>
          </Stack>
        )}
        <Stack gap="25px" align="center">
          <Button disabled={isLoading} fluid type="submit" cypressAttribute="sign-in-submit-btn">
            {isFormSubmitting ? (
              <Spinner size="small" color={colors.dark} />
            ) : (
              <Text weight="600">{submitButtonText}</Text>
            )}
          </Button>
          <Text weight="600" size="md">
            or
          </Text>
          <Button variant="outlined" fluid type="button" onClick={handleCreateWorkspace}>
            <Text weight="700">Create workspace</Text>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Form;
