import styled from 'styled-components';
import colors from 'theme/colors';
import { Stack } from 'ui';

export const Jam = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ActiveJamCount = styled.span`
  position: absolute;
  bottom: -4px;
  right: -6px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 16px;
  font-weight: 600;
  height: 16px;
  padding: 1px;
  font-size: 11px;
  border-radius: 50%;
  color: white;
  background-color: ${colors.red2};
`;

export const JamsContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark5};
  padding: 18px;
  max-width: 430px;
  max-height: 300px;
  border-radius: 8px 0px 8px 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  width: 100vw;
`;

export const JamListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const JamNameTestContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
`;

export const JamItemEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
  color: ${({ theme }) => theme.colors.gray3};
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
`;
