import React, { FC, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import JamItemCard from './JamItemCard';
import * as Layout from './Layout';
import { IEncubeJam } from '../types';

interface IProps {
  jams: IEncubeJam[];
}

const JamsList: FC<IProps> = ({ jams }) => {
  const isEmpty = useMemo(() => jams.length === 0, [jams]);
  return (
    <Layout.JamListContainer>
      {isEmpty && (
        <Layout.JamItemEmpty>There are no live Jams for now. Check back later!</Layout.JamItemEmpty>
      )}
      {!isEmpty && jams.map((jam) => <JamItemCard key={jam.id} jam={jam} />)}
    </Layout.JamListContainer>
  );
};

export default JamsList;
