import { AppThunk } from 'app/store';
import { switchCase } from 'utils/match';
import { deepCamelcaseKeys } from 'utils/mappers';
// @ts-ignore ts don't have types for audio file
import notification from 'assets/audio/notification.mp3';
import { deleteMessage, pushMessage, selectFilter, updateMessage } from '../chat/chatSlice';
import { EMessageType, IMessageResponse } from '../chat/types';
import { selectUserInfo } from '../auth/userSlice';
import { EEventType, ISocketPayload } from './types';
import { checkFiltration } from '../chat/utils';
import { INotificationResponse } from '../notification/types';
import { addMessage } from '../notification/slice';

const isOwner = (ownerId: string, { type, creator }: IMessageResponse) =>
  type === EMessageType.USER_MESSAGE && creator.id === ownerId;

export const messageHandler =
  (context: IMessageResponse): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { id } = selectUserInfo(state);
    const filter = selectFilter(state);
    if (isOwner(id, context) || !checkFiltration(context.type, filter)) {
      return;
    }
    dispatch(pushMessage(context));
  };

export const eventHandler =
  (payload: ISocketPayload): AppThunk =>
  (dispatch) => {
    const { type, context } = payload;
    const data = deepCamelcaseKeys(context);
    switchCase(type)
      .on(EEventType.MESSAGE, () => dispatch(messageHandler(data)))
      .on(EEventType.MESSAGE_DELETE, () => dispatch(deleteMessage(context)))
      .on(EEventType.MESSAGE_UPDATE, () => dispatch(updateMessage(data)))
      .on(EEventType.LIKE, () => dispatch(updateMessage(data)))
      .on(EEventType.NOTIFICATION, () => {
        dispatch(addMessage(data));
        if (document.hasFocus()) {
          const audio = new Audio(notification);
          audio.play();
        }
      })
      .otherwise(() => null);
  };
