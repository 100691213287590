import { StylesConfig } from 'react-select';
import colors from 'theme/colors';

export const selectStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: 43,
    border: 'none',
    borderRadius: 4,
    backgroundColor: `${colors.dark2}`,
    boxShadow: 'none',
    color: 'white',
  }),
  option: (styles) => {
    return {
      ...styles,
      color: 'white',
      fontSize: 14,
      backgroundColor: `${colors.dark2}`,
      padding: 10,
      margin: 0,
      minWidth: 90,
      cursor: 'pointer',
      ':hover': { backgroundColor: `${colors.blue}` },
    };
  },
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
  input: (styles) => {
    return { ...styles, color: 'white' };
  },
  menuList: (styles) => ({ ...styles, padding: 0 }),
  placeholder: (styles) => ({ ...styles, color: `${colors.gray1}`, fontSize: '14px' }),
  multiValue: (styles) => {
    return {
      ...styles,
      justifyContent: 'space-evenly',
      backgroundColor: `${colors.blue2}`,
      borderRadius: '27px',
      padding: '0 5px 0 3px',
    };
  },
  multiValueLabel: (styles) => {
    return { ...styles, color: 'white', maxWidth: 160 };
  },
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles) => {
    return {
      ...styles,
      flexShrink: 0,
      color: `${colors.dark4}`,
      cursor: 'pointer',
      ':hover': { color: 'white' },
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: (styles) => {
    return { ...styles, color: 'white', cursor: 'pointer', ':hover': { color: 'white' } };
  },
  dropdownIndicator: (styles) => {
    return { ...styles, color: 'white', cursor: 'pointer', ':hover': { color: 'white' } };
  },
  valueContainer: (styles) => {
    return { ...styles, gap: 6, '> div': { width: 'max-content!important', maxWidth: '184px' } };
  },
};
