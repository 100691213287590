import React, { FC, useMemo } from 'react';
import { DEFAULT_GOOGLE_CREATOR } from 'constants/integrations';
import type { EMessageType, IMessageResponse } from 'modules/chat/types';
import { MessageContainer } from '../EncubeMessage/components/MessageContainer';
import { EncubeReplyHistory } from '../EncubeMessage/components/ReplyHistory';

const GoogleMessage: FC<IMessageResponse> = ({ replies, creator, messageType, ...messageData }) => {
  const adaptedReplies: IMessageResponse[] = useMemo(
    () =>
      replies.map((reply) => ({
        ...reply,
        creator: reply.creator || DEFAULT_GOOGLE_CREATOR,
      })),
    [replies]
  );

  return (
    <>
      <MessageContainer
        hasReplies={adaptedReplies.length > 0}
        creator={creator || DEFAULT_GOOGLE_CREATOR}
        replies={adaptedReplies}
        messageType={messageType as EMessageType}
        {...messageData}
      />
      <EncubeReplyHistory messages={adaptedReplies} messageType={messageType} />
    </>
  );
};

export default GoogleMessage;
