import _capitalize from 'lodash/capitalize';
import _compact from 'lodash/compact';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import _tail from 'lodash/tail';
import _uniqWith from 'lodash/uniqWith';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { IEvent, IEventResponse, IEventsById } from 'modules/events/types';
import { ENotificationTypes, INotificationResponse } from 'modules/notification/types';
import moment from 'moment';
import { handleApiCall } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/mappers';
import { ECopilotSuggestionTypes, ICopilotSuggestion } from './types';

export const determineEditModalData = (suggestionType: ECopilotSuggestionTypes | string) => {
  const createEventModal = 'createEvent' as const;

  switch (suggestionType) {
    case ECopilotSuggestionTypes.CREATE:
      return {
        name: 'Create an Event',
        modal: createEventModal,
      };
    case ECopilotSuggestionTypes.MODIFY:
      return {
        name: 'Edit an Event',
        modal: createEventModal,
      };
    default:
      return {
        name: 'Create an Event',
        modal: createEventModal,
      };
  }
};

/**
 * @description Compare IEvent payloads; get field(s) that have been modified.
 */
export const getModifiedEventFields = (
  currentEvent: any,
  copilotSuggestionData: ICopilotSuggestion
) => {
  if (!currentEvent) {
    return [];
  }

  const excludedFields = ['dueDate'];
  const transformedCopilotSuggestionData = deepCamelcaseKeys(copilotSuggestionData);

  const modifiedFields: string[] = [];

  Object.keys(currentEvent).forEach((key) => {
    if (
      Object.prototype.hasOwnProperty.call(transformedCopilotSuggestionData, key) &&
      transformedCopilotSuggestionData[key] &&
      currentEvent[key as keyof IEvent] !== transformedCopilotSuggestionData[key]
    ) {
      modifiedFields.push(key);
    }
  });

  return modifiedFields.filter((field) => !excludedFields.includes(field));
};

/**
 * @description Event modal edit (modify/update) submit function for copilot suggestions.
 */
export const onCopilotEditEvent = async (
  values: IEvent,
  copilotSuggestion: ICopilotSuggestion,
  threadId: number,
  editEventCallback: any, // Passed in from `useEditEventMutation()`
  closeCallback: () => void
) => {
  const payload = { ...values, thread: threadId, id: copilotSuggestion.event_uuid };
  const res = await editEventCallback(payload);
  return handleApiCall<IEventResponse>(
    res,
    () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
    () => {
      showSuccessMessage(NOTIFICATION.EVENT_UPDATED);
      closeCallback();
    }
  );
};

/**
 * Parse stringified copilot suggestions in notifications.
 */
export const parseNotificationCopilotSuggestions = (stringifiedJson: string) => {
  try {
    return JSON.parse(stringifiedJson);
  } catch (error) {
    console.error('[parseNotificationCopilotSuggestions]:', error);
    return null;
  }
};

/**
 * Determines whether passed in data matches signature of copilot suggestion.
 */
export const isCopilotSuggestion = (data: ICopilotSuggestion | ICopilotSuggestion[]): boolean => {
  if (Array.isArray(data)) {
    return data.every((item) => 'suggestion_type' in item);
  }

  return 'suggestion_type' in data;
};

/**
 * @description Gets text for copilot suggestions in notifications list.
 */
export const getNotificationTitleForCopilotSuggestion = (
  data: ICopilotSuggestion,
  eventsById?: IEventsById
) => {
  const { name, type, event_uuid } = data;

  switch (data.suggestion_type) {
    case ECopilotSuggestionTypes.CREATE:
      return `Copilot - Suggestion to create a new ${_capitalize(type)} called '${name}'.`;

    case ECopilotSuggestionTypes.MODIFY: {
      const eventData = eventsById && eventsById[event_uuid as keyof IEventsById];
      const modifiedEventFields =
        eventsById && getModifiedEventFields(eventsById[event_uuid as keyof IEventsById], data);

      if (eventData && modifiedEventFields && modifiedEventFields.length > 0) {
        const modifiedFieldsText = modifiedEventFields.map((field) => {
          // Split camel cased fields into readable "First Second" format.
          const formattedFieldName = field.replace(/([A-Z])/g, ' $1').trim();

          const currentValue = eventData[field as keyof IEventResponse];
          const newValue = data[field as keyof ICopilotSuggestion];

          return `${_capitalize(formattedFieldName)} from '${currentValue}' to '${newValue}'`;
        });

        const modifiedFieldsTextList = modifiedFieldsText.join(', ');

        return `Copilot - Suggestion to update event (${eventData.name})'s ${modifiedFieldsTextList}`;
      }

      return `Copilot - Suggestion to update event ${eventData ? `'${eventData.name}'` : ''}`;
    }

    default:
      return 'Copilot - Unknown suggestion.';
  }
};

/**
 * Filter only copilot suggestions from all notifications.
 */
export const getCopilotSuggestionFromNotifications = (
  notifications: INotificationResponse[],
  threadId?: string,
  mostRecentOnly = true
): ICopilotSuggestion[] => {
  // Get copilot suggestion notifications only and order by createdAt to get latest first.
  let filteredNotifications = _orderBy(
    _filter(notifications, {
      notificationType: ENotificationTypes.COPILOT_EVENT_SUGGESTIONS,
      thread: Number(threadId),
    }),
    ['createdAt']
  ).reverse();

  // If this flag is true, only fetch notifications created in the last 2 minutes.
  if (mostRecentOnly && filteredNotifications.length) {
    filteredNotifications = [
      // Keep the first item in there, so we return at least something even if 2 minutes has elapsed.
      filteredNotifications[0],
      ..._tail(
        filteredNotifications.filter(({ createdAt }: INotificationResponse) =>
          moment(createdAt).isAfter(moment().subtract(2, 'minutes'))
        )
      ),
    ];
  }

  let results = [];

  // Parse the `data` key to get the suggestion's data.
  results = filteredNotifications.map((notification) =>
    parseNotificationCopilotSuggestions(notification.data)
  );

  // Clean and remove duplicate objects (deep comparison).
  results = _compact(_uniqWith(results, _isEqual));

  // Select the first item in the list of suggestions to get the latest one.
  return results;
};

/**
 * Creates or modifies the event based on copilot's suggestion.
 * @param createOrUpdateEventRequest The create/edit API call and payload.
 * @param onComplete Method to be invoked after the request has completed.
 */
export const handleCreateOrUpdateEvent = async (
  createOrUpdateEventRequest: any,
  onComplete: () => void
) => {
  const res = await createOrUpdateEventRequest;
  handleApiCall<IEventResponse>(
    res,
    () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
    () => {
      showSuccessMessage(NOTIFICATION.EVENT_CREATED);
    }
  );
  onComplete();
};
