import React, { FC, useMemo } from 'react';
import { EMessageType, IMessageResponse } from 'modules/chat/types';
import { DEFAULT_ENCUBE_CREATOR } from 'modules/EncubeAnnotations';
import { MessageContainer } from './components/MessageContainer';
import { EncubeReplyHistory } from './components/ReplyHistory';

export const EncubeMessage: FC<IMessageResponse> = ({
  replies,
  creator,
  messageType,
  ...messageData
}) => {
  const adaptedReplies: IMessageResponse[] = useMemo(
    () =>
      replies.map((reply) => ({
        ...reply,
        creator: reply.creator || DEFAULT_ENCUBE_CREATOR,
      })),
    [replies]
  );

  return (
    <>
      <MessageContainer
        hasReplies={adaptedReplies.length > 0}
        creator={creator || DEFAULT_ENCUBE_CREATOR}
        replies={adaptedReplies}
        messageType={messageType as EMessageType}
        {...messageData}
      />
      <EncubeReplyHistory messages={adaptedReplies} messageType={messageType} />
    </>
  );
};
