import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'app/api';
import { RootState } from 'app/store';
import { IPaginatedResponse } from 'types';
import { IAnnotation, IAnnotationRaw } from '../types';
import { annotationsApi } from '../api';
import { DEFAULT_ENCUBE_CREATOR } from '../helpers';

interface IAnnotationsState {
  annotations: IAnnotation[];
  next: string | null;
  previous: string | null;
  isLoadingPrev: boolean;
  isLoadingNext: boolean;
}

export const initialState: IAnnotationsState = {
  annotations: [],
  next: null,
  previous: null,
  isLoadingNext: false,
  isLoadingPrev: false,
};

const adaptAnnotationItem = (annotation: IAnnotationRaw): IAnnotation => ({
  ...annotation,
  creator: annotation.creator || DEFAULT_ENCUBE_CREATOR,
  encubeComments: annotation.encubeComments.map((comment) => ({
    ...comment,
    creator: comment.creator || DEFAULT_ENCUBE_CREATOR,
  })),
});

export const loadNextAnnotationsByLink = createAsyncThunk(
  'encube-annotations/loadNext',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(annotationsApi.endpoints.encubeAnnotations.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IAnnotationRaw>;
  }
);

export const loadPrevAnnotationsByLink = createAsyncThunk(
  'encube-annotations/loadPrev',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(annotationsApi.endpoints.encubeAnnotations.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IAnnotationRaw>;
  }
);

const adaptAnnotations = (annotations: IAnnotationRaw[]): IAnnotation[] =>
  annotations.map(adaptAnnotationItem);

const annotationsSlice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    updatedAllAnnotations: (state, { payload }: PayloadAction<IAnnotationRaw[]>) => ({
      ...state,
      annotations: adaptAnnotations(payload),
    }),
    updateAnnotation: (state, { payload }: PayloadAction<IAnnotationRaw>) => ({
      ...state,
      annotations: state.annotations.map((annotation) =>
        annotation.encubeId === payload.encubeId ? adaptAnnotationItem(payload) : annotation
      ),
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addCase(loadPrevAnnotationsByLink.pending, (state) => ({
        ...state,
        isLoadingPrev: true,
      }))
      .addCase(loadNextAnnotationsByLink.pending, (state) => ({
        ...state,
        isLoadingNext: true,
      }))
      .addCase(loadPrevAnnotationsByLink.fulfilled, (state, { payload }) => {
        state.previous = payload.previous;
        state.annotations = [...adaptAnnotations(payload.results), ...state.annotations];
        state.isLoadingPrev = false;
        return state;
      })
      .addCase(loadNextAnnotationsByLink.fulfilled, (state, { payload }) => {
        state.annotations = [...state.annotations, ...adaptAnnotations(payload.results)];
        state.next = payload.next;
        state.isLoadingNext = false;
        return state;
      })
      .addMatcher(
        annotationsApi.endpoints.encubeAnnotationsPolling.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          next: payload.next,
          previous: payload.previous,
          annotations: adaptAnnotations(payload.results),
          isLoadingNext: false,
          isLoadingPrev: false,
        })
      )
      .addMatcher(
        annotationsApi.endpoints.encubeAnnotationById.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          annotations: state.annotations.map((annotation) =>
            annotation.encubeId === payload.encubeId ? adaptAnnotationItem(payload) : annotation
          ),
        })
      ),
});

export const { updateAnnotation, updatedAllAnnotations } = annotationsSlice.actions;
export const annotationsReducer = annotationsSlice.reducer;
export const selectAnnotations = (state: RootState) => state.annotations;
