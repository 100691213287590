import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Spinner, Text } from 'ui';
import { useGetRelatedThreadsQuery } from 'modules/relatedThreads/relatedThreadsApi';
import ThreadCard from 'modules/relatedThreads/components/ThreadCard';

const RelatedThreads: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetRelatedThreadsQuery(id as string);

  const relatedThreads = data?.results;

  if (isLoading) {
    return (
      <Stack justify="center" align="center" fluid fullHeight>
        <Spinner />
      </Stack>
    );
  }

  if (relatedThreads?.length) {
    return (
      <Stack gap="12px">
        {relatedThreads.map((thread) => (
          <ThreadCard key={thread.id} {...thread} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack align="center" justify="center" style={{ height: '50%' }}>
      <Text size="lg">No Related threads...</Text>
    </Stack>
  );
};

export default RelatedThreads;
