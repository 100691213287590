import React, { useCallback, useMemo } from 'react';
import Moment from 'react-moment';
import _capitalize from 'lodash/capitalize';
import { useParams } from 'react-router-dom';
import { Group, Text, Icon, Stack, DropDownAction, Tooltip, TagBadge, EncubeDropdown } from 'ui';
import { EPermissions } from 'app/permissions/constants';
import { IReferences, REFERENCE_TYPE } from 'modules/threads/types';
import {
  handleDownloadFile,
  isImage,
  getReferenceIconName,
  handleApiCall,
  truncateRawString,
  getUserNameAndFirstCharacterSurname,
  convertCentsToDollars,
  convertPlayableDeliveryDate,
} from 'utils/helpers';
import { useModal } from 'modules/modals/ModalProvider';
import AccessControl from 'app/permissions/AccessControl';
import colors from 'theme/colors';
import { encubeDropDownItems, EncubeDropdownAction } from 'constants/encube';
import { showErrorMessage } from 'modules/alert/utils';
import { useCreateReferenceFileJamMutation } from 'modules/threads/threadsApi';
import { useEncubeStatus } from 'modules/encube';
import { REFERENCE_INTEGRATION_ICONS } from 'constants/integrations';
import * as Layout from '../Layout';

interface IReference {
  reference: IReferences;
}

const ReferenceCard: React.FC<IReference> = ({ reference }) => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const { name, description, url, fileVersions, createdAt, icons } = reference;
  const latestFile = fileVersions.length ? fileVersions[0] : null;
  const { open } = useModal();

  const [createJam] = useCreateReferenceFileJamMutation();

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'Remove Reference',
      name: 'Remove Reference',
      modal: 'removeReference',
      context: {
        id: reference.id,
        name: reference.name,
        url: reference.url,
      },
      id: 'modal-remove-reference',
    });
  };

  const handleEdit = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'Edit Reference',
      name: 'Edit Reference',
      modal: 'editReference',
      context: {
        reference,
      },
      id: 'modal-edit-reference',
    });
  };

  const actions = useMemo(
    () => [
      {
        id: 'edit',
        title: 'Edit',
        action: handleEdit,
        icon: <Icon size="small" icon="PencilIcon" />,
        permissions: [EPermissions.EDIT_REFERENCES],
      },
      {
        id: 'delete',
        title: 'Delete',
        action: handleRemove,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_REFERENCES],
      },
    ],
    [reference]
  );
  const { id: threadId } = useParams();
  const isImg = isImage(latestFile?.file);
  const iconName = getReferenceIconName(reference.referenceType, isImg, reference.url);
  const creatorName = getUserNameAndFirstCharacterSurname(reference.creator);

  const isPlayableIntegrationFile = useMemo(() => {
    return reference.referenceType === REFERENCE_TYPE.PLAYABLE;
  }, [reference.referenceType]);

  const shouldShowEncubeDropDown = useMemo(() => {
    return !!latestFile?.encubeUrl && isEncubeFunctionalAvailable;
  }, [latestFile?.encubeUrl, isEncubeFunctionalAvailable]);

  const isJamActive = useMemo(() => {
    return reference.isJamActive;
  }, [reference.isJamActive]);

  const isFile = useMemo(() => {
    return reference.referenceType === REFERENCE_TYPE.FILE;
  }, [reference.referenceType]);

  const isFileWithActiveIntegrations = useMemo(() => {
    return (
      reference.referenceType === REFERENCE_TYPE.ENCUBE ||
      reference.referenceType === REFERENCE_TYPE.PLAYABLE
    );
  }, [reference.referenceType]);

  const hasIcons = useMemo(() => {
    return icons && icons.length > 0;
  }, [icons]);

  const navigateToEncubeWorkBench = useCallback(() => {
    window.open(latestFile?.encubeUrl as string, '_blank');
  }, [latestFile?.encubeUrl]);

  const shouldShowTooltip = useMemo(() => {
    return name.length > 20;
  }, [name]);

  const onEncubeDropdownItemClick = async (id: string | number) => {
    switch (id) {
      case EncubeDropdownAction.OPEN:
        navigateToEncubeWorkBench();
        break;
      case EncubeDropdownAction.OPEN_NOTIFY:
        if (latestFile) {
          const res = await createJam({ fileVersion: latestFile.id as number });
          handleApiCall(
            res,
            () => showErrorMessage('Could not create jam. Please try again later.'),
            () => navigateToEncubeWorkBench()
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <Layout.ReferenceCard data-cy="reference-card">
      <Group align="center" justify="space-between">
        <Group align="center" gap="10px">
          <Icon icon={iconName} stroke="none" />
          {hasIcons && icons?.map((icon) => REFERENCE_INTEGRATION_ICONS[icon])}
          <Tooltip
            data={truncateRawString(name, 60)}
            position="bottom"
            isVisible={shouldShowTooltip}
            cypressAttribute="reference-card-tooltip"
          >
            <Layout.Name size="sm" data-cy="reference-card-name">
              {truncateRawString(name, 28)}
            </Layout.Name>
          </Tooltip>
        </Group>
        <Group gap="10px" align="center">
          {isJamActive && (
            <TagBadge color={colors.green1} cypressAttribute="reference-card-jam-tag">
              <Group align="center">
                <Icon icon="JamListIcon" size="small" />
                <Text weight="600" size="xs">
                  Live
                </Text>
              </Group>
            </TagBadge>
          )}
          {latestFile ? (
            <a
              href={latestFile.file}
              title={name}
              download={name}
              rel="noreferrer"
              onClick={handleDownloadFile(name, latestFile.file)}
              data-cy="reference-card-latest-file"
            >
              <Icon icon="DownloadIcon" style={{ width: 20, height: 20 }} />
            </a>
          ) : (
            <a
              href={url}
              title={name}
              target="_blank"
              rel="noreferrer"
              data-cy="reference-card-external-link"
            >
              <Icon stroke="#6E7A9A" icon="ExternalLinkIcon" style={{ width: 20, height: 20 }} />
            </a>
          )}
          <AccessControl
            permissions={[EPermissions.EDIT_REFERENCES, EPermissions.DELETE_REFERENCES]}
            threadId={threadId as string}
            strict={false}
          >
            <Layout.IconButton onClick={() => null} color={colors.dark2}>
              <DropDownAction actions={actions} cypressAttribute="reference-card-actions" />
            </Layout.IconButton>
          </AccessControl>
        </Group>
      </Group>
      <Group justify="space-between" align="flex-end" gap="25px">
        <Stack fluid>
          {description &&
            (description.length > 130 ? (
              <Tooltip
                data={description}
                mode="dark"
                position="bottom"
                width="full"
                cypressAttribute="reference-card-description-tooltip"
              >
                <Layout.Description>{reference.description}</Layout.Description>
              </Tooltip>
            ) : (
              <Layout.Description>{reference.description}</Layout.Description>
            ))}
          <Group justify="space-between" fluid>
            <Group gap="15px">
              <Group align="center" gap="5px">
                <Icon icon="CalendarIcon" stroke="none" size="normal" />
                <Text
                  color={colors.gray3}
                  size="sm"
                  weight="500"
                  cypressAttribute="reference-card-date-text"
                >
                  <Moment format="MM/DD/YYYY" date={createdAt} />
                </Text>
              </Group>
              <Group align="center" gap="5px">
                <Icon icon="UserIcon" stroke={colors.gray3} size="normal" />
                <Text
                  color={colors.gray3}
                  weight="400"
                  size="sm"
                  cypressAttribute="reference-card-creator-name-text"
                >
                  {creatorName}
                </Text>
              </Group>
              {latestFile && (
                <Group align="center" gap="5px">
                  <Icon icon="AttachIcon" size="normal" stroke={colors.gray3} />
                  <Text
                    color={colors.gray3}
                    size="sm"
                    cypressAttribute="reference-card-file-size-text"
                  >
                    {latestFile.fileSize}
                  </Text>
                </Group>
              )}
            </Group>
            <Group gap="8px" align="center">
              {isFileWithActiveIntegrations && (
                <Icon icon="ReferenceIntegrationsIcon" path="#E96B18" />
              )}
              {isFile && !isImg && <Icon icon="ReferenceIntegrationsIcon" />}
              {shouldShowEncubeDropDown && (
                <EncubeDropdown
                  items={encubeDropDownItems}
                  onItemClick={onEncubeDropdownItemClick}
                />
              )}
              {isPlayableIntegrationFile && (
                <Layout.PlayableLogoWrapper>
                  <Tooltip
                    position="left"
                    data={
                      <Stack gap="8px">
                        <Text size="sm" color={colors.gray3}>
                          Quote total:{' '}
                          {convertCentsToDollars(
                            latestFile?.plyablePriceInCents,
                            latestFile?.plyablePriceCurrency
                          ) || 'N/A'}
                        </Text>
                        <Text size="sm" color={colors.gray3}>
                          Order Status: {_capitalize(latestFile?.plyableOrderStatus) || 'N/A'}
                        </Text>
                        <Text size="sm" color={colors.gray3}>
                          Est. delivery date:{' '}
                          {convertPlayableDeliveryDate(latestFile?.plyableEstimatedDeliveryDate)}
                        </Text>
                      </Stack>
                    }
                  >
                    <Icon icon="PlyableLogo" />
                  </Tooltip>
                </Layout.PlayableLogoWrapper>
              )}
            </Group>
          </Group>
        </Stack>
        {isImg && (
          <Layout.ImgBox data-cy="reference-card-img-box">
            <a
              href={latestFile?.file as string}
              download
              target="_blank"
              rel="noreferrer"
              data-cy="reference-card-img-box-link"
            >
              <img
                src={latestFile?.file as string}
                alt={name}
                data-cy="reference-card-img-box-img"
              />
            </a>
          </Layout.ImgBox>
        )}
      </Group>
    </Layout.ReferenceCard>
  );
};

export default ReferenceCard;
