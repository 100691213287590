import React, { useState, useEffect } from 'react';
import colors from 'theme/colors';
import { Group, Stack, Text, Spinner, Icon, Switch } from 'ui';
import { EThreadParams } from 'types';
import { useAppDispatch } from 'app/hooks';
import { resetFilter } from 'modules/threads/filterSlice';
import { ThreadTable, ThreadsFilter } from 'modules/threads/components';
import { FilterButton } from 'modules/threads/components/filter/Layout';
import { EDefaultSortOption, IThreadView } from 'modules/threads/types';
import { useThreadsList } from './hooks';

const DEFAULT_SORT_DSC = EDefaultSortOption.UPDATED_AT_DSC;

const TableView: React.FC<IThreadView> = ({ url, title, cypressAttribute, isAllThreads }) => {
  const [ordering, setOrdering] = useState<EThreadParams | string>(DEFAULT_SORT_DSC);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { data, isLoading, isFetching } = useThreadsList(url, ordering, false, isAllThreads);

  const threadsList = data?.results;
  const count = data?.count;

  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);

  if (isLoading) {
    return (
      <Stack align="center" fullHeight style={{ margin: '5rem auto' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <>
      <Group
        fluid
        align="center"
        justify="space-between"
        gap="25px"
        style={{ padding: '16px 0' }}
        data-cy={cypressAttribute && `${cypressAttribute}-header`}
      >
        <Text cypressAttribute={cypressAttribute && `${cypressAttribute}-header-text`}>
          {count} {title}
        </Text>

        <div>
          <FilterButton variant="plain" onClick={toggleFilter}>
            <Icon icon="FilterIcon" size="normal" stroke={colors.gray2} />
          </FilterButton>
          {showFilter && <ThreadsFilter toggleFilter={toggleFilter} />}
        </div>
      </Group>

      {threadsList?.length ? (
        <ThreadTable
          sortOption={ordering}
          list={threadsList}
          isAllThreads={isAllThreads}
          setSortOption={setOrdering}
          isLoading={isFetching}
          cypressAttribute="thread-table"
        />
      ) : (
        <Stack align="center" justify="center" style={{ height: '370px' }} data-cy="no-threads">
          <Text size="lg">No Threads...</Text>
        </Stack>
      )}
    </>
  );
};

export default TableView;
