import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { EPermissions } from 'app/permissions/constants';
import { EEventStatus, EEvents, EVENTS_NAME_MAP, EVENT_STATUS_NAME_MAP } from 'constants/events';
import { isEmpty } from 'lodash';
import _capitalize from 'lodash/capitalize';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'theme/colors';
import {
  Badge,
  Button,
  DropDownAction,
  Group,
  Icon,
  MemberList,
  Stack,
  StatusBadge,
  Text,
  Tooltip,
} from 'ui';
import { EVENTS_ICON_MAP } from 'ui/eventBadge/EventBadge';
import { getFullName } from 'utils/helpers';
import { NOTIFICATION } from 'modules/alert/constants';
import { FaExternalLinkAlt } from 'react-icons/fa';
import routes from 'constants/routes';
import { IEventResponse } from '../types';

interface IProps extends IEventResponse {
  showLinkToOriginThread?: boolean;
}

const ICON_MAP = {
  [EEvents.END]: <Icon size="large" icon="EndEventIcon" />,
  [EEvents.ISSUE]: <Icon size="large" icon="IssueEventIcon" />,
  [EEvents.QUESTION]: <Icon size="large" icon="QuestionEventIcon" />,
  [EEvents.INSIGHT]: <Icon size="large" icon="InsightEventIcon" />,
  [EEvents.ACTION]: <Icon size="large" icon="ActionEventIcon" />,
  [EEvents.DECISION]: <Icon size="large" icon="DecisionEventIcon" />,
  [EEvents.RESOLUTION]: <Icon size="large" icon="ResolutionEventIcon" />,
  [EEvents.STOPPED_STALLED]: <Icon size="large" icon="StoppedEventIcon" />,
};

const Card = styled.div<{ isCopied: boolean }>`
  padding: 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme, isCopied }) =>
    isCopied ? theme.colors.blue10 : theme.colors.dark2};
  border: ${({ theme, isCopied }) =>
    isCopied ? `1px solid ${theme.colors.blue}` : `1px solid ${theme.colors.dark2}`};
  margin-bottom: 16px;
  transition: background-color 0.3s ease-in-out;
`;

const CardWrapper = styled(Group)``;

const AssignedToContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
`;

const EventCard = (props: IProps) => {
  const {
    type,
    name,
    status,
    creator,
    createdAt,
    dueDate,
    thread,
    id,
    relatedEvent,
    relatedThread,
    assignees,
    showLinkToOriginThread = false,
  } = props;
  const { open } = useModal();
  const { id: threadId, eventId } = useParams();
  const { id: propsEventId } = props;

  const navigate = useNavigate();

  const date = moment(dueDate).format('MM/DD/YYYY');

  const handleEditEvent = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'event-type',
      name: 'Edit Event',
      modal: 'createEvent',
      context: {
        eventId: propsEventId,
        threadId,
        editModeInitialState: false,
      },
      id: 'modal-edit-event',
    });
  };

  const handleDelete = () => {
    if (relatedThread) {
      open({
        variant: 'center',
        contentLabel: 'warning message',
        name: 'Warning',
        modal: 'relateEventWarning',
        id: 'modal-relate-event-warning',
      });
      return;
    }
    open({
      variant: 'center',
      contentLabel: 'delete event',
      name: 'Delete an Event',
      modal: 'deleteEvent',
      context: {
        id,
      },
      id: 'modal-delete-event',
    });
  };

  const isCurrentEventCopied = Number(eventId) === id;

  const [isCopiedEvent, setIsCopiedEvent] = useState(isCurrentEventCopied);

  const handleCopyEventLink = async () => {
    const { id: eventID } = props;
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/thread/${threadId}/event/${eventID}`
      );
      showSuccessMessage('Event link copied');
    } catch (error) {
      showErrorMessage(NOTIFICATION.CLIPBOARD_COPY_FAILURE);
    }
  };

  const unHighlightEvent = useCallback(() => {
    if (isCurrentEventCopied && isCopiedEvent) {
      setIsCopiedEvent(false);
    }
  }, [isCurrentEventCopied, isCopiedEvent]);

  const assigneesList = useMemo(() => {
    return assignees.map((assignee) => {
      const { firstName, lastName, avatar } = assignee;
      return { firstName, lastName, avatar, id: assignee.id };
    });
  }, [assignees]);

  const actions = useMemo(
    () => [
      {
        id: '0',
        title: 'Copy link',
        action: handleCopyEventLink,
        icon: <Icon size="small" icon="CopyLinkIcon" />,
      },
      {
        id: '1',
        title: 'Edit',
        action: handleEditEvent,
        icon: <Icon size="small" icon="PencilIcon" stroke="white" />,
        permissions: [EPermissions.EDIT_EVENT],
      },
      {
        id: '2',
        title: 'Delete',
        action: handleDelete,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_EVENT],
      },
    ],
    [props]
  );

  const isRelation = useMemo(() => relatedThread && relatedEvent, [relatedThread, relatedEvent]);
  const isBrokenRelation = useMemo(
    () => (relatedThread && !relatedEvent) || (relatedEvent && !relatedThread),
    [relatedThread, relatedEvent]
  );
  const tooltipText = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `Duplicated event: ${name} was deleted from related thread. The link from related thread is broken`,
    [name, relatedThread]
  );

  const creatorName = getFullName(creator);

  /**
   *
   * @description formats dynamic keys and value for event hover tooltip.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatEventTooltipData = (eventKey: string, eventValue: any) => {
    switch (eventKey) {
      case 'creator':
        return { key: _capitalize(eventKey), value: eventValue.email };
      case 'status':
        return {
          key: _capitalize(eventKey),
          value: EVENT_STATUS_NAME_MAP[eventValue as EEventStatus],
        };
      case 'type':
        return { key: _capitalize(eventKey), value: EVENTS_NAME_MAP[eventValue as EEvents] };
      case 'dueDate':
        return { key: 'Due Date', value: moment(eventValue).format('LLLL') };
      default:
        return { key: _capitalize(eventKey), value: eventValue };
    }
  };

  const redirectToOriginThread = () => {
    navigate(`${routes.thread}/${thread.id}`);
  };

  const eventTooltipData = (
    <>
      <Text
        style={{ display: 'flex', alignItems: 'center' }}
        size="lg"
        cypressAttribute="event-card-tooltip-status-text"
      >
        {EVENTS_ICON_MAP[type as EEvents]}{' '}
        <span style={{ marginLeft: 5 }}>{EVENTS_NAME_MAP[type as EEvents]}</span>
      </Text>
      <br />
      <table data-cy="event-card-tooltip-table">
        {Object.entries(props).map(([eventKey, eventValue]) => {
          const omittedKeys = ['createdAt', 'updatedAt', 'assignees', 'thread'];

          if (omittedKeys.includes(eventKey) || eventValue === null) {
            return null;
          }

          const { key: formattedKey, value: formattedValue } = formatEventTooltipData(
            eventKey,
            eventValue
          );

          return (
            <tr data-cy={`event-card-table-tr-${eventKey}`} key={`${eventKey}-row`}>
              <td style={{ padding: 5 }} data-cy="event-card-table-td-formatted-key">
                <Text
                  size="sm"
                  weight="700"
                  cypressAttribute="event-card-table-td-formatted-key-text"
                >
                  {formattedKey}
                </Text>
              </td>
              <td style={{ padding: 5 }} data-cy="event-card-table-td-formatted-value">
                <Text cypressAttribute="event-card-table-td-formatted-value-text">
                  {formattedValue}
                </Text>
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );

  return (
    <Card isCopied={isCopiedEvent} onMouseLeave={unHighlightEvent} data-cy="event-card">
      <CardWrapper justify="space-between">
        <Group fluid gap="12px" style={{ minWidth: 0 }}>
          <Tooltip
            data={eventTooltipData}
            mode="dark"
            position="right"
            width="md"
            cypressAttribute="event-card-tooltip"
          >
            <div data-cy="event-card-type-icon">{ICON_MAP[type]}</div>
          </Tooltip>
          <Stack fluid style={{ minWidth: 0 }}>
            <Group fluid gap="5px" align="center" style={{ flexShrink: 0 }}>
              {isRelation && <Icon size="small" icon="LinkIcon" />}
              {isBrokenRelation && (
                <Tooltip data={tooltipText} mode="dark" position="right" width="md">
                  <Icon size="small" icon="BrokenLink" />
                </Tooltip>
              )}
              <Group overflow="hidden" align="center">
                <Button textColor={colors.blue7} variant="plain" onClick={handleEditEvent}>
                  <Text
                    size="md"
                    color={colors.blue7}
                    cut={30}
                    noWrap={false}
                    cypressAttribute="event-card-name-text"
                  >
                    {name}
                  </Text>
                </Button>
              </Group>
            </Group>
            <Group align="center" justify="space-between">
              <Text
                cut={33}
                color={colors.gray}
                cypressAttribute="event-card-creator-and-date-text"
              >
                {`Created by ${creatorName}`} (<Moment fromNow>{createdAt}</Moment>)
              </Text>
            </Group>

            {showLinkToOriginThread && (
              <Badge
                style={{ alignSelf: 'start' }}
                role="button"
                backgroundColor={colors.blue9}
                onClick={redirectToOriginThread}
                id="origin-thread"
                margin="10px 0 0"
              >
                <Group align="center">
                  <FaExternalLinkAlt size={10} />
                  <Text size="xs">{thread.name}</Text>
                </Group>
              </Badge>
            )}
          </Stack>
        </Group>

        <Group align="start">
          <StatusBadge status={status} text={date} cypressAttribute="event-card-status-badge" />
          <DropDownAction
            position="vertical"
            actions={actions}
            cypressAttribute="event-card-dropdown-actions"
          />
        </Group>
      </CardWrapper>

      {!isEmpty(assignees) && (
        <AssignedToContainer data-cy="event-card-assigned-to-container">
          <Text>Assigned to: </Text>
          <MemberList list={assigneesList} />
        </AssignedToContainer>
      )}
    </Card>
  );
};

export default EventCard;
