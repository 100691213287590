import React from 'react';
import ReferenceList from 'modules/references';
import Contributors from 'modules/contributors/index';
import Events from 'modules/events';
import Resources from 'modules/resources';
import RelatedThreads from 'modules/relatedThreads';
import * as Layout from './components/Layout';
import { EPermissions } from '../../app/permissions/constants';

export const THREAD_TABS_GROUP = [
  {
    id: 1,
    name: 'Events',
    content: (
      <Layout.TabContentWrap>
        <Events />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_EVENT],
  },
  {
    id: 2,
    name: 'Contributors',
    content: <Contributors />,
    permissions: [EPermissions.ADD_CONTRIBUTORS],
  },
  {
    id: 3,
    name: 'References',
    content: <ReferenceList />,
    permissions: [EPermissions.ADD_REFERENCES],
  },
  {
    id: 4,
    name: 'Resources',
    content: (
      <Layout.TabContentWrap>
        <Resources />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_RESOURCES],
  },
  {
    id: 5,
    name: 'Related Threads',
    content: (
      <Layout.TabContentWrap>
        <RelatedThreads />
      </Layout.TabContentWrap>
    ),
    permissions: [EPermissions.ADD_RELATED_THREADS],
  },
];
