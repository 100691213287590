import React from 'react';
import { IGanttTaskList } from 'modules/threads/types';
import { GanttTable, GanttTableRow, GanttTableCell } from './Layout';

const TaskListTable: React.FC<IGanttTaskList> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
}) => {
  return (
    <GanttTable fontFamily={fontFamily} fontSize={fontSize}>
      {tasks.map((task) => (
        <GanttTableRow rowHeight={rowHeight} key={task.id}>
          <GanttTableCell rowWidth={rowWidth}>
            <a href={`/thread/${task.id}`} title={task.name}>
              {task.name}
            </a>
          </GanttTableCell>
        </GanttTableRow>
      ))}
    </GanttTable>
  );
};

export default TaskListTable;
