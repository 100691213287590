/**
 * Slice for Threads Bot
 */

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { copilotApi } from 'modules/copilot/copilotApi';
import { IPaginatedResponse } from 'types';
import { generateShortUUID } from 'utils/helpers';
import {
  EThreadsBotMessageSenderTypes,
  EThreadsBotMessageTypes,
  IThreadsBotChatResponse,
  TThreadsBotMessage,
} from './types';

interface ICopilotChatPayload {
  message: string;
  pre_chat_history: string;
}

export const initialState = {
  messages: [] as TThreadsBotMessage[],
  expanded: false,
  floatingCardVisible: true,
};

/**
 * POST message to LLM and read its response.
 */
export const sendThreadsBotMessage = createAsyncThunk(
  'threadsBot/sendThreadsBotMessage',
  async ({ message, pre_chat_history }: ICopilotChatPayload, { dispatch }) => {
    const result = dispatch(
      copilotApi.endpoints.sendCopilotChatMessage.initiate({ message, pre_chat_history })
    );
    result.reset();
    const response = await result;
    return response as IThreadsBotChatResponse;
  }
);

const threadsBotSlice = createSlice({
  name: 'threadsBot',
  initialState,
  reducers: {
    /** Add message to state list of messages. */
    pushThreadsBotMessageToChat: (state, { payload }: PayloadAction<TThreadsBotMessage>) => {
      state.messages.push({
        ...payload,
        id: generateShortUUID(),
      });
      return state;
    },

    /** Add error message to state list of messages. */
    pushThreadsBotError: (state, _: PayloadAction) => {
      state.messages.push({
        message: 'ThreadsBot was unable to generate a response. Please try again.',
        type: EThreadsBotMessageTypes.ERROR,
        sender: EThreadsBotMessageSenderTypes.THREADS_BOT,
        id: generateShortUUID(),
      });
    },

    /** @description Set global state for whether floating action card is expanded. */
    toggleThreadsBotViewExpanded: (state) => {
      return { ...state, expanded: !state.expanded };
    },

    /** @description Set global display (boolean) for threads bot floating action card. */
    setFloatingCardVisible: (state, { payload }) => {
      return { ...state, floatingCardVisible: payload };
    },
  },
});

export const selectThreadsBotIsExpanded = (state: RootState) => state.threadsBot.expanded;
export const selectThreadsBotMessages = (state: RootState) => state.threadsBot.messages;

export const { toggleThreadsBotViewExpanded, pushThreadsBotMessageToChat, pushThreadsBotError } =
  threadsBotSlice.actions;

export default threadsBotSlice.reducer;
