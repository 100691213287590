import React, { FC, ReactElement } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { EThreadStatuses } from 'types';
import styled from 'styled-components';
import { THREAD_STATUS_COLORS, THREAD_STATUS_NAMES } from 'constants/index';
import Text from 'ui/text';
import { EEventStatus } from '../../constants/events';

interface IProps {
  status: EThreadStatuses | EEventStatus;
  text?: string | ReactElement;
  cypressAttribute?: string;
}

const Wrapper = styled.div<IProps>`
  padding: 4px 8px;
  background-color: ${({ status }) => THREAD_STATUS_COLORS[status]};
  border-radius: 4px;
  display: inline-block;
`;
const StyledText = styled(Text)`
  line-height: inherit;
  white-space: nowrap;
`;

const StatusBadge: FC<IProps> = ({ status, text = '', cypressAttribute }) => {
  const { width } = useWindowSize();
  const size = width < 1250;
  const statusText = size
    ? `${THREAD_STATUS_NAMES[status]}`
    : `${THREAD_STATUS_NAMES[status]} ${text}`;
  return (
    <Wrapper status={status} data-cy={cypressAttribute}>
      <StyledText weight="500">{statusText}</StyledText>
    </Wrapper>
  );
};

export default StatusBadge;
