import React, { FC, useCallback, useMemo } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Icon, Text } from 'ui';
import colors from 'theme/colors';
import { AVAILABLE_INTEGRATIONS, INTEGRATION_LIST } from 'constants/integrations';
import { IIntegration } from 'modules/integrations/types';
import { handleApiCall } from 'utils/helpers';
import { showErrorMessage } from 'modules/alert/utils';
import { useSendGoogleAccessTokenMutation } from 'modules/integrations/integrationsApi';
import * as Layout from './Layout';

interface IProps {
  integration: IIntegration;
  setSuccessIntegration?: (name: keyof typeof AVAILABLE_INTEGRATIONS) => void;
  isAdminPanel?: boolean;
  onClick?: (integrationName: keyof typeof AVAILABLE_INTEGRATIONS) => void;
}

const IntegrationListItem: FC<IProps> = ({
  integration,
  setSuccessIntegration,
  onClick,
  isAdminPanel = false,
}) => {
  const { name, connected } = integration;

  const { title, icon } = INTEGRATION_LIST[name];

  const [sendGoogleAuthCred, { isLoading }] = useSendGoogleAccessTokenMutation();

  const googleLogin = useGoogleLogin({
    scope: `https://www.googleapis.com/auth/${AVAILABLE_INTEGRATIONS[name]} https://www.googleapis.com/auth/drive`,
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async (codeResponse) => {
      const response = await sendGoogleAuthCred(codeResponse.code);
      handleApiCall(
        response,
        () => showErrorMessage('Could not connect to Google'),
        () => setSuccessIntegration && setSuccessIntegration(name)
      );
    },
    onError: (errorResponse) =>
      showErrorMessage(errorResponse?.error || 'Could not connect to Google'),
  });

  const handleItemClick = useCallback(() => {
    if (isAdminPanel && onClick) {
      onClick(name);
    } else {
      googleLogin();
    }
  }, [isAdminPanel]);

  const activeIntegrationText = useMemo(() => (connected ? 'Connected' : ' '), [connected]);

  return (
    <Layout.IntegrationItem
      connected={isAdminPanel ? false : connected}
      data-cy="integration-list-item"
    >
      <Layout.IntegrationItemButton
        connected={connected}
        disabled={connected || isLoading}
        type="button"
        onClick={handleItemClick}
        data-cy="integration-list-item-btn"
      >
        {icon}
        <Text
          size="sm"
          weight="500"
          align="center"
          noWrap
          color={colors.white}
          cypressAttribute="integration-list-item-title"
        >
          {title}
        </Text>
      </Layout.IntegrationItemButton>
      {!isAdminPanel && (
        <Text
          size="sm"
          weight="500"
          align="center"
          color={colors.green1}
          cypressAttribute="integration-list-item-text"
        >
          {activeIntegrationText}
        </Text>
      )}
      {isAdminPanel && (
        <Icon
          icon="CloseIcon"
          path={colors.white}
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            top: '8px',
            right: '8px',
            cursor: 'pointer',
          }}
          onClick={() => onClick && onClick(name)}
        />
      )}
    </Layout.IntegrationItem>
  );
};

export default IntegrationListItem;
