import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
 ${reset}

 html, body {
   box-sizing: border-box;
   height: 100%;
}

 *,
 *::before,
 *::after {
   box-sizing: inherit;
   margin: 0;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  font-style: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1c22;
  overflow: overlay;
 }

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a{
  text-decoration: none;
  color: inherit;
}

input {
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #242732 inset !important;
  -webkit-text-fill-color: #8B8B8E !important;
  border: 1px solid #7d6b48 !important;
 }

button {
  cursor: pointer;
}

table {
  width: 100%;
  table-layout: fixed;
}

tbody {
  width: 100%;
}

tr {
  width: 100%;
}

th,
td {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}

code {
  font-family: monospace;
}

::-webkit-scrollbar {
  width: 2px;
}

body::-webkit-scrollbar {
  width: 10px;
}

body:hover::-webkit-scrollbar-thumb {
  width: 10px;
  background: transparent;
  border-right: 1px solid #c8c4c4;
  height: 1px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #c8c4c4;
}

::-webkit-scrollbar-thumb:hover {
  transition: all .5s ease;
  background: #555;
}

@keyframes skeletonShine {
  0% {
    opacity: 0.2;
  }
  85%, 100% {
    opacity: 1;
  }
}

[role="button"] {
  cursor: pointer;
}

.spacer-left {
  margin-left: 5px;
}

.spacer-right {
  margin-right: 5px;
}
`;
