import styled from 'styled-components';
import { Stack } from 'ui';
import devices from 'theme/devices';

export const Container = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark1};
  padding: 20px;
  border-radius: 8px;
  height: 100%;
`;

export const Content = styled(Stack)`
  @media screen and ${devices.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

export const Labels = styled(Stack)`
  flex-wrap: wrap;
  @media screen and ${devices.mobileXL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ChartWrapper = styled.div`
  flex: 1;
  min-width: 150px;
  height: 150px;
  align-self: center;
`;
