import React, { useMemo } from 'react';
import colors from 'theme/colors';
import AccessControl from 'app/permissions/AccessControl';
import { useParams } from 'react-router-dom';
import { useModal } from 'modules/modals/ModalProvider';
import { Card } from 'modules/relatedThreads/components/Layout';
import { getFullName } from 'utils/helpers';
import { Stack, Group, DropDownAction, Text, Icon, Button, StatusBadge, Avatar } from 'ui';
import { IRelatedThread } from 'modules/relatedThreads/types';
import { EVENTS_ICON_MAP } from 'ui/eventBadge/EventBadge';
import { EThreadStatuses } from 'types';
import { EPermissions } from 'app/permissions/constants';

const ThreadCard: React.FC<IRelatedThread> = ({ id, relatedThread, relatedEvent }) => {
  const { open } = useModal();
  const { creator } = relatedThread;
  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'remove related thread',
      name: 'Remove Related Thread',
      modal: 'deleteRelatedThread',
      context: {
        relatedThreadId: id,
      },
      id: 'modal-delete-related-thread',
    });
  };

  const actions = useMemo(
    () => [
      {
        id: 'delete',
        title: 'Delete',
        action: handleRemove,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_RELATED_THREADS],
      },
    ],
    []
  );

  const creatorName = getFullName(creator);
  const { id: threadId } = useParams();

  return (
    <Card data-cy="thread-card">
      <Group gap="5px" align="center">
        <Stack fluid gap="16px">
          <Group gap="5px" align="center" justify="space-between">
            <a href={`/thread/${relatedThread.id}`} data-cy="thread-card-link">
              <Text cypressAttribute="thread-card-text">{relatedThread.name}</Text>
            </a>
          </Group>
          <Group gap="10px" justify="space-between">
            <Button
              leftIcon={<Avatar src={creator?.avatar} size="small" />}
              size="sm"
              color={colors.dark6}
              cypressAttribute="thread-card-creator-name"
            >
              {creatorName}
            </Button>
            {relatedEvent ? (
              <Group gap="15px">
                <Group align="center" data-cy="thread-card-event-group">
                  {EVENTS_ICON_MAP[relatedEvent.type]}
                  <Text cut={15} cypressAttribute="thread-card-event-name-text">
                    {relatedEvent.name}
                  </Text>
                </Group>
                <StatusBadge
                  status={relatedEvent.status}
                  cypressAttribute="thread-card-status-badge"
                />
              </Group>
            ) : (
              <StatusBadge
                status={EThreadStatuses.DELETED}
                cypressAttribute="thread-card-status-badge-deleted"
              />
            )}
          </Group>
        </Stack>
        <AccessControl
          permissions={[EPermissions.DELETE_RELATED_THREADS]}
          threadId={threadId as string}
        >
          <DropDownAction
            position="vertical"
            actions={actions}
            cypressAttribute="thread-card-dropdown-actions"
          />
        </AccessControl>
      </Group>
    </Card>
  );
};

export default ThreadCard;
