import React from 'react';
import colors from 'theme/colors';
import { Button, Group, Stack, Text } from 'ui';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { handleApiCall } from 'utils/helpers';
import { useModal } from 'modules/modals/ModalProvider';
import { useRemoveRelatedThreadMutation } from 'modules/relatedThreads/relatedThreadsApi';

interface IDeleteThreadProps {
  relatedThreadId: number;
}

const DeleteRelatedThread = ({ relatedThreadId: id }: IDeleteThreadProps) => {
  const [removeRelatedThread, { isLoading }] = useRemoveRelatedThreadMutation();
  const { close } = useModal();
  const handleRemoveThread = async () => {
    const res = await removeRelatedThread(id);
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        close();
        showSuccessMessage(NOTIFICATION.RELATED_THREAD_DELETED);
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete this related thread?
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={() => close()}
          cypressAttribute="modal-delete-related-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.red2}
          onClick={handleRemoveThread}
          cypressAttribute="modal-delete-related-thread-remove-btn"
        >
          {isLoading ? 'Removing' : 'Remove'}
        </Button>
      </Group>
    </Stack>
  );
};

export default DeleteRelatedThread;
