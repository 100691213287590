import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import routes from 'constants/routes';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { selectUserInfo } from 'modules/auth/userSlice';
import {
  useEndImpersonationMutation,
  useImpersonateUserMutation,
} from 'modules/impersonate/impersonateApi';
import { Card } from 'modules/resources/components/Layout';
import { useGetUserByEmailMutation, useGetUsersQuery } from 'modules/user/userApi';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { IPaginatedResponse } from 'types';
import { IUser } from 'types/user';
import { Avatar, Button, Icon, Spinner, Stack, Text, TextInput, Title } from 'ui';
import {
  UserImpersonationCard,
  UserImpersonationGrid,
  UserImpersonationSearchWrapper,
} from 'ui/impersonate';
import { isAdmin } from 'utils/helpers';
import { checkPermissionsStrict, getRole } from 'app/permissions/utils';
import { EPermissions } from 'app/permissions/constants';

const GET_USERS_PARAMS = {
  offset: 0,
};

const Impersonate = () => {
  const [userSearch, setUserSearch] = useState<string>('');
  const [userQueryPaginationLimit, setUserQueryPaginationLimit] = useState<number>(20);
  const [isManuallyInputtedImpersonationLoading, setIsManuallyInputtedImpersonationLoading] =
    useState<boolean>(false);
  const debouncedSearchValue = useDebounce(userSearch, 500);

  const isImpersonateButtonDisabled = _.isEmpty(userSearch);

  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching,
  } = useGetUsersQuery({
    limit: userQueryPaginationLimit,
    ...GET_USERS_PARAMS,
  });

  const {
    id: currentUserId,
    email: impersonatedUserEmail,
    isImpersonate,
    impersonatorId,
    userRole,
    canImpersonate,
  } = useAppSelector(selectUserInfo);

  const [getUserByEmail] = useGetUserByEmailMutation();
  const [
    impersonateUser,
    { isLoading: isLoadingImpersonateUser, originalArgs: impersonateUserUuidArgs },
  ] = useImpersonateUserMutation();
  const [endImpersonation, { isLoading: isLoadingEndImpersonation }] =
    useEndImpersonationMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!canImpersonate) {
      navigate(routes.dashboard);
    }
  }, [canImpersonate]);

  const handleEndImpersonation = async () => {
    await endImpersonation();
    window.location.reload();
    showSuccessMessage('Successfully ended impersonation.');
  };

  const handleImpersonate = async (user: IUser) => {
    await impersonateUser(user.id);
    window.location.reload();
    showSuccessMessage(`Successfully impersonating ${user.email}`);
  };

  /**
   * @description Handle when user inputs full email address to impersonate.
   */
  const handleManuallyInputtedImpersonation = async (email: string): Promise<void> => {
    if (_.isEmpty(email)) return;

    setIsManuallyInputtedImpersonationLoading(true);

    const userSearchResponse = await getUserByEmail(email);

    if (userSearchResponse && 'data' in userSearchResponse) {
      const userSearchData = userSearchResponse.data as unknown as IPaginatedResponse<IUser>;

      if (userSearchData.count === 0) {
        showErrorMessage(`User '${email}' could not be found.`);
      }

      const foundUser: IUser | null = userSearchData.results[0];
      if (foundUser) {
        handleImpersonate(foundUser);
      }
    }

    setIsManuallyInputtedImpersonationLoading(false);
  };

  const getMultiPropertySearchFields = (user: IUser) => [
    `${user.firstName} ${user.lastName}`,
    user.id,
    user.email,
  ];

  const filteredUsers: IUser[] = useMemo(() => {
    if (!users?.results) {
      return [];
    }

    if (_.isEmpty(userSearch)) {
      return users?.results;
    }

    return _.filter(users?.results, (user: IUser) =>
      _.some([...getMultiPropertySearchFields(user)], (prop) =>
        _.includes(prop.toLowerCase(), userSearch.toLowerCase().trim())
      )
    );
  }, [debouncedSearchValue, users?.results]);

  const renderSkeletonLoaderCards = () =>
    Array.from({ length: 20 }).map(() => (
      <Card style={{ display: 'flex', justifyContent: 'space-between' }} align="center" gap="12px">
        <ContentLoader
          backgroundColor={colors.blue9}
          foregroundColor={colors.blue8}
          viewBox="0 0 580 70"
        >
          <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
      </Card>
    ));

  return (
    <Stack gap="64px">
      {isImpersonate && [
        <Stack gap="20px" align="center">
          <Icon size="large" icon="InfoIcon" />
          <Text color={colors.gray} size="lg" cypressAttribute="impersonate-info-text">
            You are currently impersonating{' '}
            <span data-cy="impersonate-info-email" style={{ color: colors.white }}>
              {impersonatedUserEmail}
            </span>
          </Text>
          <Button
            disabled={isLoadingEndImpersonation}
            onClick={handleEndImpersonation}
            size="sm"
            color={colors.red}
            cypressAttribute="end-impersonation-btn"
          >
            {isLoadingEndImpersonation ? <Spinner /> : 'End Impersonation'}
            <Icon icon="ExitIcon" stroke={colors.white} />
          </Button>
        </Stack>,
      ]}

      <Title heading="h5" weight="600" cypressAttribute="impersonate-title">
        Impersonate
      </Title>

      <UserImpersonationSearchWrapper gap="10px">
        <TextInput
          isSearch
          name="search"
          placeholder="Search... Or enter full email address"
          aria-label="search"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setUserSearch(event.target.value)
          }
          onClearTextInput={() => setUserSearch('')}
          value={userSearch}
          maxLength={50}
          onKeyDown={(event: React.KeyboardEvent) =>
            event.key === 'Enter' && handleManuallyInputtedImpersonation(userSearch)
          }
          data-cy="impersonate-input-field"
        />
        <Button
          disabled={isImpersonateButtonDisabled || isManuallyInputtedImpersonationLoading}
          onClick={() => handleManuallyInputtedImpersonation(userSearch)}
          cypressAttribute="impersonate-btn"
        >
          Impersonate
        </Button>
      </UserImpersonationSearchWrapper>
      <UserImpersonationGrid gap="15px">
        {isUsersLoading || isFetching
          ? renderSkeletonLoaderCards()
          : filteredUsers.map((user) => {
              const isMe = user.id === currentUserId;

              // Disable impersonation buttons if user impersonated, or user id is current item in this map.
              const isImpersonationDisabled =
                (isLoadingImpersonateUser && impersonateUserUuidArgs === user.id) ||
                user.id === impersonatorId ||
                user.id === currentUserId;

              return (
                <UserImpersonationCard align="center" gap="12px" data-cy="impersonate-user-card">
                  <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <Avatar src={user.avatar} cypressAttribute="impersonate-user-card-avatar" />
                    <Stack>
                      <Text weight="500" cypressAttribute="impersonate-user-card-name">
                        {user.firstName} {user.lastName} {isMe && '(me)'}
                      </Text>
                      <Text
                        wordBreak="break-word"
                        color={colors.gray}
                        cypressAttribute="impersonate-user-card-email"
                      >
                        {user.email}
                      </Text>
                      <Text
                        color={colors.gray}
                        weight="200"
                        size="xs"
                        cypressAttribute="impersonate-user-card-id"
                      >
                        {user.id}
                      </Text>
                    </Stack>
                  </div>
                  <Button
                    size="sm"
                    style={{ opacity: isImpersonationDisabled ? 0.3 : 1 }}
                    color={colors.blue5}
                    variant="filled"
                    onClick={() => handleImpersonate(user)}
                    disabled={isImpersonationDisabled}
                    cypressAttribute="handle-impersonate-btn"
                  >
                    <Icon
                      stroke={isImpersonationDisabled ? colors.white : colors.gray}
                      icon="ImpersonateIcon"
                    />
                  </Button>
                </UserImpersonationCard>
              );
            })}
      </UserImpersonationGrid>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button
          disabled={users?.count === users?.results.length || isUsersLoading}
          onClick={() => setUserQueryPaginationLimit(userQueryPaginationLimit + 10)}
          cypressAttribute="impersonate-load-more-btn"
        >
          Load More
        </Button>
      </div>
    </Stack>
  );
};

export default Impersonate;
