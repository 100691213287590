import React from 'react';
import { useAppSelector } from 'app/hooks';
import { useCanContribute } from 'app/permissions/useCanContribute';
import { icons } from 'assets/icons';
import { EEvents } from 'constants/events';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useModal } from 'modules/modals/ModalProvider';
import { modals } from 'modules/modals/modals';
import { truncateRawString } from 'utils/helpers';
import moment from 'moment';
import { BsDot } from 'react-icons/bs';
import colors from 'theme/colors';
import { Avatar, Button, Group, Icon, Text } from 'ui';
import { EVENTS_KEY_MAP } from 'ui/eventBadge/EventBadge';
import { isObject } from 'lodash';
import { TUserMinimizeSnakeCase } from 'types/user';
import { ESystemMessageType, IMessageResponse } from '../../types';
import { Name, SysMessage, SysMessageContent } from '../Layout';
import { ACTION_MAP } from './constants';
import { checkForAction, parseSystemMessage } from './helpers';
import MessageDetail from './MessageDetail';

interface ITriggerProps {
  data: any;
  type: ESystemMessageType;
}

const Trigger = ({ data, type }: ITriggerProps) => {
  const { open } = useModal();
  const action = () =>
    open({
      variant: 'fullRight',
      contentLabel: 'action',
      name: ACTION_MAP[type].title,
      modal: ACTION_MAP[type].modal as keyof typeof modals,
      context: ACTION_MAP[type].getContext(data),
    });
  return (
    <Button
      textColor={colors.blue7}
      variant="plain"
      onClick={action}
      style={{ overflow: 'hidden' }}
    >
      <Name noWrap color={colors.blue7}>
        {data.name}
      </Name>
    </Button>
  );
};

const SystemMessage = ({ text, creator }: IMessageResponse) => {
  const { id, firstName, lastName } = useAppSelector(selectUserInfo);

  const { message, type, data, message_detail: messageDetail } = parseSystemMessage(text as string);

  const canContribute = useCanContribute();

  // replace entity name with button
  const formattedMessage = message
    // Omit string resource name.
    .replaceAll(`“${data.name}”.`, '');

  // exclude BE fields from payload
  const { updated_at, ...rest } = data;

  // Check whether `creator` returns a UUID only or an entire user object.
  const isCreatorDataPresent: boolean = isObject(data.creator);

  const isCreator = isCreatorDataPresent && (data.creator as TUserMinimizeSnakeCase).id === id;

  return (
    <SysMessage>
      <SysMessageContent justify="space-between" align="center" gap="10px">
        <Group overflow="hidden" align="center">
          {type === 'event' ? (
            <Icon
              icon={EVENTS_KEY_MAP[data.type?.toUpperCase() as EEvents] as keyof typeof icons}
            />
          ) : (
            <Icon icon="CheckCircle" />
          )}
          <Text>
            {checkForAction(message) && canContribute
              ? formattedMessage
              : truncateRawString(message, 100)}
          </Text>
          {checkForAction(message) && canContribute && <Trigger type={type} data={rest} />}
          {messageDetail?.length && (
            <MessageDetail description={messageDetail} cypressAttribute="system-message-detail" />
          )}
        </Group>

        <Group align="center">
          <Text size="xs" color={colors.gray}>
            {updated_at && moment(updated_at).format('L')}
          </Text>
          {isCreatorDataPresent && [
            <BsDot />,
            <Text size="xs" color={colors.gray}>
              {isCreator ? 'You' : (data.creator as TUserMinimizeSnakeCase).first_name}
            </Text>,
            <Avatar
              src={creator?.avatar || (data.creator as TUserMinimizeSnakeCase).avatar}
              size="mediumSmall"
            />,
          ]}
        </Group>
      </SysMessageContent>
    </SysMessage>
  );
};

export default SystemMessage;
