import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { useAppDispatch } from 'app/hooks';
import { MODAL_STATUS_LABELS_COLORS, MODAL_STATUS_LABELS_TEXT } from 'constants/labels';
import { UseFormRegister } from 'react-hook-form';
import { Button, FileLoader, Group, Icon, Stack, Text, Title } from 'ui';
import { IEditReferenceMutation, IFileVersions, REFERENCE_TYPE } from 'modules/threads/types';
import { CAD_FILE_PARAMS } from 'constants/index';
import { setReferenceName, setSelectedReference } from 'modules/threads/referencesSlice';
import FileVersionItem from './FileVersionItem';
import * as Layout from './Layout';

const UploadFileContainer = styled.div`
  margin-top: 12px;
}`;

interface IProps {
  versionFiles: IFileVersions[];
  fileName: string;
  attach: File | null;
  isLoading: boolean;
  fileType: typeof REFERENCE_TYPE[keyof typeof REFERENCE_TYPE];
  register: UseFormRegister<IEditReferenceMutation>;
  uploadFile: (file: File) => void;
  resetUpload: () => void;
  cypressAttribute?: string;
}

const FileVersionForm: FC<IProps> = ({
  versionFiles,
  fileName,
  attach,
  isLoading,
  fileType,
  register,
  uploadFile,
  resetUpload,
  cypressAttribute,
}) => {
  const dispatch = useAppDispatch();
  const hasVersionsFiles = !!versionFiles.length;

  const isEncubeFile = fileType === REFERENCE_TYPE.ENCUBE;

  useEffect(() => {
    if (versionFiles.length > 0) {
      const latestFileVersion = versionFiles[0];
      dispatch(setSelectedReference(latestFileVersion));
      dispatch(setReferenceName(fileName));
    }
  }, []);
  return (
    <Layout.AdditionalSectionContainer
      color={MODAL_STATUS_LABELS_COLORS[MODAL_STATUS_LABELS_TEXT.VERSIONED]}
      data-cy={cypressAttribute && `${cypressAttribute}-container`}
    >
      <Title
        heading="h6"
        color={colors.gray2}
        style={{ marginBottom: '10px' }}
        cypressAttribute={cypressAttribute && `${cypressAttribute}-title`}
      >
        {`Versions of ${fileName}`}
      </Title>
      <Stack
        gap="12px"
        style={{
          height: '100%',
          maxHeight: '180px',
          overflowY: hasVersionsFiles ? 'auto' : undefined,
        }}
        data-cy={cypressAttribute && `${cypressAttribute}-stack`}
      >
        {hasVersionsFiles ? (
          <>
            {versionFiles.map((file, index) => (
              <FileVersionItem
                key={file.id}
                file={file}
                fileName={fileName}
                isCurrentVersion={index === 0}
                fileType={fileType}
                cypressAttribute={cypressAttribute && `${cypressAttribute}-item`}
              />
            ))}
          </>
        ) : (
          <Text weight="500" color={colors.gray2} align="center">
            No versions yet
          </Text>
        )}
      </Stack>
      <UploadFileContainer data-cy={cypressAttribute && `${cypressAttribute}-upload-container`}>
        {attach ? (
          <Layout.FilePreviewItem
            data-cy={cypressAttribute && `${cypressAttribute}-preview-container`}
          >
            <Group
              gap="10px"
              align="center"
              data-cy={cypressAttribute && `${cypressAttribute}-preview-name`}
            >
              <Icon icon="ReferenceDocumentIcon" size="large" stroke="none" />
              <Text>{attach.name}</Text>
            </Group>

            <Button
              onClick={resetUpload}
              variant="plain"
              disabled={isLoading}
              cypressAttribute={cypressAttribute && `${cypressAttribute}-preview-close-btn`}
            >
              <Icon icon="CloseIcon" />
            </Button>
          </Layout.FilePreviewItem>
        ) : (
          <FileLoader
            register={register}
            name="file"
            accept={isEncubeFile ? CAD_FILE_PARAMS.ACCEPT : undefined}
            label="Upload new version"
            text="max size is 128MB"
            onChange={uploadFile}
          />
        )}
      </UploadFileContainer>
    </Layout.AdditionalSectionContainer>
  );
};

export default FileVersionForm;
