import React, { FC } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { MODAL_HEADER } from 'constants/index';
import { Divider } from 'ui';
import './styles.css';

interface IModal {
  name?: string;
  contentLabel?: string;
  variant: TVariant;
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
  id?: string;
}

export type TVariant = 'fullRight' | 'fullLeft' | 'center' | 'default';

enum EVariants {
  fullRight = 'modal_full-right',
  fullLeft = 'modal_full-left',
  center = 'modal_center',
  default = 'modal_default',
}

const ModalContent = styled.div`
  padding: 24px;
`;

const Modal: FC<IModal> = ({
  isOpen,
  onRequestClose,
  contentLabel,
  name,
  variant,
  children,
  id,
}) => {
  const position = EVariants[variant] || EVariants.default;
  const zIndex = position === EVariants.center ? 'z-100' : 'z-99';

  return (
    <ReactModal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={{
        base: `modal_base ${position}`,
        afterOpen: 'modal_after-open',
        beforeClose: 'modal_before-close',
      }}
      overlayClassName={{
        base: 'modal-overlay',
        afterOpen: `modal-overlay_after-open ${zIndex}`,
        beforeClose: 'modal-overlay_before-close',
      }}
      shouldCloseOnOverlayClick
      closeTimeoutMS={500}
      id={id}
    >
      {name && <h5 className="modal_title">{name}</h5>}
      <Divider />
      <button data-cy={id && `${id}-close-btn`} className="modal-close" onClick={onRequestClose}>
        ✕
      </button>
      <ModalContent data-cy={id && `${id}-content`} className="modal-content">
        {children}
      </ModalContent>
    </ReactModal>
  );
};

export default Modal;
