import React, { FC } from 'react';
import { Button } from 'ui';
import { ITabsNav, ITab } from './types';
import * as Layout from './Layout';

const TabsNav: FC<ITabsNav> = ({ tabs, activeTabId, onClick, cypressAttribute }) => {
  return (
    <Layout.TabsList data-cy={cypressAttribute}>
      {tabs.map((tab: ITab) => (
        <Layout.TabNavItem
          key={tab.id}
          active={activeTabId === tab.id}
          data-cy={cypressAttribute && `${cypressAttribute}-item`}
        >
          <Button
            variant="plain"
            textColor="inherit"
            onClick={() => onClick(tab.id)}
            cypressAttribute={cypressAttribute && `${cypressAttribute}-item-button`}
          >
            {tab.name}
          </Button>
        </Layout.TabNavItem>
      ))}
    </Layout.TabsList>
  );
};

export default TabsNav;
