import React from 'react';
import { useAppSelector } from 'app/hooks';
import Logo from 'assets/icons/Logo.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import * as AppLayout from 'layout/App';
import { selectUser } from 'modules/auth/userSlice';
import CartImg from 'pages/login/assets/ChartBar.png';
import * as Layout from 'pages/login/components/Layout';
import styled from 'styled-components';
import { mediaSize } from 'theme/devices';
import { Button, Group, Stack, Text, Title } from 'ui';
import { useUpdateProfileMutation } from 'modules/user/userApi';
import moment from 'moment';
import { handleApiCall, redirectToSSOResetPassword } from 'utils/helpers';
import { IUserProfileMutation } from 'modules/user/types';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage } from 'modules/alert/utils';
import colors from 'theme/colors';
import { useSubscription } from 'hooks/useSubscription';
import { selectSubscriptionName } from 'modules/subscriptionsPlans/subscriptionsPlansSlice';

const TermsOfService = () => {
  const { userInfo } = useAppSelector(selectUser);
  const subscriptionName = useAppSelector(selectSubscriptionName);
  const size = useWindowSize();
  const { isLoading: isSubscriptionLoading } = useSubscription();
  const [updateProfile, { isLoading: isFetchingUpdateProfile }] = useUpdateProfileMutation();

  const isBusinessSubscriptionUser = subscriptionName === 'business';

  const ContentWraper = styled(AppLayout.Container)`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  `;

  const onAcceptTermsAndConditions = async () => {
    const res = await updateProfile({
      termsAndConditions: moment().toDate(),
    });

    handleApiCall<IUserProfileMutation>(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      ({ data }) => redirectToSSOResetPassword(data.redirectUrl)
    );
  };

  return (
    <Group style={{ height: '100vh' }} fullHeight>
      <Stack style={{ flex: 1 }} justify="center" align="center">
        <ContentWraper>
          <img width={260} src={Logo} alt="logo" />
          <Title heading="h5" cypressAttribute="terms-of-service-title">
            Terms of Service
          </Title>
          <Text size="lg" style={{ opacity: 0.6, lineHeight: '24px' }}>
            <>
              You must accept the{' '}
              <a
                style={{ color: colors.blue }}
                href={
                  isBusinessSubscriptionUser
                    ? 'https://www.authentise.com/terms-of-service'
                    : 'https://www.authentise.com/tos-users'
                }
                className="link"
                target="_blank"
                rel="noreferrer"
                data-cy="terms-of-service-link"
              >
                Terms of Service
              </a>{' '}
              to use this software as {userInfo.email}.
            </>
          </Text>
          <Button
            disabled={isFetchingUpdateProfile || isSubscriptionLoading}
            cypressAttribute="accept-terms-and-conditions-btn"
            onClick={onAcceptTermsAndConditions}
          >
            I Accept
          </Button>
        </ContentWraper>
      </Stack>
      {size.width > mediaSize.laptop && (
        <Layout.RightSide justify="end" align="end">
          <Layout.TextLayer gap="15px" fluid fullHeight>
            <Title heading="h5" style={{ lineHeight: '38px' }}>
              Enable cross functional agile teams to more easily work together on common
              work-threads
            </Title>
            <Title heading="h6" style={{ opacity: 0.6 }}>
              Capture ideas... Share Insights... Own work-threads... Mint decisions... Commit
              actions... Drive Focus.... Iterate fast
            </Title>
          </Layout.TextLayer>
          <Layout.CircleBig />
          <Layout.CircleSmall />
          <Layout.CircleFilled />
          <img src={CartImg} style={{ position: 'relative', width: '80%' }} alt="chart" />
        </Layout.RightSide>
      )}
    </Group>
  );
};

export default TermsOfService;
