import React, { FC } from 'react';
import { AnnotationContent } from './AnnotationContent';
import { AnnotationsSkeleton } from './AnnotationsSkeleton';
import { AnnotationAvatar, AnnotationItemWrapper } from './AnnotationLayout';
import { IEncubeComment } from '../types';
import { useLazyEncubeAnnotationByIdQuery } from '../api';

interface IAnnotationItemProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Annotation author avatar source.
   */
  authorAvatarSrc: string;
  /**
   * Annotation author name.
   */
  authorName: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * List of comments for the annotation.
   */
  comments: IEncubeComment[];
}

export const AnnotationItem: FC<IAnnotationItemProps> = (props) => {
  const {
    id,
    authorAvatarSrc,
    authorName,
    createdAt,
    fileId,
    fileName,
    fileVersion,
    fileUrl,
    comments = [],
  } = props;

  const [fetchAnnotation, { isFetching }] = useLazyEncubeAnnotationByIdQuery();

  const annotationRefreshClickHandler = (annotationId: string) => {
    fetchAnnotation({ id: annotationId });
  };

  if (isFetching) {
    return <AnnotationsSkeleton />;
  }

  return (
    <AnnotationItemWrapper
      fluid
      gap="12px"
      justify="flex-start"
      align="flex-start"
      data-cy="annotation-item-wrapper"
    >
      <AnnotationContent
        key={id}
        id={id}
        authorName={authorName}
        createdAt={createdAt}
        fileId={fileId}
        fileName={fileName}
        fileVersion={fileVersion}
        fileUrl={fileUrl}
        comments={comments}
        onRefreshClick={annotationRefreshClickHandler}
      />
      <AnnotationAvatar size="mediumSmall" src={authorAvatarSrc} data-cy="annotation-avatar" />
    </AnnotationItemWrapper>
  );
};
