import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Group, Avatar } from 'ui';
import { formatAvatarSrcURI, isValidUrl } from 'utils/helpers';
import { ENV } from 'constants/env';
import { IMember, IMemberListProps } from './types';
import RestMembers from './Restmembers';

const StyledAvatar = styled(Avatar)<{ offset: number }>`
  margin-left: -${({ offset }) => offset && 10}px;
`;

const MemberList: FC<IMemberListProps> = ({ show = 4, list, disabled }) => {
  const filteredList = list.reduce((acc, member) => {
    if (!acc.find((m) => m.id === member.id)) acc.push(member);
    return acc;
  }, [] as IMember[]);
  const data = useMemo(() => filteredList.slice(0, show), [show, list]);
  const restMembers = filteredList.slice(show);

  return (
    <Group gap="0" data-cy="members-list-group">
      {data.map((member, index) => (
        <StyledAvatar
          size="mediumSmall"
          key={member.id}
          offset={index}
          src={formatAvatarSrcURI(ENV.REACT_APP_API_URL, member.avatar)}
          data-cy="members-list-avatar"
        />
      ))}
      {restMembers.length > 0 && <RestMembers disabled={disabled} list={list.slice(show)} />}
    </Group>
  );
};

export default MemberList;
