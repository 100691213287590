import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import path from 'constants/path';
import routes from 'constants/routes';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { NOTIFICATION } from 'modules/alert/constants';
import { selectUserRole } from 'modules/auth/userSlice';
import { resetView, selectGanttView, toggleView } from 'modules/threads/GanttSlice';
import StatisticCard from 'modules/threads/components/StatisticCard';
import { useThreadsList } from 'modules/threads/hooks';
import {
  useCreateReferenceMutation,
  useCreateThreadMutation,
  useGetViewabilityOfThreadsQuery,
} from 'modules/threads/threadsApi';
import { EDefaultSortOption, REFERENCE_TYPE } from 'modules/threads/types';
import moment from 'moment';
import * as Layout from 'pages/dashboard/components/Layout';

import { useLocation, useNavigate } from 'react-router-dom';
import { EThreadPriority, EThreadStatuses } from 'types';
import { Stack, Switch } from 'ui';
import { handleApiCall, isGuest } from 'utils/helpers';

import { AllThreads, MyContributions, MyThreads } from './components';
import DashboardEvents from './components/DashboardEvents';
import WrappedDropdownSelector from './components/WrappedDropdownSelector';
import { TDropdownSelectorOption } from './types';

const lastWeek = moment().subtract(7, 'days').toDate();
const lastMonth = moment().subtract(30, 'days').toDate();

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const isGantt = useAppSelector(selectGanttView);

  const { data: guestViewabilityAllThreads } = useGetViewabilityOfThreadsQuery(undefined, {
    skip: !isGuest(userRole),
  });

  const { data: myThreads } = useThreadsList(
    path.threadsList,
    EDefaultSortOption.UPDATED_AT_DSC,
    isGuest(userRole)
  );

  const { data: myContributions } = useThreadsList(path.contributionsList);
  const { data: allThreads } = useThreadsList(
    path.allThreadsList,
    EDefaultSortOption.UPDATED_AT_DSC,
    isGuest(userRole) && !guestViewabilityAllThreads
  );

  const [createThread] = useCreateThreadMutation();
  const [createReference] = useCreateReferenceMutation();

  const { handleSubscriptionPermission } = useSubscriptionPermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const headerHeight = 140;

  const { pathname, search } = location;
  const searchData = new URLSearchParams(search);

  /**
   * For dropdown listing threads in table.
   */
  const renderThreadsListOptions = useCallback(() => {
    const threadsListOptions: TDropdownSelectorOption[] = [
      {
        id: 1,
        name: `My Threads ${myThreads ? `(${myThreads.count})` : ''}`,
        content: <MyThreads />,
        permissions: [],
      },
      {
        id: 2,
        name: `My Contributions ${myContributions ? `(${myContributions.count})` : ''}`,
        content: <MyContributions />,
        permissions: [],
      },
      {
        id: 3,
        name: `All Threads ${allThreads ? `(${allThreads.count})` : ''}`,
        content: <AllThreads />,
        permissions: [],
      },
    ];

    if (isGuest(userRole)) {
      if (guestViewabilityAllThreads) {
        return threadsListOptions.filter((option) => option.id !== 1);
      }
      return threadsListOptions.filter((option) => option.id !== 1 && option.id !== 3);
    }
    return threadsListOptions;
  }, [myThreads, myContributions, allThreads, guestViewabilityAllThreads, userRole]);

  /**
   * For dropdown listing user's statistics.
   */
  const threadsStatisticOptions: TDropdownSelectorOption[] = [
    {
      id: 1,
      name: 'Last 7 Days',
      content: (
        <StatisticCard title="Last 7 days" cypressAttribute="last-7-days" dateFrom={lastWeek} />
      ),
      permissions: [],
    },
    {
      id: 2,
      name: 'Last 30 Days',
      content: (
        <StatisticCard title="Last 30 days" cypressAttribute="last-30-days" dateFrom={lastMonth} />
      ),
      permissions: [],
    },
  ];

  const handleThreadsViewChange = () => {
    dispatch(toggleView());
  };

  useEffect(() => {
    return () => {
      dispatch(resetView());
    };
  }, []);

  const handleCreateThreadFromUrl = async () => {
    const newThreadName = searchData.get('name');
    const newThreadDescription = searchData.get('description');
    const newThreadReferenceUri = searchData.get('reference_uri');

    const isRequiredDataPresent: boolean = [newThreadName].every(Boolean);

    if (pathname === routes.new_thread && isRequiredDataPresent) {
      const createThreadResponse = await createThread({
        name: newThreadName as string,
        description:
          newThreadDescription || `Thread about ${newThreadName} ${newThreadReferenceUri}`,
        status: EThreadStatuses.ON_TARGET,
        priority: EThreadPriority.LOW,
        tags: [],
        isPrivate: false,
      });
      handleApiCall(
        createThreadResponse,
        (error) => {
          handleSubscriptionPermission(error, NOTIFICATION.INVALID_THREAD);
        },
        ({ data }) => {
          const createdThreadId = data.id;
          const newThreadUri = `${routes.thread}/${createdThreadId}`;
          navigate(newThreadUri);

          const referencePayload = {
            name: newThreadName as string,
            description: null,
            url: newThreadReferenceUri as string,
            thread: createdThreadId,
            file: null,
            fileSize: null,
            referenceType: REFERENCE_TYPE.LINK,
            plyableQuoteFor: '',
          };

          if (newThreadReferenceUri) {
            createReference(referencePayload);
          }
        }
      );
    }
  };

  useEffect(() => {
    handleCreateThreadFromUrl();
  }, []);

  // ${height - headerHeight}px
  return (
    <Layout.DashboardContainer id="dashboard-container">
      {!isGantt && (
        // Hide all of this content if gantt view is enabled to make space for it.
        <Layout.FlexItem id="left-flex-container">
          <Stack fullHeight maxHeight={`calc(100vh - ${headerHeight}px)`} gap="20px">
            <Layout.FlexItem id="dashboard-statistics-flex-container">
              <WrappedDropdownSelector
                title="Statistics"
                options={threadsStatisticOptions}
                cypressAttribute="statistic-options"
              />
            </Layout.FlexItem>

            <Layout.FlexItem id="dashboard-events-flex-container" overflow>
              <DashboardEvents />
            </Layout.FlexItem>
          </Stack>
        </Layout.FlexItem>
      )}

      <Layout.FlexItem id="right-flex-container" overflow>
        <Stack overflow maxHeight={`calc(100vh - ${headerHeight}px)`}>
          <WrappedDropdownSelector
            options={renderThreadsListOptions()}
            cypressAttribute="threads-options"
          >
            <Switch
              leftText="List View"
              rightText="Live View"
              checked={isGantt}
              onChange={handleThreadsViewChange}
              cypressAttribute="switch-view"
            />
          </WrappedDropdownSelector>
        </Stack>
      </Layout.FlexItem>
    </Layout.DashboardContainer>
  );
};

export default Dashboard;
