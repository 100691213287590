import styled from 'styled-components';

export const DropdownItemButton = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  gap: 6px;
  padding: 6px 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark5};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:active {
    transition-duration: 0s;
    background-color: ${({ theme }) => theme.colors.dark5};
    opacity: 0.6;
  }
`;
