import React, { FC } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import colors from 'theme/colors';
import { sizeMap } from './constants';
import { SMALL, MEDIUM, LARGE } from '../../constants';

export type TSpinnerSize = typeof SMALL | typeof MEDIUM | typeof LARGE;

interface IProps {
  color?: string;
  size?: TSpinnerSize;
}

const Spinner: FC<IProps> = ({ color = colors.gray1, size = MEDIUM }) => {
  return <RotatingLines strokeColor={color} animationDuration="0.75" {...sizeMap[size]} visible />;
};

export default Spinner;
