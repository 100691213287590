import { SUBSCRIPTION_ERROR_KEYS } from 'constants/subscription';
import { showErrorMessage, showWarningMessage } from 'modules/alert/utils';
import { useModal } from 'modules/modals/ModalProvider';
import { IError } from 'types';

interface ISubscriptionLimitError {
  detail: string;
  subscription_limit_error: keyof typeof SUBSCRIPTION_ERROR_KEYS;
  redirect_url: string | null;
}

const useSubscriptionPermissions = () => {
  const { open } = useModal();
  /**
   * @description
   * Handle subscription permission error
   * @param error
   * Error object that comes from BE
   * @param fallBackErrorMessage
   * A fall back error message in case the error is not a subscription error
   */
  const handleSubscriptionPermission = (
    { error }: IError,
    fallBackErrorMessage = 'An error occurred. Please try again.'
  ) => {
    const customErrorResponse = error?.data as ISubscriptionLimitError;
    if (error.status === 403 && customErrorResponse.subscription_limit_error) {
      showWarningMessage('You have reached your subscription limit.');
      open({
        variant: 'center',
        contentLabel: 'subscriptionLimits',
        name: 'Subscription Limits',
        modal: 'subscriptionLimits',
        context: {
          subscriptionErrorKey: customErrorResponse.subscription_limit_error,
          redirectUrl: customErrorResponse.redirect_url,
        },
      });
      return;
    }
    showErrorMessage(fallBackErrorMessage);
  };

  return { handleSubscriptionPermission };
};

export default useSubscriptionPermissions;
