import { useAppSelector } from 'app/hooks';
import { selectFeatures } from 'modules/app/appSlice';
import _find from 'lodash/find';
import { TFeatureFlag } from 'types';
import { EFeatureFlags } from 'constants/features';

/**
 * Hook for only checking feature flags are enabled.
 */
export const useIsFeatureEnabled = (featureName: EFeatureFlags): boolean => {
  const allFeatures: TFeatureFlag[] = useAppSelector(selectFeatures);

  if (!allFeatures.length) {
    return false;
  }

  const feature = _find(allFeatures, { name: featureName });

  return Boolean(feature?.isEnabled);
};

/**
 * Hook for feature flags and util functions.
 */
const useFeatures = () => {
  const allFeatures: TFeatureFlag[] = useAppSelector(selectFeatures);

  return [allFeatures];
};

export default useFeatures;
