import * as yup from 'yup';
import { CountryCode, isPossiblePhoneNumber } from 'libphonenumber-js';

export const MESSAGE = {
  REQUIRED: 'Required field',
  NOT_EMAIL: 'Invalid email',
  PASSWORD:
    // eslint-disable-next-line max-len
    'Should contain at least one digit, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8-16 characters long',
  PASSWORDS_SHOULD_MATCH: 'Passwords should match',
  INVALID_PHONE: 'Invalid phone number',
  INVALID_EMAIL: 'Invalid email format',
  INVALID_TEXTAREA: 'Ensure this field has no more than 1000 characters',
  INVALID_TEXT_LENGTH: 'Ensure this field has no more than 100 characters',
  INVALID_URL: 'Invalid url',
  INVALID_AMOUNT: 'The field should have digits only',
};

const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
const DIGITS_ONLY_REGEX = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
export const EMAIL_REGEX = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
export const DOMAIN_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?\s]+)\.[^\/\n?]+(?:\/[^\/\n?]+)*$/;

const validatePassword = (value: string) => PASSWORD_REGEX.test(value);
const validateAmount = (value: string) => DIGITS_ONLY_REGEX.test(value);

export const validationSchema = {
  emailRequired: () => yup.string().email(MESSAGE.NOT_EMAIL).required(MESSAGE.REQUIRED),
  requiredMix: () => yup.mixed().required(MESSAGE.REQUIRED),
  password: () =>
    yup
      .string()
      .matches(PASSWORD_REGEX, { message: MESSAGE.PASSWORD, excludeEmptyString: true })
      .nullable(true),
  passwordRequired: () =>
    yup.mixed().required(MESSAGE.REQUIRED).test('password', MESSAGE.PASSWORD, validatePassword),
  string: () => yup.string().max(100, MESSAGE.INVALID_TEXT_LENGTH),
  stringRequired: () =>
    yup.string().max(100, MESSAGE.INVALID_TEXT_LENGTH).required(MESSAGE.REQUIRED),
  textArea: () => yup.string().max(1000, MESSAGE.INVALID_TEXTAREA),
  url: () => yup.string().url(MESSAGE.INVALID_URL).nullable(),
  amount: () =>
    yup.mixed().required(MESSAGE.REQUIRED).test('amount', MESSAGE.INVALID_AMOUNT, validateAmount),
  confirmPassword: (field?: string) =>
    yup
      .mixed()
      .required(MESSAGE.REQUIRED)
      .oneOf([yup.ref(field || 'password')], MESSAGE.PASSWORDS_SHOULD_MATCH),
  phoneRequired: () =>
    yup
      .string()
      .required(MESSAGE.REQUIRED)
      .test({
        name: 'phone',
        exclusive: false,
        params: {},
        message: MESSAGE.INVALID_PHONE,
        test(phone) {
          return !!phone && isPossiblePhoneNumber(phone, this.parent.country);
        },
      }),
  phone: () =>
    yup
      .string()
      .test({
        name: 'phone',
        exclusive: false,
        params: {},
        message: MESSAGE.INVALID_PHONE,
        test(phone) {
          const [country, number] = phone?.split(' ') || [];
          return !number ? true : isPossiblePhoneNumber(number, country as CountryCode);
        },
      })
      .nullable()
      .transform((value) => {
        const [_, number] = value?.split(' ') || [];
        return !number || number === '+' ? null : value;
      }),
};
