export interface ICopilotSuggestion {
  suggestion_type: string;
  event_uuid: string | null;
  name: string;
  type: string;
  description: string;
  status: string;
  due_date: Date;
}

export enum ECopilotSuggestionTypes {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY',
}
