import React, {
  CSSProperties,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DropdownContentHolder } from './DropdownContentHolder';
import { mergeRefs } from '../utils/react';
import { IEncubeDropdownItem } from '../EncubeDropdown';
import { DropdownEmpty } from './DropdownEmpty';
import { DropdownItem } from './DropdownItem';

interface IDropdownContentProps {
  /**
   * List of dropdown items.
   */
  items: IEncubeDropdownItem[];
  /**
   * The trigger element's DOM dimensions.
   */
  triggerRect?: DOMRect | null;
  /**
   * Callback that is called when the dropdown item is clicked.
   * @param itemId - Unique identifier of the clicked item.
   */
  onItemClick: (itemId: string | number) => void;
}

const DropdownContentRaw: ForwardRefRenderFunction<HTMLUListElement, IDropdownContentProps> = (
  props,
  ref
) => {
  const { items = [], triggerRect = null, onItemClick } = props;

  const [node, setNode] = useState<HTMLUListElement | null>(null);

  /**
   * Since useRef doesn't trigger rerender, we need to use useCallback to update the node styles.
   */
  const onRefChange = useCallback((refNode: HTMLUListElement) => {
    setNode(refNode);
  }, []);

  const styles: CSSProperties = useMemo(() => {
    if (!triggerRect || !node) {
      return {};
    }
    const contentRect = node.getBoundingClientRect();
    const xAxis = triggerRect.left + triggerRect.width - contentRect.width;
    const yAxis = triggerRect.top + triggerRect.height;
    const translate = `translate(${xAxis}px, ${yAxis}px)`;
    return { transform: translate };
  }, [triggerRect, node]);

  const isEmpty: boolean = useMemo(() => items.length === 0, [items]);

  return (
    <DropdownContentHolder
      ref={mergeRefs([ref, onRefChange])}
      role="menu"
      aria-orientation="vertical"
      style={styles}
      data-cy="dropdown-content-holder"
    >
      {isEmpty ? (
        <DropdownEmpty>No options available</DropdownEmpty>
      ) : (
        items.map((item) => (
          <DropdownItem
            key={item.id}
            id={item.id}
            icon={item.icon}
            content={item.content}
            onClick={onItemClick}
          />
        ))
      )}
    </DropdownContentHolder>
  );
};

export const DropdownContent = forwardRef(DropdownContentRaw);
