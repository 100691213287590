import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDeleteReferenceMutation } from 'modules/threads/threadsApi';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { Group, Stack, Button, Text, Icon } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import colors from 'theme/colors';
import { handleApiCall } from 'utils/helpers';
import { useAppDispatch } from 'app/hooks';
import { pushAppEvent } from 'modules/app/appSlice';
import { EAppEventTypes } from 'modules/app/constants';

interface IRemoveReference {
  id: string | number;
  name: string;
  url: string | null;
}

const ReferenceItem = styled(Group)`
  min-height: 56px;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.dark2};
  border-color: ${({ theme }) => theme.colors.blue2};
  border-radius: 8px;
  padding: 12px;
`;

const RemoveReference = ({ id, name, url }: IRemoveReference) => {
  const [deleteReference, { isLoading, isSuccess, isError }] = useDeleteReferenceMutation();
  const dispatch = useAppDispatch();
  const { close } = useModal();

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.REFERENCE_DELETED);
      close();
    }

    if (isError) {
      showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
    }
  }, [isLoading]);

  const handleDeleteReference = async () => {
    const res = await deleteReference(id.toString());
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        close();

        dispatch(
          pushAppEvent({
            type: EAppEventTypes.REFERENCE_DELETED,
            appEventPayload: res,
          })
        );
      }
    );
  };

  return (
    <Stack gap="24px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to remove this Reference?
      </Text>
      <ReferenceItem gap="10px" align="center">
        <Icon
          icon={url ? 'ReferenceLinkIcon' : 'ReferenceDocumentIcon'}
          size="large"
          stroke="none"
        />
        {name}
      </ReferenceItem>

      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-remove-reference-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteReference}
          cypressAttribute="modal-remove-reference-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Stack>
  );
};

export default RemoveReference;
