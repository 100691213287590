import colors from 'theme/colors';
import { EThreadStatuses, EThreadPriority, TReactSelectOption } from 'types';
import { EVENT_STATUS_COLOR_MAP, EVENT_STATUS_NAME_MAP } from './events';

export const MAIN_BOTTOM_PADDING = 31;
export const MODAL_HEADER = 80;
export const SECONDS_IN_DAY = 86400000;

export const THREAD_STATUS_NAMES = {
  [EThreadStatuses.TO_DO]: 'To do',
  [EThreadStatuses.COMPLETE]: 'Complete',
  [EThreadStatuses.STALLED]: 'Stalled',
  [EThreadStatuses.AT_RISK]: 'At risk',
  [EThreadStatuses.AHEAD_OF_SCHEDULE]: 'Ahead of schedule',
  [EThreadStatuses.NEEDS_ATTENTION]: 'Needs attention',
  [EThreadStatuses.ON_TARGET]: 'On target',
  [EThreadStatuses.DELETED]: 'Deleted',
  [EThreadStatuses.ARCHIVE]: 'Archive',
  ...EVENT_STATUS_NAME_MAP,
};

export const THREAD_VISIBILITY_NAMES: TReactSelectOption[] = [
  { label: 'Public', value: 'public' },
  { label: 'Private', value: 'private' },
];

export const THREAD_PRIORITY_NAMES = {
  [EThreadPriority.LOW]: 'Low',
  [EThreadPriority.MEDIUM]: 'Medium',
  [EThreadPriority.HIGH]: 'High',
};

export const THREAD_STATUS_COLORS = {
  [EThreadStatuses.TO_DO]: colors.green1,
  [EThreadStatuses.COMPLETE]: colors.green2,
  [EThreadStatuses.STALLED]: colors.red,
  [EThreadStatuses.AT_RISK]: colors.orange_dark,
  [EThreadStatuses.AHEAD_OF_SCHEDULE]: colors.blue,
  [EThreadStatuses.NEEDS_ATTENTION]: colors.red,
  [EThreadStatuses.ON_TARGET]: colors.green1,
  [EThreadStatuses.DELETED]: colors.blue9,
  [EThreadStatuses.ARCHIVE]: colors.gray,
  ...EVENT_STATUS_COLOR_MAP,
};

export const THREAD_FILTER_STATUSES = [
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.ON_TARGET],
    value: EThreadStatuses.ON_TARGET,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.AHEAD_OF_SCHEDULE],
    value: EThreadStatuses.AHEAD_OF_SCHEDULE,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.AT_RISK],
    value: EThreadStatuses.AT_RISK,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.NEEDS_ATTENTION],
    value: EThreadStatuses.NEEDS_ATTENTION,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.COMPLETE],
    value: EThreadStatuses.COMPLETE,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.ARCHIVE],
    value: EThreadStatuses.ARCHIVE,
  },
];

export const THREAD_OPTIONS_STATUSES = [
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.ON_TARGET],
    value: EThreadStatuses.ON_TARGET,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.AHEAD_OF_SCHEDULE],
    value: EThreadStatuses.AHEAD_OF_SCHEDULE,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.AT_RISK],
    value: EThreadStatuses.AT_RISK,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.NEEDS_ATTENTION],
    value: EThreadStatuses.NEEDS_ATTENTION,
  },
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.COMPLETE],
    value: EThreadStatuses.COMPLETE,
  },
];

export const THREAD_OPTIONS_STATUSES_EDIT = [
  ...THREAD_OPTIONS_STATUSES,
  {
    optionLabel: THREAD_STATUS_NAMES[EThreadStatuses.ARCHIVE],
    value: EThreadStatuses.ARCHIVE,
  },
];

export const THREAD_OPTIONS_PRIORITY = [
  { optionLabel: THREAD_PRIORITY_NAMES[EThreadPriority.LOW], value: EThreadPriority.LOW },
  { optionLabel: THREAD_PRIORITY_NAMES[EThreadPriority.MEDIUM], value: EThreadPriority.MEDIUM },
  { optionLabel: THREAD_PRIORITY_NAMES[EThreadPriority.HIGH], value: EThreadPriority.HIGH },
];

export const SAVED_STATE = 'savedState';
export const RESET_EMAIL = 'resetEmail';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const IMAGE_TYPES = [
  'png',
  'bmp',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'tiff',
  'webp',
  'x-icon',
];
export const MAX_FILE_SIZE = 214_748_364_800;
export const FILE_PARAMS = {
  ACCEPT:
    // eslint-disable-next-line max-len
    'audio/*, video/*, image/*, .pdf, .doc, .docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .stp, .step',
  // max size should be 127.99 MB
  SIZE: 134_217_728,
};

export const CAD_FILE_PARAMS = {
  ACCEPT:
    // eslint-disable-next-line max-len
    '.PDF,.3DXML,.3DS, .MAX,.SAT, .ASAT, .SAB, .ASAB,.WIRE,.DWG, .DXF,.FBX,.CAD,.PRT, .CKD,.MODEL,.CATPart, .CATProduct,.CGR,.CATPart & .CATProduct,.DAE,.DRW, .PRT, .ASM,.F3Z, .F3D, .CAM360,.CMP, .GCD,.WRP,.GBX,.HMF,.HSF,.IGS, .IGES,.IPT, .IAM,.ICS,.JT,.CKD, CDL,.CKD,.LWO,.DGN,.LXO,.NWC, .NWD, .NWF,.PRT,.X_T, .X_B,.POD,.PLY,.G,.PRT, .ASM,.3DM,.PRT, .ASM,.SKP,.SMS, .IWB, IWP,.PAR, .ASM, .PSM,.EDRW,.SLDPRT, .SLDASM,.STEP, .STP,.PRT,.OBJ,.ZBR, .ZPR,.Z3,.WRL,.HTML',

  SIZE: 214_748_364_800,
};
