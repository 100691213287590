import React, { FC, useCallback, useRef, useState } from 'react';
import { RawDraftContentState } from 'draft-js';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { Group, Icon, Stack, Text, Tooltip } from 'ui';
import colors from 'theme/colors';
import { getFullName, handleApiCall, truncateRawString } from 'utils/helpers';
import { showErrorMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { REFERENCE_INTEGRATION_ICONS } from 'constants/integrations';
import * as ChatLayout from '../Layout';
import Actions from './Actions';
import Editor from '../Editor/Editor';
import FileView from '../FileView';
import { IMessageResponse } from '../../types';
import { getLinks } from '../../utils';
import { useEditMessagesMutation } from '../../chatApi';
import MediaBlock from '../MediaBlock';
import MessageLoader from './MessageLoader';

const MessageFileVersionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: ${colors.blue9};
`;

interface IProps extends IMessageResponse {
  isOwner: boolean;
  isReplied: boolean;
}

const MessageContent: FC<IProps> = ({
  id: messageId,
  isOwner,
  likes,
  numberOfLikes,
  text,
  isReplied,
  references,
  createdAt,
  creator,
  isLoading,
  preloadFiles,
}) => {
  const { msgId } = useParams();

  const isCurrentMessageCopied = Number(msgId) === messageId;

  const [isCopiedMessage, setIsCopiedMessage] = useState(isCurrentMessageCopied);

  const [editMessage, { isLoading: isSubmittingEditMessage }] = useEditMessagesMutation();
  const [editMode, setEditMode] = useState(false);
  const [isActionVisible, setActionVisible] = useState(false);

  const onEdit = async (data: RawDraftContentState, hasTest: boolean) => {
    if (!hasTest) return;
    const res = await editMessage({
      id: messageId,
      text: JSON.stringify(data),
      references: getLinks(data),
    });
    handleApiCall<IMessageResponse>(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => setEditMode(false)
    );
  };
  const onEditAction = () => setEditMode(true);
  const creatorName = getFullName(creator);
  const fullName = isOwner ? 'You' : `${creatorName}`;

  const unHighlightMessage = useCallback(() => {
    if (isCurrentMessageCopied && isCopiedMessage) {
      setIsCopiedMessage(false);
    }
    setActionVisible(false);
  }, [isCurrentMessageCopied, isCopiedMessage]);

  return (
    <ChatLayout.MessageContent
      id={`chat-message-content-${messageId}`}
      owner={isOwner}
      isCopiedMessage={isCopiedMessage}
      // make dropdown window close when mouse leave message
      onMouseOver={() => setActionVisible(true)}
      onMouseLeave={unHighlightMessage}
      data-cy="chat-message-content"
    >
      {preloadFiles && preloadFiles.length > 0 && <MessageLoader />}
      {!editMode && !isLoading && (
        <Actions
          isActionVisible={isActionVisible}
          messageId={messageId}
          usersLike={likes}
          likes={numberOfLikes}
          onEdit={onEditAction}
          isOwner={isOwner}
          isReplied={isReplied}
          text={text}
        />
      )}
      <>
        <ChatLayout.MessageHeader owner={isOwner} data-cy="chat-message-content-header">
          <Group>
            <Stack data-cy="chat-message-content-stack">
              <Text
                align={isOwner ? 'right' : 'left'}
                color={isOwner ? 'green' : 'orange'}
                cypressAttribute="chat-message-content-full-name"
              >
                {fullName}
              </Text>
              {creator?.group && (
                <Text
                  align={isOwner ? 'right' : 'left'}
                  size="xs"
                  color={colors.gray1}
                  cypressAttribute="chat-message-content-creator-group"
                >
                  {creator?.group}
                </Text>
              )}
            </Stack>
          </Group>
          <Text size="xs" color={colors.gray1}>
            <Tooltip
              data={<Moment format="MM/DD/YYYY HH:mm:ss" date={createdAt} />}
              mode="dark"
              position="bottom"
              width="full"
              cypressAttribute="chat-message-content-date-tooltip"
            >
              <Moment fromNow date={createdAt} />
            </Tooltip>
          </Text>
        </ChatLayout.MessageHeader>
        <Editor
          readonly={!editMode}
          initialValue={text}
          onSubmit={onEdit}
          toggleEditMode={{ editMode, setEditMode }}
          isSubmittingEditMessage={isSubmittingEditMessage}
        />
        {!!references.length && (
          <Stack align="flex-start" data-cy="chat-message-content-references-list">
            {references.map(({ id, fileVersions, name, icons }) => {
              const firstUploadedVersion = fileVersions?.[fileVersions.length - 1];
              const fileVersion = firstUploadedVersion?.version
                ? `(version ${firstUploadedVersion?.version})`
                : '';
              return (
                <Stack gap="8px" key={id} data-cy="chat-message-content-references-stack">
                  <FileView name={name} {...firstUploadedVersion} />
                  <Group justify="space-between" align="center" wrap="wrap">
                    <MessageFileVersionContainer data-cy="chat-message-content-file-version-container">
                      {icons?.length > 0 ? (
                        REFERENCE_INTEGRATION_ICONS[icons[0]]
                      ) : (
                        <>
                          <Icon icon="LinkIcon" size="small" />
                          <Text weight="400">/</Text>
                          <Icon icon="AttachIcon" size="small" path="#fff" />{' '}
                        </>
                      )}
                      <Text
                        weight="400"
                        size="sm"
                        cypressAttribute="chat-message-content-file-version"
                      >
                        {`${truncateRawString(name, 30)} ${fileVersion}`}
                      </Text>
                    </MessageFileVersionContainer>
                  </Group>
                </Stack>
              );
            })}
          </Stack>
        )}
        {isLoading && preloadFiles && <MediaBlock files={preloadFiles} />}
      </>
    </ChatLayout.MessageContent>
  );
};

export default MessageContent;
