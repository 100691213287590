import React, { useState, useMemo, useDeferredValue } from 'react';
import colors from 'theme/colors';
import {
  useSearchRelatedThreadsQuery,
  useCreateRelatedThreadMutation,
} from 'modules/relatedThreads/relatedThreadsApi';
import { useForm } from 'react-hook-form';
import { Stack, Group, TextInput, Icon, Divider, Title, Button } from 'ui';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { useModal } from 'modules/modals/ModalProvider';
import { isValidUrl, handleApiCall, getThreadIdFromUrl } from 'utils/helpers';
import {
  IRelatedEvent,
  ICreateThreadMutation,
  ISearchRelatedThread,
} from 'modules/relatedThreads/types';
import * as Layout from 'modules/relatedThreads/components/Layout';
import RelatedThread from 'modules/relatedThreads/components/RelatedThread';

interface IProps {
  threadId: string;
}

const initialValues = {
  threadId: '',
  eventId: [],
};

const AddRelatedThread = ({ threadId }: IProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [relatedThreadId, setRelatedThreadId] = useState<string>('');
  const [selectedEvents, setSelectedEvents] = useState<IRelatedEvent[]>([]);
  const [selectedThreads, setSelectedThreads] = useState<ISearchRelatedThread[]>([]);
  const { close } = useModal();

  const deferredSearch = useDeferredValue(searchValue);

  const [createRelatedThread, { isLoading }] = useCreateRelatedThreadMutation();
  const { data, isFetching } = useSearchRelatedThreadsQuery({
    id: threadId,
    name: deferredSearch,
    relatedThreadId,
    limit: 25,
    offset: 0,
  });

  const results = data?.results || [];

  const { handleSubmit } = useForm<ICreateThreadMutation>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const searchThreadsResult = useMemo(() => {
    const selectedThreadIds = selectedThreads.map((thread) => thread.id);
    return results.filter((thread) => !selectedThreadIds.includes(thread.id)) || [];
  }, [deferredSearch, selectedThreads, relatedThreadId, isFetching]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isUrl = isValidUrl(value);
    if (isUrl) {
      setSearchValue('');
      setRelatedThreadId(getThreadIdFromUrl(value));
      return;
    }
    setSearchValue(value.toLocaleLowerCase());
    setRelatedThreadId('');
  };

  const handleEventChange = (option: IRelatedEvent) => {
    const newEvents = selectedEvents.filter((event) => event.thread.id !== option.thread.id);
    newEvents.push(option);
    setSelectedEvents(newEvents);
  };

  const handleThreadChange = (checked: boolean, value: number) => {
    const selectedThread = results.find((thread) => thread.id === value);
    const updateSelectedThreads = checked
      ? [...selectedThreads, selectedThread]
      : selectedThreads.filter((thread) => thread.id !== value);

    const updateEvents = !checked
      ? selectedEvents.filter((event) => event.thread.id !== value)
      : selectedEvents;

    setSelectedThreads(updateSelectedThreads as ISearchRelatedThread[]);
    setSelectedEvents(updateEvents);
  };

  const onSubmit = async (payload: ICreateThreadMutation) => {
    const eventsIds = selectedEvents.map((event) => event.id);
    payload.threadId = threadId;
    payload.eventId = eventsIds;

    const res = await createRelatedThread(payload);

    handleApiCall<IRelatedEvent[]>(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        showSuccessMessage(NOTIFICATION.RELATED_THREAD_CREATED);
        close();
      }
    );
  };

  return (
    <Stack gap="24px" fullHeight data-cy="modal-add-related-thread-stack">
      <Title heading="h6" color={colors.gray2} cypressAttribute="modal-add-related-thread-title">
        Search Threads
      </Title>
      <Layout.SearchBar data-cy="modal-add-related-thread-search">
        <Layout.SearchIcon aria-hidden="true">
          <Icon fill={colors.gray1} icon="SearchIcon" size="small" />
        </Layout.SearchIcon>
        <TextInput
          autoFocus
          type="text"
          name="search-threads"
          placeholder="Search thread by name or paste url"
          onChange={handleSearchChange}
          style={{ paddingLeft: '44px' }}
          data-cy="modal-add-related-thread-search-input"
        />
      </Layout.SearchBar>
      <Divider color={colors.blue3} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ height: '100%' }}
        data-cy="modal-add-related-thread-form"
      >
        <Stack gap="24px" fullHeight>
          {selectedThreads.length > 0 && (
            <>
              <Stack gap="12px" data-cy="modal-add-related-thread-stack">
                <Title
                  heading="h6"
                  color={colors.gray2}
                  cypressAttribute="modal-add-related-thread-form-title"
                >
                  Selected Threads
                </Title>
                {selectedThreads.map((thread) => (
                  <RelatedThread
                    key={thread.id}
                    {...thread}
                    selected
                    toggleThread={handleThreadChange}
                    changeEvent={handleEventChange}
                    cypressAttribute="modal-add-related-thread-related-thread-selected"
                  />
                ))}
              </Stack>
              <Divider color={colors.blue3} />
            </>
          )}

          <Stack gap="12px">
            {searchThreadsResult.map((thread) => (
              <RelatedThread
                key={thread.id}
                {...thread}
                selected={false}
                toggleThread={handleThreadChange}
                changeEvent={handleEventChange}
                cypressAttribute="modal-add-related-thread-related-thread-search"
              />
            ))}
          </Stack>
          <Group justify="flex-end" gap="15px" style={{ marginTop: 'auto' }}>
            <Button
              onClick={close}
              color={colors.dark2}
              cypressAttribute="modal-add-related-thread-close-btn"
            >
              Close
            </Button>
            <Button
              type="submit"
              disabled={!selectedEvents.length || isLoading}
              cypressAttribute="modal-add-related-thread-save-btn"
            >
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddRelatedThread;
