import React from 'react';
import { Icon } from 'ui';
import { IEncubeDropdownItem } from 'ui/EncubeDropdown';

export const EncubeDropdownAction = {
  OPEN: 'OPEN',
  OPEN_NOTIFY: 'OPEN_NOTIFY',
} as const;

export const encubeDropDownItems: IEncubeDropdownItem[] = [
  {
    id: EncubeDropdownAction.OPEN,
    content: 'Open with Encube Only',
    icon: <Icon icon="ArrowCurveIcon" />,
  },
  {
    id: EncubeDropdownAction.OPEN_NOTIFY,
    content: 'Open with Encube and Notify Collaborators',
    icon: <Icon icon="BellArrowIcon" />,
  },
];

export const JAM_REFETCH_INTERVAL = 1000 * 60 * 0.5; // 30 seconds

export const THREAD_POLLING_INTERVAL = 1000 * 60; // 1 minute
