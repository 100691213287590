import React from 'react';
import { MenuItem } from 'modules/header/components/Layout';

interface ISearchItemProps {
  id: number;
  name: string;
  search: string;
}

const SearchItem: React.FC<ISearchItemProps> = ({ id, name, search }) => {
  const threadName = name.toLowerCase();
  const isMatch = threadName.includes(search);
  const title = isMatch ? threadName.replace(search, `<span>${search}</span>`) : threadName;

  return (
    <MenuItem>
      <a
        href={`/thread/${id}`}
        title={name}
        aria-label="thread link"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </MenuItem>
  );
};

export default SearchItem;
