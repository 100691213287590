import React, { FC, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Icon } from 'ui';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectFilter } from 'modules/chat/chatSlice';
import { EChatFilter } from 'modules/chat/types';

import { REFETCH_INTERVAL, SKELETON_COUNT } from 'modules/EncubeAnnotations/helpers';

import { AnnotationsSkeleton } from 'modules/EncubeAnnotations/components/AnnotationsSkeleton';
import { AnnotationsEmpty } from 'modules/EncubeAnnotations/components/AnnotationsEmpty';

import {
  loadNextAnnotationsByLink,
  loadPrevAnnotationsByLink,
  selectPlayableAnnotations,
} from '../store';

import {
  AnnotationListScroller,
  AnnotationRefreshButton,
  AnnotationsContainer,
  LoadButtonContainer,
} from '../../EncubeAnnotations/components/AnnotationLayout';

import { usePlyableAnnotationsPollingQuery } from '../api';
import PlyableAnnotationItem from './PlyableAnnotationItem';

const PlyableAnnotations: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const chatFilter = useAppSelector(selectFilter);
  const [limit, setLimit] = useState<number>(10);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const isPlayableAnnotations = useMemo(
    () => chatFilter === EChatFilter.PLAYABLE_EVENT,
    [chatFilter]
  );

  const { isLoading: isInitiallyLoading, refetch } = usePlyableAnnotationsPollingQuery(
    { id: id as string, limit },
    {
      refetchOnMountOrArgChange: false,
      pollingInterval: isPlayableAnnotations ? REFETCH_INTERVAL : undefined,
      skip: !isPlayableAnnotations,
    }
  );

  const { playableAnnotations, next, previous, isLoadingNext, isLoadingPrev } =
    useAppSelector(selectPlayableAnnotations);

  const hasPrevLink = useMemo(() => previous && previous?.length > 0, [previous]);
  const hasNextLink = useMemo(() => next && next?.length > 0, [next]);

  const isEmpty = useMemo(() => playableAnnotations.length === 0, [playableAnnotations]);
  const isLoading = useMemo(
    () => isRefetchLoading || isInitiallyLoading,
    [isRefetchLoading, isInitiallyLoading]
  );

  const loadMore = () => {
    setLimit((prev) => prev + 10);
    dispatch(loadNextAnnotationsByLink({ link: next }));
  };

  const loadPrev = () => {
    setLimit((prev) => prev + 10);
    dispatch(loadPrevAnnotationsByLink({ link: previous }));
  };

  const refreshAnnotationsClickHandler = () => {
    setIsRefetchLoading(true);
    refetch().then(() => setIsRefetchLoading(false));
  };

  return (
    <AnnotationListScroller fullHeight justify="flex-start" data-cy="annotation-list-scroller">
      <AnnotationsContainer gap="35px" fluid fullHeight data-cy="annotations-container">
        {
          isLoading &&
            new Array(SKELETON_COUNT)
              .fill(null)
              .map((_, index) => <AnnotationsSkeleton key={index} />) // eslint-disable-line react/no-array-index-key
        }
        {isEmpty && !isLoading && (
          <AnnotationsEmpty onRefreshClick={refreshAnnotationsClickHandler} />
        )}
        {hasPrevLink && !isLoading && (
          <LoadButtonContainer isBottomButton={false} data-cy="load-btn-container">
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
              onClick={loadPrev}
              data-cy="annotation-load-previous-btn"
            >
              {isLoadingPrev ? 'Loading...' : 'Load Previous'}
            </AnnotationRefreshButton>
          </LoadButtonContainer>
        )}
        {!isEmpty &&
          !isLoading &&
          playableAnnotations.map((annotation) => (
            <PlyableAnnotationItem
              key={annotation.id}
              id={annotation.id.toString()}
              authorAvatarSrc={annotation.creator.avatar}
              authorName={`${annotation.creator.firstName} ${annotation.creator.lastName}`}
              createdAt={annotation.createdAt}
              fileId={annotation.versionedFile.id}
              fileName={annotation.versionedFile.file}
              fileVersion={annotation.versionedFile.version.toString()}
              fileUrl={annotation.versionedFile.plyableUrl}
              comments={annotation.replies}
            />
          ))}
        <LoadButtonContainer isBottomButton data-cy="annotation-load-btn-container">
          {hasNextLink && !isLoading && (
            <AnnotationRefreshButton
              type="button"
              rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
              onClick={loadMore}
              data-cy="annotation-load-more-btn"
            >
              {isLoadingNext ? 'Loading...' : 'Load More'}
            </AnnotationRefreshButton>
          )}
        </LoadButtonContainer>
      </AnnotationsContainer>
    </AnnotationListScroller>
  );
};

export default PlyableAnnotations;
