import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'app/hooks';
import { CAD_FILE_PARAMS } from 'constants/index';
import { useDebounce, useFeatures } from 'hooks';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { useEncubeStatus } from 'modules/encube';
import {
  useCheckForAccessToTheDocumentMutation,
  useGetUserAvailableIntegrationsQuery,
} from 'modules/integrations/integrationsApi';
import { IHasAccessMutationResponse } from 'modules/integrations/types';
import { useModal } from 'modules/modals/ModalProvider';
import { pushAppEvent } from 'modules/app/appSlice';
import { useCreateReferenceMutation } from 'modules/threads/threadsApi';
import { ICreateReferenceMutation, REFERENCE_TYPE } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import colors from 'theme/colors';
import {
  Button,
  FileLoader,
  Group,
  Icon,
  RadioButton,
  Spinner,
  Stack,
  Text,
  TextArea,
  TextInput,
  Tooltip,
} from 'ui';
import { handleApiCall, fileTypeMatches } from 'utils/helpers';
import { validationSchema } from 'utils/validation';
import * as yup from 'yup';
import IntegrationSection from 'modules/references/components/IntegrationSection';
import { EFeatureFlags } from 'constants/features';
import Card from 'ui/card';
import { CardBody, CardHeader } from 'ui/card/Layout';
import AnchorLink from 'ui/anchorLink';
import { TbWorld } from 'react-icons/tb';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import { EAppEventTypes } from 'modules/app/constants';
import { usePlayableStatus } from 'modules/playable';

const initialValues = {
  name: '',
  description: '',
  url: '',
  thread: '',
  file: null,
  plyableQuoteFor: undefined,
};

const validation = yup
  .object({
    name: validationSchema.stringRequired(),
    description: validationSchema.textArea(),
    url: validationSchema.url(),
  })
  .required();

interface IAddReferenceParams {
  threadId: number;
}

const AddReference = ({ threadId }: IAddReferenceParams) => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const [isPlayableIntegration, setIsPlayableIntegration] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [preview, setPreview] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [isPlayableError, setIsPlayableError] = useState<boolean>(false);
  const [referenceType, setReferenceType] = useState<
    typeof REFERENCE_TYPE[keyof typeof REFERENCE_TYPE]
  >(REFERENCE_TYPE.LINK);

  const [linkAccessResponse, setLinkAccessResponse] = useState<
    IHasAccessMutationResponse | undefined
  >();

  const [isIntegrationWarning, setIsIntegrationWarning] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ICreateReferenceMutation>({
    defaultValues: initialValues,
    mode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(validation),
  });
  const { close, open } = useModal();
  const isCopilotFeatureEnabled = useIsFeatureEnabled(EFeatureFlags.COPILOT);
  const { isPlayableFunctionalAvailable } = usePlayableStatus();

  const { data: integrations, isLoading: isIntegrationsLoading } =
    useGetUserAvailableIntegrationsQuery();

  const [createReference, { isLoading }] = useCreateReferenceMutation();
  const [checkForDocumentAccess, { isLoading: isChecking }] =
    useCheckForAccessToTheDocumentMutation();

  const inputUrl = useWatch({ name: 'url', control });
  const debouncedSearchValue = useDebounce(inputUrl, 600);

  const isFile = referenceType === REFERENCE_TYPE.FILE;
  const isLink = referenceType === REFERENCE_TYPE.LINK;
  const isEncube = referenceType === REFERENCE_TYPE.ENCUBE;

  const shouldDisplayUploadFileSection = isFile || isEncube;
  const uploadFileLabel = isFile ? 'Upload a file' : 'Upload a CAD file';

  const hasLinkError = useMemo(() => 'url' in errors, [errors.url]);

  const shouldDisplayLinkWarningText = useMemo(
    () => linkAccessResponse && linkAccessResponse.isGoogle && !linkAccessResponse.hasAccess,
    [linkAccessResponse]
  );

  const shouldDisplayLinkSuccessTest = useMemo(
    () =>
      linkAccessResponse &&
      (!linkAccessResponse.isGoogle ||
        (linkAccessResponse.isGoogle && linkAccessResponse.hasAccess)),
    [linkAccessResponse]
  );

  const isButtonDisabled = useMemo(
    () => isLoading || isChecking || hasLinkError,
    [isLoading, isChecking, hasLinkError]
  );

  const onChangeReferenceType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { name } = e.target;
    if (name === REFERENCE_TYPE.LINK) {
      setReferenceType(REFERENCE_TYPE.LINK);
      setValue('file', null);
      setPreview(null);
    }
    if (name === REFERENCE_TYPE.FILE) {
      setReferenceType(REFERENCE_TYPE.FILE);
      setValue('url', '');
    }
    if (name === REFERENCE_TYPE.ENCUBE) {
      setReferenceType(REFERENCE_TYPE.ENCUBE);
      setValue('file', null);
      setPreview(null);
    }
  };

  const resetUploadFile = () => {
    setPreview(null);
    setError(false);
  };

  const onFileChange = (file: File) => {
    setError(false);
    setPreview(file);
  };

  const checkLinkAccess = useCallback(async () => {
    setIsIntegrationWarning(false);
    if (errors.url) return;
    const response = await checkForDocumentAccess({ url: debouncedSearchValue });
    handleApiCall(
      response,
      ({ error: checkError }) => {
        if (checkError?.status === 403 && !integrations?.isGoogleEnabled) return;
        if (checkError?.status === 403 && integrations?.isGoogleEnabled) {
          setIsIntegrationWarning(true);
          return;
        }
        showErrorMessage('An error ocurred when checking you link');
      },
      ({ data }) => setLinkAccessResponse(data)
    );
  }, [debouncedSearchValue]);

  const onEnableIntegrationClick = useCallback(() => {
    close();
    open({
      variant: 'center',
      contentLabel: 'integrations',
      name: 'Choose your integration',
      modal: 'choseIntegration',
      id: 'modal-choose-integration',
    });
  }, []);

  const onSubmit: SubmitHandler<ICreateReferenceMutation> = async (payload, event) => {
    event?.preventDefault();
    const requestObject: ICreateReferenceMutation = {
      ...payload,
      file: preview,
      referenceType: isPlayableIntegration ? REFERENCE_TYPE.PLAYABLE : referenceType,
    };
    if (!requestObject.plyableQuoteFor) {
      if (isPlayableIntegration) {
        setIsPlayableError(true);
        return;
      }
      delete requestObject.plyableQuoteFor;
    }

    if (requestObject.file === null && requestObject.url === '') {
      setError(true);
      return;
    }

    const res = await createReference(requestObject);
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        showSuccessMessage(NOTIFICATION.REFERENCE_CREATED);
        close();

        dispatch(
          pushAppEvent({
            type: EAppEventTypes.REFERENCE_CREATED,
            appEventPayload: res,
          })
        );
      }
    );
  };

  const handlePlayableManufactureChange = (option: { label: string; value: string }) => {
    setIsPlayableError(false);
    setValue('plyableQuoteFor', option.value);
  };

  useEffect(() => {
    setValue('thread', Number(threadId));
  }, []);

  useEffect(() => {
    if (isLink && inputUrl.length > 0) {
      checkLinkAccess();
    }
  }, [debouncedSearchValue]);

  if (isIntegrationsLoading) {
    return (
      <Stack fullHeight fluid align="center" justify="center">
        <Spinner />
      </Stack>
    );
  }

  return (
    <Layout.Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-add-reference-form">
      <Stack fluid gap="25px" style={{ height: '100%' }}>
        <TextInput
          register={register}
          name="name"
          placeholder="Name"
          type="text"
          label="Name"
          required
          error={errors.name}
          data-cy="modal-add-reference-name"
        />
        <TextArea
          register={register}
          name="description"
          placeholder="Description"
          label="Description"
          resize="none"
          error={errors.description}
          data-cy="modal-add-reference-description"
        />

        <Group gap="12px" role="radiogroup" data-cy="modal-add-reference-radio-group">
          <RadioButton
            id="radio-link"
            value="link"
            text="Link"
            icon={<Icon icon="ReferenceLinkIcon" size="medium" stroke="none" />}
            name={REFERENCE_TYPE.LINK}
            checked={isLink}
            aria-checked={isLink}
            onChange={onChangeReferenceType}
            data-cy="modal-add-reference-radio-link"
          />
          <RadioButton
            id="radio-file"
            value="file"
            text="File"
            icon={<Icon icon="ReferenceDocumentIcon" size="medium" stroke="none" />}
            name={REFERENCE_TYPE.FILE}
            checked={isFile}
            aria-checked={isFile}
            onChange={onChangeReferenceType}
            data-cy="modal-add-reference-radio-file"
          />
          {isEncubeFunctionalAvailable && (
            <RadioButton
              id="radio-encube"
              value="encube"
              text="Encube file"
              icon={<Icon icon="ReferenceEncubeIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.ENCUBE}
              checked={isEncube}
              aria-checked={isEncube}
              onChange={onChangeReferenceType}
              data-cy="modal-add-reference-radio-encube"
            />
          )}
        </Group>

        {isLink && (
          <Stack gap="8px">
            <TextInput
              register={register}
              required={isLink}
              error={errors.url}
              label="Paste link"
              name="url"
              type="text"
              icon={<Icon icon="LinkIcon" />}
              placeholder="Paste your link here"
              style={{ border: `1px solid ${colors.blue}` }}
              data-cy="modal-add-reference-paste-link"
            />
            {isChecking && (
              <Group align="center" data-cy="modal-add-reference-checking-link">
                <Text weight="600" size="xs">
                  ...Checking link
                </Text>
                <Spinner size="small" color={colors.white} />
              </Group>
            )}
            {!isChecking && !hasLinkError && shouldDisplayLinkWarningText && (
              <Group align="center" data-cy="modal-add-reference-paste-link-no-access">
                <Icon icon="InfoIcon" path={colors.orange} />
                <Text
                  weight="600"
                  size="xs"
                  color={colors.orange}
                  style={{ lineHeight: 1.3, letterSpacing: 0.5 }}
                >
                  Your google account does not have access to this resource. Comments sync and
                  spreadsheets links highlighting will not work.
                </Text>
              </Group>
            )}
            {!isChecking && !hasLinkError && shouldDisplayLinkSuccessTest && (
              <Group align="center" data-cy="modal-add-reference-paste-link-check-success">
                <Icon icon="CheckIcon" path={colors.green1} style={{ width: 15, height: 15 }} />
                <Text weight="600" size="xs" color={colors.green1}>
                  Link checked successfully
                </Text>
              </Group>
            )}
            {!isChecking && !hasLinkError && isIntegrationWarning && (
              <Layout.AddReferenceIntegrationWarningContainer
                align="flex-start"
                gap="12px"
                data-cy="modal-add-reference-integration-warning"
              >
                <Icon icon="InfoIcon" path={colors.blue} />
                <Stack gap="8px" align="flex-start">
                  <Text weight="600" size="xs" style={{ lineHeight: 1.3, letterSpacing: 0.4 }}>
                    You have added a link to Google resource, but it seems you don&apos;t have
                    Google integration enabled in your account.
                  </Text>
                  <Button
                    type="button"
                    variant="plain"
                    color={colors.blue}
                    style={{ border: `1px solid ${colors.blue}`, padding: '5px 10px' }}
                    onClick={onEnableIntegrationClick}
                    cypressAttribute="modal-add-reference-integration-warning-enable-btn"
                  >
                    Enable integrations
                  </Button>
                </Stack>
              </Layout.AddReferenceIntegrationWarningContainer>
            )}
          </Stack>
        )}

        {shouldDisplayUploadFileSection && !preview && (
          <FileLoader
            register={register}
            name="file"
            accept={isEncube ? CAD_FILE_PARAMS.ACCEPT : undefined}
            size={CAD_FILE_PARAMS.SIZE}
            label={uploadFileLabel}
            text="max size is 128MB"
            onChange={onFileChange}
          />
        )}
        {error && <Text color={colors.red}>Should contain at least one reference</Text>}
        {preview && (
          <Layout.FilePreview
            gap="10px"
            align="center"
            justify="space-between"
            data-cy="modal-add-reference-file-preview"
          >
            <Group gap="10px" align="center">
              <Icon icon="ReferenceDocumentIcon" size="large" stroke="none" />
              <Text>{preview.name}</Text>
            </Group>

            <Button
              onClick={resetUploadFile}
              variant="plain"
              disabled={isLoading}
              cypressAttribute="modal-add-reference-file-preview-reset-btn"
            >
              <Icon icon="CloseIcon" />
            </Button>
          </Layout.FilePreview>
        )}
        {fileTypeMatches(preview, ['stp', 'step']) &&
          !isEncube &&
          isPlayableFunctionalAvailable && (
            <Stack gap="25px">
              <Stack gap="10px">
                <Group gap="8px" align="center">
                  <Text size="lg">Integrations</Text>
                  <Icon icon="NewIntegrationsIcon" style={{ width: 22, height: 22 }} />
                </Group>
                <Text
                  size="xs"
                  color={colors.dark4}
                  style={{ lineHeight: 'normal', width: '100%', maxWidth: '400px' }}
                >
                  For CAD files, select one or more of the integrations below to generate more
                  information about your model.
                </Text>
              </Stack>
              <Tooltip
                isVisible={!isPlayableIntegration}
                data={<Text size="sm">Click to initiate quote with Plyable</Text>}
              >
                <IntegrationSection
                  type="plyable"
                  isSelectDisabled={isLoading}
                  active={isPlayableIntegration}
                  isCreateSection={isPlayableIntegration}
                  toggleIntegration={setIsPlayableIntegration}
                  onChange={handlePlayableManufactureChange}
                  isPlayableError={isPlayableError}
                />
              </Tooltip>
            </Stack>
          )}
      </Stack>

      {isCopilotFeatureEnabled && (
        <Card
          id="data-usage-notice-warning"
          maxWidth="100%"
          backgroundColor={colors.dark3}
          padding="15px"
        >
          <CardHeader
            display="flex"
            alignItems="center"
            gap="5px"
            fontSize="medium"
            fontWeight="bold"
            padding="0 0 10px 0"
            titleStyle={false}
          >
            <TbWorld size={15} /> Data Usage Notice
          </CardHeader>
          <CardBody lineHeight="1.5">
            <Text size="xs">
              We&apos;re using your data to enhance our services with a Large Language Model (LLM).
            </Text>
            <Text style={{ marginTop: 3 }} size="xs">
              Your privacy is our priority. For details, click{' '}
              <AnchorLink href="https://authentise.zendesk.com/hc/en-us/sections/22531456557204-Large-Language-Model">
                here
              </AnchorLink>
              .
            </Text>
          </CardBody>
        </Card>
      )}

      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-add-reference-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isButtonDisabled}
          cypressAttribute="modal-add-reference-add-btn"
        >
          {isLoading ? <Spinner color="white" size="small" /> : 'Add Reference'}
        </Button>
      </Group>
    </Layout.Form>
  );
};

export default AddReference;
