import React, { FC, cloneElement } from 'react';
import Text from 'ui/text';
import colors from 'theme/colors';
import { DropdownItemHolder } from './DropdownItemHolder';
import { DropdownItemButton } from './DropdownItemButton';

interface IDropdownItemProps {
  /**
   * Unique identifier of the dropdown item.
   */
  id: string | number;
  /**
   * Icon of the dropdown item that is displayed before the content.
   */
  icon?: React.ReactElement;
  /**
   * Content of the dropdown item.
   */
  content: string;
  /**
   * Callback that is called when the dropdown item is clicked.
   * @param itemId - Unique identifier of the clicked item.
   */
  onClick: (itemId: string | number) => void;
}

export const DropdownItem: FC<IDropdownItemProps> = (props) => {
  const { id, icon, content, onClick } = props;

  const dropdownItemClickHandler = () => {
    onClick(id);
  };

  return (
    <DropdownItemHolder data-cy="dropdown-item-holder">
      <DropdownItemButton
        type="button"
        role="menuitem"
        onClick={dropdownItemClickHandler}
        data-cy="dropdown-item-button"
      >
        {icon &&
          cloneElement(icon, {
            stroke: 'none',
            fill: 'none',
            style: { width: '18px', height: '18px' },
          })}
        <Text
          color={colors.gray2}
          weight="400"
          cypressAttribute="dropdown-item-holder-text-content"
        >
          {content}
        </Text>
      </DropdownItemButton>
    </DropdownItemHolder>
  );
};
