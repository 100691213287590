/**
 * When developing low level UI components, it is common to have to use a local ref
 * but also support an external one using React.forwardRef.
 * Natively, React does not offer a way to set two refs inside the ref property. This is the goal of this small utility.
 *
 * @param refs - array of ref objects
 */
export function mergeRefs<T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
