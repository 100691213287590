import React, { FC } from 'react';
import { Icon, Text } from 'ui';
import colors from 'theme/colors';
import { AnnotationRefreshButton, AnnotationsEmptyHolder } from './AnnotationLayout';

interface IAnnotationsEmptyProps {
  /**
   * Callback that is called when the refresh button is clicked.
   */
  onRefreshClick?: () => void;
}

export const AnnotationsEmpty: FC<IAnnotationsEmptyProps> = (props) => {
  const { onRefreshClick } = props;

  return (
    <AnnotationsEmptyHolder
      fluid
      justify="center"
      align="center"
      gap="18px"
      data-cy="annotations-empty-holder"
    >
      <Text size="lg" weight="400" color={colors.gray3}>
        No annotations yet
      </Text>
      <AnnotationRefreshButton
        type="button"
        rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
        onClick={onRefreshClick}
        data-cy="annotations-refresh-btn"
      >
        Refresh
      </AnnotationRefreshButton>
    </AnnotationsEmptyHolder>
  );
};
