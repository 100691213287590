import React, { CSSProperties, FC } from 'react';
import IconWrapper, { IIconWrapperProps, TSize } from 'ui/icon/IconWrapper';
import { icons } from 'assets/icons';
import colors from 'theme/colors';

interface IIconProps extends Omit<IIconWrapperProps, 'size'> {
  id?: string;
  icon: keyof typeof icons;
  onClick?: () => void;
  size?: TSize;
  style?: CSSProperties;
  cypressAttribute?: string;
}

const Icon: FC<IIconProps> = ({
  id,
  icon,
  size = 'medium',
  fill = 'none',
  stroke = colors.gray1,
  strokeWidth,
  hoverColor,
  onClick,
  style,
  ...props
}) => {
  if (!icon) {
    return null;
  }

  const IconComponent = () => icons[icon];
  return (
    <IconWrapper
      id={id}
      style={style}
      onClick={onClick}
      size={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      hoverColor={hoverColor}
      data-cy={props.cypressAttribute}
      {...props}
    >
      <IconComponent />
    </IconWrapper>
  );
};

export default Icon;
