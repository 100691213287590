import { api } from 'app/api';
import { IId, IPaginatedResponse } from 'types';
import { IAnnotationRaw, IGetAnnotationParams } from '../types';

export const annotationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    encubeAnnotations: build.query<
      IPaginatedResponse<IAnnotationRaw>,
      Partial<IGetAnnotationParams>
    >({
      query: ({ id, link }) => ({
        url: link || `/threads/${id}/encube-annotations/`,
      }),
      providesTags: ['annotations'],
    }),
    encubeAnnotationById: build.query<IAnnotationRaw, IId>({
      query: ({ id }) => `/encube/annotations/${id}/`,
      providesTags: ['annotations'],
    }),
    encubeAnnotationsPolling: build.query<
      IPaginatedResponse<IAnnotationRaw>,
      Partial<IGetAnnotationParams>
    >({
      query: ({ id, limit }) => `/threads/${id}/encube-annotations/?limit=${limit}&offset=0`,
      providesTags: ['annotations'],
    }),
  }),
});

export const {
  useEncubeAnnotationsQuery,
  useEncubeAnnotationByIdQuery,
  useLazyEncubeAnnotationByIdQuery,
  useEncubeAnnotationsPollingQuery,
} = annotationsApi;
