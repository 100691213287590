import styled from 'styled-components';
import { Avatar, Button, Group, Stack, Text } from 'ui';

export const AnnotationAvatar = styled(Avatar)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const AnnotationCommentContent = styled(Text)`
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray3};
  word-break: break-word;
`;

export const AnnotationCommentDate = styled(Text)`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  line-height: 1.5;
  text-align: right;
`;

export const AnnotationCommentHolder = styled(Group)`
  padding: 7px 16px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const AnnotationCommentLink = styled.a`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  transform: translateZ(0); /* fixes a bug in Safari iOS where the scroller doesn't update */
`;

export const AnnotationCommentListScroller = styled(Stack)`
  flex-direction: column-reverse;
  padding: 8px;
  max-width: 60%;
  width: 100%;
  max-height: 311px;
  min-height: 311px;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.blue3};
  background-color: ${({ theme }) => theme.colors.dark1};
`;

export const AnnotationCommentList = styled(Stack)`
  width: 100%;
  margin-bottom: auto;
`;

export const AnnotationContentHolder = styled(Stack)`
  flex-flow: row nowrap;
  padding: 16px;
  border-radius: 8px;
  max-height: 345px;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.dark2};
  border: 1px solid ${({ theme }) => theme.colors.green1};
  box-shadow: inset 0px 0px 10px 0px ${({ theme }) => theme.colors.green1};
  overflow: hidden;
`;

export const AnnotationFileLink = styled.a`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.brandSecondaryLight};
`;

export const AnnotationItemWrapper = styled(Stack)`
  flex-flow: row nowrap;
  &:last-child {
    padding-bottom: 25px;
  }
`;

export const AnnotationRefreshButton = styled(Button)`
  gap: 4px;
  padding: 4px 8px;
  border-radius: 40px;
  color: #62aafe;
  background-color: ${({ theme }) => theme.colors.blue2};
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`;

export const AnnotationListScroller = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark1};
  border-radius: 8px;
  overflow-y: auto;
`;

export const AnnotationsContainer = styled(Stack)`
  padding: 24px;
  margin-bottom: auto;
`;

export const AnnotationsEmptyHolder = styled(Stack)`
  padding: 64px 0;
`;

export const LoadButtonContainer = styled(Stack)<{ isBottomButton: boolean }>`
  width: 100%;
  max-width: 150px;
  align-self: center;
  padding-bottom: ${({ isBottomButton }) => (isBottomButton ? '25px' : '0')};
  padding-top: ${({ isBottomButton }) => (isBottomButton ? '0' : '25px')};
`;

export const LogoWrapper = styled.div`
  width: 32;
  height: 32;
  flex-shrink: 0;
`;
