import { JsonObject } from 'react-use-websocket/dist/lib/types';

export enum EEventType {
  MESSAGE = 'message',
  MESSAGE_UPDATE = 'message_update',
  MESSAGE_DELETE = 'message_destroy',
  LIKE = 'like',
  NOTIFICATION = 'notification',
}

export interface ISocketPayload extends JsonObject {
  type: EEventType;
  context: any;
}
export enum EAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}
