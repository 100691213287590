import React, { FC } from 'react';

import { Spinner, Text } from 'ui';
import { useGetUserAvailableIntegrationsQuery } from 'modules/integrations/integrationsApi';
import { AVAILABLE_INTEGRATIONS } from 'constants/integrations';
import colors from 'theme/colors';
import IntegrationListItem from '../IntegrationListItem';
import * as Layout from '../Layout';
import { IntegrationSuccessful } from '../IntegrationSuccessful';

const ChooseIntegration: FC = () => {
  const [successIntegrationName, setSuccessIntegrationName] = React.useState<
    keyof typeof AVAILABLE_INTEGRATIONS | null
  >(null);
  const { data, isLoading } = useGetUserAvailableIntegrationsQuery();

  const isIntegrationsEmpty = !isLoading && !data?.integrations.length;

  if (isLoading) {
    return (
      <Layout.ListContainer align="center" justify="center" fluid>
        <Spinner />
      </Layout.ListContainer>
    );
  }

  if (successIntegrationName) {
    return <IntegrationSuccessful successIntegrationName={successIntegrationName} />;
  }

  return (
    <Layout.IntegrationsMenuContainer data-cy="modal-choose-integration-menu">
      {isIntegrationsEmpty ? (
        <Layout.ListContainer
          align="center"
          justify="center"
          fluid
          data-cy="modal-choose-integration-container"
        >
          <Text weight="600" size="md" align="center" style={{ lineHeight: 1.3 }}>
            No Integrations enabled. Contact{' '}
            <a href="mailto:support@authentise.zendesk.com" style={{ color: colors.blue }}>
              support@authentise.zendesk.com
            </a>{' '}
            to enable Threads integrations to Google, Sheets, Presentation, Docs etc.
          </Text>
        </Layout.ListContainer>
      ) : (
        <Layout.IntegrationsList data-cy="modal-choose-integration-list">
          {data?.integrations.map((integration) => (
            <IntegrationListItem
              key={integration.name}
              integration={integration}
              setSuccessIntegration={setSuccessIntegrationName}
            />
          ))}
        </Layout.IntegrationsList>
      )}
    </Layout.IntegrationsMenuContainer>
  );
};

export default ChooseIntegration;
