import React, { CSSProperties } from 'react';
import { useAppDispatch } from 'app/hooks';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Icon, Tooltip } from 'ui';
import { StyledInput } from 'ui/textInput/Layout';
import { ThreadsBotTextInputContainer } from 'ui/threadsBot/Layout';
import { UseFormHandleSubmit, UseFormRegister, useForm } from 'react-hook-form';
import { BsArrowRepeat } from 'react-icons/bs';

interface IProps {
  handleSendThreadsBotMessage: ({ message }: { message: string }) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<{ message: string }>;
  register: UseFormRegister<{ message: string }>;
  isSending: boolean;
}

const ThreadsBotTextInput = ({
  handleSendThreadsBotMessage,
  handleSubmit,
  register,
  isSending,
}: IProps) => {
  const dispatch = useAppDispatch();

  const SendButton = styled(Button)<{
    backgroundColor?: CSSProperties['backgroundColor'];
  }>`
    transition: 0.2s ease;
    transform: scale(1);
    padding: 2px 15px;
    ${({ backgroundColor }) => backgroundColor}

    &:hover {
      transform: scale(1.1);
      background: ${colors.bgSlate50};
    }
  `;

  const { ref: messageRegisterRef, onChange } = register('message');

  return (
    <form onSubmit={handleSubmit(handleSendThreadsBotMessage)}>
      <ThreadsBotTextInputContainer>
        <StyledInput
          autoComplete="off"
          ref={messageRegisterRef}
          name="message"
          label="message"
          placeholder="Message"
          onChange={onChange}
          onKeyDown={(event: KeyboardEvent) =>
            event.key === 'Enter' && handleSubmit(handleSendThreadsBotMessage)
          }
          backgroundColor={colors.blue5}
        />
        <SendButton
          disabled={isSending}
          type="submit"
          backgroundColor={colors.blue5}
          variant="plain"
          data-cy="chat-edit-send-btn"
        >
          {isSending ? (
            <Tooltip data="ThreadsBot is processing...">
              <BsArrowRepeat size={25} strokeWidth={0.7} color={colors.gray1} />
            </Tooltip>
          ) : (
            <Icon icon="SendIcon" stroke={colors.gray1} />
          )}
        </SendButton>
      </ThreadsBotTextInputContainer>
    </form>
  );
};

export default ThreadsBotTextInput;
