import React, { FC, useEffect, useRef, useState } from 'react';
import Popup from 'ui/popup';
import Icon from 'ui/icon';
import { IEncubeJam } from 'modules/liveJams/types';
import { PopupTrigger } from './components/PopupTrigger';
import { PopupContent } from './components/PopupContent';

interface IProps {
  jams: IEncubeJam[];
  loading: boolean;
}

export const ThreadLiveJams: FC<IProps> = ({ jams, loading }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const triggerRef = useRef<HTMLButtonElement>(null);

  const popupTriggerClickHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const popupCloseHandler = (evt: Event) => {
    if (evt.target === triggerRef.current) {
      // We have specified handler for trigger click, so we don't need to close popup
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <PopupTrigger
        ref={triggerRef}
        type="button"
        onClick={popupTriggerClickHandler}
        data-cy="thread-live-jam-trigger"
      >
        <Icon icon="JamListIcon" style={{ width: '18px', height: '18px' }} />
        Live Jams
        <Icon
          icon="JamCaretIcon"
          rotate={isOpen ? '180deg' : '0deg'}
          style={{
            width: '16px',
            height: '16px',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </PopupTrigger>
      <Popup
        isOpen={isOpen}
        trigger={triggerRef}
        onClose={popupCloseHandler}
        options={{ placement: 'bottom-start' }}
        cypressAttribute="threads-live-jams-popup"
      >
        <PopupContent
          jams={jams}
          loading={loading}
          cypressAttribute="threads-live-jams-popup-content"
        />
      </Popup>
    </>
  );
};
