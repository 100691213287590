import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { signupApi } from 'modules/signup/signupApi';
import { ILoginResponse, IUserState } from './types';
import authApi from './authApi';
import { userApi } from '../user/userApi';
import { IUser, EUserRole } from '../../types/user';
import { resetAll } from '../../app/api';

const jwt = localStorage.getItem('token');

export const initialState: IUserState = {
  userInfo: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatar: '',
    siteId: '',
    description: '',
    phone: '',
    group: '',
    userRole: EUserRole.USER,
    termsAndConditions: null,
    isTrialExpired: false,
    isImpersonate: false,
    isSiteOwner: false,
    impersonatorId: null,
    canImpersonate: false,
  },
  isAuthenticated: !!jwt,
  token: jwt,
  redirectUrl: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<IUserState>) => ({
      ...state,
      ...payload,
    }),
    logout: () => {
      localStorage.clear();
      return { ...initialState, isAuthenticated: false, token: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }: PayloadAction<ILoginResponse>) => {
          localStorage.setItem('token', payload.access);
          const { pk, ...rest } = payload.user;
          return {
            ...state,
            token: payload.access,
            isAuthenticated: true,
            id: pk,
            ...rest,
          };
        }
      )
      .addMatcher(
        authApi.endpoints.loginWithSSO.matchFulfilled,
        (state, { payload }: PayloadAction<ILoginResponse>) => {
          localStorage.setItem('token', payload.access);
          const { pk, ...rest } = payload.user;
          return {
            ...state,
            token: payload.access,
            isAuthenticated: true,
            id: pk,
            ...rest,
          };
        }
      )
      .addMatcher(signupApi.endpoints.selfLinkRegister.matchFulfilled, (state, { payload }) => {
        localStorage.setItem('token', payload.access);
        const { pk, ...rest } = payload.user;
        return {
          ...state,
          redirectUrl: payload.redirectUrl,
          token: payload.access,
          isAuthenticated: true,
          id: pk,
          ...rest,
        };
      })
      .addMatcher(
        userApi.endpoints.getUserInfo.matchFulfilled,
        (state, { payload }: PayloadAction<IUser>) => ({ ...state, userInfo: payload })
      );
  },
});

export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectUser = (state: RootState) => state.user;
export const selectUserRole = (state: RootState) => state.user.userInfo.userRole;

export const { updateUser, logout } = userSlice.actions;
export default userSlice.reducer;
