import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface IGroupProps {
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  wrap?: CSSProperties['flexWrap'];
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  overflow?: CSSProperties['overflow'];
  gap?: string;
  fluid?: boolean;
  fullHeight?: boolean;
  minWidth?: number;
}

const Group = styled.div<IGroupProps>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'start'};
  align-items: ${({ align }) => align || 'stretch'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  gap: ${({ gap }) => gap || '5px'};
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  overflow: ${({ overflow }) => overflow || 'unset'};
`;

export default Group;
