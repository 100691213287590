import React, { useState, useEffect, useMemo, FC } from 'react';
import * as yup from 'yup';
import colors from 'theme/colors';
import { useAppDispatch } from 'app/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Group, TextInput, TextArea, Icon, RadioButton, Text, Spinner } from 'ui';
import FileVersionForm from 'modules/references/components/FileVersionForm';
import SyncDataForm from 'modules/references/components/SyncDataForm';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { IReferences, IEditReferenceMutation, REFERENCE_TYPE } from 'modules/threads/types';
import { NOTIFICATION } from 'modules/alert/constants';
import { FILE_PARAMS } from 'constants/index';
import { useGetReferenceQuery, useUpdateReferenceMutation } from 'modules/threads/threadsApi';
import { validationSchema } from 'utils/validation';
import { fileTypeMatches } from 'utils/helpers';
import { useModal } from 'modules/modals/ModalProvider';
import IntegrationSection from 'modules/references/components/IntegrationSection';
import { removeSelectedReference } from 'modules/threads/referencesSlice';
import { usePlayableStatus } from 'modules/playable';
import * as Layout from './Layout';

interface IEditReference {
  reference?: IReferences;
  referenceId?: number;
}

const validation = yup
  .object({
    name: validationSchema.stringRequired(),
    description: validationSchema.textArea().nullable(),
    url: validationSchema.url(),
  })
  .required();

const EditReference: FC<IEditReference> = ({ reference, referenceId }) => {
  const currentRefId = reference?.id || referenceId;
  const {
    data,
    refetch,
    isLoading: isReferenceLoading,
    isFetching: isReferenceFetching,
  } = useGetReferenceQuery(currentRefId as number, {
    refetchOnMountOrArgChange: true,
  });

  const [updateReference, { isError, isLoading, isSuccess, error: updateError }] =
    useUpdateReferenceMutation();
  const [isPlayableError, setIsPlayableError] = useState<boolean>(false);
  const { close } = useModal();

  const dispatch = useAppDispatch();
  const { isPlayableFunctionalAvailable } = usePlayableStatus();

  const [preview, setPreview] = useState<string | null>();
  const [attach, setAttach] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);

  const isLink = useMemo(() => data?.referenceType === REFERENCE_TYPE.LINK, [data?.referenceType]);
  const isFile = useMemo(() => data?.referenceType === REFERENCE_TYPE.FILE, [data?.referenceType]);
  const isEncubeFile = useMemo(
    () => data?.referenceType === REFERENCE_TYPE.ENCUBE,
    [data?.referenceType]
  );
  const isPlayableFile = useMemo(
    () => data?.referenceType === REFERENCE_TYPE.PLAYABLE,
    [data?.referenceType]
  );

  const shouldShowFileVersionSection = isFile || isEncubeFile || isPlayableFile;

  const isGoogleIntegrationReference = useMemo(
    () => reference && reference.icons,
    [reference?.icons]
  );

  const hasAttachedFile = useMemo(() => {
    return attach !== null;
  }, [attach]);

  const isFileWithNoIntegrationConnected = useMemo(
    () =>
      isFile &&
      data?.fileVersions.length === 1 &&
      fileTypeMatches(data?.fileVersions[0].file, ['stp', 'step']) &&
      attach === null,
    [data, attach]
  );

  const [isPlayableIntegrationActive, setIsPlayableIntegrationActive] =
    useState<boolean>(isPlayableFile);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEditReferenceMutation>({
    mode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation),
  });

  const handleReplaceFile = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    if (!target.files) return;
    if (target.files[0].size > FILE_PARAMS.SIZE) {
      showErrorMessage(NOTIFICATION.MAX_SIZE_UPLOAD);
      target.value = '';
      return;
    }
    setAttach(target.files[0]);
    setPreview(null);
    setError(false);
  };

  const onFileChange = (uploadFile: File) => {
    setError(false);
    setAttach(uploadFile);
  };

  const resetUpload = () => {
    setValue('file', null);
    setAttach(null);
    setError(false);
  };

  const onSubmit: SubmitHandler<IEditReferenceMutation> = (payload, event) => {
    event?.preventDefault();

    if (attach !== null) {
      payload.file = attach;
    }

    if (typeof preview === 'string') {
      delete payload.file;
    }

    if (isPlayableFile && !payload.plyableQuoteFor && attach !== null) {
      setIsPlayableError(true);
      return;
    }
    if (
      attach === null &&
      isFileWithNoIntegrationConnected &&
      !payload.plyableQuoteFor &&
      isPlayableIntegrationActive
    ) {
      setIsPlayableError(true);
      return;
    }

    if (isFileWithNoIntegrationConnected && isPlayableIntegrationActive) {
      payload.referenceType = REFERENCE_TYPE.PLAYABLE;
    }

    updateReference({ ...payload, id: currentRefId as number });
  };

  const handlePlayableManufactureChange = (option: { label: string; value: string }) => {
    setIsPlayableError(false);
    setValue('plyableQuoteFor', option.value);
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage(NOTIFICATION.REFERENCE_UPDATED);
      close();
    }

    if (isError) {
      const fileExtensionError = updateError as { data: { file: string[] } };
      showErrorMessage(fileExtensionError?.data?.file[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    // remove selected encube file when edit reference unmounts
    return () => {
      dispatch(removeSelectedReference());
    };
  }, []);

  useEffect(() => {
    if (data) {
      setValue('name', data?.name);
      setValue('description', data?.description as string);
    }
  }, [data]);

  useEffect(() => {
    if (isPlayableFile) {
      setIsPlayableIntegrationActive(true);
    }
  }, [isPlayableFile]);

  if (isReferenceLoading || isReferenceFetching) {
    return (
      <Stack style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <Spinner />
      </Stack>
    );
  }

  return (
    <Layout.Form onSubmit={handleSubmit(onSubmit)} data-cy="modal-edit-reference-form">
      <Stack fluid gap="25px" style={{ marginBottom: '20px' }}>
        <TextInput
          label="Name"
          name="name"
          placeholder="Name"
          type="text"
          defaultValue={data?.name}
          register={register}
          required
          error={errors.name}
          data-cy="modal-edit-reference-name"
        />
        <TextArea
          label="Description"
          name="description"
          placeholder="Description"
          resize="none"
          defaultValue={data?.description}
          register={register}
          error={errors.description}
          data-cy="modal-edit-reference-description"
        />
        <Group gap="12px" role="radiogroup">
          {isLink && (
            <RadioButton
              id="edit-link"
              value="link"
              text="Link"
              icon={<Icon icon="ReferenceLinkIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.LINK}
              checked={isLink}
              aria-checked={isLink}
              onChange={() => null}
              data-cy="modal-edit-reference-radio-link"
            />
          )}
          {(isFile || isPlayableFile) && (
            <RadioButton
              id="edit-file"
              value="file"
              text="File"
              icon={<Icon icon="ReferenceDocumentIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.FILE}
              checked={isFile || isPlayableFile}
              aria-checked={isFile || isPlayableFile}
              onChange={() => null}
              data-cy="modal-edit-reference-radio-file"
            />
          )}
          {isEncubeFile && (
            <RadioButton
              id="edit-encube"
              value="encube"
              text="Encube"
              icon={<Icon icon="ReferenceEncubeIcon" size="medium" stroke="none" />}
              name={REFERENCE_TYPE.ENCUBE}
              checked={isEncubeFile}
              aria-checked={isEncubeFile}
              onChange={() => null}
              data-cy="modal-edit-reference-radio-encube"
            />
          )}
        </Group>

        {isLink && (
          <TextInput
            register={register}
            required={isLink}
            error={errors.url}
            defaultValue={data?.url || ''}
            label="Paste link"
            disabled={isGoogleIntegrationReference}
            name="url"
            type="text"
            icon={<Icon icon="LinkIcon" />}
            placeholder="Paste your link here"
            style={{ border: `1px solid ${colors.blue}` }}
            data-cy="modal-edit-reference-paste-link"
          />
        )}

        {shouldShowFileVersionSection && (
          <FileVersionForm
            versionFiles={data?.fileVersions || []}
            fileName={data?.name as string}
            isLoading={isLoading}
            attach={attach}
            register={register}
            uploadFile={onFileChange}
            resetUpload={resetUpload}
            fileType={data?.referenceType as typeof REFERENCE_TYPE[keyof typeof REFERENCE_TYPE]}
            cypressAttribute="modal-edit-reference-file-version"
          />
        )}
        {isEncubeFile && <SyncDataForm reference={data as IReferences} updateReference={refetch} />}
        {(isFileWithNoIntegrationConnected || isPlayableFile) && isPlayableFunctionalAvailable && (
          <IntegrationSection
            type="plyable"
            isEdit
            isSelectDisabled={isLoading}
            isInfoSection={isPlayableFile}
            isCreateSection={isFileWithNoIntegrationConnected || hasAttachedFile}
            active={isPlayableIntegrationActive}
            toggleIntegration={setIsPlayableIntegrationActive}
            dataLabels={data?.labels}
            onChange={handlePlayableManufactureChange}
            isPlayableError={isPlayableError}
          />
        )}

        {error && <Text color={colors.red}>Should contain at least one reference</Text>}
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-edit-reference-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          cypressAttribute="modal-edit-reference-cancel-update-btn"
        >
          {isLoading ? <Spinner color="white" size="small" /> : 'Update and Save'}
        </Button>
      </Group>
    </Layout.Form>
  );
};

export default EditReference;
