import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface IGantt {
  ganttView: boolean;
}

const initialState: IGantt = {
  ganttView: false,
};

const ganttSlice = createSlice({
  name: 'gantt',
  initialState,
  reducers: {
    toggleView: (state) => {
      state.ganttView = !state.ganttView;
    },
    resetView: () => initialState,
  },
});

export const selectGanttView = (state: RootState) => state.gantt.ganttView;
export const { toggleView, resetView } = ganttSlice.actions;

export default ganttSlice.reducer;
