import React, { FC, useCallback } from 'react';
import { useAppSelector } from 'app/hooks';
import { EChatFilter } from 'modules/chat/types';
import { selectFilter } from 'modules/chat/chatSlice';

import PlyableAnnotations from 'modules/PlyableAnnotations/components/PlyableAnnotations';

import EncubeAnnotations from './components/EncubeAnnotations';

interface IProps {
  annotationType: EChatFilter;
}

export const Annotations: FC<IProps> = ({ annotationType }) => {
  const chatFilter = useAppSelector(selectFilter);

  const renderAnnotations = useCallback(() => {
    switch (chatFilter) {
      case EChatFilter.ENCUBE_EVENT:
        return <EncubeAnnotations />;

      case EChatFilter.PLAYABLE_EVENT:
        return <PlyableAnnotations />;

      default:
        return null;
    }
  }, [chatFilter]);

  return renderAnnotations();
};
