import { api } from 'app/api';
import type { IGetAnnotationParams } from 'modules/EncubeAnnotations/types';
import type { IPlayableAnnotationRaw } from 'modules/playable/types';
import type { IId, IPaginatedResponse } from 'types';

export const plyableAnnotationApi = api.injectEndpoints({
  endpoints: (build) => ({
    plyableAnnotations: build.query<
      IPaginatedResponse<IPlayableAnnotationRaw>,
      Partial<IGetAnnotationParams>
    >({
      query: ({ id, link }) => ({
        url: link || `/threads/${id}/plyable-comments/`,
      }),
      providesTags: ['plyableAnnotations'],
    }),
    plyableAnnotationById: build.query<IPlayableAnnotationRaw, IId>({
      query: ({ id }) => `/plyable/comments/${id}/`,
      providesTags: ['plyableAnnotations'],
    }),
    plyableAnnotationsPolling: build.query<
      IPaginatedResponse<IPlayableAnnotationRaw>,
      Partial<IGetAnnotationParams>
    >({
      query: ({ id, limit }) => `/threads/${id}/plyable-comments/?limit=${limit}&offset=0`,
      providesTags: ['plyableAnnotations'],
    }),
  }),
});

export const {
  usePlyableAnnotationsQuery,
  useLazyPlyableAnnotationByIdQuery,
  usePlyableAnnotationsPollingQuery,
} = plyableAnnotationApi;
