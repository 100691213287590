import { useAppSelector } from 'app/hooks';
import { formatDate, getNextDay } from 'utils/helpers';
import { EThreadParams } from 'types';
import { selectFilter } from './filterSlice';
import { useGetThreadsListQuery } from './threadsApi';
import { DEFAULT_LIMIT } from './constants';
import { EDefaultSortOption } from './types';

export const useThreadsList = (
  url: string,
  ordering: EThreadParams | string = EDefaultSortOption.UPDATED_AT_DSC,
  shouldSkipRequest = false,
  shouldRefetchOnMountOrArgChange = false
) => {
  const { search, status, group, tags, date } = useAppSelector(selectFilter);

  const dateEnd = getNextDay(date[1]);
  const updatedAt_Gte = formatDate(date[0]);
  const updatedAt_Lte = formatDate(dateEnd);
  return useGetThreadsListQuery(
    {
      url,
      ordering,
      limit: DEFAULT_LIMIT,
      offset: 0,
      search,
      status,
      group,
      tags,
      updatedAt_Gte,
      updatedAt_Lte,
    },
    {
      refetchOnMountOrArgChange: shouldRefetchOnMountOrArgChange,
      skip: shouldSkipRequest,
    }
  );
};
