import React, { ChangeEvent } from 'react';
import { getFullName } from 'utils/helpers';
import { Card } from 'modules/relatedThreads/components/Layout';
import { ISearchRelatedThread, IRelatedEvent } from 'modules/relatedThreads/types';
import { Group, Text, Checkbox, Button, Avatar } from 'ui';
import colors from 'theme/colors';
import SelectEvent from './SelectEvent';

interface IRelatedThreadProps extends ISearchRelatedThread {
  selected?: boolean;
  toggleThread: (checked: boolean, val: number) => void;
  changeEvent: (value: IRelatedEvent) => void;
  cypressAttribute?: string;
}

const RelatedThread: React.FC<IRelatedThreadProps> = ({
  id,
  name,
  events,
  creator,
  selected,
  toggleThread,
  changeEvent,
  cypressAttribute,
}) => {
  const handleThreadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    toggleThread(checked, Number(value));
  };

  const creatorName = getFullName(creator);

  return (
    <Card data-cy={cypressAttribute && `${cypressAttribute}-card`}>
      <Group
        align="center"
        justify="space-between"
        gap="15px"
        data-cy={cypressAttribute && `${cypressAttribute}-group`}
      >
        <Checkbox
          name="thread"
          value={id}
          checked={selected}
          size="xl"
          onChange={handleThreadChange}
          cypressAttribute={cypressAttribute}
        />

        <Text cypressAttribute={cypressAttribute && `${cypressAttribute}-id`}>{id}</Text>
        <Text cut={25} cypressAttribute={cypressAttribute && `${cypressAttribute}-name`}>
          {name}
        </Text>
        <Button
          leftIcon={<Avatar src={creator?.avatar} size="small" />}
          size="sm"
          color={colors.dark6}
          style={{ flex: 1.5, maxWidth: 150 }}
          cypressAttribute={cypressAttribute && `${cypressAttribute}-creator-btn`}
        >
          {creatorName}
        </Button>
        {selected && <SelectEvent events={events} onChange={changeEvent} />}
      </Group>
    </Card>
  );
};

export default RelatedThread;
