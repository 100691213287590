import React from 'react';

import { ReactComponent as GoogleSheetsIcon } from 'assets/icons/google-sheets.svg';
import { ReactComponent as GoogleDocsIcon } from 'assets/icons/google-docs.svg';
import { ReactComponent as GoogleSlidesIcon } from 'assets/icons/google-slides.svg';
import { ReactComponent as GoogleDriveIcon } from 'assets/icons/google-drive.svg';
import styled from 'styled-components';
import { IUserMinimize } from 'types/user';
import { Icon } from 'ui';

export const AVAILABLE_INTEGRATIONS = {
  spreadsheets: 'spreadsheets',
  document: 'documents',
  presentation: 'presentations',
  drive: 'drive',
} as const;

export const INTEGRATION_LIST = {
  spreadsheets: {
    title: 'Google Sheets',
    icon: <GoogleSheetsIcon width={52} height={72} />,
  },
  document: {
    title: 'Google Docs',
    icon: <GoogleDocsIcon width={52} height={72} />,
  },
  presentation: {
    title: 'Google Slides',
    icon: <GoogleSlidesIcon width={52} height={72} />,
  },
  drive: {
    title: 'Google Drive',
    icon: <GoogleDriveIcon width={52} height={72} />,
  },
};

export const INTEGRATION_SUCCESSFUL = {
  spreadsheets: {
    integrationName: 'Google Sheets',
    helpLink: 'https://authentise.zendesk.com/hc/en-us/sections/19807393261716-Integrations',
    icon: <GoogleSheetsIcon width={88} height={75} />,
  },
  document: {
    integrationName: 'Google Docs',
    helpLink: 'https://authentise.zendesk.com/hc/en-us/sections/19807393261716-Integrations',
    icon: <GoogleDocsIcon width={88} height={75} />,
  },
  presentation: {
    integrationName: 'Google Slides',
    helpLink: 'https://authentise.zendesk.com/hc/en-us/sections/19807393261716-Integrations',
    icon: <GoogleSlidesIcon width={88} height={75} />,
  },
  drive: {
    integrationName: 'Google Drive',
    helpLink: 'https://authentise.zendesk.com/hc/en-us/sections/19807393261716-Integrations',
    icon: <GoogleDriveIcon width={88} height={75} />,
  },
};

const IntegrationIconContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color || 'rgba(0, 134, 249, 0.2)'};
`;

export const REFERENCE_INTEGRATION_ICONS = {
  spreadsheets: (
    <IntegrationIconContainer color="#21A4644D">
      <GoogleSheetsIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  document: (
    <IntegrationIconContainer>
      <GoogleDocsIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  presentation: (
    <IntegrationIconContainer color="#F5BA154D">
      <GoogleSlidesIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
  drive: (
    <IntegrationIconContainer>
      <GoogleDriveIcon width={10} height={15} />
    </IntegrationIconContainer>
  ),
};

export const DEFAULT_GOOGLE_CREATOR: IUserMinimize = {
  avatar: '/images/google-logo.svg',
  firstName: 'Google',
  lastName: 'Comments',
  id: 'DEFAULT_GOOGLE_CREATOR',
  email: 'admin@google.com',
  group: 'Google Comments',
};

export const INTEGRATION_COLORS = {
  encube: '#11B667',
  plyable: '#F583FF',
};

export const INTEGRATION_LOGOS = {
  encube: (
    <Icon
      icon="EncubeLogoIcon"
      stroke="none"
      style={{ width: '30px', height: '30px' }}
      aria-label="Encube logo"
    />
  ),
  plyable: (
    <div style={{ backgroundColor: '#000', borderRadius: '50%', width: 30, height: 30 }}>
      <Icon
        icon="PlyableLogo"
        stroke="none"
        style={{ width: '30px', height: '30px' }}
        aria-label="Plyable logo"
      />
    </div>
  ),
};

export const REFERENCE_INTEGRATIONS = {
  encube: 'encube',
  plyable: 'plyable',
} as const;

export const PLAYABLE_QUOTE_FOR = {
  TOOL: 'Tool manufacture',
  COMPONENT: 'Component manufacture',
} as const;

export const PLAYABLE_MOLD_TYPES_OPTIONS = [
  { value: 'COMPONENT', label: 'Component manufacture' },
  { value: 'TOOL', label: 'Tool manufacture' },
];
