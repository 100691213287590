import styled, { css } from 'styled-components';
import colors from 'theme/colors';
import { EVariant, EPosition, EWidth, TextProps, EAlignment } from './types';

const top = css<TextProps>`
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
`;

const bottom = css<TextProps>`
  top: 100%;
`;

const right = css<TextProps>`
  top: -5px;
  left: 125%;
`;

const left = css<TextProps>`
  top: -5px;
  right: 125%;
`;

const dark = css<TextProps>`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
`;

const light = css<TextProps>`
  background-color: white;
  color: black;
`;

const VARIANT_MODE = {
  [EVariant.DARK]: dark,
  [EVariant.LIGHT]: light,
};

const POSITION_MAP = {
  [EPosition.TOP]: top,
  [EPosition.BOTTOM]: bottom,
  [EPosition.RIGHT]: right,
  [EPosition.LEFT]: left,
};

const WIDTH_MAP = {
  [EWidth.AUTO]: 'auto',
  [EWidth.FULL]: '100%',
  [EWidth.MD]: '250px',
  [EWidth.LG]: '350px',
};

const ALIGNMENT_MAP = {
  [EAlignment.CENTER]: 'center',
  [EAlignment.START]: 'start',
  [EAlignment.END]: 'end',
};

export const Text = styled.div<TextProps>`
  visibility: hidden;
  position: absolute;
  font-size: 10px;
  padding: 8px;
  border-radius: 6px;
  z-index: 20;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: max-content;
  max-width: 300px;
  // width: ${({ width }) => (width ? WIDTH_MAP[width] : WIDTH_MAP[EWidth.AUTO])};
  ${({ position }) => (position ? POSITION_MAP[position] : POSITION_MAP[EPosition.TOP])};
  ${({ mode }) => (mode ? VARIANT_MODE[mode] : VARIANT_MODE[EVariant.DARK])};
  // text-align: ${({ alignment }) =>
    alignment ? ALIGNMENT_MAP[alignment] : ALIGNMENT_MAP[EAlignment.START]};
`;

export const Tooltip = styled.div<{ isVisible: boolean }>`
  position: relative;
  &:hover {
    ${Text} {
      visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
      opacity: 1;
    }
  }
`;
