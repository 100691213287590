import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IId, IPaginatedResponse } from 'types';
import { resetAll } from 'app/api';
import { selectOwner } from 'modules/threads/threadsSlice';
import { getMention } from 'utils/mappers';
import { contributorsApi } from './contributorsApi';
import { IContributor } from './types';

interface IContributorsState {
  count: number;
  contributors: IContributor[];
  isLoading: boolean;
}

export const initialState: IContributorsState = {
  count: 0,
  contributors: [],
  isLoading: false,
};

export const getContributors = createAsyncThunk(
  'contributors/fetchAll',
  async (id: string, { dispatch }) => {
    const result = dispatch(contributorsApi.endpoints.getContributors.initiate({ id }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IContributor>;
  }
);

export const contributorsSlice = createSlice({
  name: 'contributors',
  initialState,
  reducers: {
    deleteContributor: (state, { payload }: PayloadAction<IId>) => {
      state.contributors = state.contributors.filter(
        (contributor) => contributor.id !== payload.id
      );
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        getContributors.fulfilled,
        (state, { payload }: PayloadAction<IPaginatedResponse<IContributor>>) => {
          state.count = payload.count;
          state.contributors = payload.results;
          state.isLoading = false;
          return state;
        }
      )
      .addCase(getContributors.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(resetAll, () => initialState)
      .addMatcher(
        contributorsApi.endpoints.addContributors.matchFulfilled,
        (state, { payload }: PayloadAction<IContributor[]>) => {
          state.contributors = [...state.contributors, ...payload];
        }
      ),
});

export const selectContributors = (state: RootState) => state.contributors.contributors;
export const selectLoadingStatus = (state: RootState) => state.contributors.isLoading;
export const selectMentions = (threadId: string) => (state: RootState) => {
  const threadOwner = selectOwner(threadId)(state);
  // We a getting mentions from contributors and thread owner could be a contributor list so we need to filter it out
  const mentions = state.contributors.contributors
    .map(({ user }) => getMention(user))
    .filter((mention) => mention.id !== threadOwner.id);
  return [...mentions, getMention(threadOwner)];
};
export const selectIsContributor = (userId: string) => (state: RootState) =>
  !!state.contributors.contributors.find(({ user }) => user.id === userId);

export const { deleteContributor, resetState } = contributorsSlice.actions;

export default contributorsSlice.reducer;
