import React, { useCallback, useRef, useState } from 'react';
import { Icon, Popup } from 'ui';
import { useAppSelector } from 'app/hooks';
import * as Layout from './components/Layout';
import { LIMIT, selectUnreadCount } from './slice';
import View from './components/View';
import { useGetNotificationsQuery } from './notificationApi';

const Notification = () => {
  const ref = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const openNotifications = () => setShowNotifications(true);
  const closeNotification = useCallback(() => setShowNotifications(false), []);
  const count = useAppSelector(selectUnreadCount);

  useGetNotificationsQuery({ offset: 0, limit: LIMIT });

  return (
    <>
      <Layout.Notify ref={ref} onClick={openNotifications} data-cy="header-user-notifications">
        {count > 0 && <Layout.NotifyCount>{count > 9 ? '9+' : count}</Layout.NotifyCount>}
        <Icon icon="BellIcon" size="medium" stroke="#8B8B8E" />
      </Layout.Notify>
      <Popup
        isOpen={showNotifications}
        trigger={ref}
        onClose={closeNotification}
        options={{ placement: 'bottom-end' }}
      >
        <View closeNotificationModal={closeNotification} />
      </Popup>
    </>
  );
};

export default Notification;
