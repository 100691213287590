import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Group } from 'ui';
import { EChatFilter } from 'modules/chat/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectFilter, updateFilter } from 'modules/chat/chatSlice';
import { useEncubeStatus } from 'modules/encube';

const ActiveButton = styled(Button)<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => isActive && theme.primaryColor};
  border-color: ${({ theme, isActive }) => isActive && theme.primaryColor};
  color: ${({ isActive }) => isActive && 'white'};
  background-color: ${({ theme, isActive }) => isActive && theme.primaryColor};

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

const ChatFilter = () => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();

  const chatFilter = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();
  const onFilterClick = (event: MouseEvent<HTMLElement>) => {
    const filter = event.currentTarget.dataset.filter as EChatFilter;
    dispatch(updateFilter(filter));
  };

  return (
    <Group data-cy="chat-filter-group">
      <ActiveButton
        variant="outlined"
        onClick={onFilterClick}
        isActive={chatFilter === EChatFilter.ALL}
        size="sm"
        data-filter={EChatFilter.ALL}
        color={colors.dark4}
      >
        All
      </ActiveButton>
      <ActiveButton
        isActive={chatFilter === EChatFilter.COMMENTS}
        size="sm"
        color={colors.dark4}
        variant="outlined"
        onClick={onFilterClick}
        data-filter={EChatFilter.COMMENTS}
      >
        Comments
      </ActiveButton>
      <ActiveButton
        isActive={chatFilter === EChatFilter.LOGS}
        size="sm"
        color={colors.dark4}
        variant="outlined"
        onClick={onFilterClick}
        data-filter={EChatFilter.LOGS}
      >
        Log
      </ActiveButton>
      {isEncubeFunctionalAvailable && (
        <ActiveButton
          isActive={chatFilter === EChatFilter.ENCUBE_EVENT}
          size="sm"
          color={colors.dark4}
          variant="outlined"
          onClick={onFilterClick}
          data-filter={EChatFilter.ENCUBE_EVENT}
        >
          Encube Event
        </ActiveButton>
      )}
      <ActiveButton
        isActive={chatFilter === EChatFilter.PLAYABLE_EVENT}
        size="sm"
        color={colors.dark4}
        variant="outlined"
        onClick={onFilterClick}
        data-filter={EChatFilter.PLAYABLE_EVENT}
      >
        Plyable Log
      </ActiveButton>
    </Group>
  );
};

export default ChatFilter;
