import { IError } from 'types';
import { NOTIFICATION } from '../alert/constants';
import { showErrorMessage } from '../alert/utils';

export const onChangePassError = ({ error }: IError) => {
  if (error.status === 400) {
    showErrorMessage(NOTIFICATION.INVALID_PASSWORD);
    return;
  }
  showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
};
