import React from 'react';
import * as Layout from 'modules/resources/components/Layout';
import colors from 'theme/colors';
import { useDeleteResourceMutation } from 'modules/resources/resourcesApi';
import { IDeleteResource } from 'modules/resources/types';
import { Button, Group, Stack, Text } from 'ui';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { useModal } from 'modules/modals/ModalProvider';
import { handleApiCall } from 'utils/helpers';

const DeleteResource = ({ id, name, unit, amount }: IDeleteResource) => {
  const [deleteResource, { isLoading }] = useDeleteResourceMutation();
  const { close } = useModal();

  const handleDeleteResource = async () => {
    const res = await deleteResource(id);
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        close();
        showSuccessMessage(NOTIFICATION.RESOURCE_DELETED);
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete this Resource?
      </Text>
      <Layout.Card>
        <Stack data-cy="modal-delete-resource-stack">
          <Text weight="500" cypressAttribute="modal-delete-resource-name">
            {name}
          </Text>
          <Text color={colors.gray3} cypressAttribute="modal-delete-resource-amount-unit">
            {amount} {unit}
          </Text>
        </Stack>
      </Layout.Card>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={() => close()}
          cypressAttribute="modal-delete-resource-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteResource}
          cypressAttribute="modal-delete-resource-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Stack>
  );
};

export default DeleteResource;
