import React, { FC } from 'react';

import type { IPlyableReply } from 'modules/playable/types';
import { AnnotationItemWrapper } from 'modules/EncubeAnnotations/components/AnnotationLayout';

import { ReactComponent as PlayableEventLogo } from 'assets/icons/playable-logo.svg';
import { AnnotationsSkeleton } from 'modules/EncubeAnnotations/components/AnnotationsSkeleton';
import { useLazyPlyableAnnotationByIdQuery } from '../api';
import PlyableAnnotationContent from './PlyableAnnotationContent';

import * as Layout from '../../EncubeAnnotations/components/AnnotationLayout';

interface IPlyableAnnotationItemProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Annotation author avatar source.
   */
  authorAvatarSrc: string;
  /**
   * Annotation author name.
   */
  authorName: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * List of comments for the annotation.
   */
  comments: IPlyableReply[];
}

const PlyableAnnotationItem: FC<IPlyableAnnotationItemProps> = (props) => {
  const {
    id,
    authorAvatarSrc,
    authorName,
    createdAt,
    fileId,
    fileName,
    fileVersion,
    fileUrl,
    comments = [],
  } = props;

  const [fetchPlyableAnnotation, { isFetching }] = useLazyPlyableAnnotationByIdQuery();

  const annotationRefreshClickHandler = (annotationId: string) => {
    fetchPlyableAnnotation({ id: annotationId });
  };

  if (isFetching) {
    return <AnnotationsSkeleton />;
  }

  return (
    <AnnotationItemWrapper
      fluid
      gap="12px"
      justify="flex-start"
      align="flex-start"
      data-cy="annotation-item-wrapper"
    >
      <PlyableAnnotationContent
        key={id}
        id={id}
        authorName={authorName}
        createdAt={createdAt}
        fileId={fileId}
        fileName={fileName}
        fileVersion={fileVersion}
        fileUrl={fileUrl}
        comments={comments}
        onRefreshClick={annotationRefreshClickHandler}
      />
      <Layout.LogoWrapper data-cy="playable-annotations-avatar">
        <PlayableEventLogo />
      </Layout.LogoWrapper>
    </AnnotationItemWrapper>
  );
};

export default PlyableAnnotationItem;
