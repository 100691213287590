export const SEARCH_THREADS_PARAMS = {
  LIMIT: 50,
  OFFSET: 0,
  SEARCH: '',
  FIELDS: 'name',
  ID: '',
};

export const DEFAULT_LIMIT = 150;

export const GANTT_CELL_WIDTH = {
  LIST: '250px',
  DAY: 120,
  WEEK: 250,
  MONTH: 300,
};

export const GANTT_BAR_WIDTH = {
  DAY: 10,
  WEEK: 50,
  MONTH: 70,
};

export const STATIC_GANTT_PROPS = {
  type: 'task',
  progress: 0,
  isDisabled: true,
};

export const FETCH_ERRORS = {
  INVALID_TOKEN: 'token_not_valid',
};
