import { Card } from 'modules/resources/components/Layout';
import styled from 'styled-components';
import devices from 'theme/devices';
import Group from 'ui/group';
import Stack from 'ui/stack';

export const ImpersonationSectionContainer = styled(Stack)`
  @media screen and ${devices.mobileS} {
    width: 100%;
  }

  @media screen and ${devices.tablet} {
    width: 75%;
  }

  @media screen and ${devices.laptop} {
    width: 50%;
  }
`;

export const UserImpersonationGrid = styled(Group)`
  display: grid;

  @media screen and ${devices.mobileS} {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
`;

export const UserImpersonationCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

export const UserImpersonationSearchWrapper = styled(Group)`
  @media screen and ${devices.mobileS} {
    width: 100%;
  }

  @media screen and ${devices.tablet} {
    width: 75%;
  }

  @media screen and ${devices.laptop} {
    width: 50%;
  }
`;
