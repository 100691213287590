import React, { useEffect, useMemo, useState } from 'react';
import { AddButton, Group, MemberList, Stack, Tags, Text, Title as Heading } from 'ui';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectThread } from 'modules/threads/threadsSlice';
import { getContributors, selectContributors } from 'modules/contributors/contributorsSlice';
import { useModal } from 'modules/modals/ModalProvider';
import { AddTagsModal } from 'pages/thread/modals';
import styled from 'styled-components';
import Moment from 'react-moment';
import devices, { mediaSize } from 'theme/devices';
import { useWindowSize } from 'hooks/useWindowSize';
import colors from 'theme/colors';
import { EPermissions } from 'app/permissions/constants';
import AccessControl from 'app/permissions/AccessControl';
import { truncateRawString } from 'utils/helpers';
import TextButton from 'ui/textButton';
import ChatFilter from './chatFilter';

const AdaptiveWrapper = styled(Stack)`
  position: relative;
  z-index: 10;
  @media screen and ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const MobileAdaptive = styled(Stack)`
  gap: 15px;
  @media screen and ${devices.mobileL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface ITitleProps {
  isArchived: boolean;
  cypressAttribute?: string;
}

const ThreadTitle = ({ isArchived, cypressAttribute }: ITitleProps) => {
  const [addTagsModal, setAddTagsModal] = useState<boolean>(false);
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
  const { id: threadId } = useParams();
  const { width } = useWindowSize();
  const { tags, description } = useAppSelector(selectThread(threadId as string));
  const dispatch = useAppDispatch();
  const contributors = useAppSelector(selectContributors);
  const memberList = useMemo(() => {
    return contributors.map((member) => {
      const { firstName, lastName, avatar } = member.user;
      return { firstName, lastName, avatar, id: member.id };
    });
  }, [contributors]);
  const isDescriptionTruncated = description?.length > 150;

  useEffect(() => {
    dispatch(getContributors(threadId as string));
  }, [threadId]);

  const toggleTagsModal = () => {
    setAddTagsModal((prev) => !prev);
  };

  return (
    <Stack gap="10px" style={{ position: 'relative', zIndex: 10, paddingTop: 5 }}>
      <Text color={colors.gray1} cypressAttribute="thread-description-container-text">
        {isDescriptionTruncated ? (
          <Text color={colors.gray1} cypressAttribute="thread-description-full">
            {showFullDescription ? description : truncateRawString(description, 150)}
            <TextButton
              style={{ marginLeft: 5 }}
              onClick={() => setShowFullDescription((prev) => !prev)}
              cypressAttribute="thread-description-text-btn"
            >
              {showFullDescription ? 'hide full description' : 'show full description'}
            </TextButton>
          </Text>
        ) : (
          <Text color={colors.gray1} cypressAttribute="thread-description">
            {description}
          </Text>
        )}
      </Text>
      <MobileAdaptive fluid>
        <ChatFilter />
        <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-tags-group`}>
          <Text>Tags:</Text>
          <Tags
            tags={tags}
            offset={width > mediaSize.laptop ? 5 : 2}
            cypressAttribute="thread-tags"
          />
          {!isArchived && (
            <AccessControl
              permissions={[EPermissions.ADD_TAG_FROM_THREAD_PAGE]}
              threadId={threadId as string}
            >
              <AddButton onClick={toggleTagsModal} cypressAttribute="toggle-tags-modal-btn" />
            </AccessControl>
          )}

          {addTagsModal && <AddTagsModal closeModal={() => setAddTagsModal(false)} />}
        </Group>
      </MobileAdaptive>
    </Stack>
  );
};

export default ThreadTitle;
