import React, { FC } from 'react';
import moment from 'moment';
import _capitalize from 'lodash/capitalize';

import { useAppSelector } from 'app/hooks';
import { selectReferenceName, selectSelectedReference } from 'modules/threads/referencesSlice';

import { Group, Icon, Stack, TagBadge, Text, Tooltip } from 'ui';

import { convertCentsToDollars, convertPlayableDeliveryDate } from 'utils/helpers';
import { MODAL_STATUS_LABELS_COLORS, MODAL_STATUS_LABELS_TEXT } from 'constants/labels';

import colors from 'theme/colors';
import * as Layout from './Layout';

interface IProps {
  dataLabels?: typeof MODAL_STATUS_LABELS_TEXT[keyof typeof MODAL_STATUS_LABELS_TEXT][];
}

const EditPlayableSection: FC<IProps> = ({ dataLabels }) => {
  const currentFile = useAppSelector(selectSelectedReference);
  const currentFileName = useAppSelector(selectReferenceName);

  const PLAYABLE_QUOTE_LINK = currentFile?.plyableUrl;
  const PLAYABLE_UPLOAD_ERROR_MESSAGE = currentFile?.plyableUploadError;
  const QUOTE_DELIVERY_DATE = currentFile?.plyableEstimatedDeliveryDate;
  const QUOTE_PRICE_IN_CENTS = currentFile?.plyablePriceInCents;
  const QUOTE_PLAYABLE_CURRENCY = currentFile?.plyablePriceCurrency;

  const handleNavigateToPlayable = () => {
    if (!PLAYABLE_QUOTE_LINK) return;
    window.open(PLAYABLE_QUOTE_LINK, '_blank', 'noopener,noreferrer');
  };

  return (
    <Stack gap="8px">
      <Group gap="10px" align="center">
        <Icon icon="ReferenceDocumentIcon" style={{ width: 32, height: 32 }} stroke="none" />
        <Text>{currentFileName}</Text>
        <TagBadge>
          <Text size="xs" weight="400" noWrap>{`Version ${currentFile?.version}`}</Text>
        </TagBadge>
      </Group>

      <Text color={colors.gray3}>
        Quote total: {convertCentsToDollars(QUOTE_PRICE_IN_CENTS, QUOTE_PLAYABLE_CURRENCY) || 'N/A'}
      </Text>
      <Text color={colors.gray3}>
        Order Status: {_capitalize(currentFile?.plyableOrderStatus) || 'N/A'}
      </Text>
      <Group align="center" justify="space-between">
        <Text color={colors.gray3}>
          Est. delivery date: {convertPlayableDeliveryDate(QUOTE_DELIVERY_DATE)}
        </Text>
        <Group gap="15px" align="center">
          {currentFile?.isPlyableDfmCheckFailed && (
            <Tooltip
              data={<Text size="sm">Your mold design has failed one or more DFM checks.</Text>}
              position="left"
            >
              <Icon icon="InfoIcon" path={colors.red} size="medium" />
            </Tooltip>
          )}
          {PLAYABLE_QUOTE_LINK && (
            <Group
              style={{
                borderRadius: 4,
                backgroundColor: '#F583FF',
                padding: 2,
                cursor: 'pointer',
              }}
              align="center"
              onClick={handleNavigateToPlayable}
            >
              <Layout.IntegrationImageWrapper>
                <Icon icon="PlyableLogo" stroke="none" aria-label="Plyable logo" />
              </Layout.IntegrationImageWrapper>

              <Icon icon="ArrowRightIcon" size="medium" />
            </Group>
          )}
        </Group>
      </Group>
      {dataLabels && PLAYABLE_QUOTE_LINK && (
        <Group align="center" gap="25px">
          {dataLabels.map((label) => (
            <TagBadge color={MODAL_STATUS_LABELS_COLORS[label]} key={label}>
              {_capitalize(label)}
            </TagBadge>
          ))}
        </Group>
      )}
      {PLAYABLE_UPLOAD_ERROR_MESSAGE && (
        <Group gap="10px" style={{ marginTop: 10 }} align="center">
          <Icon icon="InfoIcon" path={colors.red} size="normal" />
          <Text style={{ lineHeight: 'normal' }} color={colors.red}>
            {PLAYABLE_UPLOAD_ERROR_MESSAGE}
          </Text>
        </Group>
      )}
    </Stack>
  );
};

export default EditPlayableSection;
