import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Icon from 'ui/icon';
import { useOnClickOutside } from 'hooks';
import { DropdownTrigger } from './components/DropdownTrigger';
import { DropdownPortal } from './components/DropdownPortal';
import { DropdownContent } from './components/DropdownContent';

export interface IEncubeDropdownItem {
  /**
   * Unique identifier of the dropdown item.
   */
  id: string | number;
  /**
   * Content of the dropdown item.
   */
  content: string;
  /**
   * Icon of the dropdown item that is displayed before the content.
   */
  icon?: React.ReactElement;
}

export interface IEncubeDropdownProps {
  /**
   * List of dropdown items.
   * @default []
   */
  items?: IEncubeDropdownItem[];
  /**
   * Whether the dropdown is open.
   * Provide this prop if you want to control the dropdown state.
   */
  open?: boolean;
  /**
   * Whether the dropdown is open by default.
   * @default false
   */
  defaultOpen?: boolean;
  /**
   * Callback that is called when the trigger is clicked.
   * @param openState - indicates whether the dropdown is open.
   */
  onTriggerClick?: (openState: boolean) => void;
  /**
   * Callback that is called when the user clicks outside of the dropdown.
   */
  onOutsideClick?: () => void;
  /**
   * Callback that is called when the dropdown item is clicked.
   * @param itemId - Unique identifier of the clicked item.
   */
  onItemClick?: (itemId: string | number) => void;
}

export const EncubeDropdown: FC<IEncubeDropdownProps> = (props) => {
  const {
    open,
    defaultOpen = false,
    items = [],
    onTriggerClick,
    onOutsideClick,
    onItemClick,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const [triggerRect, setTriggerRect] = useState<DOMRect | null>(null);

  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);
  const dropdownContentRef = useRef<HTMLUListElement>(null);

  const dropdownTriggerClickHandler = () => {
    const openState = !isOpen;
    setIsOpen(openState);
    onTriggerClick?.(openState);
  };

  const dropdownOutsideClickHandler = () => {
    setIsOpen(false);
    onOutsideClick?.();
  };

  const contentPositionHandler = () => {
    const trigger = dropdownTriggerRef.current;
    if (!trigger) {
      return;
    }
    const rect = trigger.getBoundingClientRect();
    setTriggerRect(rect);
  };

  const composeItemClickHandler = useCallback(
    (itemId: string | number) => {
      setIsOpen(false);
      onItemClick?.(itemId);
    },
    [onItemClick]
  );

  useOnClickOutside(dropdownContentRef, dropdownOutsideClickHandler);

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      contentPositionHandler();
      window.addEventListener('scroll', contentPositionHandler);
      window.addEventListener('resize', contentPositionHandler);
    }

    if (!isOpen) {
      window.removeEventListener('scroll', contentPositionHandler);
      window.removeEventListener('resize', contentPositionHandler);
    }

    return () => {
      window.removeEventListener('scroll', contentPositionHandler);
      window.removeEventListener('resize', contentPositionHandler);
    };
  }, [isOpen]);

  return (
    <>
      <DropdownTrigger
        ref={dropdownTriggerRef}
        type="button"
        aria-haspopup="menu"
        aria-expanded={isOpen}
        aria-label="Choose an option to open within Encube"
        onClick={dropdownTriggerClickHandler}
        data-cy="encube-dropdown-trigger"
      >
        <Icon
          icon="EncubeLogoIcon"
          stroke="none"
          style={{ width: '24px', height: '24px' }}
          aria-label="Encube logo"
        />
        <Icon
          icon="CaretIcon"
          stroke="none"
          style={{
            width: '18px',
            height: '18px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </DropdownTrigger>
      {isOpen && (
        <DropdownPortal wrapperId="encube-dropdown-portal">
          <DropdownContent
            ref={dropdownContentRef}
            triggerRect={triggerRect}
            items={items}
            onItemClick={composeItemClickHandler}
          />
        </DropdownPortal>
      )}
    </>
  );
};
