import { api } from 'app/api';
import { IPaginatedResponse } from 'types';
import {
  ISearchThreadsParams,
  ISearchRelatedThread,
  IRelatedEvent,
  IRelatedThread,
  ICreateThreadMutation,
} from './types';

export const relatedThreads = api.injectEndpoints({
  endpoints: (build) => ({
    searchRelatedThreads: build.query<
      IPaginatedResponse<ISearchRelatedThread>,
      ISearchThreadsParams
    >({
      query: ({ id, name, relatedThreadId, limit, offset }) => ({
        // eslint-disable-next-line max-len
        url: `/threads/${id}/related-threads-choices/?limit=${limit}&offset=${offset}&search=${name}&id=${relatedThreadId}`,
      }),
    }),
    getRelatedThreads: build.query<IPaginatedResponse<IRelatedThread>, string>({
      query: (id) => ({
        url: `/threads/${id}/related-threads/`,
      }),
      providesTags: ['relatedThreads'],
    }),
    createRelatedThread: build.mutation<IRelatedEvent[], ICreateThreadMutation>({
      query: (payload) => ({
        url: '/events/create-related-thread/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['relatedThreads', 'events'],
    }),
    removeRelatedThread: build.mutation<void, number>({
      query: (id) => ({
        url: `/events/${id}/remove-related-thread/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['relatedThreads', 'events'],
    }),
  }),
});

export const {
  useSearchRelatedThreadsQuery,
  useGetRelatedThreadsQuery,
  useCreateRelatedThreadMutation,
  useRemoveRelatedThreadMutation,
} = relatedThreads;
