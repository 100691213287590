import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ENV } from '../../constants/env';

interface IStreamState {
  url: string;
  isOpen: boolean;
  channels: Array<string>;
}

const SOCKET_URL = ENV.REACT_APP_WEBSOCKET_URL || '';

const initialState: IStreamState = {
  url: SOCKET_URL,
  isOpen: false,
  channels: [],
};

const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    updateSocket: (state, { payload }: PayloadAction<Partial<IStreamState>>) => ({
      ...state,
      ...payload,
    }),
    unsubscribe: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.channels = state.channels.filter((channel) => channel !== payload);
        return state;
      }
      state.channels = [];
      return state;
    },
    subscribe: (state, { payload }: PayloadAction<string>) => {
      state.channels.push(payload);
    },
  },
});

export const selectSocket = (state: RootState) => state.stream;
export const selectChannels = (state: RootState) => state.stream.channels;

export const { updateSocket, subscribe, unsubscribe } = streamSlice.actions;
export default streamSlice.reducer;
