import React, { ReactElement } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';

interface IProps {
  children: ReactElement | ReactElement[] | string | string[];
  href?: string;
  underlined?: boolean;
  color?: string;
  bold?: boolean;
  onClick?: () => unknown;
  trailingIcon?: ReactElement;
}

const BaseAnchorLink = styled.a<{ color?: string; underlined: boolean; bold?: boolean }>`
  cursor: pointer;
  color: ${({ color }) => color || colors.blue7};
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

/** @description A universal link component */
const AnchorLink = ({
  children,
  href,
  underlined = true,
  color,
  bold = false,
  onClick,
  trailingIcon,
}: IProps) => {
  return (
    <BaseAnchorLink
      onClick={onClick}
      target="_blank"
      bold={bold}
      href={href}
      underlined={underlined}
      color={color}
    >
      {children}
      <span>{trailingIcon}</span>
    </BaseAnchorLink>
  );
};

export default AnchorLink;
