import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextInput, Title, Text } from 'ui';
import { validationSchema } from 'utils/validation';
import colors from 'theme/colors';
import * as yup from 'yup';
import { useCreateWorkspaceMutation } from 'modules/createWorkspace/createWorkspaceApi';
import { useAppSelector } from 'app/hooks';
import { selectSubscriptionPriceId } from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { handleApiCall } from 'utils/helpers';

const validation = yup.object({
  email: validationSchema.emailRequired(),
  siteName: validationSchema
    .stringRequired()
    .max(30, 'Workspace name must be less than 30 characters'),
});

interface ICreateWorkspaceForm {
  email: string;
  siteName: string;
}

const defaultValues: ICreateWorkspaceForm = {
  email: '',
  siteName: '',
};

export const CreateWorkspaceForm: FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);

  const subscriptionPriceId = useAppSelector(selectSubscriptionPriceId);
  const [isCreationSuccessful, setIsCreationSuccessful] = useState(false);
  const [workspaceCreationLink, setWorkspaceCreationLink] = useState('');

  const [createWorkspace, { isLoading }] = useCreateWorkspaceMutation();

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues,
    mode: 'onSubmit',
  });

  const handleFormSubmit = async (formData: ICreateWorkspaceForm) => {
    const response = await createWorkspace({
      email: formData.email,
      siteName: formData.siteName,
      priceId: subscriptionPriceId,
    });
    handleApiCall(
      response,
      ({ error }) => {
        const errorData = error.data as { email: string[]; site_name: string[] };
        if (errorData.email) setError('email', { message: errorData.email[0] });
        if (errorData.site_name) setError('siteName', { message: errorData.site_name[0] });
      },
      ({ data }) => {
        setIsCreationSuccessful(true);
        setWorkspaceCreationLink(data.magicLink);
      }
    );
  };

  const handleCreateWorkspace = () => {
    window.open(workspaceCreationLink, '_self');
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, [emailRef]);

  if (isCreationSuccessful) {
    return (
      <Stack gap="30px">
        <Title style={{ lineHeight: '40px', fontSize: 28 }} heading="h5" weight="600">
          Thank you for entering your details! Click on the button below to create a workspace with
          your unique link.
        </Title>
        <Button type="button" onClick={handleCreateWorkspace} disabled={!workspaceCreationLink}>
          <Text weight="600">Create Workspace</Text>
        </Button>
        <Title heading="h5" color={colors.gray1} style={{ lineHeight: 'normal' }}>
          {/* Display actual email to what the link was sent */}
          Your one-time workspace creation link has also been sent to your email.
        </Title>
      </Stack>
    );
  }

  return (
    <>
      <Title style={{ lineHeight: 'normal', fontSize: 28 }} heading="h5">
        Please enter your details to get a one-time workspace creation link
      </Title>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack gap="48px">
          <TextInput
            error={errors.email}
            register={register}
            name="email"
            label="Email"
            required
            ref={emailRef}
          />
          <TextInput
            error={errors.siteName}
            register={register}
            name="siteName"
            label="Workspace name"
            required
          />
          <Button type="submit" disabled={isLoading}>
            <Text weight="600">Submit</Text>
          </Button>
        </Stack>
      </form>
    </>
  );
};
