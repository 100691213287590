import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { ITypography } from 'theme';

type TComponent = 'p' | 'span';

interface ITextProps {
  style?: CSSProperties;
  size?: keyof ITypography;
  component?: TComponent;
  color?: string;
  align?: CSSProperties['textAlign'];
  weight?: '200' | '400' | '500' | '600' | '700';
  children?: string | number | ReactElement | ReactNode;
  noWrap?: boolean;
  letterSpacing?: string;
  cut?: number;
  wordBreak?: string;
  cypressAttribute?: string;
}

const Typography = styled.p<ITextProps>`
  text-align: ${({ align }) => align || 'left'};
  color: ${({ color }) => color || 'white'};
  font-weight: ${({ weight }) => weight || '400'};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  word-break: ${({ wordBreak }) => wordBreak};
  ${({ size, theme }) => (size ? theme.typography[size] : theme.typography.sm)};
`;

const Text: FC<ITextProps> = ({ component = 'p', children, cut, ...props }) => {
  const getText = () => {
    if (typeof children !== 'string') return children;
    if (cut && cut < children.length) {
      return `${children.slice(0, cut)}...`;
    }
    return children;
  };

  return (
    <Typography style={props.style} as={component} data-cy={props.cypressAttribute} {...props}>
      {getText()}
    </Typography>
  );
};

export default Text;
