import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AccessControl from 'app/permissions/AccessControl';
import { EPermissions } from 'app/permissions/constants';
import { RootState } from 'app/store';
import { usePageInteraction } from 'hooks';
import _debounce from 'lodash/debounce';
import CopilotSuggestion from 'modules/copilot/components/CopilotSuggestion';
import {
  loadCopilotSuggestions,
  selectShowSuggestions,
  selectSuggestions,
  setShowSuggestions,
} from 'modules/copilot/copilotSlice';
import { ICopilotSuggestion } from 'modules/copilot/types';
import {
  loadNotifications,
  selectCopilotSuggestionFromNotificationsState,
  selectNotificationsState,
} from 'modules/notification/slice';
import { selectLastAppEvents } from 'modules/app/appSlice';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import colors from 'theme/colors';
import { Stack, Text } from 'ui';
import TextEditor from '@draft-js-plugins/editor';
import { DEFAULT_LIMIT } from 'modules/threads/constants';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import { EFeatureFlags } from 'constants/features';
import Input from './components/Input';
import * as ChatLayout from './components/Layout';
import { findByMsgIndexById } from './utils';

import {
  loadMessages,
  loadNextMessagesByLink,
  loadPrevMessagesByLink,
  selectChat,
  selectFilter,
} from './chatSlice';
import { EncubeMessage } from './components/EncubeMessage';
import GoogleMessage from './components/GoogleMessage/GoogleMessage';
import ChatMessage from './components/Message';
import SystemMessage from './components/SystemMessage';
import { MESSAGE_PER_PAGE } from './constants';
import { EMessageType, IMessageResponse } from './types';
import { useFocus } from './components/FocusContext';
import { PlayableMessage } from './components/PlayableMessage';

const ChatLoading = () => (
  <Stack align="center" justify="center" style={{ paddingTop: '12px' }}>
    <Text color={colors.gray2}>Loading...</Text>
  </Stack>
);

/**
 * Schema that will be appended to end of virtuoso messages.
 */
type TVirtuosoCopilotSuggestionPayload = {
  type: string;
  list: ICopilotSuggestion[];
};

interface IChatProps {
  isArchived: boolean;
}

const Chat = ({ isArchived }: IChatProps) => {
  const { id, msgId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const referer = searchParams.get('referer');
  const navigate = useNavigate();

  const { count, prevLink, nextLink, currentOffset, isLoading, messages } =
    useAppSelector(selectChat);
  const { isLoading: isLoadingSuggestions } = useAppSelector(selectSuggestions);
  const { isLoading: isNotificationsLoading } = useAppSelector(selectNotificationsState);
  const copilotSuggestions = useAppSelector((state: RootState) =>
    selectCopilotSuggestionFromNotificationsState(state, id)
  );

  const isCopilotFeatureEnabled = useIsFeatureEnabled(EFeatureFlags.COPILOT);
  const { focus, focusedInputRef } = useFocus();

  const [copilotSuggestionsPayload, setCopilotSuggestionsPayload] = useState<
    TVirtuosoCopilotSuggestionPayload[] | []
  >([]);

  // ⚠️ Hack to hide suggestions once dismissed
  // ⚠️ TODO: Replace this with an API call and invalidation
  const showSuggestions = useAppSelector(selectShowSuggestions);

  const dispatch = useAppDispatch();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const chatFilter = useAppSelector(selectFilter);

  const hasInteractedWithPage = usePageInteraction(isLoading);
  const lastAppEvents = useAppSelector(selectLastAppEvents);

  // Focus the 'main' (i.e non-reply) input on mount (input is hidden until finished loading)
  useEffect(() => {
    if (!isLoading) {
      focus(focusedInputRef.current, undefined);
    }
  }, [isLoading]);

  const loadPrev = useCallback(() => {
    if (prevLink !== 'None') {
      const newLink = prevLink?.replace(/limit=\d+/, `limit=${currentOffset}`);
      dispatch(
        loadPrevMessagesByLink({
          link: Number(currentOffset) < MESSAGE_PER_PAGE ? (newLink as string) : prevLink,
        })
      );
    }
  }, [prevLink, currentOffset]);

  const loadNext = useCallback(() => {
    if (nextLink && nextLink !== 'None') {
      dispatch(loadNextMessagesByLink({ link: nextLink }));
    }
  }, [nextLink]);

  const dismissSuggestion = () => dispatch(setShowSuggestions(false));

  const renderCopilotSuggestions = (suggestions: ICopilotSuggestion[]) => {
    return <CopilotSuggestion suggestions={suggestions} dismissSuggestion={dismissSuggestion} />;
  };

  const renderMessageContent = (messageData: IMessageResponse): React.ReactNode => {
    switch (messageData?.type) {
      case EMessageType.USER_MESSAGE:
        return <ChatMessage key={messageData.id} messages={messages} {...messageData} />;

      case EMessageType.SYSTEM_MESSAGE:
        return <SystemMessage key={messageData.id} {...messageData} />;

      case EMessageType.ENCUBE_MESSAGE:
        return (
          <EncubeMessage
            key={messageData.id}
            {...messageData}
            messageType={EMessageType.ENCUBE_MESSAGE}
          />
        );

      case EMessageType.GOOGLE_MESSAGE:
        return (
          <GoogleMessage
            key={messageData.id}
            {...messageData}
            messageType={EMessageType.GOOGLE_MESSAGE}
          />
        );

      case EMessageType.PLYABLE_MESSAGE:
        return (
          <PlayableMessage
            key={messageData.id}
            {...messageData}
            messageType={EMessageType.PLYABLE_MESSAGE}
          />
        );

      default:
        return null;
    }
  };

  /**
   * If URL contains thread/{id}/messages/{msgId}/ scroll to the highlighted comment
   * whose msgId matches.
   */
  useEffect(() => {
    if (msgId) {
      const highlightedMessageIndex = findByMsgIndexById(messages, msgId);

      // `?referer=notificationsList`
      if (referer === 'notificationsList') {
        // Skip checks and scroll to index.
        virtuosoRef.current?.scrollToIndex(highlightedMessageIndex);
        // Pop off the `?referer=` query param straightaway.
        navigate(`${location.pathname}${location.hash}`);
      } else if (!hasInteractedWithPage) {
        /* If no referer, scroll only if the page hasn't been interacted (clicked). This is to prevent
        annoying jumps (to highlighted comment) when the user is performing regular tasks on this page. */
        virtuosoRef.current?.scrollToIndex(highlightedMessageIndex);
      }
    }
  }, [virtuosoRef.current]);

  const handleInitializeCopilotData = _debounce(() => {
    Promise.all([
      dispatch(loadCopilotSuggestions({ isCopilotFeatureEnabled, threadId: Number(id) ?? null })),
      dispatch(loadNotifications({ offset: 0, limit: DEFAULT_LIMIT })),
    ]);
  }, 1000);

  const onInitialize = async () => {
    // Load messages first, then load copilot data.
    await dispatch(loadMessages({ id: id as string, filter: chatFilter, msgId }));
    handleInitializeCopilotData();
  };

  useEffect(() => {
    onInitialize();
  }, [lastAppEvents, chatFilter, id]);

  const handleRenderItemContent = (_: unknown, data: unknown) => {
    const suggestionsPayload = data as TVirtuosoCopilotSuggestionPayload;
    const messagePayload = data as IMessageResponse;

    if (suggestionsPayload.type === 'COPILOT_SUGGESTIONS_PAYLOAD' && !isLoadingSuggestions) {
      if (!suggestionsPayload.list.length) {
        return null;
      }

      return renderCopilotSuggestions(suggestionsPayload.list as ICopilotSuggestion[]);
    }

    return renderMessageContent(messagePayload);
  };

  /**
   * Append payload to show suggestions when user clicks bulb.
   */
  const appendCopilotSuggestions = () => {
    if (showSuggestions && !isNotificationsLoading) {
      setCopilotSuggestionsPayload([
        {
          type: 'COPILOT_SUGGESTIONS_PAYLOAD',
          list: copilotSuggestions,
        },
      ]);
    } else {
      setCopilotSuggestionsPayload([]);
    }
  };

  useEffect(() => {
    appendCopilotSuggestions();

    if (showSuggestions) {
      setTimeout(() => {
        const bottomIndex = [...messages, ...copilotSuggestionsPayload].length;
        virtuosoRef.current?.scrollToIndex(bottomIndex);
      }, 100);
    }
  }, [showSuggestions]);

  return (
    <ChatLayout.Wrapper id="chat-wrapper" data-cy="chat-wrapper">
      <div style={{ flex: 1 }}>
        {messages.length > 0 && (
          <Virtuoso
            id="chat-virtuoso"
            style={{ height: '100%' }}
            data={[...messages, ...copilotSuggestionsPayload]}
            ref={virtuosoRef}
            firstItemIndex={count && messages ? count - messages.length : 0}
            followOutput={false}
            startReached={loadPrev}
            endReached={loadNext}
            initialTopMostItemIndex={
              msgId ? findByMsgIndexById(messages, msgId as string) : messages.length - 1
            }
            itemContent={handleRenderItemContent}
            components={{
              /* eslint-disable-next-line react/no-unstable-nested-components */
              Footer: () => (isLoading ? <ChatLoading /> : null),
              /* eslint-disable-next-line react/no-unstable-nested-components */
              Header: () => (isLoading ? <ChatLoading /> : null),
            }}
          />
        )}
      </div>

      {!isLoading && !isArchived && (
        <AccessControl permissions={[EPermissions.SEND_CHAT_MESSAGE]} threadId={id as string}>
          <Input ref={focusedInputRef as RefObject<TextEditor>} />
        </AccessControl>
      )}

      {!messages.length && (
        <ChatLayout.Placeholder data-cy="chat-wrapper-no-message">
          No message
        </ChatLayout.Placeholder>
      )}
    </ChatLayout.Wrapper>
  );
};

export default Chat;
