import styled from 'styled-components';
import Stack from 'ui/stack';
import Title from 'ui/title';

export const PopupContentHolder = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark5};
  padding: 18px;
  max-width: 432px;
  max-height: 300px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  margin-top: 27px;
`;

export const PopupContentArrow = styled.div`
  position: absolute;
  top: 7px;
  left: 34px;
  z-index: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 28.5px 38px 28.5px;
  border-color: transparent transparent ${({ theme }) => theme.colors.dark5} transparent;
`;

export const PopupContentTitle = styled(Title)`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray2};
`;
