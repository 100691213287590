import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import usePushNotifications from 'hooks/usePushNotifications';
import featureApi from 'modules/app/featuresApi';
import ThreadsAppErrorBoundary from 'pages/errorBoundary';
import SubscriptionsPlans from 'pages/subscriptionsPlans';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { selectUserInfo } from 'modules/auth/userSlice';
import AppRouter from './AppRouter';
import routes from './constants/routes';
import CheckEmail from './pages/checkEmail';
import { CreateWorkspace } from './pages/createWorkspace';
import ForgotPassword from './pages/forgotPassword';
import Login from './pages/login';
import ResetPassword from './pages/resetPassword';
import SignupPage from './pages/signup';

const App = () => {
  const dispatch = useAppDispatch();
  const { siteId } = useAppSelector(selectUserInfo);

  useEffect(() => {
    // Ensure there is a siteId before trying to make call to getFeatures.
    if (siteId) {
      dispatch(featureApi.endpoints.getFeatures.initiate(null));
    }
  }, [siteId]);

  usePushNotifications();

  return (
    <ThreadsAppErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<AppRouter />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.signup} element={<SignupPage />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
          <Route path={routes.checkEmail} element={<CheckEmail />} />
          <Route path={`${routes.resetPassword}/:userId/:token`} element={<ResetPassword />} />
          <Route
            path={`${routes.subscriptionsPlans}/auth`}
            element={<SubscriptionsPlans isAuthFlow />}
          />
          <Route path={`${routes.createWorkspace}`} element={<CreateWorkspace />} />
        </Routes>
      </BrowserRouter>
    </ThreadsAppErrorBoundary>
  );
};

export default App;
