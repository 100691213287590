import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetEncubeInfo, selectEncube, updateEncubeInfo } from '../store/encubeSlice';
import { IEncubePing, IEncubeState } from '../types';

interface IUseEncubeStatus
  extends Omit<IEncubeState, 'isIntegrationEnabled' | 'isEncubeAvailable'> {
  /**
   * Setter function for updating Encube info.
   * @param updateInfo - Encube info to update.
   */
  updateEncubeInfo: (updateInfo: IEncubePing) => void;
  /**
   * Resets Encube info to initial state.
   */
  resetEncubeInfo: () => void;
}

/**
 * Hook for getting Encube status and setter function for updating Encube info.
 * @returns Encube status and setter function for updating Encube info.
 */
export const useEncubeStatus = (): IUseEncubeStatus => {
  const { isIntegrationEnabled, isEncubeAvailable } = useAppSelector(selectEncube);
  const dispatch = useAppDispatch();

  const isEncubeFunctionalAvailable = isIntegrationEnabled && isEncubeAvailable;

  return {
    isEncubeFunctionalAvailable,
    updateEncubeInfo: (updateInfo: IEncubePing) => {
      dispatch(updateEncubeInfo(updateInfo));
    },
    resetEncubeInfo: () => {
      dispatch(resetEncubeInfo());
    },
  };
};
