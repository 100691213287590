import { api } from 'app/api';
import { ISSORegisterMutation } from 'modules/signup/types';
import {
  IChangePasswordMutation,
  ICheckAuthMethodMutation,
  ICheckAuthMethodResponse,
  ILoginMutation,
  ILoginResponse,
  IResetPasswordMutation,
} from './types';

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, ILoginMutation>({
      query: (payload) => ({
        url: `/auth/login/`,
        method: 'POST',
        body: payload,
      }),
    }),
    logout: build.mutation<ILoginResponse, null>({
      query: () => ({
        url: `/auth/logout/`,
        method: 'POST',
      }),
    }),
    checkAuthMethod: build.mutation<ICheckAuthMethodResponse, ICheckAuthMethodMutation>({
      query: (payload) => ({
        url: `/auth/is-sso/`,
        method: 'POST',
        body: payload,
      }),
    }),
    loginWithSSO: build.mutation<ILoginResponse, ISSORegisterMutation>({
      query: (payload) => ({
        url: `/auth/keycloak/`,
        method: 'POST',
        body: payload,
      }),
    }),
    resetConfirmEmail: build.mutation<undefined, { email: string }>({
      query: (payload) => ({
        url: '/auth/password/reset/',
        method: 'POST',
        body: payload,
      }),
    }),
    resetPassword: build.mutation<undefined, IResetPasswordMutation>({
      query: (payload) => ({
        url: '/auth/password/reset/confirm/',
        method: 'POST',
        body: payload,
      }),
    }),
    changePassword: build.mutation<void, IChangePasswordMutation>({
      query: (payload) => ({
        url: '/auth/password/change/',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useResetConfirmEmailMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useLoginWithSSOMutation,
  useCheckAuthMethodMutation,
} = authApi;
export default authApi;
