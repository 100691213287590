import React, { useCallback, useEffect } from 'react';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import { useAppSelector } from 'app/hooks';
import { selectUnreadCount } from 'modules/notification/slice';
import { showPushNotification } from 'modules/alert/utils';
import NotificationPopUp from 'modules/notification/NotificationPopUp';
import { useSendDeviceTokenMutation } from 'modules/notification/notificationApi';

const usePushNotifications = () => {
  const count = useAppSelector(selectUnreadCount);
  const [sendDeviceToken] = useSendDeviceTokenMutation();
  const requestNotificationPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      // send token to backend
      if (token) sendDeviceToken({ registrationId: token, type: 'web' });
      // This is where we show the in app notification
      onMessage(messaging, (payload) => {
        showPushNotification(<NotificationPopUp messagePayload={payload} />);
      });
    }
  }, []);

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    document.title = count > 0 ? `(${count}) 🔴 New Notification  | Threads` : 'Threads';
  }, [count]);
};

export default usePushNotifications;
