import React, { FC, useCallback } from 'react';
import Moment from 'react-moment';
import colors from 'theme/colors';
import { Group, Icon, Stack, Text, EncubeDropdown } from 'ui';
import type { IEncubeDropdownItem } from 'ui/EncubeDropdown';
import { useCreateReferenceFileJamMutation } from 'modules/threads/threadsApi';
import { showErrorMessage } from 'modules/alert/utils';
import { AnnotationFileLink, AnnotationRefreshButton } from './AnnotationLayout';
import { getFileNameFromUrl } from '../helpers';

enum EEncubeDropdownAction {
  OPEN = 'OPEN',
  OPEN_NOTIFY = 'OPEN_NOTIFY',
}

const items: IEncubeDropdownItem[] = [
  {
    id: EEncubeDropdownAction.OPEN,
    content: 'Open with Encube Only',
    icon: <Icon icon="ArrowCurveIcon" />,
  },
  {
    id: EEncubeDropdownAction.OPEN_NOTIFY,
    content: 'Open with Encube and Notify Collaborators',
    icon: <Icon icon="BellArrowIcon" />,
  },
];

interface IAnnotationContentSidebarProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Author of the annotation.
   */
  author: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  date: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * Callback that is called when the refresh button is clicked.
   * @param id - Unique identifier of the annotation.
   */
  onRefreshClick?: (id: string) => void;
}

export const AnnotationContentSidebar: FC<IAnnotationContentSidebarProps> = (props) => {
  const { id, author, date, fileId, fileName, fileUrl, fileVersion, onRefreshClick } = props;

  const [createJam] = useCreateReferenceFileJamMutation();

  const refreshAnnotationClickHandler = () => {
    onRefreshClick?.(id);
  };

  const navigateToEncubeWorkBench = useCallback(() => {
    window.open(fileUrl, '_blank');
  }, [fileUrl]);

  const dropdownItemClickHandler = (itemId: string | number) => {
    switch (itemId) {
      case EEncubeDropdownAction.OPEN:
        navigateToEncubeWorkBench();
        break;

      case EEncubeDropdownAction.OPEN_NOTIFY:
        createJam({ fileVersion: fileId })
          .then(navigateToEncubeWorkBench)
          .catch(() => showErrorMessage('Could not create jam. Please try again later.'));
        break;

      default:
        console.error(`Unknown dropdown item id: ${itemId}`); // eslint-disable-line no-console
    }
  };

  return (
    <Stack
      gap="16px"
      justify="space-between"
      align="flex-end"
      fluid
      data-cy="annotation-content-sidebar"
    >
      <Stack gap="24px" align="flex-end" justify="flex-start" fluid>
        <Stack
          gap="8px"
          align="flex-end"
          justify="flex-start"
          fluid
          data-cy="annotation-content-sidebar-stack"
        >
          <Text
            color={colors.white}
            size="sm"
            weight="700"
            align="right"
            cypressAttribute="annotation-content-sidebar-author-text"
          >
            {author}
          </Text>

          <Text
            color={colors.gray3}
            size="xs"
            weight="400"
            align="right"
            noWrap
            cypressAttribute="annotation-content-sidebar-date-text"
          >
            <Moment format="MM/DD/YYYY, hh:mm a z" date={date} />
          </Text>
        </Stack>
        <Group gap="10px" align="center">
          <Stack>
            <Text color={colors.white} size="sm" weight="700" align="right" noWrap>
              Reference
            </Text>
            <AnnotationFileLink
              href={fileUrl}
              target="_target"
              data-cy="annotation-content-sidebar-file-link"
            >
              {getFileNameFromUrl(fileName)} (Version {fileVersion})
            </AnnotationFileLink>
          </Stack>
          <EncubeDropdown items={items} onItemClick={dropdownItemClickHandler} />
        </Group>
      </Stack>
      <AnnotationRefreshButton
        type="button"
        rightIcon={<Icon icon="RefreshAnnotationIcon" size="small" />}
        onClick={refreshAnnotationClickHandler}
        data-cy="annotation-refresh-btn"
      >
        Refresh
      </AnnotationRefreshButton>
    </Stack>
  );
};
