import React, { ReactNode, SetStateAction, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Group, Stack, Text, Title } from 'ui';
import Logo from 'ui/logo';
import AnchorLink from 'ui/anchorLink';
import { PRODUCTION } from 'constants/env';
import * as ErrorBoundaryLayout from './components/Layout';

interface IErrorBoundaryProps {
  children: ReactNode | ReactNode[];
}

interface IErrorBoundaryFallbackProps {
  error: Error | null;
  showFullError: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const ErrorBoundaryFallback = ({
  error,
  showFullError: [showFullError, setShowFullError],
}: IErrorBoundaryFallbackProps) => {
  return (
    <ErrorBoundaryLayout.Wrapper id="error-boundary-fallback-container">
      <Stack align="start">
        <Group align="center">
          <Logo className="spacer-right" height={40} width={40} />
          <Title heading="h1">An Error Has Occurred</Title>
        </Group>
        <ErrorBoundaryLayout.Body id="error-boundary-content">
          <p>
            We&apos;re sorry, but an error occurred and we were unable to load this page. Please
            refresh to try again.
          </p>
          <Stack margin="20px 0" id="error-details-link">
            {process.env.NODE_ENV === PRODUCTION ? (
              <p>Our engineering team has been notified.</p>
            ) : (
              <AnchorLink onClick={() => setShowFullError(!showFullError)} bold underlined={false}>
                {showFullError ? 'Hide error' : 'View full error'}
              </AnchorLink>
            )}
          </Stack>
          {showFullError && (
            <Stack
              style={{ maxHeight: 250, overflow: 'auto' }}
              margin="20px 0"
              padding="5px"
              id="error-details-container"
            >
              <code>{JSON.stringify(error?.message)}</code>
              <code>{JSON.stringify(error?.stack)}</code>
            </Stack>
          )}
          <Stack margin="50px 0" align="end">
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </Stack>
        </ErrorBoundaryLayout.Body>
      </Stack>
    </ErrorBoundaryLayout.Wrapper>
  );
};

/**
 * Catch errors and fall back to this component instead of displaying
 * browser white wall.
 */
const ThreadsAppErrorBoundary = ({ children }: IErrorBoundaryProps) => {
  const [showFullError, setShowFullError] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<null | Error>(null);

  return (
    <ErrorBoundary
      onError={(error) => setErrorState(error)}
      fallback={
        <ErrorBoundaryFallback
          error={errorState}
          showFullError={[showFullError, setShowFullError]}
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default ThreadsAppErrorBoundary;
