import React from 'react';
import { useModal } from 'modules/modals/ModalProvider';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { Group, Stack, Button, Text } from 'ui';
import { useUpdateThreadMutation } from 'modules/threads/threadsApi';
import { IUpdateThreadMutation } from 'modules/threads/types';
import { handleApiCall } from 'utils/helpers';
import colors from 'theme/colors';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';

const ArchiveThread = (args: IUpdateThreadMutation) => {
  const { close } = useModal();
  const { handleSubscriptionPermission } = useSubscriptionPermissions();

  const [updateThread, { isLoading }] = useUpdateThreadMutation();

  const handleSubmit = async () => {
    const res = await updateThread({ ...args });
    handleApiCall(
      res,
      (error) => handleSubscriptionPermission(error, 'Could not archive thread. Please try again.'),
      () => {
        showSuccessMessage(NOTIFICATION.THREAD_ARCHIVED);
        close();
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to archive this thread? Other users won&apos;t be able to contribute
        to it and won&apos;t see it on threads listing
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-archive-thread-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.orange}
          onClick={handleSubmit}
          cypressAttribute="modal-archive-thread-archive-btn"
        >
          {isLoading ? 'Archiving...' : 'Archive Thread'}
        </Button>
      </Group>
    </Stack>
  );
};

export default ArchiveThread;
