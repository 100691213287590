import styled from 'styled-components';

export const DropdownContentHolder = styled.ul`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 2px;
  padding: 8px;
  margin: 0;
  list-style: none;
  border-radius: 4px;
  min-width: max-content;
  background-color: ${({ theme }) => theme.colors.dark5};
  will-change: transform;
`;
