import styled from 'styled-components';

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  overflow: hidden;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.addBtnBg};
  transition: all 0.4s;
  border-radius: 16px;
  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.colors.orange};
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider}:before {
    transform: translateX(21px);
  }
`;
