import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'modules/auth/userSlice';
import { usePingPlayableQuery } from '../api';
import { IPlayablePing } from '../types';

interface IUseEncubePing {
  /**
   * Encube service status data.
   */
  data: IPlayablePing | null;
  /**
   * Indicates if Encube service status data is loading.
   */
  isLoading: boolean;
  /**
   * Indicates if Encube service status data loading has failed.
   */
  isError: boolean;
  /**
   * Encube service error data.
   */
  error: FetchBaseQueryError | SerializedError | null;
}

// 10 minutes
const PLYABLE_PING_INTERVAL = 10 * 60 * 1000;

/**
 * Hook for getting Encube service status data.
 * @param interval - Interval for polling Encube service status data.
 */
export const usePlayablePing = (interval = PLYABLE_PING_INTERVAL): IUseEncubePing => {
  const { isAuthenticated } = useAppSelector(selectUser);

  const {
    data = null,
    isLoading,
    isError,
    error = null,
  } = usePingPlayableQuery(null, {
    pollingInterval: interval,
    skip: !isAuthenticated,
  });

  return { data, isLoading, isError, error };
};
