import { api } from 'app/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { IId, IPaginatedResponse } from 'types';
import { toFormData } from 'utils/toFormData';
import { deepUnderscoreKeys } from 'utils/mappers';
import {
  IGetMessageParams,
  IMessageCreate,
  IMessageEdit,
  IMessageLoad,
  IMessageResponse,
} from './types';
import { MESSAGE_PER_PAGE } from './constants';
import { addQuery } from './utils';

export const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendMessages: build.mutation<IMessageResponse, IMessageCreate>({
      query: (payload) => ({
        url: '/messages/',
        method: 'POST',
        body: payload.references.length ? toFormData(deepUnderscoreKeys(payload)) : payload,
      }),
      invalidatesTags: ['threadList'],
    }),
    editMessages: build.mutation<IMessageResponse, IMessageEdit>({
      query: ({ id, references, ...payload }) => ({
        url: `/messages/${id}/`,
        method: 'PATCH',
        body: references.length ? toFormData({ ...payload, references }) : payload,
      }),
    }),
    likeMessages: build.mutation<IMessageResponse, number>({
      query: (id) => ({
        url: `/messages/${id}/toggle-like/`,
        method: 'PATCH',
      }),
    }),
    deleteMessage: build.mutation<void, IId>({
      query: ({ id }) => ({
        url: `/messages/${id}/`,
        method: 'DELETE',
      }),
    }),
    getMessages: build.query<IPaginatedResponse<IMessageResponse>, Partial<IGetMessageParams>>({
      query: ({ id, link, ...query }) => ({
        url: link || addQuery(`/threads/${id}/messages/`, query),
      }),
      providesTags: ['chatMessages'],
    }),
    loadInitialMessage: build.query<IPaginatedResponse<IMessageResponse>, IMessageLoad>({
      async queryFn({ id, filter, msgId }, _queryApi, _extraOptions, fetchWithBQ) {
        const url = `/threads/${id}/messages/`;
        const randomResult = await fetchWithBQ(addQuery(url, { type: filter, message_id: msgId }));
        if (randomResult.error) return { error: randomResult.error as FetchBaseQueryError };
        // @ts-ignore
        const offset = randomResult.data.count as number;
        const lastIndex = offset > MESSAGE_PER_PAGE ? offset - MESSAGE_PER_PAGE : 0;
        const query = {
          limit: MESSAGE_PER_PAGE,
          offset: lastIndex,
          type: filter,
          message_id: msgId,
        };
        const result = await fetchWithBQ(addQuery(url, query));
        return result.data
          ? { data: result.data as IPaginatedResponse<IMessageResponse> }
          : { error: result.error as FetchBaseQueryError };
      },
      keepUnusedDataFor: 2,
    }),
  }),
});

export const {
  useSendMessagesMutation,
  useGetMessagesQuery,
  useEditMessagesMutation,
  useDeleteMessageMutation,
  useLikeMessagesMutation,
} = chatApi;
