import React, { FC, useCallback, useMemo } from 'react';
import colors from 'theme/colors';
import moment from 'moment';
import { Group, Icon, Stack, Text } from 'ui';
import routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { IEncubeJam } from '../types';
import * as Layout from './Layout';

interface IProps {
  jam: IEncubeJam;
}

const JamItemCard: FC<IProps> = ({ jam }) => {
  const { fileVersion, reference, thread } = jam;
  const navigate = useNavigate();

  const fileName = useMemo(() => {
    return reference.name;
  }, [reference.name]);

  const versionOfTheFile = useMemo(() => {
    return fileVersion.version;
  }, [fileVersion.version]);

  const threadName = useMemo(() => {
    return thread.name;
  }, [thread.name]);

  const encubeUrl = useMemo(() => {
    return fileVersion.encubeUrl || '';
  }, [fileVersion.encubeUrl]);

  const threadId = useMemo(() => {
    return thread.id;
  }, [thread.id]);

  const startedAt = useMemo(() => {
    return jam.createdAt;
  }, [jam.createdAt]);

  const navigateToThread = useCallback(() => {
    navigate(`${routes.thread}/${threadId}`);
  }, [threadId]);

  return (
    <Group style={{ padding: '0 5px', marginBottom: '20px' }} gap="10px" fluid align="center">
      <div>
        <Icon icon="EncubeLogoIcon" style={{ width: 35, height: 35 }} />
      </div>
      <Stack fluid>
        <Group justify="space-between" align="center" fluid>
          <Layout.JamNameTestContainer href={encubeUrl} target="_blank">
            <Text size="sm" weight="500" color={colors.brandSecondaryLight}>
              {`${fileName} Version(${versionOfTheFile})`}
            </Text>
            <Icon
              icon="ExternalLinkIcon"
              size="small"
              stroke={colors.brandSecondaryLight}
              style={{ width: 18, height: 18 }}
              path={colors.brandSecondaryLight}
            />
          </Layout.JamNameTestContainer>
          <Text size="xs" weight="400" color={colors.gray3}>
            Started {moment(startedAt).fromNow()}
          </Text>
        </Group>
        <Group
          gap="5px"
          align="center"
          style={{
            padding: '4px 8px',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={navigateToThread}
        >
          <Icon icon="DocumentIcon" size="small" />
          <Text size="xs" weight="500" color={colors.gray3}>
            {threadName}
          </Text>
        </Group>
      </Stack>
    </Group>
  );
};

export default JamItemCard;
