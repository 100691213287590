import React, { FC, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import colors from 'theme/colors';
import { useOnClickOutside } from 'hooks';
import { Group, Text, Icon, Stack } from 'ui';
import './styles.css';
import moment from 'moment';

const Control = styled(Group)<{ disabled?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 12px 16px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'arrow' : 'pointer')};
`;

const DropDown = styled.div`
  position: absolute;
  right: 0;
  bottom: 100%;
`;

interface IProps {
  label?: string;
  value?: Date;
  range?: Date[];
  selectRange?: boolean;
  onChange?: (date: Date) => void;
  onRangeChange?: (date: Date[]) => void;
  disabled?: boolean;
  cypressAttribute?: string;
}

const CalendarInput: FC<IProps> = ({
  value,
  range,
  onChange,
  onRangeChange,
  label,
  selectRange,
  disabled = false,
  cypressAttribute,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(value ? new Date(value) : new Date());
  const ref = useRef(null);
  const close = () => setIsOpen(false);

  useOnClickOutside(ref, close);

  const rangeValue: Date[] | undefined = useMemo(() => {
    return range?.length ? [new Date(range[0]), new Date(range[1])] : undefined;
  }, [range]);

  const handleChange = (data: Date | Date[]) => {
    if (Array.isArray(data)) {
      onRangeChange?.(data);
      setIsOpen(false);
      return;
    }
    setDate(data);
    onChange?.(data);
    setIsOpen(false);
  };

  return (
    <Stack gap="15px" data-cy={cypressAttribute && `${cypressAttribute}-field`}>
      {label && <Text>{label}</Text>}
      {disabled ? (
        <Control disabled>
          <Text>{moment(date).format('L')}</Text>
        </Control>
      ) : (
        <Control ref={ref} onClick={() => setIsOpen(true)} justify="space-between" align="center">
          <Text color={colors.dark4}>
            {selectRange && rangeValue ? (
              <>
                <Moment format="MM/DD/YYYY" date={rangeValue[0]} />
                <span> - </span>
                <Moment format="MM/DD/YYYY" date={rangeValue[1]} />
              </>
            ) : (
              <Moment format="MM/DD/YYYY" date={date} />
            )}
          </Text>

          {isOpen && (
            <DropDown data-cy={cypressAttribute}>
              <Calendar
                defaultValue={selectRange ? rangeValue : date}
                locale="en-US"
                calendarType="US"
                onChange={handleChange}
                selectRange={selectRange}
                returnValue={selectRange ? 'range' : 'start'}
              />
            </DropDown>
          )}
          <Icon icon="CalendarIcon" />
        </Control>
      )}
    </Stack>
  );
};

export default CalendarInput;
