import React, { useMemo, useState, useCallback } from 'react';
import colors from 'theme/colors';
import * as Layout from 'pages/thread/components/Layout';
import { Group, Stack, Button, Text, ReactSelect, Icon, Spinner } from 'ui';
import { IError, TErrorData, TReactSelectOption } from 'types';
import { useForm } from 'react-hook-form';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { EMAIL_REGEX, MESSAGE } from 'utils/validation';
import {
  ICheckInviteContributorMutation,
  IContributor,
  IContributorCreate,
  IExternalContributorsCreate,
} from 'modules/contributors/types';
import { useGetUsersQuery } from 'modules/user/userApi';
import { IUser } from 'types/user';
import { useModal } from 'modules/modals/ModalProvider';
import {
  useAddContributorsMutation,
  useAddExternalContributorsMutation,
  useCheckInviteContributorPermissionMutation,
} from 'modules/contributors/contributorsApi';
import { handleApiCall } from 'utils/helpers';
import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';

const GET_USERS_PARAMS = {
  limit: 500,
  offset: 0,
};

interface IProps {
  threadId: number;
}

const AddContributors = ({ threadId }: IProps) => {
  const [contributors, setContributors] = useState<TReactSelectOption[]>([]);

  const [addContributors, { isLoading }] = useAddContributorsMutation();
  const [checkInviteContributorsPermission, { isLoading: isChecking }] =
    useCheckInviteContributorPermissionMutation();
  const [addExternalContributors] = useAddExternalContributorsMutation();

  const { handleSubscriptionPermission } = useSubscriptionPermissions();
  const { close } = useModal();

  const [error, setError] = useState<boolean>(false);
  const { data, isLoading: isUsersLoading, isFetching } = useGetUsersQuery(GET_USERS_PARAMS);
  const users = data?.results || [];

  const { handleSubmit } = useForm<IContributorCreate[]>({
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const createUserOption = (label: string, userId: string) => ({
    label,
    value: label,
    user: userId,
  });

  const emailsOptions: TReactSelectOption[] = useMemo(
    () => users?.map((user: IUser) => createUserOption(user.email, user.id)) || [],
    [isFetching]
  );

  const externalUsers: IExternalContributorsCreate[] = useMemo(() => {
    return (
      contributors
        /* eslint-disable-next-line no-underscore-dangle */
        .filter((contributor) => contributor.__isNew__)
        .map((contributor) => ({ email: contributor.value, thread: [threadId] }))
    );
  }, [contributors]);

  const existingUsers: IContributorCreate[] = useMemo(() => {
    return contributors
      .filter((contributor) => contributor.user)
      .map((contributor) => ({ user: contributor.user, thread: threadId }));
  }, [contributors]);

  const handleChange = useCallback((options: TReactSelectOption[]) => {
    setError(false);
    const isValid = options.every((option) => option.value.trim().match(EMAIL_REGEX));
    if (!isValid) {
      setError(true);
    }
    setContributors(options);
  }, []);

  const handleContributorInvitePermissionCheck = async (contributorsData: IContributor[]) => {
    const invitedContributorsUserId: ICheckInviteContributorMutation = {
      userIds: contributorsData.map((contributor) => contributor.user.id),
      threadId,
    };

    const response = await checkInviteContributorsPermission(invitedContributorsUserId);
    handleApiCall(
      response,
      (responseError) =>
        handleSubscriptionPermission(
          responseError,
          'Can not check invite contributors permission. Please try again.'
        ),
      () => {
        showSuccessMessage(NOTIFICATION.CONTRIBUTOR_CREATED);
        close();
      }
    );
  };

  const onSubmit = async () => {
    if (existingUsers.length) {
      const existingUsersRes = await addContributors(existingUsers);
      handleApiCall(
        existingUsersRes,
        () => {
          showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
        },
        ({ data: responseData }) => handleContributorInvitePermissionCheck(responseData)
      );
    }
    if (externalUsers.length) {
      const externalUsersRes = await addExternalContributors(externalUsers);
      handleApiCall(
        externalUsersRes,
        ({ error: externalUsersResError }: IError) => {
          const errorData = (externalUsersResError.data as TErrorData).error;
          if ('message' in errorData) {
            showErrorMessage(errorData.message);
          } else {
            showErrorMessage(NOTIFICATION.SOMETHING_WRONG);
          }
        },
        () => {
          showSuccessMessage('External contributors added successfully');
          close();
        }
      );
    }
  };

  if (isUsersLoading) {
    return (
      <Stack align="center" justify="center">
        <Spinner size="medium" />
      </Stack>
    );
  }

  return (
    <Layout.ContributorForm onSubmit={handleSubmit(onSubmit)} data-cy="modal-contributor-form">
      <Stack>
        <Layout.InputWrap data-cy="modal-contributor-search-wrapper">
          <Layout.SearchWrap aria-hidden="true">
            <Icon fill={colors.gray1} icon="SearchIcon" size="normal" />
          </Layout.SearchWrap>
          <ReactSelect
            id="contributors-select"
            placeholder="Search | invite users by email"
            isSearchable
            autoFocus
            options={emailsOptions}
            onChange={handleChange}
          />
        </Layout.InputWrap>
        {error && <Text color={colors.red}>{MESSAGE.INVALID_EMAIL}</Text>}
      </Stack>
      <Group justify="end" gap="15px" style={{ marginTop: 'auto' }}>
        <Button color={colors.dark2} onClick={close} data-cy="modal-contributor-close-btn">
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={error || isLoading || !contributors.length || isChecking}
          cypressAttribute="modal-contributor-save-btn"
        >
          Save
        </Button>
      </Group>
    </Layout.ContributorForm>
  );
};

export default AddContributors;
