import React, { FC } from 'react';
import { Stack } from 'ui';
import Moment from 'react-moment';
import {
  AnnotationAvatar,
  AnnotationCommentContent,
  AnnotationCommentDate,
  AnnotationCommentHolder,
  AnnotationCommentLink,
} from 'modules/EncubeAnnotations/components/AnnotationLayout';
import colors from 'theme/colors';

interface IPlyableAnnotationCommentProps {
  /**
   * Content of the comment.
   */
  content: string;
  /**
   * Date of the comment.
   */
  date: string;
  /**
   * Comment author avatar source.
   */
  avatarSrc: string;
}

const PlyableAnnotationComment: FC<IPlyableAnnotationCommentProps> = (props) => {
  const { content, date, avatarSrc } = props;
  return (
    <AnnotationCommentLink target="_blank" rel="noreferrer" data-cy="annotation-comment-link">
      <AnnotationCommentHolder
        justify="space-between"
        align="center"
        gap="16px"
        fluid
        data-cy="annotation-comment-holder"
      >
        <AnnotationCommentContent size="sm" weight="400" data-cy="annotation-comment-content">
          {content}
        </AnnotationCommentContent>
        <Stack gap="3px" justify="flex-start" align="flex-end" data-cy="annotation-comment-stack">
          <AnnotationAvatar size="small" src={avatarSrc} data-cy="annotation-comment-avatar" />
          <AnnotationCommentDate
            color={colors.gray3}
            size="sm"
            weight="400"
            noWrap
            data-cy="annotation-comment-date"
          >
            <Moment fromNow date={date} />
          </AnnotationCommentDate>
        </Stack>
      </AnnotationCommentHolder>
    </AnnotationCommentLink>
  );
};

export default PlyableAnnotationComment;
