import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { AnnotationItemWrapper } from './AnnotationLayout';

const AnnotationSkeletonAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.dark2};
  animation: skeletonShine 1s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const AnnotationSkeleton = styled.div`
  position: relative;
  width: 100%;
  height: 343px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.dark2};
  animation: skeletonShine 0.9s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const AnnotationSkeletonInner = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.dark1};
`;

export const AnnotationsSkeleton: FC = () => {
  return (
    <AnnotationItemWrapper fluid justify="flex-start" align="flex-start" gap="12px" aria-hidden>
      <AnnotationSkeleton>
        <AnnotationSkeletonInner
          style={{ top: '16px', left: '16px', height: 'calc(100% - 32px)', width: '50%' }}
        >
          <AnnotationSkeletonInner
            style={{
              top: '8px',
              left: '8px',
              height: '64px',
              width: 'calc(100% - 16px)',
              background: colors.dark2,
            }}
          />
          <AnnotationSkeletonInner
            style={{
              top: '80px',
              left: '8px',
              height: '64px',
              width: 'calc(100% - 16px)',
              background: colors.dark2,
            }}
          />
          <AnnotationSkeletonInner
            style={{
              top: '152px',
              left: '8px',
              height: '64px',
              width: 'calc(100% - 16px)',
              background: colors.dark2,
            }}
          />
        </AnnotationSkeletonInner>
        <AnnotationSkeletonInner
          style={{ top: '16px', right: '16px', height: '34px', width: '20%' }}
        />
        <AnnotationSkeletonInner
          style={{ top: '68px', right: '16px', height: '34px', width: '25%' }}
        />
        <AnnotationSkeletonInner
          style={{ bottom: '16px', right: '16px', height: '24px', width: '10%' }}
        />
      </AnnotationSkeleton>
      <AnnotationSkeletonAvatar />
    </AnnotationItemWrapper>
  );
};
