import React, { FC, useState, useRef, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import _capitalize from 'lodash/capitalize';

import { useAppSelector } from 'app/hooks';

import { Group, Icon, Spinner, Stack, Text, Tooltip } from 'ui';

import { selectUser } from 'modules/auth/userSlice';
import { selectReferenceName, selectSelectedReference } from 'modules/threads/referencesSlice';

import {
  INTEGRATION_LOGOS,
  INTEGRATION_COLORS,
  REFERENCE_INTEGRATIONS,
  PLAYABLE_QUOTE_FOR,
  PLAYABLE_MOLD_TYPES_OPTIONS,
} from 'constants/integrations';
import { MODAL_STATUS_LABELS_TEXT } from 'constants/labels';

import { showErrorMessage } from 'modules/alert/utils';

import { saveAs } from 'file-saver';

import type { IReferences } from 'modules/threads/types';

import colors from 'theme/colors';

import PlayableIntegrationSelect from 'ui/PlayableSelect';
import EditPlayableSection from './EditPlayableSection';

import * as Layout from './Layout';

/**
 * 60px dropdown header height + 30px padding + 10px margin-bottom
 */
const baseContentHeight = 60 + 30 + 10;

const SlideDownContainer = styled(Stack)<{
  expanded: boolean;
  maxHeight: number;
  active: boolean;
  type: TSection;
}>`
  overflow: hidden;
  min-height: 60px;
  padding: 15px;
  border: ${({ active, type }) => `1px solid ${active ? INTEGRATION_COLORS[type] : '#737D9B'}`};
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight + baseContentHeight : 0)}px;
`;

const Content = styled.div<{ expanded: boolean }>`
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
`;

const ImageContainer = styled.div<{ expanded: boolean }>`
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  ${({ expanded }) => expanded && 'transform: rotate(0deg);'}
`;

type TSection = 'encube' | 'plyable';

interface IProps {
  type: TSection;
  active: boolean;
  isInfoSection?: boolean;
  isCreateSection?: boolean;
  isEdit?: boolean;
  isPlayableError: boolean;
  isSelectDisabled: boolean;
  dataLabels?: typeof MODAL_STATUS_LABELS_TEXT[keyof typeof MODAL_STATUS_LABELS_TEXT][];
  onChange: (option: { label: string; value: string }) => void;
  toggleIntegration?: React.Dispatch<React.SetStateAction<boolean>>;
}

const IntegrationSection: FC<IProps> = ({
  type,
  active,
  dataLabels,
  isPlayableError,
  isInfoSection,
  isCreateSection,
  isEdit = false,
  onChange,
  toggleIntegration,
  isSelectDisabled,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const { token } = useAppSelector(selectUser);

  const currentFile = useAppSelector(selectSelectedReference);
  const currentFileName = useAppSelector(selectReferenceName);

  const QUOTE_DOWNLOAD_URL = currentFile?.plyableDownloadUrl;
  const PLAYABLE_QUOTE_LINK = currentFile?.plyableUrl;

  const isEncubeIntegrations = useMemo(() => type === REFERENCE_INTEGRATIONS.encube, [type]);
  const isPlyableIntegrations = useMemo(() => type === REFERENCE_INTEGRATIONS.plyable, [type]);

  const handleDownloadPlayableQuote = async () => {
    if (!QUOTE_DOWNLOAD_URL) return;
    try {
      setIsQuoteLoading(true);
      const response = await fetch(QUOTE_DOWNLOAD_URL, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
        },
      });
      const blob = await response.blob();
      setIsQuoteLoading(false);
      saveAs(blob, `Quote-for-${currentFileName}(version ${currentFile.version}).pdf`);
    } catch (error) {
      setIsQuoteLoading(false);
      showErrorMessage('Could not download quote. Please try again later.');
    }
  };

  useLayoutEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, [active, isCreateSection, isInfoSection, isEdit, PLAYABLE_QUOTE_LINK]);

  const toggleExpand = () => {
    if (isInfoSection) return;
    toggleIntegration?.(!active);
  };

  return (
    <SlideDownContainer expanded={active} maxHeight={maxHeight} active={active} type={type}>
      <Group
        justify="space-between"
        align="center"
        style={{
          marginBottom: '10px',
          cursor: isInfoSection ? 'default' : 'pointer',
        }}
        onClick={toggleExpand}
      >
        <Group gap="10px" align="center">
          {INTEGRATION_LOGOS[type]}
          <Text size="lg">{_capitalize(type)}</Text>
          {isEdit && currentFile?.plyableQuoteFor && !isCreateSection && (
            <Layout.PlayableQuoteForContainer>
              <Text size="xs" weight="500" color={colors.dark4}>
                {currentFile ? PLAYABLE_QUOTE_FOR[currentFile.plyableQuoteFor] : 'N/A'}
              </Text>
            </Layout.PlayableQuoteForContainer>
          )}
        </Group>
        <Group gap="8px">
          {isEdit &&
            currentFile?.plyableDownloadUrl &&
            !isCreateSection &&
            (isQuoteLoading ? (
              <Spinner size="small" color="white" />
            ) : (
              <Tooltip data="Download Quote" position="left">
                <Icon
                  icon="DownloadPlayableIcon"
                  size="medium"
                  style={{ cursor: 'pointer' }}
                  onClick={handleDownloadPlayableQuote}
                />
              </Tooltip>
            ))}
          <ImageContainer expanded={active}>
            <Icon icon="ArrowUpIcon" />
          </ImageContainer>
        </Group>
      </Group>
      <Content ref={contentRef} expanded={active}>
        {isEncubeIntegrations && <Text>Encube</Text>}
        {isPlyableIntegrations && (isCreateSection || !isEdit) && (
          <Stack gap="10px">
            <PlayableIntegrationSelect
              options={PLAYABLE_MOLD_TYPES_OPTIONS}
              onChange={onChange}
              isDisabled={isSelectDisabled}
            />
            {isPlayableError && (
              <Group gap="5px" align="center">
                <Icon icon="InfoIcon" path={colors.red} size="normal" />
                <Text weight="500" color={colors.red}>
                  Please select manufacture type
                </Text>
              </Group>
            )}
          </Stack>
        )}
        {isPlyableIntegrations && isInfoSection && !isCreateSection && (
          <EditPlayableSection dataLabels={dataLabels} />
        )}
      </Content>
    </SlideDownContainer>
  );
};

export default IntegrationSection;
