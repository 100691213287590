import React, { FC } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectGanttView } from 'modules/threads/GanttSlice';
import { GanttView as Gantt } from 'modules/threads/components';
import { TITLES } from 'pages/dashboard/constants';
import Table from 'modules/threads';
import path from 'constants/path';

const AllThreads: FC = () => {
  const isGantt = useAppSelector(selectGanttView);
  return (
    <div>
      {isGantt ? (
        <Gantt url={path.allThreadsGanttList} title={TITLES.ALL} />
      ) : (
        <Table
          url={path.allThreadsList}
          title={TITLES.ALL}
          cypressAttribute="all-threads-table"
          isAllThreads
        />
      )}
    </div>
  );
};

export default AllThreads;
