import React, { FC } from 'react';
import { Button, Group, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { handleApiCall } from 'utils/helpers';
import { useAppDispatch } from 'app/hooks';
import { pushAppEvent } from 'modules/app/appSlice';
import { EAppEventTypes } from 'modules/app/constants';
import { showErrorMessage, showSuccessMessage } from '../../alert/utils';
import { NOTIFICATION } from '../../alert/constants';
import { useModal } from '../../modals/ModalProvider';
import { useDeleteEventMutation } from '../eventsApi';

interface IDeleteEvent {
  id: number;
}

const DeleteEvent: FC<IDeleteEvent> = ({ id }) => {
  const [deleteEvent, { isLoading }] = useDeleteEventMutation();
  const { close } = useModal();
  const dispatch = useAppDispatch();
  const handleDeleteMessage = async () => {
    const res = await deleteEvent(id);
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        close();
        showSuccessMessage(NOTIFICATION.EVENT_DELETED);

        dispatch(
          pushAppEvent({
            type: EAppEventTypes.EVENT_DELETED,
            appEventPayload: res,
          })
        );
      }
    );
  };

  return (
    <Stack gap="20px">
      <Text size="lg" color={colors.gray2}>
        Are you sure you want to delete this event?
      </Text>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={() => close()}
          cypressAttribute="modal-delete-event-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          color={colors.red2}
          onClick={handleDeleteMessage}
          cypressAttribute="modal-delete-event-delete-btn"
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </Group>
    </Stack>
  );
};

export default DeleteEvent;
