import React, { useEffect, useState } from 'react';
import { Divider, Group, ReactSelect, Stack, Text, Title } from 'ui';
import { EThreadStatuses } from 'types';
import pallet from 'theme/colors';
import { THREAD_STATUS_COLORS, THREAD_STATUS_NAMES } from 'constants/index';
import moment from 'moment';
import Chart from '../chart';
import { useGetStatisticQuery } from '../../threadsApi';
import { getOptions } from './utils';
import { EChartType, IChartProps } from '../../types';
import Label from './Label';
import * as Layout from './Layout';

interface IProps {
  title: string;
  dateFrom: Date;
  cypressAttribute?: string;
}

const options = [
  {
    label: 'My Threads',
    value: EChartType.MY_THREADS,
  },
  {
    label: 'My Contributions',
    value: EChartType.CONTRIBUTION,
  },
  {
    label: 'All Threads',
    value: EChartType.ALL_THREADS,
  },
];

const initChatData: Omit<IChartProps, 'title'> = {
  labels: [],
  data: [],
  colors: [],
};

const StatisticCard = ({ title, dateFrom, cypressAttribute }: IProps) => {
  const [threadType, setThreadType] = useState(options[0].value);
  const { data } = useGetStatisticQuery(
    {
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      type: threadType,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [chartData, setChatData] = useState(initChatData);

  useEffect(() => {
    if (data) {
      const { all, ...rest } = data;
      const threadTypes = Object.keys(rest);
      const labels = getOptions(threadTypes as EThreadStatuses[], THREAD_STATUS_NAMES);
      const colors = getOptions(threadTypes as EThreadStatuses[], THREAD_STATUS_COLORS);
      const values = Object.values(rest);
      setChatData({ labels, data: values, colors });
    }
  }, [data]);

  if (!data) return null;

  return (
    <Layout.Container gap="32px" data-cy={cypressAttribute}>
      {data.all === 0 ? (
        <Stack fullHeight justify="center" align="center">
          <Text color={pallet.gray1}>No data</Text>
        </Stack>
      ) : (
        <Layout.Content gap="30px" data-cy={cypressAttribute && `${cypressAttribute}-data`}>
          <Layout.ChartWrapper>
            <Chart title="threads" {...chartData} />
          </Layout.ChartWrapper>
          <div style={{ flex: 3 }}>
            <Stack gap="16px">
              <Title
                cypressAttribute={cypressAttribute && `${cypressAttribute}-data-threads`}
                heading="h1"
              >
                {data?.all}
              </Title>
              <Divider />
              <Layout.Labels data-cy={cypressAttribute && `${cypressAttribute}-data-labels`}>
                {[
                  EThreadStatuses.ON_TARGET,
                  EThreadStatuses.COMPLETE,
                  EThreadStatuses.NEEDS_ATTENTION,
                ].map((status) => (
                  <Label
                    key={status}
                    type={status}
                    count={data[status]}
                    dateFrom={dateFrom}
                    cypressAttribute={cypressAttribute && `${cypressAttribute}-data-label-item`}
                  />
                ))}
              </Layout.Labels>
              <Layout.Labels data-cy={cypressAttribute && `${cypressAttribute}-data-labels`}>
                {[
                  EThreadStatuses.AHEAD_OF_SCHEDULE,
                  EThreadStatuses.AT_RISK,
                  EThreadStatuses.ARCHIVE,
                ].map((status) => (
                  <Label
                    key={status}
                    type={status}
                    count={data[status]}
                    dateFrom={dateFrom}
                    cypressAttribute={cypressAttribute && `${cypressAttribute}-data-label-item`}
                  />
                ))}
              </Layout.Labels>
            </Stack>
          </div>
        </Layout.Content>
      )}
    </Layout.Container>
  );
};

export default StatisticCard;
