import React from 'react';
import { toast } from 'react-toastify';
import '../styles.css';
import CheckCircleIcon from 'assets/icons/check-circle.svg';
import XCircleIcon from 'assets/icons/x-circle.svg';

export const showSuccessMessage = (msg: string) =>
  toast.success(msg, {
    className: 'success-toaster',
    icon: <img src={CheckCircleIcon} alt="success" />,
  });

export const showWarningMessage = (msg: string) =>
  toast.warn(msg, {
    className: 'warn-toaster',
    icon: <img src={XCircleIcon} alt="warn" />,
    autoClose: 4000,
  });

export const showErrorMessage = (msg: string) =>
  toast.error(msg, {
    className: 'error-toaster',
    icon: <img src={XCircleIcon} alt="error" />,
  });

export const showPushNotification = (body: JSX.Element) =>
  toast.info(body, {
    theme: 'dark',
    autoClose: 4000,
    pauseOnHover: true,
  });
