import styled from 'styled-components';

export const JamListHolder = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  min-width: 396px;
  max-height: 130px;
  overflow-y: auto;
`;
