import useSubscriptionPermissions from 'hooks/useSubscriptionPermissions';
import { showSuccessMessage } from 'modules/alert/utils';
import { useRequestToJoinThreadMutation } from 'modules/contributors/contributorsApi';
import { useModal } from 'modules/modals/ModalProvider';
import React, { FC } from 'react';
import colors from 'theme/colors';
import { Group, Stack, Text, Button } from 'ui';
import { handleApiCall } from 'utils/helpers';

interface IProps {
  threadName: string;
  threadId: string;
}

const JoinThreadModal: FC<IProps> = ({ threadId, threadName }) => {
  const { close } = useModal();
  const [requestToJoinThreadAsContributor, { isLoading }] = useRequestToJoinThreadMutation();
  const { handleSubscriptionPermission } = useSubscriptionPermissions();

  const handleJoinThread = async () => {
    const response = await requestToJoinThreadAsContributor({ thread: threadId });
    handleApiCall(
      response,
      (responseError) =>
        handleSubscriptionPermission(
          responseError,
          'An error occurred while requesting to join thread as contributor.'
        ),
      () => {
        showSuccessMessage('Request to join thread as contributor sent successfully.');
        close();
      }
    );
  };
  return (
    <Stack gap="30px" justify="center">
      <Stack gap="20px" justify="center">
        <Text size="lg" style={{ lineHeight: 'normal' }} align="center">
          Select Continue to request to join {`"${threadName}"` || 'this'} thread.
        </Text>
        <Text size="lg" align="center">
          You will be notified once you&apos;ve been added as a contributor.
        </Text>
      </Stack>
      <Group gap="15px" align="center" justify="center" fluid>
        <Button color={colors.dark2} onClick={close} fluid>
          Cancel
        </Button>
        <Button onClick={handleJoinThread} fluid disabled={isLoading}>
          {isLoading ? <Stack align="center">Loading...</Stack> : 'Continue'}
        </Button>
      </Group>
    </Stack>
  );
};

export default JoinThreadModal;
