import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Modal } from 'ui';
import { modals } from './modals';
import { IModalOptions } from './types';

const defaultOptions: IModalOptions = {
  variant: 'fullRight',
  contentLabel: '',
  name: '',
  context: {},
};

interface IModalContext {
  open: (options: IModalOptions) => void;
  close: () => void;
  isOpen: boolean;
}

const ModalContext = createContext<IModalContext>({
  open: (option) => null,
  close: () => null,
  isOpen: false,
});

const NulComponent: FC = () => null;

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [options, setOptions] = useState<IModalOptions>(defaultOptions);
  const close = () => setOptions(defaultOptions);
  const open = (option: IModalOptions) => {
    setOptions(option);
  };
  const { variant, name, context, contentLabel, modal } = options;
  const ModalComponent = modal ? modals[modal] : NulComponent;
  const isOpen = !!modal;
  const store = useMemo(
    () => ({
      close,
      open,
      isOpen,
    }),
    [modal]
  );

  return (
    <ModalContext.Provider value={store}>
      {children}
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        variant={variant}
        contentLabel={contentLabel}
        name={name}
        id={options?.id}
      >
        {isOpen && <ModalComponent {...(context as any)} />}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
