import React from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'app/hooks';
import { deleteContributor } from 'modules/contributors/contributorsSlice';
import { Avatar, Stack, Group, Button, Text } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import { useRemoveContributorMutation } from 'modules/contributors/contributorsApi';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { handleApiCall } from 'utils/helpers';
import colors from 'theme/colors';

interface IRemoveParams {
  id: string | number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

const Form = styled.form`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const UserInfo = styled(Group)`
  position: relative;
  padding: 16px 45px 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

const RemoveContributor = ({ id, email, firstName, lastName, avatar }: IRemoveParams) => {
  const [removeContributor, { isLoading }] = useRemoveContributorMutation();
  const dispatch = useAppDispatch();
  const { close } = useModal();
  const userName = !!firstName || !!lastName;

  const handleRemove = async () => {
    const res = await removeContributor(id as string);
    handleApiCall(
      res,
      () => showErrorMessage(NOTIFICATION.SOMETHING_WRONG),
      () => {
        dispatch(deleteContributor({ id }));
        showSuccessMessage(NOTIFICATION.CONTRIBUTOR_DELETED);
        close();
      }
    );
  };

  return (
    <Form data-cy="modal-remove-contributor-form">
      <UserInfo gap="12px">
        <Avatar src={avatar} />
        <Stack gap="10px" justify="center" data-cy="modal-remove-contributor-user-info">
          {userName ? (
            <>
              <Text weight="500">
                {firstName} {lastName}
              </Text>
              <Text color={colors.gray3}>{email}</Text>
            </>
          ) : (
            <Text>{email}</Text>
          )}
        </Stack>
      </UserInfo>
      <Group justify="end" gap="15px">
        <Button
          color={colors.dark2}
          onClick={close}
          cypressAttribute="modal-remove-contributor-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color={colors.red2}
          disabled={isLoading}
          onClick={handleRemove}
          cypressAttribute="modal-remove-contributor-remove-btn"
        >
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Group>
    </Form>
  );
};

export default RemoveContributor;
