import { api } from 'app/api';

export const copilotApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCopilotSuggestions: build.mutation<unknown, unknown>({
      query: ({ threadId, async, signal }) => ({
        url: `/copilot/${threadId}/${async ? '?async=1' : ''}/`,
        signal,
      }),
    }),
    copilotAction: build.mutation<unknown, unknown>({
      query: ({ threadId, copilotAction }) => ({
        url: `/copilot/suggestion/${threadId}/action/`,
        method: 'POST',
        body: {
          user_action: copilotAction,
        },
      }),
    }),
    sendCopilotChatMessage: build.mutation<unknown, unknown>({
      query: ({ message, pre_chat_history }) => ({
        url: 'copilot/chat/',
        method: 'POST',
        body: {
          q: message,
          pre_chat_history,
        },
      }),
    }),
  }),
});

export const { useGetCopilotSuggestionsMutation, useSendCopilotChatMessageMutation } = copilotApi;
