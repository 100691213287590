import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routes from 'constants/routes';
import styled from 'styled-components';
import { mediaSize } from 'theme/devices';
import { Group, Spinner, Stack } from 'ui';

import { api, resetAll } from 'app/api';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'modules/auth/userSlice';
import { useWindowSize } from 'hooks/useWindowSize';
import { useLoginWithSSOMutation } from 'modules/auth/authApi';
import Signup from 'modules/signup';
import useSSO from 'hooks/useSSO';
import { handleApiCall } from 'utils/helpers';
import { showErrorMessage } from 'modules/alert/utils';
import RingsImg from './assets/Rings.png';
import AppImg from './assets/App.png';

const RightSide = styled.div`
  background: no-repeat right / cover url('${RingsImg}') ${({ theme }) => theme.colors.dark2};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  flex: 1;
  max-width: 650px;
  padding: 20px 32px;
  display: flex;
  align-items: center;
`;

const SignupPage = () => {
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const size = useWindowSize();
  const { authInviteToken } = useSSO();
  const [loginWithSSO] = useLoginWithSSOMutation();

  // SSO auth code
  const ssoAuthCode = query.get('code') || '';
  const isSSOAuth = ssoAuthCode !== null && ssoAuthCode.length > 0;

  const [isSSOAuthLoading, setIsSSOAuthLoading] = React.useState(isSSOAuth);

  const ssoAuth = async () => {
    const payload = authInviteToken
      ? { code: ssoAuthCode, inviteToken: authInviteToken as string }
      : { code: ssoAuthCode };
    const response = await loginWithSSO(payload);
    handleApiCall(
      response,
      () => {
        showErrorMessage('Wrong invite code');
        navigate(routes.login);
      },
      () => {
        localStorage.removeItem('auth_invite_token');
        navigate(routes.dashboard);
      }
    );
  };

  useEffect(() => {
    dispatch(logout());
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    if (isSSOAuth) {
      ssoAuth();
    }
  }, []);

  if (isSSOAuthLoading) {
    return (
      <Stack justify="center" align="center" fluid style={{ minHeight: '100vh' }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Group style={{ minHeight: '100vh' }} fluid>
      <Group justify="center" style={{ flex: 1 }}>
        <FormWrapper>
          <Signup />
        </FormWrapper>
      </Group>
      {size.width > mediaSize.laptop && (
        <RightSide>
          <img style={{ width: '90%' }} src={AppImg} alt="app" />
        </RightSide>
      )}
    </Group>
  );
};

export default SignupPage;
