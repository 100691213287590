import React from 'react';
import colors from 'theme/colors';
import { Icon, Text } from 'ui';
import { ICheckBoxProps } from './types';
import * as Layout from './Layout';

const CheckBox: React.FC<ICheckBoxProps> = ({
  name,
  checked,
  value,
  size,
  onChange,
  label,
  cypressAttribute,
}) => {
  return (
    <Layout.Label data-cy={cypressAttribute && `${cypressAttribute}-checkbox-label`}>
      <Layout.Input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        data-cy={cypressAttribute && `${cypressAttribute}-checkbox-input`}
      />
      <Layout.Check size={size} data-cy={cypressAttribute && `${cypressAttribute}-checkbox-check`}>
        <Icon fill={colors.blue} icon="CheckIcon" size="normal" />
      </Layout.Check>
      {label && (
        <Text
          color={colors.gray}
          size="sm"
          cypressAttribute={cypressAttribute && `${cypressAttribute}-checkbox-text`}
        >
          {label}
        </Text>
      )}
    </Layout.Label>
  );
};

export default CheckBox;
