import React, { FC } from 'react';

import { TagBadge, Text, Group, Icon, Button } from 'ui';
import { IFileVersions, REFERENCE_TYPE } from 'modules/threads/types';
import colors from 'theme/colors';
import { handleDownloadFile } from 'utils/helpers';
import { useAppDispatch } from 'app/hooks';
import { setSelectedReference } from 'modules/threads/referencesSlice';
import { useEncubeStatus } from 'modules/encube';
import { usePlayableStatus } from 'modules/playable';
import * as Layout from './Layout';

interface IProps {
  file: IFileVersions;
  fileName: string;
  isCurrentVersion: boolean;
  fileType: typeof REFERENCE_TYPE[keyof typeof REFERENCE_TYPE];
  cypressAttribute?: string;
}

const FileVersionItem: FC<IProps> = ({
  file,
  fileName,
  isCurrentVersion,
  fileType,
  cypressAttribute,
}) => {
  const { isEncubeFunctionalAvailable } = useEncubeStatus();
  const { isPlayableFunctionalAvailable } = usePlayableStatus();
  const dispatch = useAppDispatch();
  const isCurrent = isCurrentVersion ? '(current)' : '';

  const isFileWithIntegration =
    fileType === REFERENCE_TYPE.ENCUBE || fileType === REFERENCE_TYPE.PLAYABLE;

  const isEncubeIntegration = fileType === REFERENCE_TYPE.ENCUBE && isEncubeFunctionalAvailable;
  const isPlayableIntegration =
    fileType === REFERENCE_TYPE.PLAYABLE && isPlayableFunctionalAvailable;

  const handelEncubeFileSync = () => {
    if (isEncubeIntegration || isPlayableIntegration) {
      dispatch(setSelectedReference(file));
    }
  };

  const viewSyncDataText =
    isEncubeIntegration || isPlayableIntegration ? 'View Sync Data' : 'Integration disabled';

  return (
    <Layout.FilePreviewItem data-cy={cypressAttribute}>
      <Text weight="500" color={colors.gray2}>
        {fileName}
      </Text>
      <Layout.BadgeContainer>
        <TagBadge color={colors.blue9} data-cy={cypressAttribute && `${cypressAttribute}-badge`}>
          <Text weight="400" size="xs" noWrap>
            {`Version ${file.version} ${isCurrent}`}
          </Text>
        </TagBadge>
      </Layout.BadgeContainer>
      {isFileWithIntegration ? (
        <Layout.SyncFileButton
          type="button"
          onClick={handelEncubeFileSync}
          data-cy={cypressAttribute && `${cypressAttribute}-encube-sync`}
        >
          <Text weight="400" size="xs" noWrap>
            {viewSyncDataText}
          </Text>
          {(isEncubeIntegration || isPlayableIntegration) && (
            <Icon icon="ViewSyncData" size="small" />
          )}
        </Layout.SyncFileButton>
      ) : (
        <Group align="center" data-cy={cypressAttribute && `${cypressAttribute}-file`}>
          <Icon icon="ReferenceDocumentIcon" size="medium" stroke="none" />
          <Text weight="400" size="sm">
            File
          </Text>
        </Group>
      )}
      <Button
        variant="plain"
        onClick={handleDownloadFile(fileName, file.file)}
        cypressAttribute={cypressAttribute && `${cypressAttribute}-download-btn`}
      >
        <Text weight="400" color={colors.brandSecondaryLight}>
          Download
        </Text>
        <Icon icon="ShareIcon" size="normal" />
      </Button>
    </Layout.FilePreviewItem>
  );
};

export default FileVersionItem;
