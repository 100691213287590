import React, { FC } from 'react';
import styled from 'styled-components';
import Group from 'ui/group';

const JamSkeletonAvatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.05);
  animation: skeletonShine 1s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const JamSkeleton = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  animation: skeletonShine 0.9s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const SKELETON_COUNT = 2;

export const JamItemSkeleton: FC = () => {
  const skeletonArray = Array.from({ length: SKELETON_COUNT });
  return (
    <>
      {skeletonArray.map(() => (
        <Group
          key={new Date().getTime()}
          fluid
          justify="flex-start"
          align="flex-start"
          gap="10px"
          aria-hidden
          data-cy="jam-list-skeleton-item"
        >
          <JamSkeletonAvatar data-cy="jam-list-skeleton-avatar" />
          <JamSkeleton data-cy="jam-list-skeleton" />
        </Group>
      ))}
    </>
  );
};
