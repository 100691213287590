import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAppState, setShowSupportModal } from 'modules/app/appSlice';
import SupportModalContent from 'modules/app/components/SupportModalContent';
import React, { ReactNode } from 'react';
import { Modal } from 'ui';

interface IProps {
  children: ReactNode | ReactNode[];
}

/** @description Component to display modals accessible across the application. */
const GlobalModalContainer = ({ children }: IProps) => {
  const dispatch = useAppDispatch();

  const appState = useAppSelector(selectAppState);
  const { showSupportModal } = appState;

  return (
    <Modal
      isOpen={showSupportModal}
      onRequestClose={() => dispatch(setShowSupportModal(false))}
      variant="center"
      contentLabel="threadsSupport"
      name="Threads Support"
    >
      {showSupportModal && children}
    </Modal>
  );
};

export default GlobalModalContainer;
