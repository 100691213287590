import styled from 'styled-components';
import colors from 'theme/colors';

export const FilePreviewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${colors.dark2};
}`;

export const BadgeContainer = styled.div`
width: 100%;
max-width: 140px;
}`;

export const AdditionalSectionContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 8px;
  border: 2px solid ${({ color }) => color};
`;

export const SyncStatusContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 13px;
  border-radius: 40px;
  background-color: ${({ color }) => color};
  color: ${colors.white};
`;

export const SyncFileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.blue9};
  border-radius: 40px;
  gap: 4px;
  outline: none;
  border: none;
  padding: 4px 10px;
`;

export const EncubeLink = styled.a`
  background-color: ${colors.green1};
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const PlayableQuoteForContainer = styled.div`
  border: 1px solid var(--737-d-9-b, #737d9b);
  padding: 4px 12px;
  border-radius: 4px;
`;

export const IntegrationImageWrapper = styled.div`
  background-color: ${colors.dark};
  width: 35px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
