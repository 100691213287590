import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import _isEmpty from 'lodash/isEmpty';
import { copilotApi } from './copilotApi';
import { ICopilotSuggestion } from './types';

export const initialState = {
  suggestions: [] as ICopilotSuggestion[],
  isLoading: false,
  showSuggestions: true,
};

interface ILoadCopilotSuggestions {
  isCopilotFeatureEnabled?: boolean;
  threadId: number | null;
}

export const loadCopilotSuggestions = createAsyncThunk(
  'copilot/suggestions',
  async ({ isCopilotFeatureEnabled, threadId }: ILoadCopilotSuggestions, { dispatch }) => {
    if (!isCopilotFeatureEnabled) return null;

    const result = dispatch(
      copilotApi.endpoints.getCopilotSuggestions.initiate({
        threadId,
        async: true,
      })
    );
    result.reset();
    const response = await result;

    if ('data' in response) {
      return response.data as ICopilotSuggestion[];
    }

    return null;
  }
);

/**
 * @description sends request on user Accept/Edit/Dismiss
 */
export const sendCopilotAction = createAsyncThunk(
  'copilot/suggestions/action',
  async (
    { threadId, copilotAction }: { threadId: number | null; copilotAction: string },
    { dispatch }
  ) => {
    const result = dispatch(
      copilotApi.endpoints.copilotAction.initiate({ threadId, copilotAction })
    );
    result.reset();
    const response = await result;

    if ('data' in response) {
      return response.data;
    }

    return null;
  }
);

const copilotSlice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    setShowSuggestions: (state, { payload }) => ({
      ...state,
      showSuggestions: payload,
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadCopilotSuggestions.fulfilled, (state, { payload }) => {
        if (payload) {
          state.suggestions = payload;
        }

        state.showSuggestions = false;
        state.isLoading = false;
        return state;
      })
      .addCase(loadCopilotSuggestions.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(loadCopilotSuggestions.pending, (state) => {
        state.isLoading = true;
        return state;
      }),
});

export const selectSuggestions = (state: RootState) => state.suggestions;
export const selectShowSuggestions = (state: RootState) => state.suggestions.showSuggestions;
export const selectIsLoadingCopilotsuggestions = (state: RootState) => state.suggestions.isLoading;

export const { setShowSuggestions } = copilotSlice.actions;

export default copilotSlice.reducer;
