import React, { useMemo } from 'react';
import Text from 'ui/text';
import * as Layout from './Layout';
import { ITooltip } from './types';

const Tooltip: React.FC<ITooltip> = ({
  data,
  children,
  position,
  mode,
  width,
  isVisible = true,
  cypressAttribute,
}) => {
  const cleanedData = useMemo(() => (Array.isArray(data) ? data.join(', ') : data), [data]);

  return (
    <Layout.Tooltip isVisible={isVisible} data-cy={cypressAttribute}>
      {children}
      <Layout.Text mode={mode} position={position} width={width}>
        {cleanedData}
      </Layout.Text>
    </Layout.Tooltip>
  );
};

export default Tooltip;
