import { isNaN } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TReactSelectOption } from 'types';
import { ReactSelect } from 'ui';
import Group from 'ui/group';
import { ITab, ITabProps } from 'ui/tabs/types';
import * as Layout from './Layout';
import TabsNav from './TabsNav';

const Tabs: FC<ITabProps> = ({ tabs, onChange, height, cypressAttribute }) => {
  const [activeTabId, setActiveTab] = useState<number | string>(tabs[0].id);

  const activeTab = useMemo(
    () => tabs.find((tab: ITab) => tab.id === activeTabId),
    [activeTabId, tabs]
  );

  // Tab options for dropdown select, tabId must be a string
  const selectOptions: TReactSelectOption[] = useMemo(
    () => tabs.map((tab) => ({ label: tab.name, value: String(tab.id) })),
    [tabs]
  );

  useEffect(() => {
    if (onChange) {
      onChange(activeTabId, activeTab);
    }
  }, [activeTabId]);

  const handleChangeTab = (tab: TReactSelectOption | null) => {
    if (tab?.value === undefined) return;
    // Need to parse to int otherwise isn't recognised
    const tabId = parseInt(tab.value, 10);
    if (isNaN(tabId)) return;
    setActiveTab(tabId);
  };

  return (
    <Layout.Tabs data-cy={cypressAttribute && `${cypressAttribute}-data`}>
      {tabs.length > 3 ? (
        // If there's more than 3 tabs, use a select to prevent things getting squished
        <Group>
          <ReactSelect
            id={cypressAttribute && `${cypressAttribute}-dropdown`}
            isMulti={false}
            defaultValue={selectOptions[0]}
            isSearchable={false}
            options={selectOptions}
            onChange={(tab) => handleChangeTab(tab)}
          />
        </Group>
      ) : (
        <TabsNav
          tabs={tabs}
          onClick={setActiveTab}
          activeTabId={activeTabId}
          cypressAttribute={cypressAttribute}
        />
      )}
      <Layout.TabContent
        height={height}
        data-cy={cypressAttribute && `${cypressAttribute}-data-id-${activeTabId}`}
      >
        {activeTab?.content}
      </Layout.TabContent>
    </Layout.Tabs>
  );
};

export default Tabs;
