import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAppSelector } from 'app/hooks';
import { selectFeatures } from 'modules/app/appSlice';
import routes from 'constants/routes';
import Dashboard from 'pages/dashboard';
import Account from 'pages/account';
import Members from 'pages/members';
import Auth from 'modules/auth/Auth';
import WithHeader from 'layout/WithHeader';
import Thread from 'pages/thread';
import SubscriptionsPlans from 'pages/subscriptionsPlans';
import Impersonate from 'pages/impersonate';
import { useEncubePing } from 'modules/encube';
import { usePlayablePing } from 'modules/playable';
import SupportModalContent from 'modules/app/components/SupportModalContent';
import GlobalModalContainer from 'modules/modals/components/GlobalModalContainer';
import ThreadsBot from 'modules/threadsBot/components';
import { EFeatureFlags } from 'constants/features';
import { useFeatures } from 'hooks';
import About from 'pages/about';
import { useIsFeatureEnabled } from 'hooks/useFeatures';
import ModalProvider from './modules/modals/ModalProvider';
import Stream from './modules/stream/Stream';

const AppRouter = () => {
  const isCopilotFeatureEnabled = useIsFeatureEnabled(EFeatureFlags.COPILOT);

  useEncubePing();
  usePlayablePing();
  return (
    <Auth>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <ModalProvider>
          {isCopilotFeatureEnabled && <ThreadsBot />}

          <GlobalModalContainer>
            <SupportModalContent />
          </GlobalModalContainer>

          <WithHeader>
            <Stream>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path={routes.account} element={<Account />} />
                <Route path={routes.members} element={<Members />} />
                <Route path={`${routes.thread}/:id`} element={<Thread />} />
                <Route path={`${routes.thread}/:id/messages/:msgId`} element={<Thread />} />
                <Route path={`${routes.thread}/:id/event/:eventId`} element={<Thread />} />
                <Route path={`${routes.new_thread}`} element={<Dashboard />} />
                <Route path={`${routes.impersonate}`} element={<Impersonate />} />
                <Route
                  path={`${routes.subscriptionsPlans}/dashboard`}
                  element={<SubscriptionsPlans />}
                />
                <Route path={`${routes.about}`} element={<About />} />
              </Routes>
            </Stream>
          </WithHeader>
        </ModalProvider>
      </GoogleOAuthProvider>
    </Auth>
  );
};

export default AppRouter;
