import { api } from 'app/api';
import { IDeviceTokenMutation, IPaginationParams } from 'types';
import { INotificationResponse, INotifications } from './types';

export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<INotifications, IPaginationParams>({
      query: ({ offset, limit }) => `/user-notifications/?offset=${offset}&limit=${limit}`,
      providesTags: (arg) => [{ type: 'notifications', id: arg ? JSON.stringify(arg) : '0' }],
    }),
    markAsRead: build.mutation<INotificationResponse, number>({
      query: (id) => ({
        url: `/user-notifications/${id}/mark-as-read/`,
        method: 'PATCH',
      }),
    }),
    markAllAsRead: build.mutation<void, void>({
      query: () => ({
        url: `/user-notifications/mark-all-as-read/`,
        method: 'PATCH',
      }),
    }),
    sendDeviceToken: build.mutation<void, IDeviceTokenMutation>({
      query: (payload) => ({
        url: `/devices/`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useMarkAsReadMutation,
  useMarkAllAsReadMutation,
  useSendDeviceTokenMutation,
} = notificationApi;
