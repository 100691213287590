import React, { FC } from 'react';
import { AnnotationComment } from './AnnotationComment';
import { AnnotationContentSidebar } from './AnnotationContentSidebar';
import {
  AnnotationCommentList,
  AnnotationCommentListScroller,
  AnnotationContentHolder,
} from './AnnotationLayout';
import { IEncubeComment } from '../types';

interface IAnnotationContentProps {
  /**
   * Unique identifier of the annotation.
   */
  id: string;
  /**
   * Annotation author name.
   */
  authorName: string;
  /**
   * Date of the annotation creation. ISO 8601 format.
   * @example "2021-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * Unique identifier of the file that the annotation is attached to.
   */
  fileId: number;
  /**
   * Name of the file that the annotation is attached to.
   */
  fileName: string;
  /**
   * Version of the file that the annotation is attached to.
   * @example "1.0.0"
   */
  fileVersion: string;
  /**
   * URL of the file that the annotation is attached to.
   */
  fileUrl: string;
  /**
   * List of comments for the annotation.
   */
  comments: IEncubeComment[];
  /**
   * Callback that is called when the refresh button is clicked.
   * @param id - Unique identifier of the annotation.
   */
  onRefreshClick?: (id: string) => void;
}

export const AnnotationContent: FC<IAnnotationContentProps> = (props) => {
  const {
    id,
    authorName,
    createdAt,
    fileId,
    fileName,
    fileVersion,
    fileUrl,
    comments = [],
    onRefreshClick,
  } = props;

  return (
    <AnnotationContentHolder
      fluid
      justify="space-between"
      align="stretch"
      gap="10px"
      data-cy="annotation-content-holder"
    >
      <AnnotationCommentListScroller
        fullHeight
        justify="flex-start"
        data-cy="annotation-comments-list-scroller"
      >
        <AnnotationCommentList
          fluid
          fullHeight
          gap="8px"
          justify="flex-start"
          align="flex-start"
          data-cy="annotation-comments-list"
        >
          {comments.map((comment) => (
            <AnnotationComment
              key={comment.encubeId}
              avatarSrc={comment.creator.avatar as string}
              content={comment.message}
              date={comment.createdAt}
              commentUrl={comment.deeplink}
            />
          ))}
        </AnnotationCommentList>
      </AnnotationCommentListScroller>
      <AnnotationContentSidebar
        author={authorName}
        date={createdAt}
        fileId={fileId}
        fileName={fileName}
        fileVersion={fileVersion}
        id={id}
        fileUrl={fileUrl}
        onRefreshClick={onRefreshClick}
      />
    </AnnotationContentHolder>
  );
};
