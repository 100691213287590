import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { resetAll } from 'app/api';
import { IEncubePing, IEncubeState } from '../types';
import { encubePingApi } from '../api';

const initialState: Omit<IEncubeState, 'isEncubeFunctionalAvailable'> = {
  isIntegrationEnabled: false,
  isEncubeAvailable: false,
};

const encubeSlice = createSlice({
  name: 'encube',
  initialState,
  reducers: {
    updateEncubeInfo: (state, { payload }: PayloadAction<IEncubePing>) => ({
      ...state,
      isIntegrationEnabled: payload.integrationEnabled,
      isEncubeAvailable: payload.ping,
    }),
    resetEncubeInfo: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(
        encubePingApi.endpoints.pingEncube.matchFulfilled,
        (state, { payload }: PayloadAction<IEncubePing>) => ({
          ...state,
          isIntegrationEnabled: payload.integrationEnabled,
          isEncubeAvailable: payload.ping,
        })
      )
      .addMatcher(encubePingApi.endpoints.pingEncube.matchRejected, (state) => ({
        ...state,
        isIntegrationEnabled: false,
        isEncubeAvailable: false,
      })),
});

export const encubeReducer = encubeSlice.reducer;
export const selectEncube = (state: RootState) => state.encube;
export const { updateEncubeInfo, resetEncubeInfo } = encubeSlice.actions;
