import React, { ReactElement } from 'react';
import { selectIsContributor } from 'modules/contributors/contributorsSlice';
import { selectUserInfo } from 'modules/auth/userSlice';
import { selectOwner } from 'modules/threads/threadsSlice';
import { EPermissions } from './constants';
import { useAppSelector } from '../hooks';
import { hasAccess } from './utils';

interface IProps {
  permissions: EPermissions[];
  threadId: string;
  children: ReactElement;
  strict?: boolean;
}

const AccessControl = ({ permissions, threadId = '0', children, strict = true }: IProps) => {
  const userInfo = useAppSelector(selectUserInfo);
  const { id: ownerId } = useAppSelector(selectOwner(threadId));
  const isContributor = useAppSelector(selectIsContributor(userInfo.id));
  const isPermitted = hasAccess(permissions, userInfo, ownerId, isContributor, strict);
  return isPermitted ? children : null;
};

export default AccessControl;
