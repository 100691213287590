import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { SerializedError } from '@reduxjs/toolkit';

export enum EThreadStatuses {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_TARGET = 'ON_TARGET',
  AHEAD_OF_SCHEDULE = 'AHEAD_OF_SCHEDULE',
  AT_RISK = 'AT_RISK',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  STALLED = 'STALLED',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
  ARCHIVE = 'ARCHIVE',
}

export enum EThreadPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum EThreadVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum EThreadParams {
  ID = 'id',
  NAME = 'name',
  STATUS = 'status',
  GROUP = 'group',
  DESCRIPTION = 'description',
  TAGS = 'tags',
  METRICS = 'metrics',
  CREATOR = 'creator',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PRIORITY = 'priority',
  LAST_ACTIVITY = 'lastActivity',
  IS_PRIVATE = 'isPrivate',
  PENDING_INVITES = 'pendingInvites',
}

export enum EInviteUserStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export type TReactSelectOption = {
  label: any;
  value: string;
  user?: string;
  __isNew__?: boolean;
};

export interface IId {
  id: string | number;
}

export interface IPaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface ISuccessResult<T> {
  data: T;
}

export interface IError {
  error: FetchBaseQueryError;
}

export type TErrorData = {
  error: {
    error_type: string;
    message: string;
    status_code: string | number;
  };
};

export interface ISerializeError {
  error: SerializedError;
}

export interface IErrorMessages {
  [key: string]: string[];
}

export interface IModalDefaultProps {
  closeModal: () => void;
}

export interface IThreadMOdalsDefaultProps {
  threadId: string;
  threadName?: string;
}

export interface IPaginationParams {
  limit: number;
  offset: number;
}

declare global {
  interface Window {
    _env_: any;
  }
}
export interface IDeviceTokenMutation {
  registrationId: string;
  type: 'web';
}

/**
 * Reusable thunk interface.
 */
export interface IPayloadThunk<T> {
  payload: T;
}

export type TFeatureFlag = {
  name: string;
  description: string;
  isEnabled: boolean;
};
