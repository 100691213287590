import React, { FC, ReactElement, useEffect } from 'react';
import { useAppSelector } from 'app/hooks';
import routes from 'constants/routes';
import _ from 'lodash';
import TermsOfService from 'pages/termsOfService';
import { useNavigate } from 'react-router-dom';
import { ScreenLoader } from 'ui';
import { useGetUserInfoQuery } from '../user/userApi';
import { selectUser } from './userSlice';

interface IProps {
  children: ReactElement;
}

const Auth: FC<IProps> = ({ children }: IProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, userInfo, redirectUrl } = useAppSelector(selectUser);
  const hasAcceptedTermsAndConditions = !!userInfo.termsAndConditions;

  const { error, isLoading } = useGetUserInfoQuery();

  useEffect(() => {
    const caughtErrorStatuses = ['FETCH_ERROR', 401];

    if (error && 'status' in error) {
      if (caughtErrorStatuses.includes(error.status)) {
        navigate(routes.login);
      }
    }

    if (!isAuthenticated) navigate(routes.login);
  }, [isAuthenticated, error]);

  if (isLoading) {
    return <ScreenLoader />;
  }
  // redirect user to stripe checkout page to pay for his subscription
  if (redirectUrl) {
    window.open(redirectUrl, '_self', 'noopener noreferrer');
  }

  if (isAuthenticated && !hasAcceptedTermsAndConditions) {
    return <TermsOfService />;
  }

  return children;
};

export default Auth;
