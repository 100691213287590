import React, { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { EEventStatus, EEvents } from 'constants/events';
import _isEmpty from 'lodash/isEmpty';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { ECopilotSuggestionTypes, ICopilotSuggestion } from 'modules/copilot/types';
import { determineEditModalData, handleCreateOrUpdateEvent } from 'modules/copilot/utils';
import { useCreateEventMutation, useEditEventMutation } from 'modules/events/eventsApi';
import { selectAllEventsById } from 'modules/events/eventsSlice';
import { IEventResponse } from 'modules/events/types';
import { useModal } from 'modules/modals/ModalProvider';
import { pushAppEvent } from 'modules/app/appSlice';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { Group, Icon, Text } from 'ui';
import { handleApiCall } from 'utils/helpers';
import { EAppEventTypes } from 'modules/app/constants';
import CopilotSuggestionMessageContent from '../CopilotMessageContent';
import {
  CopilotAction,
  CopilotActionsContainer,
  CopilotMessageContainer,
  CopilotMessageContent,
  CopilotSuggestionPaginationContainer,
} from './Layout';

interface CopilotSuggestionProps {
  dismissSuggestion: () => void;
  suggestions: ICopilotSuggestion[];
}

const CopilotSuggestion: React.FC<CopilotSuggestionProps> = ({
  suggestions,
  dismissSuggestion,
}) => {
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const currentCopilotSuggestion = suggestions[selectedSuggestionIndex];

  // Guard the destructuring below to prevent breakage if current suggestion is falsy.
  if (!currentCopilotSuggestion) {
    return null;
  }

  const { due_date, status, description, type, name, event_uuid, suggestion_type } =
    currentCopilotSuggestion;

  const dispatch = useDispatch();
  const { id } = useParams();
  const { open } = useModal();
  const [createEvent] = useCreateEventMutation();
  const [editEvent] = useEditEventMutation();
  const eventsById = useAppSelector(selectAllEventsById);

  const message = () => (
    <CopilotSuggestionMessageContent
      copilotSuggestion={currentCopilotSuggestion}
      eventsById={eventsById}
    />
  );

  const handleEditBeforeSavingCopilotSuggestion = async () => {
    if (!id) return;
    const thread = parseInt(id, 10);
    if (Number.isNaN(thread)) return;

    const modalData = determineEditModalData(suggestion_type);

    open({
      variant: 'fullRight',
      contentLabel: 'events',
      name: modalData.name,
      modal: modalData.modal,
      context: {
        // Not passing in `eventUuid`, as its values are force-set to current values.
        threadId: id,
        name,
        description,
        type: type as EEvents,
        status: (status as EEventStatus) ?? EEventStatus.NOT_STARTED,
        assignees: [],
        dueDate: due_date,
        copilotSuggestion: currentCopilotSuggestion,
      },
      id: 'modal-create-event',
    });
    dismissSuggestion();
  };

  const handleAcceptCopilotSuggestion = () => {
    if (!id) return;
    const thread = parseInt(id, 10);
    if (Number.isNaN(thread)) return;

    const eventPayload = {
      type: type as EEvents,
      name,
      description,
      thread,
      status: (status as EEventStatus) ?? EEventStatus.NOT_STARTED,
      assignees: [],
      dueDate: due_date ?? new Date(),
      relatedEvent: null,
      relatedThread: null,
    };

    switch (suggestion_type) {
      case ECopilotSuggestionTypes.CREATE:
        handleCreateOrUpdateEvent(createEvent(eventPayload), () => {
          dispatch(
            pushAppEvent({
              type: EAppEventTypes.EVENT_CREATED,
              appEventPayload: eventPayload,
            })
          );
          dismissSuggestion();
        });
        break;
      case ECopilotSuggestionTypes.MODIFY:
        if (event_uuid) {
          handleCreateOrUpdateEvent(editEvent({ id: Number(event_uuid), ...eventPayload }), () => {
            dispatch(
              pushAppEvent({
                type: EAppEventTypes.EVENT_CREATED,
                appEventPayload: eventPayload,
              })
            );
            dismissSuggestion();
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <CopilotMessageContainer>
      <CopilotMessageContent justify="space-between" align="center" gap="10px">
        <Group align="center">
          <Text style={{ lineHeight: 1.2 }}>{message()}</Text>
        </Group>
        {suggestions && (
          <CopilotSuggestionPaginationContainer id="copilotSuggestionsPaginationContainer">
            {selectedSuggestionIndex + 1 > 1 && (
              <FaChevronLeft
                onClick={() => setSelectedSuggestionIndex((previous) => previous - 1)}
                role="button"
                color={colors.gray}
                className="spacer-right"
                size={12}
              />
            )}
            <Text size="xs" color={colors.gray}>
              Response {selectedSuggestionIndex + 1} of {suggestions.length}
            </Text>
            {selectedSuggestionIndex + 1 < suggestions.length && (
              <FaChevronRight
                onClick={() => setSelectedSuggestionIndex((previous) => previous + 1)}
                role="button"
                color={colors.gray}
                className="spacer-left"
                size={12}
              />
            )}
          </CopilotSuggestionPaginationContainer>
        )}
      </CopilotMessageContent>
      <CopilotActionsContainer>
        <CopilotAction onClick={handleAcceptCopilotSuggestion}>
          Accept
          <Icon icon="CheckIcon" size="small" stroke="white" fill="white" />
        </CopilotAction>
        <CopilotAction onClick={handleEditBeforeSavingCopilotSuggestion}>
          Edit
          <Icon icon="PencilIcon" size="small" stroke="white" />
        </CopilotAction>
        <CopilotAction onClick={dismissSuggestion}>
          Dismiss
          <Icon icon="CloseIcon" size="small" path="white" />
        </CopilotAction>
      </CopilotActionsContainer>
    </CopilotMessageContainer>
  );
};

export default CopilotSuggestion;
