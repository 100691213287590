import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectThread } from 'modules/threads/threadsSlice';
import { IThreadsResult, IReferences } from 'modules/threads/types';
import * as Layout from 'pages/thread/components/Layout';
import ReferenceCard from './components/ReferenceCard';

const ReferenceList: React.FC = () => {
  const { id } = useParams<string>();
  const { references }: IThreadsResult = useAppSelector(selectThread(id as string));

  if (!references.length) {
    return <Layout.NoItems>No References...</Layout.NoItems>;
  }

  return (
    <Layout.TabContentWrap>
      {references.map((reference: IReferences) => (
        <ReferenceCard key={reference.id} reference={reference} />
      ))}
    </Layout.TabContentWrap>
  );
};

export default ReferenceList;
